import React, { FC } from 'react';
import { BiTrash } from 'react-icons/bi';
import CustomButton from 'src/components/CustomButton';
import { ImageBoxViewProps } from './Image.interface';
import { Edit02 } from '@untitled-ui/icons-react/build/cjs';
import { onError } from 'src/utils/global-functions';
import { FaCheck } from 'react-icons/fa6';
import { RxCross2 } from 'react-icons/rx';

const ImageBoxView: FC<ImageBoxViewProps> = ({ item, onChangeCrop, handleRemoveImage, name, isEdit = true, className, isPrimary = false, handleEditImage }) => {
    const random = Math.random();
    return (
        <div className={`flex items-center justify-center w-[109px] h-[109px] xl:w-[120px] xl:h-[120px] xxl:w-[150px] xxl:h-[150px] relative rounded-lg shadow-InputAndButton ${className}`}>
            <img src={item.url} className="w-full h-full object-cover rounded-lg" alt="" title={item.name} onError={onError} />
            {isEdit && <input type="file" name={name} id={`${name}_image_name_edit${random}`} className="w-full h-full absolute inset-0 opacity-0 cursor-pointer" onChange={onChangeCrop(name)} />}
            <div className="absolute inset-0 flex items-center justify-center gap-[10px] bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-all rounded-lg">
                {isEdit && (
                    <label htmlFor={`${name}_image_name_edit${random}`} className="cursor-pointer flex items-center justify-center font-medium text-white w-10 h-10 rounded-lg bg-white">
                        {''}
                        <Edit02 className="w-5 text-gray-700" />
                    </label>
                )}
                {isPrimary && (
                    <>
                        <CustomButton
                            type="button"
                            className="outline-none  text-gray-700 w-10 !px-2 !min-w-10 max-w-10  h-10 rounded-lg bg-white cursor-pointer"
                            onClick={handleEditImage}
                            icon={<FaCheck size="20" className="w-5 text-gray-700" />}
                        />

                        <CustomButton
                            type="button"
                            className="outline-none  text-white w-10 !px-2 !min-w-10 max-w-10 h-10 rounded-lg bg-error-600"
                            onClick={handleRemoveImage}
                            icon={<RxCross2 size="20" />}
                        />
                    </>
                )}
                {!isPrimary && (
                    <CustomButton
                        type="button"
                        className="outline-none !px-2 text-white w-10 !min-w-10 max-w-10 h-10 rounded-lg bg-error-600"
                        onClick={handleRemoveImage}
                        icon={<BiTrash size="20" />}
                    />
                )}
            </div>
        </div>
    );
};
export default ImageBoxView;
