import React, { useEffect, useMemo, useState } from 'react';

import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Button from 'src/components/Button';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import Add from './AdminForm';
import WorkingLocation from './WorkingLocation';
import AdminPopup from './AdminPopup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from '../Auth/Login/Login.slice';
import AdminCard from './AdminCard';
import { getSelectBoxOptionsIdAsValue } from 'src/utils/global-functions';
import { IAdminDetails, IAdminFormData, ISelectedLocation, IWorkingLocation } from './Admin.interface';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { adminList, createAdmin } from './Admin.slice';
import AdminDetails from './AdminDetails/AdminDetails';
import { mapLocationPin } from 'src/theme/Images';
import { allShopLocations } from 'src/redux/services/common/Common.slice';

const Admin = () => {
    const defaultFormData: IAdminFormData = {
        first_name: '',
        last_name: '',
        phone: '',
        phone_country_code: 'US',
        email: '',
        role_type: 'admin',
        shop_location_id: [],
    };
    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: 'Admin details',
                step: 1,
            },
            {
                name: 'Working location',
                step: 2,
            },
        ],
        [],
    );
    const [adminPopup, setAdminPopup] = useState(false);
    const [step, setStep] = useState<number | ICountStep>(1);
    const [formData, setFormData] = useState<IAdminFormData | null>(defaultFormData);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedAdminId, setSelectedAdminId] = useState<number | null>(null);
    const [adminListArray, setAdminListArray] = useState([]);
    const [adminFilteredListArray, setAdminFilteredListArray] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [locationDataDetails, setLocationDataDetails] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [customError, setCustomError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation | null>(null);
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    const shopLocationList: any = useAppSelector(allShopLocations);

    const dispatch = useAppDispatch();
    type FieldKey = 'first_name' | 'last_name' | 'phone' | 'phone_country_code' | 'role_type' | 'shop_location_id';

    const validationSchema = [
        //validation for step1
        Yup.object({
            first_name: Yup.string().required('This field is required'),
            last_name: Yup.string().required('This field is required'),
            phone: Yup.string().required('This field is required'),
            phone_country_code: Yup.string().required('This field is required'),
            email: Yup.string().email('Please enter valid email address').required('This field is required'),
            role_type: Yup.string().required('This field is required'),
        }).required(),
        //validation for step2
        Yup.object({
            shop_location_id: Yup.array().min(1, 'This field is required'),
        }),
    ];
    const currentValidationSchema = validationSchema[step - 1] as Yup.ObjectSchema<IAdminDetails | IWorkingLocation, Record<string, any>, any, ''>;

    const defaultValues = {
        first_name: '',
        last_name: '',
        phone: '',
        phone_country_code: 'US',
        email: '',
        role_type: 'admin',
        shop_location_id: [],
    };
    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(currentValidationSchema),
        mode: 'onChange',
    });
    const { handleSubmit, reset, trigger, setError } = methods;

    const handleDrawer =
        (open: boolean, admin_id: number | null = null) =>
        () => {
            setIsDrawerOpen(open);
            setSelectedAdminId(admin_id);
        };

    const handleCloseAdminPopup = (value: boolean) => {
        setAdminPopup(value);
        handleReset();
    };

    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) setStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleReset = () => {
        setStep(1);
        reset();
    };

    const getAdmin = async () => {
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(adminList(data));
        if (result.type === adminList.fulfilled.toString()) {
            setAdminListArray(result.payload.data);
            setAdminFilteredListArray(result.payload.data);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const locationData: any = getSelectBoxOptionsIdAsValue(shopLocationList);
        setLocationOptions(locationData);
        setSelectedLocation(locationData[0]);
        setLocationDataDetails(shopLocationList);
    }, []);
    const handleSearchFilter = () => {
        const filteredData = adminListArray.filter((item: any) => {
            const searchLowerCase = searchString.toLowerCase();
            const firstNameMatch = item.first_name.toLowerCase().includes(searchLowerCase);
            const lastNameMatch = item.last_name.toLowerCase().includes(searchLowerCase);
            const phoneMatch = item.phone.includes(searchString);

            return firstNameMatch || lastNameMatch || phoneMatch;
        });
        const filteredLocationData = filteredData.filter((item: any) => {
            const locationNameMatches = item.locations.some((location: any) => location.id === selectedLocation?.value);
            return locationNameMatches;
        });

        setAdminFilteredListArray(filteredLocationData);
    };

    useEffect(() => handleSearchFilter(), [searchString, selectedLocation, adminListArray]);
    useEffect(() => {
        getAdmin();
    }, []);

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const submitData = {
            payload: data,
            shop_id: shopId,
        };

        const result = await dispatch(createAdmin(submitData));
        if (result.type === createAdmin.fulfilled.toString()) {
            handleCloseAdminPopup(false);
            getAdmin();
        }

        if (result.type === createAdmin.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        const conditions = [['first_name', 'last_name', 'phone', 'phone_country_code', 'role_type'], ['shop_location_id']];

                        const pageNumber = conditions.findIndex((fields) => fields.every((field1) => Object.keys(response.data).includes(field1))) + 1;

                        setStep(pageNumber + 1);
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                        if (field === 'phone') setCustomError(response.data[field][0]);
                    });
                }
                setIsLoading(false);
                return;
            }
            toast.error(response.message);
        }
    };

    const adminAddCardContent = (
        <div className=" h-full flex flex-col justify-center">
            <div className="text-center">
                <p className="mb-5 text-sm font-normal text-secondaryTxtColor leading-[140%] -tracking-[0.3px]">Click to add new admin member</p>
                <Button className="btn_outline_primary max-w-[140px]" onClick={() => handleCloseAdminPopup(true)}>
                    + Add admin
                </Button>
            </div>
        </div>
    );

    const renderStepComponent = () => {
        if (step === 1) {
            return <Add />;
        } else {
            return <WorkingLocation setStep={setStep} locationDataDetails={locationDataDetails} onClick={handleSubmit(onSubmit)} isLoading={isLoading} />;
        }
    };

    return (
        <>
            <div className="inner-page-wrape">
                <div className="flex items-center">
                    <span className="ml-[18px] text-secondaryTxtColor text-base font-medium -tracking-[0.16px]">&bull; ({adminFilteredListArray.length} member)</span>
                </div>
                <div className="w-full flex-1 rounded-2xl bg-white p-6 mt-6 border-[1px] border-lineColor relative">
                    <div className="flex justify-between mb-6">
                        <SearchBar
                            placeholder="Search by name or mobile phone"
                            className=""
                            onChange={(e: any) => {
                                setSearchString(e.target.value);
                            }}
                        />
                        <div className="w-[193px] ml-6">
                            <SelectBox
                                name="territory"
                                id="handlingUnit"
                                options={locationOptions}
                                classComp="outline-select-box"
                                value={selectedLocation}
                                onChangeFunc={(e: any) => {
                                    setSelectedLocation(e);
                                }}
                                openOnFocus={true}
                                autofocus={true}
                                allowIcon={<img src={mapLocationPin} alt="" />}
                            />
                        </div>
                    </div>
                    <div className="staff-card-row -m-3  flex flex-wrap ">
                        {adminFilteredListArray.length > 0 && (
                            <>
                                {adminFilteredListArray.map((adminDetails: any) => (
                                    <div className="staff-card-col p-3 w-2/6 xxl:w-1/4" key={adminDetails.id}>
                                        {/* <Card content={<AdminCard adminDetails={adminDetails} />} onClick={handleDrawer(true, adminDetails.id)} className="rounded-3xl bg-white py-5 cursor-pointer" /> */}
                                    </div>
                                ))}
                            </>
                        )}
                        <div className={`staff-card-col p-3 w-2/6 xxl:w-1/4 min-h-[200px] ${isLoading ? 'hidden' : ''}`}>
                            {/* <Card content={adminAddCardContent} className="rounded-3xl bg-white p-5 drop-shadow-none border-dashed border-primary h-full" /> */}
                        </div>
                    </div>
                </div>
            </div>
            {isDrawerOpen && (
                <AdminDetails
                    handleDrawer={() => {
                        setIsDrawerOpen(false);
                        setSelectedAdminId(null);
                    }}
                    isDrawerOpen={isDrawerOpen}
                    adminId={selectedAdminId}
                    getAdminList={getAdmin}
                />
            )}
            {adminPopup && (
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <AdminPopup handleCloseAdminPopup={() => handleCloseAdminPopup(false)} renderStepComponent={renderStepComponent} step={step} STEPS={STEPS} />
                    </form>
                </FormProvider>
            )}
        </>
    );
};

export default Admin;
