import { ImagePlus, UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';

interface IProps {
    cropData: any;
    onChangeCrop: any;
    id: string;
    error?: any;
    isIcon?: boolean;
    disabled?: boolean;
    shape?: 'circle' | 'square' | 'rectangle';
}
const ImageUpload = ({ cropData, error, id, onChangeCrop, isIcon = false, shape = 'circle', disabled = false }: IProps) => {
    const [isImageError, setIsImageError] = useState<boolean>(false);

    useEffect(() => {
        setIsImageError(false);
    }, [cropData]);

    const handleImageError = () => setIsImageError(true);

    return (
        <>
            <div className={`flex flex-row relative gap-4  w-full ${isIcon ? 'items-start' : 'items-center'}`}>
                <div className={`h-14 flex relative min-w-14 justify-center items-center ${shape === 'rectangle' ? 'w-25' : 'w-14 '}"`}>
                    {cropData && !isImageError ? (
                        <img
                            src={cropData}
                            alt=""
                            className={`h-14 border-[0.75px] border-[#F2F4F7] relative object-cover ${shape === 'rectangle' ? 'w-25' : 'w-14 '} ${shape === 'circle' ? 'rounded-full' : 'rounded-md'}`}
                            onError={handleImageError}
                        />
                    ) : (
                        <div
                            className={`w-14 h-14 border border-black/[0.08] ${
                                shape === 'circle' ? 'rounded-full' : 'rounded-md'
                            } object-cover flex justify-center items-center bg-[#f2f4f7] text-[#667085]`}
                        >
                            <ImagePlus className="w-6 h-6" />
                        </div>
                    )}
                </div>

                {/* <img src={cropData || defaultImage} alt="avatar" className="w-14 h-14 border-[0.75px] border-[#F2F4F7] rounded-full object-contain" /> */}
                <div
                    className={`   z-[1] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl border  border-borderSecondary hover:shadow py-3.5 px-5 text-center bg-white ${
                        error?.message ? 'is-invalid' : ''
                    }`}
                >
                    <div className="relative overflow-hidden cursor-pointer w-full">
                        <input type="file" id={id} className="upload-pic-input" onChange={onChangeCrop} accept="image/png, image/jpg, image/jpeg" disabled={disabled} />
                        <label htmlFor={id} className=" cursor-pointer text-center ">
                            {''}
                            {isIcon && (
                                <div className="mx-auto mb-3 w-9 h-9 border border-[#EAECF0] bg-white shadow rounded-lg flex items-center justify-center">
                                    <UploadCloud02 width={20} height={20} />
                                </div>
                            )}

                            <div className=" text-secondaryTxtColor text-xs font-normal cursor-pointer">
                                <p>
                                    <span className="text-primary font-semibold pr-1">Click to upload </span>or drag and drop
                                </p>
                                <p className="text-[11px] leading-[18px]"> PNG or JPG</p>
                            </div>
                        </label>
                    </div>
                </div>
                {/* {cropData && (
                <div
                    className={`absolute  w-14 h-14  bg-black flex justify-center items-center bg-opacity-50 opacity-0 hover:opacity-100 transition-all ${
                        shape === 'circle' ? 'rounded-full' : 'rounded-md'
                    }`}
                >
                    <CustomButton
                        type="button"
                        className="outline-none  text-white min-w-4 w-4 min-h-4 h-4 rounded-full bg-error-600 p-1 !px-0 !py-0 !flex !justify-center !items-center"
                        onClick={() => {}}
                        icon={<X className="w-4 h-4 text-white" />}
                    />
                </div>
            )} */}
            </div>
            {error?.type !== 'required' && error?.type !== 'nullable' && error?.message && <p className="text-error-500 text-sm">{error.message}</p>}
        </>
    );
};

export default ImageUpload;
