import React, { useState } from 'react';
import { defaultImage } from 'src/theme/Images';
import { GoDotFill } from 'react-icons/go';
import CustomButton from 'src/components/CustomButton';
import moment from 'moment';
import { currentShopMe } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { IDataRequest } from '../Signup/Invitation/Invitation.interface';
import { onError } from 'src/utils/global-functions';

const NotificationGeneral = ({ notificationRecord, isLoading = false, viewDetail, checkNotificationStatus, isMarkingAsRead }: any) => {
    const currentUser = useAppSelector(currentShopMe);
    const [isDecliningInvitation, setIsDecliningInvitation] = useState(false);

    const handleDeclineInvitation = (status: boolean) => async (e: React.MouseEvent) => {
        e.stopPropagation();
        checkNotificationStatus('single', notificationRecord)();
        setIsDecliningInvitation(true);
        const cread: IDataRequest = {
            shop_id: currentUser.pay_structure.shop_id,
            status: status,
        };
        await axiosPatch(API.USER.INVITATION, cread, { shop_id: cread.shop_id })
            .then(async (response) => {
                setIsDecliningInvitation(false);
            })
            .finally(() => setIsDecliningInvitation(false));
    };

    const handleAcceptInvitation = (e: React.MouseEvent) => {
        e.stopPropagation();
        checkNotificationStatus('single', notificationRecord)();
        viewDetail(notificationRecord)();
    };
    return (
        <div onClick={viewDetail(notificationRecord)} className={`flex flex-row   px-6  w-full ${notificationRecord.is_read && !isLoading ? 'opacity-[50%]' : 'cursor-pointer hover:bg-[#F9FAFC]'} `}>
            <div className="border-b gap-3 border-borderSecondary relative flex flex-row py-5 w-full">
                <div className={`${isLoading ? 'custom-loading w-[40px] h-[40px]' : ''}`}>
                    <img
                        src={notificationRecord.shop && notificationRecord.shop.admin_logo_image_url ? notificationRecord.shop.admin_logo_image_url : defaultImage}
                        alt=""
                        className="w-10 h-10 min-w-10 min-h-10 rounded-[6px] object-cover"
                        onError={onError}
                    ></img>
                </div>
                <div className="flex flex-col gap-[3px]">
                    <div className="text-sm font-medium text-mainTextColor flex flex-row gap-1.5">
                        {isLoading ? (
                            <span className={`${isLoading ? 'custom-loading max-w-[250px] w-[250px] min-w-[250px]' : ''}`}>loading</span>
                        ) : (
                            <>
                                {notificationRecord.name}
                                <span className="text-sm font-normal text-secondaryTxtColor"> invited you</span>
                            </>
                        )}
                    </div>
                    <div className="text-xs font-medium text-[#475467] flex  items-center">
                        {isLoading ? (
                            <span className={`${isLoading ? 'custom-loading max-w-[350px] w-[350px] min-w-[350px]' : ''}`}>loading</span>
                        ) : (
                            <>
                                {notificationRecord.status === 'staff_invite' ? 'Invitation' : ''}
                                <GoDotFill size={8} color="#667085" className="h-2.5 w-2.5 rounded-full flex mx-1 mt-[1px]" />
                                <span className="text-xs font-normal text-secondaryTxtColor">{moment(notificationRecord.created_at).fromNow()}</span>
                            </>
                        )}
                    </div>
                    {!isLoading && !notificationRecord.is_read && (
                        <div className="flex items-center gap-2 mt-[3px]">
                            <CustomButton
                                isLoading={isDecliningInvitation || isMarkingAsRead}
                                disabled={isDecliningInvitation || isMarkingAsRead}
                                onClick={handleDeclineInvitation(false)}
                                secondary
                                className="!h-[26px] !rounded-md !py-1 !px-[14px]"
                            >
                                Decline
                            </CustomButton>
                            <CustomButton onClick={handleAcceptInvitation} isLoading={isMarkingAsRead} disabled={isMarkingAsRead} primary className="!h-[26px] !rounded-md !py-1 !px-[14px]">
                                Accept
                            </CustomButton>
                        </div>
                    )}
                </div>
            </div>
            {!notificationRecord.is_read && (
                <div className="absolute top-[5px] right-[5px]">
                    <GoDotFill size={16} color="#17B26A" className="h-4 w-4 rounded-full flex mr-0.5" />
                </div>
            )}
        </div>
    );
};

export default NotificationGeneral;
