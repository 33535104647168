import React, { useEffect, useMemo, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'src/components/NoDataMessage';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, currentShop } from 'src/redux/services/common/Common.slice';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { ArrowUp, ArrowDown, Plus } from '@untitled-ui/icons-react/build/cjs';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useParams } from 'react-router-dom';
import CustomButton from 'src/components/CustomButton';
import { AllLocationsOptions } from 'src/utils/global-variables';

const CurrentInventory = ({ product, setCurrentTab }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const shopLocationList = useAppSelector(allShopLocations);
    const [isLoading, setIsLoading] = useState(false);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [Inventory, setInventory] = useState([]);
    const [totalInventories, setTotalInventories] = useState<number>(0);
    const [selectedFilter, setSelectedFilter] = useState({
        shop_location_id: null,
    });
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            shop_location_id: { value: null },
        },
    });

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);
    }, []);

    const listInventories = async () => {
        setIsLoading(true);
        await axiosGet(API.PRODUCT.VARIANT, { shop_id: shop.id, id }, lazyState)
            .then((response) => {
                setInventory(response.data.data.data);
                setTotalInventories(response.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        listInventories();
    }, [lazyState, shop]);

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const getLocation = (row: any) => row.location.name;
    const getUnit = (row: any) => `${row.size} ${product.unit_of_measure}`;
    const getTotalSold = (row: any) => `${row.total_booking_quantity}`;
    const getAmount = (row: any) => `$${row.total_amount}`;
    const getNetAmount = (row: any) => `$${row.total_net_amount}`;
    const getAction = (row: any) => (
        <div className="table-arrow-btn">
            <CustomButton secondary onClick={() => setCurrentTab('inventory')} className="!px-1 !py-0 !h-[24px]">
                <Plus className="h-4 w-4 text-gray-600" />
            </CustomButton>
        </div>
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                user: 'loading',
                date: 'loading',
                duration: 'loading',
                service: 'loading',
                price: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );
    return (
        <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow  datatable-custom">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div>
                    <h2 className="view-table-title">{t('Current Inventory')}</h2>
                    <p className="view-table-subtitle">{t('List and manage the inventory of the product.')}</p>
                </div>
                <div className="flex gap-4">
                    <div className="table-searchInput">
                        <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                    </div>
                    <div className="w-[200px] location-dropdown">
                        <SelectBox
                            options={locationOptions}
                            value={locationOptions.find((item: any) => item.value === selectedFilter.shop_location_id)}
                            noOptionsMessage="No Locations Found"
                            placeholder={t('All locations')}
                            onChangeFunc={handleFilter('shop_location_id')}
                            classComp=""
                            isClearable={false}
                            isSearchable={false}
                        />
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                value={isLoading ? skeletons : Inventory}
                totalRecords={totalInventories}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className="rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                        <NoDataMessage title="Current Inventory" description="Inventory not available." />
                    </div>
                }
            >
                <Column
                    field="size"
                    header={renderHeader(t('Unit'), 'size')}
                    body={isLoading ? <Skeleton /> : getUnit}
                    style={{ minWidth: '80px', maxWidth: '80px', width: '80px' }}
                    className="text-xs text-gray-600 font-normal truncate"
                    sortable
                ></Column>
                <Column
                    field="shop_locations.name"
                    header={renderHeader(t('Locations'), 'shop_locations.name')}
                    style={{ minWidth: '120px', maxWidth: '120px', width: '120px' }}
                    body={isLoading ? <Skeleton /> : getLocation}
                    className="text-xs text-gray-600 font-normal truncate"
                    sortable
                ></Column>
                <Column
                    field="total_booking_quantity"
                    header={renderHeader(t('Total Sold'), 'total_booking_quantity')}
                    style={{ minWidth: '110px', maxWidth: '110px', width: '110px' }}
                    body={isLoading ? <Skeleton /> : getTotalSold}
                    className="text-xs text-gray-600 font-medium truncate"
                    sortable
                ></Column>
                <Column
                    field="total_amount"
                    header={renderHeader(t('Total Revenue'), 'total_amount')}
                    style={{ minWidth: '140px', maxWidth: '140px', width: '140px' }}
                    body={isLoading ? <Skeleton /> : getAmount}
                    className="text-xs font-medium text-tableText truncate"
                    sortable
                ></Column>
                <Column
                    field="total_net_amount"
                    header={renderHeader(t('Net Revenue'), 'total_net_amount')}
                    style={{ minWidth: '130px', maxWidth: '130px', width: '130px' }}
                    body={isLoading ? <Skeleton /> : getNetAmount}
                    className="text-xs text-gray-600 font-normal truncate"
                    sortable
                ></Column>
                <Column
                    field="in_stock_quantity"
                    header={renderHeader(t('In Stock'), 'in_stock_quantity')}
                    style={{ minWidth: '100px', maxWidth: '100px', width: '100px' }}
                    body={isLoading ? <Skeleton /> : undefined}
                    className="text-xs font-medium text-tableText truncate"
                    sortable
                ></Column>
                <Column
                    field="action"
                    header={'Action'}
                    body={isLoading ? <Skeleton /> : getAction}
                    style={{ minWidth: '70px', maxWidth: '70px', width: '70px' }}
                    className="text-xs font-medium text-tableText"
                ></Column>
            </DataTable>
        </div>
    );
};

export default CurrentInventory;
