import React, { Fragment, memo, useEffect, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'src/redux/hooks';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import Loader from 'src/components/Loader/Loader';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { selectCalendarData } from '../../Calendar.slice';
import { IProduct } from './Product.interface';
import { Plus, X } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';

const Product = () => {
    const shop = useAppSelector(currentShop);
    const calendarData = useAppSelector(selectCalendarData);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [allProducts, setAllProducts] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [productOptionShow, setProductOptionShow] = useState<boolean>(false);
    useEffect(() => {
        const shouldHideServiceOptions = calendarData.calendarStep.updateAppointment || calendarData.calendarStep.rescheduleAppointment;
        setProductOptionShow(!shouldHideServiceOptions);
    }, [calendarData.calendarStep]);
    const { setValue } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        keyName: 'uuid',
        name: 'products',
    });

    useEffect(() => {
        if (allProducts.length) {
            let aggregatedProducts = fields.reduce((acc: any, item: any) => {
                const id = item.variantInfo.inventory.id;
                if (acc[id]) {
                    acc[id].quantity += 1;
                } else {
                    acc[id] = {
                        ...item,
                        id,
                        quantity: 1,
                    };
                }
                return acc;
            }, {});
            aggregatedProducts = Object.values(aggregatedProducts);

            const variants = allProducts.reduce((variantsAcc: any[], variant: any) => {
                const inventories = variant.variantInfo.inventories.reduce((inventoriesAcc: any[], inventory: any) => {
                    const aggregatedProduct = aggregatedProducts.find((item: any) => item.id === inventory.id);
                    const quantity = inventory.in_stock_quantity - (aggregatedProduct ? aggregatedProduct.quantity : 0);

                    if (quantity > 0) {
                        inventoriesAcc.push({ ...inventory, in_stock_quantity: quantity });
                    }
                    return inventoriesAcc;
                }, []);

                const isOutOfStock = inventories.length <= 0;
                variantsAcc.push({
                    ...variant,
                    rightLabel: isOutOfStock ? 'Out of stock' : `$${inventories[0].price}`,
                    variantInfo: { ...variant.variantInfo, inventories, inventory: isOutOfStock ? null : inventories[0] },
                });
                return variantsAcc;
            }, []);

            setProducts(variants);
        }
    }, [allProducts, fields]);

    const mergeVariants = async (Allproducts: any[]) =>
        Allproducts.reduce(
            (
                acc: string | any[],
                product: {
                    unit_of_measure: any;
                    name: any;
                    variants: any;
                },
            ) => {
                const updatedVariants = product.variants.map((variant: any) => ({
                    ...product,
                    value: variant.inventory.id, // Assuming product has a variant_type attribute
                    label: `${product.name.slice(0, 10)}${product.name.length > 10 ? '...' : ''} (${variant.size} ${product.unit_of_measure})`,
                    rightLabel: variant.inventory.price,
                    variantInfo: { ...variant, value: variant.inventory.id },
                }));
                return acc.concat(updatedVariants);
            },
            [],
        );

    const handleChangeProduct = (value: any) => {
        const selectedProduct = products.find((product) => product.value === value.value);
        setProductOptionShow(false);
        append(selectedProduct);
    };
    const getProducts = () => {
        const params = {
            shop_id: shop.id,
            id: calendarData.selectedLocation?.id,
        };
        const payload = {
            booking_id: calendarData.calendarStep.updateAppointment ? calendarData.bookedSlotInfo.id : null,
        };
        axiosGet(API.PRODUCT.LOCATION_PRODUCT, params, payload)
            .then(async (response) => {
                const result = response.data.data;
                const mergedVariants = await mergeVariants(result);
                setAllProducts(mergedVariants);
            })
            .finally(() => setIsLoading(false));
    };
    useEffect(() => {
        setIsLoading(true);
        getProducts();
    }, [calendarData.selectedLocation]);
    useEffect(() => {
        if (fields.length === 0) {
            setProductOptionShow(true);
        }
    }, [fields]);

    const handleRemoveProduct = (index: any) => async () => {
        remove(index);
        const updatedFields = fields.filter((_, i) => i !== index);
        setValue('products', updatedFields);
    };
    const handleIsOptionDisabled = (option: any) => option.variantInfo.inventories.length <= 0;
    return (
        <>
            {!calendarData.isLoading ? (
                <>
                    <div
                        className={`${
                            calendarData.calendarStep.newAppointment || calendarData.calendarStep.updateAppointment || calendarData.calendarStep.rescheduleAppointment
                                ? 'mb-4'
                                : fields.length > 0
                                ? 'pb-3 mb-3 border-borderSecondary border-b'
                                : ''
                        }`}
                    >
                        <div className="flex justify-between">
                            {((calendarData.calendarStep.updateAppointment && calendarData.bookedSlotInfo?.booking_transaction?.length === 0) ||
                                calendarData.calendarStep.rescheduleAppointment ||
                                calendarData.calendarStep.newAppointment ||
                                (calendarData.calendarStep.bookedAppointment && calendarData.bookedSlotInfo?.booking_products?.length > 0)) && (
                                <label htmlFor="newclient" className="fl-field-title-label text-gray-700 normal-case text-xs font-semibold leading-[18px] mb-1.5">
                                    {calendarData.calendarStep.newAppointment || calendarData.calendarStep.updateAppointment || calendarData.calendarStep.rescheduleAppointment
                                        ? 'Select the Product(s)'
                                        : 'Product(s)'}
                                </label>
                            )}

                            {!productOptionShow &&
                                ((calendarData.calendarStep.updateAppointment && calendarData.bookedSlotInfo?.booking_transaction?.length === 0) ||
                                    calendarData.calendarStep.rescheduleAppointment ||
                                    calendarData.calendarStep.newAppointment) && (
                                    <CustomButton
                                        type="button"
                                        onClick={() => setProductOptionShow(true)}
                                        className="!text-xs text-primary font-semibold flex items-center gap-1.5 h-[15px] shadow-none pr-0.5 "
                                        icon={<Plus width="16" />}
                                    >
                                        Add Product
                                    </CustomButton>
                                )}
                        </div>
                        <div className="flex flex-col gap-2">
                            {fields.length > 0 && (
                                <div className="gap-2 flex flex-col">
                                    {fields.map((item: any, index: any) => (
                                        <Fragment key={index}>
                                            {(calendarData.calendarStep.updateAppointment && calendarData.bookedSlotInfo?.booking_transaction?.length === 0) ||
                                            calendarData.calendarStep.rescheduleAppointment ||
                                            calendarData.calendarStep.newAppointment ? (
                                                <div className="flex justify-between items-center p-2 border border-gray-300 rounded-md shadow-sm text-xs">
                                                    <div className="flex font-medium select-box-custom ">
                                                        <span className="font-medium max-w-[210px] truncate">{`${item.name} (${item.variantInfo.size} ${item.unit_of_measure})`}</span>
                                                    </div>

                                                    <div className="flex items-center space-x-2">
                                                        <span className="font-medium ">${item.variantInfo.inventory.price}</span>
                                                        <button className="text-gray-500 hover:text-red-500" type="button" onClick={handleRemoveProduct(index)}>
                                                            <X width={16} height={16} />
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="flex justify-between items-center text-xs">
                                                    <div className="flex font-medium select-box-custom w-[270px] items-center">
                                                        <span className="font-normal text-[#101828] leading-[18px] max-w-[160px]">{item.name}</span>{' '}
                                                        <span className="font-normal ml-1 text-[#475467]">{`(${item.variantInfo.size} ${item.unit_of_measure})  `}</span>
                                                    </div>

                                                    <div className="flex items-center space-x-2">
                                                        <span className="font-medium ">${item.variantInfo.inventory.price}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </Fragment>
                                    ))}
                                </div>
                            )}
                            {productOptionShow &&
                                ((calendarData.calendarStep.updateAppointment && calendarData.bookedSlotInfo?.booking_transaction?.length === 0) ||
                                    calendarData.calendarStep.rescheduleAppointment ||
                                    calendarData.calendarStep.newAppointment) && (
                                    <SelectBox
                                        isSearchable
                                        name="product"
                                        // errorText={!!errors?.services}
                                        id="Product"
                                        rightLabelClassName="text-error-500"
                                        value={undefined}
                                        options={products}
                                        isLoading={isLoading}
                                        onChangeFunc={handleChangeProduct}
                                        placeholder="Select a Product"
                                        handleIsOptionDisabled={handleIsOptionDisabled}
                                    />
                                )}

                            {/* {errors.services && <p className="text-error">{errors.services.message}</p>} */}
                        </div>
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default memo(Product);
