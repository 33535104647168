import React, { useEffect, useMemo, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import CustomButton from 'src/components/CustomButton';
import NoDataMessage from 'src/components/NoDataMessage';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { SearchLg, ArrowUp, ArrowDown } from '@untitled-ui/icons-react/build/cjs';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { dateTimeFormate, getShortName } from 'src/utils/global-functions';

const OrderHistory = ({ product }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });
    const [filters, setFilters] = useState<any>({
        global: { value: null },
    });

    const listOrders = async () => {
        setIsLoading(true);
        await axiosGet(API.PRODUCT.BOOKING, { shop_id: shop.id, id }, lazyState)
            .then((response) => {
                setOrders(response.data.data.data);
                setTotalOrders(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        listOrders();
    }, [lazyState, shop]);

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const getClient = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.booking.user.profile_image_url ? <img src={row.booking.user.profile_image_url} alt="" className="w-full h-full object-cover" /> : getShortName(row.booking.user.full_name)}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {row.booking.user.full_name}
                </p>
            </div>
        </div>
    );

    const getStaff = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.booking.staff.profile_image_url ? <img src={row.booking.staff.profile_image_url} alt="" className="w-full h-full object-cover" /> : getShortName(row.booking.staff.full_name)}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[100px] 2xl:max-w-[100px] 2xl:min-w-[100px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {row.booking.staff.full_name}
                </p>
            </div>
        </div>
    );

    // const getDateTime = (row: any) => `${moment(row.booking.booking_date, 'YYYY-MM-DD').format('D MMM')} ${moment(row.booking.booking_start_time, 'HH:mm:ss').format('h:mm A')}`;
    const getDateTime = (row: any) => {
        const dateTime = `${row.booking.booking_date} ${row.booking.booking_start_time}`;
        return dateTimeFormate(dateTime);
    };
    const getQuantity = (row: any) => row.quantity;
    const getUnit = (row: any) => `${row.product_variant.size} ${product.unit_of_measure}`;
    const getPrice = (row: any) => `$${row.price || 0}`;
    const getStatus = (row: any) => {
        const transactionArray = row.booking.booking_transaction;
        return transactionArray.length > 0 ? (
            <p className="text-xs font-medium w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate capitalize">
                {`${transactionArray[0].payment_method} ${transactionArray.length > 1 ? `+${transactionArray.length - 1}` : ''}`}
            </p>
        ) : (
            <>-</>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                first_name: 'loading',
                phone: 'loading',
                email: 'loading',
                average_days: 'loading',
                average_amount: 'loading',
                bookings_count: 'loading',
                status: 'loading',
                action: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };
    return (
        <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow  datatable-custom">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div className="flex flex-col">
                    <div className="flex justify-start items-center">
                        <h2 className="view-table-title capitalize-first ">
                            {t('Latest Product Orders')}
                            <p className="userCounter ml-1.5">
                                <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                {totalOrders}
                                {t('Orders')}
                            </p>
                        </h2>
                    </div>
                    <p className="view-table-subtitle">{t('View name latest orders activity.', { name: product.name })}</p>
                </div>

                <div className="flex flex-row gap-4">
                    <div className="table-searchInput location-dropdown">
                        <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                    </div>
                </div>
            </div>
            <div className="w-full">
                <DataTable
                    lazy
                    value={isLoading ? skeletons : orders}
                    totalRecords={totalOrders}
                    paginator={!isLoading && totalOrders > lazyState.rows}
                    paginatorTemplate={customPaginatorTemplate}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    filters={lazyState.filters}
                    onPage={onPage}
                    onSort={onSort}
                    onFilter={onFilter}
                    dataKey="id"
                    className="rounded-b-xl overflow-hidden"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                            <NoDataMessage
                                title={`${filters.global.value ? 'No orders found.' : 'No orders added.'}`}
                                description={`${filters.global.value ? 'Try adjusting your filters or add new orders.' : 'Add a orders, to start using the orders collection.'}`}
                                iconComponent={<SearchLg className="text-gray-700" />}
                            />
                        </div>
                    }
                >
                    <Column
                        field="users.first_name"
                        header={renderHeader(t('Client Name'), 'users.first_name')}
                        style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                        body={isLoading ? <Skeleton /> : getClient}
                        className="font-medium text-secondaryTxtColor text-xs  truncate"
                        sortable
                    ></Column>
                    <Column
                        field="booking_transactions.payment_method"
                        header={renderHeader(t('Payment Method'), 'booking_transactions.payment_method')}
                        body={isLoading ? <Skeleton /> : getStatus}
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        className="text-xs font-medium text-secondaryTxtColor  truncate"
                        sortable
                    ></Column>
                    <Column
                        field="shop_admins.first_name"
                        header={renderHeader(t('Team Member'), 'shop_admins.first_name')}
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        body={isLoading ? <Skeleton /> : getStaff}
                        className="text-xs leading-[18px] font-medium text-secondaryTxtColor truncate "
                        sortable
                    ></Column>
                    <Column
                        field="bookings.booking_date"
                        header={renderHeader(t('Date and Time'), 'bookings.booking_date')}
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        body={isLoading ? <Skeleton /> : getDateTime}
                        className="text-xs leading-[18px] font-medium text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="quantity"
                        header={renderHeader(t('Qty'), 'quantity')}
                        className="text-xs font-medium text-secondaryTxtColor  truncate"
                        style={{ width: '60px', minWidth: '60px', maxWidth: '60px' }}
                        body={isLoading ? <Skeleton /> : getQuantity}
                        sortable
                    ></Column>
                    <Column
                        field="shop_product_variants.size"
                        header={renderHeader(t('Unit'), 'shop_product_variants.size')}
                        body={isLoading ? <Skeleton /> : getUnit}
                        style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                        className="text-xs font-medium text-secondaryTxtColor  truncate"
                        sortable
                    ></Column>
                    <Column
                        field="price"
                        header={renderHeader(t('Price'), 'price')}
                        body={isLoading ? <Skeleton /> : getPrice}
                        style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                        className="text-xs font-medium text-secondaryTxtColor  truncate"
                        sortable
                    ></Column>
                </DataTable>
            </div>
        </div>
    );
};

export default OrderHistory;
