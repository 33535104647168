import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ServiceTable from './ServiceTable';
import PageHeader from 'src/components/PageHeader';
import { currentRole } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { APP_FULL_NAME, ROLES, SERVICE_TABS } from 'src/constants/common';
import StaffServiceTable from './StaffServiceTable';
import { PATH } from 'src/constants/path';
import CategoryTable from './Category/CategoryTable';
import ShopServiceTable from './ShopServiceTable';

const Services = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { tab: currentTab } = useParams();

    const role = useAppSelector(currentRole);
    const [activeTabName, setActiveTabName] = useState('staff');
    const [activeTab, setActiveTab] = useState(role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT ? <StaffServiceTable /> : <ServiceTable />);

    useEffect(() => {
        if (role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT) {
            setActiveTabName(activeTabName);
            switch (activeTabName) {
                case 'staff':
                    setActiveTab(<StaffServiceTable />);
                    break;
                case 'shop':
                    setActiveTab(<ShopServiceTable setActiveTab={setActiveTabName} />);
                    break;
                default:
                    setActiveTab(<StaffServiceTable />);
                    break;
            }
        } else {
            switch (currentTab) {
                case SERVICE_TABS.SERVICE:
                    document.title = `${t('Service')} - ${APP_FULL_NAME}`;
                    setActiveTab(<ServiceTable />);
                    break;
                case SERVICE_TABS.CATEGORY:
                    document.title = `${t('Service Category')} - ${APP_FULL_NAME}`;
                    setActiveTab(<CategoryTable />);
                    break;
                default:
                    document.title = `${t('Service')} - ${APP_FULL_NAME}`;
                    setActiveTab(<ServiceTable />);
                    break;
            }
        }
    }, [activeTabName, currentTab]);

    const handleTabClick = (type: string) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTabName(type);
    };
    const handleTabNavigateClick = (type: string) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        document.title = type === SERVICE_TABS.SERVICE ? `${t('Service')} - ${APP_FULL_NAME}` : `${t('Service Category')} - ${APP_FULL_NAME}`;
        switch (type) {
            case SERVICE_TABS.SERVICE:
                navigate(PATH.SERVICES.LIST);
                break;
            case SERVICE_TABS.CATEGORY:
                navigate(`${PATH.SERVICES.LIST}/${type}`);
                break;
            default:
                navigate(PATH.SERVICES.LIST);
                break;
        }
    };

    const tabs = useMemo(
        () => [
            {
                name: 'My services',
                type: 'staff',
            },
            {
                name: 'Brand services',
                type: 'shop',
            },
        ],
        [],
    );

    const adminTabs = useMemo(
        () => [
            {
                name: 'Services',
                type: 'services',
            },
            {
                name: 'Service Categories',
                type: 'category',
            },
        ],
        [],
    );

    return (
        <div className="inner-page-wrape pb-5">
            <PageHeader title={t('Services')} subtitle={t('Easily view, add, and manage all the services your brand offers.')}>
                {/* {hasPermission('service_add') && (
                    <>
                        <CustomButton secondary onClick={handleClick(ROUTES.CATEGORY.CREATE)} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                            {t('Add new category')}
                        </CustomButton>
                        <CustomButton primary onClick={handleClick(ROUTES.SERVICES.CREATE)} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                            {t('Add new service')}
                        </CustomButton>
                    </>
                )} */}
            </PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full">
                {role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT ? (
                    <div className=" flex flex-col flex-1">
                        <div className="fl-tab-btn-view w-full">
                            {tabs.map((tab, index) => (
                                <button key={index} type="button" className={`fl-tab-link !max-w-[182px]  ${activeTabName === tab.type ? 'active' : ''}`} onClick={handleTabClick(tab.type)}>
                                    {tab.name}
                                </button>
                            ))}
                        </div>
                        {activeTab}
                        {/* {activeTab === 'staff' && <StaffServiceTable />}
                        {activeTab === 'shop' && <ShopServiceTable setActiveTab={setActiveTab} />} */}
                    </div>
                ) : (
                    <div className=" flex flex-col flex-1">
                        <div className="fl-tab-btn-view w-full mb-4">
                            {adminTabs.map((tab, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    className={`fl-tab-link !max-w-[182px]  ${currentTab === tab.type || (!currentTab && tab.type === SERVICE_TABS.SERVICE) ? 'active' : ''}`}
                                    onClick={handleTabNavigateClick(tab.type)}
                                >
                                    {tab.name}
                                </button>
                            ))}
                        </div>
                        {activeTab}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Services;
