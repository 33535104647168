import React, { FC, memo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';

import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { getReceipt, initAction, selectCalendarData, setCalendarStep, setClearBooking, setData } from '../../Calendar.slice';
import CustomButton from 'src/components/CustomButton';
import { useTranslation } from 'react-i18next';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { format } from 'date-fns';
import moment from 'moment';
interface IManageSubmit {
    appointmentInfo: any;
}
const ManageSubmit: FC<IManageSubmit> = ({ appointmentInfo }) => {
    const { handleSubmit } = useFormContext();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(false);
    const [isReceiptDownloadLoading, setIsReceiptDownloadLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const calendarData: any = useAppSelector(selectCalendarData);
    const { t } = useTranslation();

    const handleClose = () => {
        dispatch(setData({ calendarStep: initAction, getBookingList: true }));
        dispatch(setClearBooking());
    };
    const sendReceipt = (isMail: boolean) => async () => {
        isMail ? setIsReceiptLoading(true) : setIsReceiptDownloadLoading(true);
        const data = {
            params: {
                shop_id: shop.id,
                id: appointmentInfo.id,
            },
            payload: {
                is_send_mail: isMail,
            },
        };
        const result = await dispatch(getReceipt(data));
        if (result.type === getReceipt.fulfilled.toString()) {
            if (isMail) {
                setIsReceiptLoading(false);
                return;
            }
            const response = result.payload.data.receipt;
            const byteCharacters = atob(response);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Create download link
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${appointmentInfo.receipt_code}-appointment.pdf`;

            // Append the link to the body and trigger the click event
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            setIsReceiptDownloadLoading(false);
        }
        if (result.type === getReceipt.rejected.toString()) {
            const response = await result.payload.data;
            if (response.status === errorCode.unprocessable) {
                toast.error(response.message);
            }
            setIsReceiptDownloadLoading(false);
            setIsReceiptLoading(false);
        }
    };

    const getBookingInfo = async (id: number) => {
        let payload = {
            shop_id: shop.id,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                return response.data.data;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
        }
    };
    const onSubmit = async (data: any, update: boolean = false) => {
        setIsLoading(true);
        try {
            const products = data.products.map((product: any) => ({
                id: product.value,
                quantity: 1,
            }));
            const services = data.services.map((service: any) => ({
                id: service.id,
                quantity: 1,
                price: service.price,
                duration: service.duration,
            }));
            let newData = {
                ...data,
                services: services,
                products: products,
                booking_date: format(data.booking_date, 'yyyy-MM-dd'),
            };
            if (data.is_recurring) {
                const dateString = moment(newData.recurring_end_date).toDate();
                newData.recurring_end_date = format(dateString, 'yyyy-MM-dd');
            } else {
                delete newData.recurring_number;
                delete newData.recurring_frequency;
                delete newData.recurring_end_date;
            }

            if (update) {
                const result = await axiosPatch(API.BOOKING.UPDATE, newData, {
                    shop_id: shop.id,
                    location_id: calendarData.selectedLocation?.id,
                    id: calendarData.bookedSlotInfo.id,
                });
                const info = await getBookingInfo(result.data.data.id);
                const updateState = {
                    bookedSlotInfo: info,
                    getBookingList: true,
                };
                dispatch(setData(updateState));
                dispatch(setCalendarStep('bookedAppointment'));
            } else {
                const result = await axiosPost(API.BOOKING.CREATE, newData, {
                    shop_id: shop.id,
                    location_id: calendarData.selectedLocation?.id,
                });
                const info = await getBookingInfo(result.data.data.id);
                const updateState = {
                    bookedSlotInfo: info,
                    getBookingList: true,
                };
                dispatch(setData(updateState));
                dispatch(setCalendarStep('bookedAppointment'));
            }
        } catch (error: any) {
            toast.error(error?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitForm = async () => {
        setIsLoading(true);
        if (calendarData.calendarStep.bookedAppointment) {
            if (calendarData.bookedSlotInfo.status === 'confirmed') {
                const updateState = {
                    calendarStep: { ...initAction, checkoutAppointment: true },
                    getBookingList: true,
                };
                dispatch(setData(updateState));
            }
        } else if (calendarData.calendarStep.updateAppointment) {
            await handleSubmit((e) => {
                onSubmit(e, true);
            })();
            const updateState = {
                calendarStep: { ...initAction, bookedAppointment: true },
                getBookingList: true,
            };
            dispatch(setData(updateState));
        }
        setIsLoading(false);
        // await dispatch(setGetBookingList(true));
    };

    if (calendarData.calendarStep.newAppointment || calendarData.calendarStep.rescheduleAppointment) {
        return (
            <div className="border-t px-5 py-4">
                <div className="flex gap-4 w-full">
                    <CustomButton className="w-1/2" disabled={isLoading} secondary onClick={handleClose}>
                        {t('Cancel')}
                    </CustomButton>
                    <CustomButton type="button" className="w-1/2" isLoading={isLoading} disabled={isLoading} primary onClick={handleSubmit((e) => onSubmit(e))}>
                        {!isLoading && t('Book Appointment')}
                    </CustomButton>
                </div>
            </div>
        );
    } else if ((appointmentInfo && appointmentInfo.status === 'canceled') || appointmentInfo.status === 'completed' || appointmentInfo.status === 'no_show') {
        return (
            <div className="border-t px-5 py-4">
                <div className="flex gap-4 w-full">
                    <CustomButton className="w-1/2" isLoading={isReceiptDownloadLoading} disabled={isReceiptDownloadLoading} secondary onClick={sendReceipt(false)}>
                        {!isLoading && t('Download receipt')}
                    </CustomButton>
                    <CustomButton type="button" className="w-1/2" isLoading={isReceiptLoading} disabled={isReceiptLoading} primary onClick={sendReceipt(true)}>
                        {!isLoading && t('Resend receipt')}
                    </CustomButton>
                </div>
            </div>
        );
    } else if (appointmentInfo && appointmentInfo.status === 'confirmed' && calendarData.calendarStep.updateAppointment) {
        return (
            <div className="border-t px-5 py-4">
                <div className="flex gap-4 w-full">
                    <CustomButton className="w-1/2" secondary disabled={isLoading} onClick={handleClose}>
                        {t('Cancel')}
                    </CustomButton>
                    <CustomButton type="button" className="w-1/2" isLoading={isLoading} disabled={isLoading} primary onClick={handleSubmit((e) => onSubmit(e, true))}>
                        {t('Save')}
                    </CustomButton>
                </div>
            </div>
        );
    } else if (appointmentInfo && appointmentInfo.status !== 'canceled' && appointmentInfo && appointmentInfo.status !== 'no_show' && appointmentInfo.status !== 'completed') {
        return (
            <div className="border-t px-5 py-4">
                <div className="flex gap-4 w-full">
                    <CustomButton className="w-1/2" secondary onClick={handleClose}>
                        {t('Cancel')}
                    </CustomButton>
                    <CustomButton type="button" className="w-1/2" primary onClick={handleSubmitForm}>
                        {t('Checkout')}
                    </CustomButton>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default memo(ManageSubmit);
