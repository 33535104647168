import React, { useState } from 'react';
import moment from 'moment';
import { Box } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'src/components/NoDataMessage';
import { usePhone } from 'src/hooks/usePhone';

import { dateTimeFormate, formatName, getShortName } from 'src/utils/global-functions';
import { useAppSelector } from 'src/redux/hooks';
import { currentRole } from 'src/redux/services/common/Common.slice';
import { ROLES } from 'src/constants/common';

const LatestBookingActivity = ({ handleViewClick, latestData, isLoading }: any) => {
    const { t } = useTranslation();
    const role = useAppSelector(currentRole);
    const { getCustomFormatPhone } = usePhone();
    const [isImageError, setIsImageError] = useState<{ [key: number]: boolean }>({});

    const handleImageError = (ids: number) => () => {
        setIsImageError((old: any) => ({ ...old, [ids]: true }));
    };

    const getData = () =>
        latestData.map((item: any) => {
            let image = item.staff.profile_image_url;
            let name = item.staff.full_name;
            let phone = item.staff.phone;
            let code = item.staff.phone_country_code;
            if (role.name !== ROLES.OWNER) {
                image = item.user.profile_image_url;
                name = item.user.full_name;
                phone = item.user.phone;
                code = item.user.phone_country_code;
            }
            return (
                <div key={item.id} className="py-2.5 flex justify-between items-center">
                    <div className="flex items-center">
                        <figure className="w-[30px] h-[30px] basis-[30px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center NoImgName">
                            {image && !isImageError[item.id] ? (
                                <img src={image} alt="" className="w-full h-full object-cover" onError={handleImageError(item.id)} />
                            ) : name ? (
                                getShortName(`${name}`)
                            ) : (
                                '+'
                            )}
                        </figure>
                        <div>
                            <p className="text-xs font-medium mb-[2px] text-mainTextColor leading-[18px] -tracking-[0.12px] max-2xl:max-w-[50px] truncate">
                                {name ? `${formatName(name)}` : `${getCustomFormatPhone(phone, code)}`}
                            </p>
                            <p className="text-xs font-normal leading-[18px] -tracking-[0.12px] text-secondaryTxtColor  max-2xl:max-w-[70px] 2xl:max-w-[118px] xlm:max-w-[142px] 2xls:max-w-[150px] 2xlm:max-w-[175px] truncate">
                                {dateTimeFormate(`${item.booking_date}' '${item.booking_start_time}`)}
                            </p>
                        </div>
                    </div>
                    <p className="text-xs border border-borderSecondary bg-[#F9FAFB] font-medium leading-[150%] text-mainTextColor -tracking-[0.12px]  px-2 py-[2px] rounded-full">
                        {moment(`${item.booking_date}' '${item.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').fromNow()}
                    </p>
                </div>
            );
        });

    return (
        <div className="bg-white  h-auto mt-3 xxl:mt-4">
            <div className="flex items-center mb-[14px]">
                <h3 className="text-sm font-medium text-secondaryTxtColor">{t('Latest Booking Activity')}</h3>
                {/* <button type="button" onClick={handleViewClick('latestBookingActivity')} className="text-primary ml-auto -tracking-[0.3] font-medium">
                    {t('View all')}
                </button> */}
            </div>
            {isLoading ? (
                Array(8)
                    .fill(null)
                    .map((item: any, index: number) => (
                        <div key={index} className="py-2.5 flex justify-between items-center ">
                            <div className="flex items-center ">
                                <figure className="w-[40px] h-[40px] basis-[40px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center custom-loading ">
                                    12
                                </figure>
                                <div>
                                    <p className="text-xs font-medium mb-[2px] text-mainTextColor leading-[18px] -tracking-[0.12px] custom-loading max-w-[70px] max-2xl:max-w-[50px]">loading</p>
                                    <p className="text-xs font-normal leading-[18px] -tracking-[0.12px] text-secondaryTxtColor custom-loading max-2xl:max-w-[70px]">loadingloadingloading</p>
                                </div>
                            </div>
                            <p className="text-xs border border-borderSecondary bg-[#F9FAFB] font-medium leading-[150%] text-mainTextColor -tracking-[0.12px]  px-2 py-[2px] rounded-full custom-loading ">
                                booking date
                            </p>
                        </div>
                    ))
            ) : latestData.length > 0 ? (
                <div>{getData()}</div>
            ) : (
                <div className="h-[500px] flex justify-center items-center">
                    <NoDataMessage title={t('No latest booking activity')} description={t('No latest booking activity available')} iconComponent={<Box className="text-gray-700 " />} />
                </div>
            )}
        </div>
    );
};

export default LatestBookingActivity;
