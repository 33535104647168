export const FONT_FAMILY = [
    { value: 'helvetica', label: 'Helvetica' },
    { value: 'plus_jakarta_sans', label: 'Plus jakarta sans' },
    { value: 'roboto', label: 'Roboto' },
    { value: 'inter', label: 'Inter' },
    { value: 'montserrat', label: 'Montserrat' },
    { value: 'poppins', label: 'Poppins' },
    { value: 'mulish', label: 'Mulish' },
];

export const FONT_WEIGHT = [
    { value: '200', label: 'Thin' },
    { value: '300', label: 'Light' },
    { value: '400', label: 'Regular' },
    { value: '500', label: 'Medium' },
    { value: '600', label: 'Semi Bold' },
    { value: '700', label: 'Bold' },
    { value: '800', label: 'Extra Bold' },
];

export type LogoType = 'logo' | 'logo_text' | 'text';

export interface IFormData {
    logo_type: LogoType;
    name?: string;
    logo_font_family?: string;
    font_weight?: string;
    font_size?: string;
    logo_height?: string;
}

export interface LogoDisplaySettingsProps {
    setFormDetails: React.Dispatch<any>;
    displayType: 'logo' | 'logo_text' | 'text';
}
