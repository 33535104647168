import { Dispatch, SetStateAction } from 'react';
import { IPaymentState } from '../../Calendar.Interface';

export interface ISidebar {
    isOpen: boolean;
    handleClose: any;
}
export interface IListOption {
    name: string;
    price: {
        price: string;
        duration: string;
    };
    id: number;
    value: string | number;
    label: string;
    isDisabled?: boolean;
}

export interface IServices {
    name?: string;
    price?: number;
    staff_name?: string;
    time_slot?: string;
    is_custom_price?: boolean;
    duration?: string;
    id?: number;
    qty?: number;
}
export interface ISidebarSchema {
    services: IServices[] | null;
    products: any[] | null;
    booking_date: string | null;
    booking_time: string | null;
    payment_method: string | null;
    staff_id: number | null;
    user_id: number | null;
    note: string;
    is_recurring: boolean;
    recurring_frequency: number | null;
    recurring_end_date: Date | null;
    recurring_number: number | null;
    user_info?: any | null;
    payments: IPaymentState | null;
}

export interface ISidebarState {
    serviceArrayList: IListOption[] | []; // Replace SomeType with the actual type
    selectedOption: any; // Replace SomeType with the actual type
    serviceOptionShow: boolean;
}

export interface IPaymentReceiptCalculationProps {
    bookedSlotInfo: any;
}
export interface ICalulationDetails {
    appointmentCalulation: {
        product: number;
        service: number;
        subtotal: number;
        taxes_and_fees: number;
        total: number;
        tips: number;
    };
}

export interface IAppointmentOption {
    label: JSX.Element;
    value: string;
    icon1: JSX.Element;
    icon1Position: string;
}

export interface IAddClient {
    getClientList: any;
    handleSelectChange: Dispatch<SetStateAction<any>>;
    setIsNewClient: Dispatch<SetStateAction<boolean>>;
}

export interface IBlockTime {
    name: string;
    fromTime: Date;
    toTime: Date;
}

export interface IInitAction {
    checkout: boolean;
    payment: boolean;
    complate_payment: boolean;
}
export const initAction = {
    checkout: true,
    payment: false,
    complate_payment: false,
};
