import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DateRangeFormatFunction, Calendar as ReactCalendar, SlotInfo, View, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';
import Toolbar from '../Calendar/Toolbar';
import ResourceHeader from './ResourceHeader';
import { defaultAction, IActionType, IResourceMap } from './Calendar.Interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { initAction, selectCalendarData, setClearBooking, setData } from './Calendar.slice';
import { generateTimeRanges, getResourceOptions, getShortName, splitBookingTime } from 'src/utils/global-functions';
import Sidebar from './Sidebar/Sidebar';
import moment from 'moment-timezone';
import { axiosGet, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import EventCard from './EventCard';
import usePrevious from 'src/hooks/usePrevious';
import { debounce, isEqual } from 'lodash';
import { allShopLocations, currentShop } from 'src/redux/services/common/Common.slice';
import PageHeader from 'src/components/PageHeader';
import CustomButton from 'src/components/CustomButton';
import { Plus, UsersX } from '@untitled-ui/icons-react/build/cjs';
import { GoDotFill } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import CheckoutSidebar from './NewCheckout/Sidebar/CheckoutSidebar';
import NoDataMessage from 'src/components/NoDataMessage';
import Terminal from '../Terminal/Terminal';
import { useTranslation } from 'react-i18next';

const Calendar = () => {
    const { t } = useTranslation();
    const localizer = momentLocalizer(moment);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const calendarData: any = useAppSelector(selectCalendarData);
    const shopLocations = useAppSelector(allShopLocations);
    const prevStateValue = usePrevious(calendarData);
    const calendarRef = useRef<HTMLDivElement>(null);
    const [events, setEvents] = useState<any>([]);
    const [bookings, setBookings] = useState<any>([]);
    const [staffOptions, setStaffOptions] = useState<any>([]);
    // const [resources, setResources] = useState<any>([]);
    const [filter, setFilter] = useState(['confirmed', 'new', 'completed', 'no_show']);
    const [selectedEvent, setSelectedEvent] = useState<any>(null);
    const [action, setAction] = useState(defaultAction);
    const [terminal, setTerminal] = useState<any>(null);
    const [isTerminalConnected, setIsTerminalConnected] = useState(false);
    const [isTerminalLoading, setIsTerminalLoading] = useState(true);
    const [calendarDate, setCalendarDate] = useState(moment().toDate());
    const [defaultDate, setDefaultDate] = useState(moment().toDate());

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClearBooking();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        // Use memo instead of this: https://github.com/jquense/react-big-calendar/blob/master/stories/demos/exampleCode/timezones.js
        if (calendarData.selectedLocation) {
            const { timezone } = calendarData.selectedLocation;
            moment.tz.setDefault(timezone);
            setDefaultDate(moment().tz(timezone).toDate());
            dispatch(setData({ timeZone: timezone }));
        }
        return () => {
            moment.tz.setDefault();
        };
    }, [calendarData.selectedLocation]);

    useEffect(() => {
        if (
            !isEqual(prevStateValue?.selectedStaff, calendarData.selectedStaff) ||
            !isEqual(prevStateValue?.selectedLocation, calendarData.selectedLocation) ||
            !isEqual(prevStateValue?.view, calendarData.view) ||
            !isEqual(prevStateValue?.getBookingCalendarDate, calendarData.getBookingCalendarDate)
        ) {
            if (calendarData.view === Views.WEEK) {
                if (calendarData.selectedStaff && calendarData.selectedStaff.value) {
                    getBookingList();
                }
            } else {
                getBookingList();
            }
        }
    }, [calendarData.selectedStaff, calendarData.selectedLocation, calendarData.view, calendarData.getBookingCalendarDate]);

    useEffect(() => {
        if (calendarData.selectedLocation && calendarData.selectedLocation.shop_staff.length) {
            let staffOptionData = calendarData.selectedLocation.shop_staff.map((item: any) => ({
                ...item,
                value: item.id,
                label: item.full_name,
                image: item.profile_image_url,
                shortName: getShortName(item.full_name),
            }));

            const allOption = { value: null, label: 'All Team Members', id: null };
            if (calendarData.view === Views.DAY) {
                staffOptionData = [allOption].concat(staffOptionData);
            }
            setStaffOptions(staffOptionData);
            let selectedStaff = calendarData.selectedStaff;
            if (calendarData.selectedStaff && calendarData.selectedStaff.id) {
                selectedStaff = staffOptionData.find((item: any) => item.id === calendarData.selectedStaff.id);
            }
            selectedStaff = selectedStaff && selectedStaff.id ? selectedStaff : calendarData.view === Views.WEEK ? staffOptionData[0] : allOption;
            dispatch(setData({ selectedStaff }));
        }
    }, [calendarData.selectedLocation, calendarData.view]);

    useEffect(() => {
        if (calendarData.selectedLocation && calendarData.selectedLocation.shop_staff.length) {
            if (calendarData.selectedStaff && calendarData.selectedStaff.id) {
                const filteredStaff = calendarData.selectedLocation.shop_staff.filter((item: any) => item.id === calendarData.selectedStaff.id);
                // setResources(getResourceOptions(filteredStaff));
                dispatch(setData({ resourceList: getResourceOptions(filteredStaff) }));
            } else {
                // setResources(getResourceOptions(calendarData.selectedLocation.shop_staff));
                dispatch(setData({ resourceList: getResourceOptions(calendarData.selectedLocation.shop_staff) }));
            }
        }
    }, [calendarData.selectedLocation, calendarData.selectedStaff]);

    useEffect(() => {
        if (calendarData.getBookingList) {
            getBookingList();
        }
    }, [calendarData.getBookingList]);

    useEffect(() => {
        if (shopLocations.length) {
            dispatch(setData({ selectedLocation: shopLocations[0] }));
        }
        return () => {
            dispatch(setClearBooking());
        };
    }, [shopLocations]);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTimeIndicator = calendarRef.current?.querySelector('.rbc-current-time-indicator');
            if (currentTimeIndicator) {
                currentTimeIndicator.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                });
                clearInterval(interval); // Stop polling once the element is found
            }
        }, 500); // Check every 100 milliseconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [calendarData.view]);

    useEffect(() => {
        handleAppointmentStatus(filter);
    }, [bookings]);

    useEffect(() => {
        if (window.StripeTerminal) {
            const terminalInstance = window.StripeTerminal.create({
                onFetchConnectionToken: () => axiosPost(API.STRIPE.TERMINAL_TOKEN, {}, { shop_id: shop.id }).then((response) => response.data.data.secret),
                onUnexpectedReaderDisconnect: () => setIsTerminalConnected(false),
                onConnectionStatusChange: ({ status }: any) => {
                    switch (status) {
                        case 'connected':
                            setIsTerminalConnected(true);
                            break;
                        case 'not_connected':
                            setIsTerminalConnected(false);
                            break;
                    }
                },
            });

            setTerminal(terminalInstance);
            handleTerminal(terminalInstance)();
        }
    }, []);

    /* useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/terminal/v1/';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []); */

    const updatedCalendarStep = useCallback(
        (activeKey: keyof IActionType) =>
            Object.keys(initAction).reduce((acc, key) => {
                acc[key as keyof IActionType] = key === activeKey; // Only the activeKey is true, others false
                return acc;
            }, {} as IActionType),
        [],
    );

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleActionClose = () => {
        setAction(defaultAction);
    };

    const messages = {
        previous: 'Previous',
        today: 'Today',
        next: 'Next',
    };

    const handleNavigate = useCallback((date: Date) => {
        setCalendarDate(moment(date).toDate());
        setTimeout(() => {
            const currentTimeElement = document.querySelector('.rbc-current-time-indicator');
            if (currentTimeElement) {
                currentTimeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 100);
    }, []);

    const handleRangeChange = useCallback((range: Date[] | { start: Date; end: Date }) => {
        if (Array.isArray(range)) {
            dispatch(setData({ getBookingCalendarDate: moment(range[0]).toDate() }));
        } else {
            dispatch(setData({ getBookingCalendarDate: moment(range.start).toDate() }));
        }
    }, []);

    const handleCheckoutShow = useCallback(() => {
        if (action.checkout) {
            setAction((old) => ({ ...old, checkout: false }));
        } else {
            dispatch(setData({ isSidebarOpen: false }));
            handleAction('checkout')();
        }
    }, [action.checkout]);

    const handleSelectSlot = useCallback(
        async (slotInfo: SlotInfo) => {
            if (slotInfo.action === 'select') {
                setAction((old) => ({ ...old, checkout: false }));
                const formattedDate = calendarData.timeZone && slotInfo.start.toLocaleString('en-US', { timeZone: calendarData.timeZone });
                dispatch(setClearBooking());
                dispatch(setData({ isLoading: true, isSidebarOpen: true, calendarStep: updatedCalendarStep('newAppointment') }));

                const updatedEvents = bookings.filter((event: any) => event.status !== 'new');
                const newSlotInfo = {
                    status: 'new',
                    booking_date: moment(slotInfo.start).format('YYYY-MM-DD'),
                    booking_start_time: moment(slotInfo.start).format('HH:mm:ss'),
                    booking_end_time: moment(slotInfo.end).format('HH:mm:ss'),
                    staff_id: slotInfo.resourceId ?? calendarData.selectedStaff.id,
                };
                setBookings([...updatedEvents, newSlotInfo]);
                dispatch(
                    setData({
                        selectedDate: moment(slotInfo.start).toDate(),
                        selectedTime: moment(formattedDate).toDate(),
                        selectedSlotStaffId: Number(slotInfo.resourceId ?? calendarData.selectedStaff.id),
                        isLoading: false,
                    }),
                );

                setSelectedEvent(newSlotInfo);
            } else {
                handleClearBooking();
            }
        },
        [calendarData, bookings],
    );

    const handleClearBooking = useCallback(() => {
        const updatedEvents = bookings.filter((event: any) => event.status !== 'new');

        setSelectedEvent(null);
        setBookings(updatedEvents);
        dispatch(setData({ isSidebarOpen: false, calendarStep: initAction, bookedSlotInfo: null }));
        setAction((old) => ({ ...old, checkout: false }));
    }, [bookings]);

    const getBookingInfo = useCallback(async (id: number) => {
        let payload = {
            shop_id: shop.id,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                return response.data.data;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
            dispatch(setData({ isLoading: false }));
        }
    }, []);

    const handleSelectEvent = useCallback(
        async (booking: any) => {
            if (booking.status === 'new' || (calendarData.bookedSlotInfo && calendarData.bookedSlotInfo.id === booking.id)) {
                return;
            }
            dispatch(setData({ isLoading: true, isSidebarOpen: true }));

            const isNewAppoinment = bookings.some((event: any) => event.status === 'new');
            if (isNewAppoinment) {
                const updatedEvents = bookings.filter((event: any) => event.status !== 'new');
                setBookings(updatedEvents);
            } else if (booking.status !== 'block_time') {
                booking = await getBookingInfo(booking.id);
            }

            dispatch(
                setData({
                    calendarStep: updatedCalendarStep(booking.status === 'block_time' ? 'blockTime' : 'bookedAppointment'),
                    bookedSlotInfo: booking,
                    selectedSlotStaffId: Number(booking.staff_id),
                }),
            );
            setTimeout(() => {
                setSelectedEvent(booking);
            }, 200);
        },
        [bookings, calendarData],
    );

    const getBookingList = () => {
        if (calendarData.selectedLocation) {
            const payload: {
                location_id: number;
                booking_date: string;
                type: View;
                shop_id: number;
                staff_id?: number;
            } = {
                location_id: calendarData.selectedLocation.id,
                booking_date: moment(calendarData.getBookingCalendarDate).format('YYYY-MM-DD'),
                type: calendarData.view,
                shop_id: shop.id,
            };

            if (calendarData.selectedStaff && calendarData.selectedStaff.id) {
                payload.staff_id = calendarData.selectedStaff.id;
            }

            axiosPost(API.BOOKING.LIST_BY_DATE, payload, { shop_id: shop.id })
                .then((response) => {
                    const blockTimes = response.data.data.block_times.flatMap((item: any) => generateTimeRanges(item.from, item.to, item));
                    const bookingArray = response.data.data.bookings.flatMap((item: any) => splitBookingTime(item.booking_date, item.booking_start_time, item.booking_end_time, item));
                    setBookings([...bookingArray, ...blockTimes]);
                })
                .catch((error: any) => toast.error(error?.message))
                .finally(() => dispatch(setData({ getBookingList: false })));
        } else {
            setBookings([]);
        }
    };

    const handleTerminal = useCallback(
        (terminals: any, isConnect = false, isBtnClick = false) =>
            async () => {
                setIsTerminalLoading(true);

                if (isConnect) {
                    terminal.disconnectReader();
                } else {
                    /* if (isBtnClick) {
                        handleAction('terminal')();
                    } */
                    var config = { simulated: true };
                    const discoverResult = await terminals.discoverReaders(config);

                    if (!discoverResult.error && discoverResult.discoveredReaders.length) {
                        const reader = discoverResult.discoveredReaders[0];
                        const connected = await terminals.connectReader(reader);
                        if (isBtnClick && connected?.error) {
                            toast.error(connected.error.message);
                        }
                    }
                }
                setIsTerminalLoading(false);
            },
        [],
    );

    const handleChangeFilter = useCallback(
        debounce((options) => {
            const values = options ? options.map((option: any) => option.value) : [];
            setFilter(values);
            handleAppointmentStatus(values);
        }, 300),
        [bookings],
    );

    const handleAppointmentStatus = (statuses: string[]) => {
        const filteredData = bookings.filter((item: any) => statuses.includes(item.status) || statuses.includes('new'));
        setEvents(filteredData);
    };

    const selectRangeFormat: DateRangeFormatFunction = (range, culture, localizer1) => {
        localizer1!.format(range.start, 'MMMM D', culture); // TODO: Remove
        localizer1!.format(range.end, 'MMMM D', culture); // TODO: Remove

        return 'New Appointment';
    };
    localizer.formats = {
        ...localizer.formats,
        selectRangeFormat,
    };
    const onKeyPressEvent = useCallback(async (event: any, keypressEvent: any) => {
        if (keypressEvent.keyCode === 27) {
            handleClearBooking();
        }
    }, []);
    const startAccessor = useCallback((event: any) => (event.status === 'block_time' ? moment(event.startTime).toDate() : moment(`${event.booking_date} ${event.booking_start_time}`).toDate()), []);

    const endAccessor = useCallback((event: any) => (event.status === 'block_time' ? moment(event.endTime).toDate() : moment(`${event.booking_date} ${event.booking_end_time}`).toDate()), []);

    const slotPropGetter = (date: any, id: any) => {
        const currentHour = moment(date).hour();
        let style = {};
        if (calendarData.selectedLocation && calendarData.selectedLocation.shop_staff) {
            const staff = calendarData.selectedLocation.shop_staff.find((resource: any) => resource.id === id);
            if (staff) {
                const hour = staff.working_hours.find((working_hour: any) => working_hour.day === moment(date).format('dddd').toLowerCase() && working_hour.status);
                if (hour) {
                    const start = moment(hour.from, 'HH:mm:ss').hour();
                    const end = moment(hour.to, 'HH:mm:ss').hour();
                    if (currentHour < start || currentHour >= end) {
                        style = {
                            backgroundColor: '#F7F9FA',
                        };
                    }
                } else {
                    style = {
                        backgroundColor: '#F7F9FA',
                    };
                }
            }
        }
        return {
            style: style,
        };
    };

    const resourceHeaderComponent = useMemo(() => (eventInfo: any) => <ResourceHeader eventInfo={eventInfo} />, [calendarData.resourceList]);

    return (
        <div className="inner-page-wrape">
            <PageHeader title={'Calendar'} subtitle="Here’s a detailed overview of your completed and upcoming appointments.">
                <CustomButton
                    outlineSecondary
                    icon={<GoDotFill size={12} color={isTerminalLoading ? '#F29339' : isTerminalConnected ? '#17B26A' : '#D9512C'} className="h-4 w-4 rounded-full flex " />}
                    className="w-full rounded-lg shadow-InputAndButton min-w-max pl-[10px]"
                    onClick={handleTerminal(terminal, isTerminalConnected, true)}
                >
                    {isTerminalLoading ? 'Connecting...' : isTerminalConnected ? 'Terminal Connected' : 'Connect a Terminal'}
                </CustomButton>
                <CustomButton outlinePrimary icon={<Plus width="16" />} onClick={handleCheckoutShow} className="!px-4 py-[9px] w-full rounded-lg shadow-InputAndButton">
                    New Checkout
                </CustomButton>
            </PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full transition-all !duration-[0.5s]">
                <div
                    ref={calendarRef}
                    className={`calendar-block ${calendarData.isSidebarOpen || action.checkout ? 'w-[calc(100%-340px)]' : 'w-full'} flex-1 transition-all !duration-[0.5s] ${
                        calendarData.isSidebarOpen || action.checkout ? 'open_calendar !transition-all !duration-[0.5s]' : 'close_calendar transition-all !duration-[0.5s]'
                    }  ${calendarData.resourceList.length >= 8 ? 'calendar-without-resource' : ''} ${calendarData.view === Views.WEEK ? 'calendar-with-resource' : ''} `}
                >
                    <ReactCalendar
                        selectable
                        enableAutoScroll
                        showMultiDayTimes={false}
                        showAllEvents={false}
                        timeslots={4}
                        step={15}
                        scrollToTime={moment().toDate()}
                        events={events}
                        messages={messages}
                        localizer={localizer}
                        selected={selectedEvent}
                        defaultView={Views.DAY}
                        view={calendarData.view}
                        date={calendarDate}
                        defaultDate={defaultDate}
                        resources={calendarData.view === Views.DAY ? calendarData.resourceList : undefined}
                        resourceIdAccessor="staff_id"
                        className={calendarData.view === Views.WEEK ? 'week-calendar' : calendarData.resourceList.length === 0 ? 'custom-calendar-no-data' : 'custom-calendar'}
                        dayLayoutAlgorithm="no-overlap"
                        onView={() => {}}
                        resourceAccessor={(resource: IResourceMap) => resource.staff_id}
                        startAccessor={startAccessor}
                        endAccessor={endAccessor}
                        onNavigate={handleNavigate}
                        onRangeChange={handleRangeChange}
                        onSelectEvent={handleSelectEvent}
                        onSelectSlot={handleSelectSlot}
                        onKeyPressEvent={onKeyPressEvent}
                        slotPropGetter={slotPropGetter}
                        // onDrillDown={(e) => console.log(e)}
                        components={{
                            resourceHeader: resourceHeaderComponent,
                            timeGutterWrapper:
                                calendarData.view === Views.DAY && calendarData.resourceList.length === 0
                                    ? () => (
                                          <div className="w-full rounded-xl border border-borderSecondary shadow flex h-[calc(100%-20px)] justify-center items-center mb-5  ">
                                              <NoDataMessage
                                                  title={t('Unable to access Calendar')}
                                                  description={t('The calendar cannot be accessible because no team members has been assigned to this shop. Please add team member to proceed.')}
                                                  iconComponent={<UsersX className="text-gray-700" />}
                                                  buttonText="Add Team Member"
                                                  onButtonClick={() => navigate(ROUTES.STAFF.CREATE)}
                                              />
                                          </div>
                                      )
                                    : undefined,

                            week: {
                                header: (e) => (
                                    <>
                                        <div className="day-name ">{moment(e.date).format('ddd')}</div>
                                        <div className="day-date">{moment(e.date).format('D')}</div>
                                    </>
                                ),
                            },
                            event: (props: any) => <EventCard eventInfo={props} />,
                            toolbar: (props) => (
                                <Toolbar
                                    date={props.date}
                                    view={props.view}
                                    onNavigate={props.onNavigate}
                                    onView={props.onView}
                                    handleAppointmentStatus={handleChangeFilter}
                                    filter={filter}
                                    staffOptions={staffOptions}
                                />
                            ),
                        }}
                    />
                </div>

                {calendarData.isSidebarOpen && <Sidebar />}
                {action.checkout && <CheckoutSidebar isOpen={action.checkout} handleClose={handleActionClose} />}
                {action.terminal && <Terminal handleClose={handleActionClose} />}
            </div>
        </div>
    );
};

export default Calendar;
