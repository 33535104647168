import React, { FC } from 'react';
import { PNGSvg } from 'src/theme/Images';
import { ImageInfoProps } from './Image.interface';
import { BsFiletypeMp4 } from 'react-icons/bs';
import { Trash01 } from '@untitled-ui/icons-react/build/cjs';

const ImageInfo: FC<ImageInfoProps> = ({ name, onRemove, className, url, usedData, ImageInfoClassname, isApperance }) => {
    const fileExtension = name?.substring(name?.lastIndexOf('.') + 1);
    return (
        <div className={`flex items-center p-4 border rounded-lg shadow-sm bg-white relative ${className}`}>
            <div className="flex items-center gap-3">
                <div className="flex items-center justify-center w-10 h-10 ">
                    {fileExtension === 'mp4' ? <BsFiletypeMp4 size={20} /> : <img src={url ?? PNGSvg} alt="" className="w-10 h-10 rounded-[6.4px] object-contain" />}
                </div>
                <div className={`${ImageInfoClassname}`}>
                    <p className="text-sm font-medium text-gray-700 truncate">{name}</p>
                    {usedData && <div className="text-sm fomt-normal text-gray-600">{usedData}</div>}
                </div>
            </div>
            <button className={`ml-1 p-2 text-gray-500 hover:text-red-500 ${isApperance ? 'absolute top-2 right-2' : ''}`} onClick={onRemove}>
                <Trash01 className={` text-gray-600 ${isApperance ? 'w-4 h-4' : 'w-5 h-5'}`} />
            </button>
        </div>
    );
};

export default ImageInfo;
