import React, { useEffect } from 'react';
import { useAppDispatch } from 'src/redux/hooks';
import { me } from 'src/redux/services/common/Common.slice';
import { axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';

const PaymentReturn = () => {
    const dispatch = useAppDispatch();

    const handleCallback = (message: string) => {
        window.opener.postMessage(message, window.location.origin);
    };

    const verifyAccount = async () => {
        axiosGet(API.USER.VERIFY_STRIPE_ACCOUNT)
            .then(() => {
                handleCallback('stripe_connect_verified');
            })
            .catch(() => {
                updateAccountStatus();
                /* const response = result.payload.data;

            if (response.status === errorCode.unprocessable) {
                handleCallback('stripe_connect_not_verified');
            } */
            });
    };

    const updateAccountStatus = () => {
        axiosPatch(API.USER.UPDATE_STRIPE_ACCOUNT_STATUS, {})
            .then(async () => {
                await dispatch(me());
                handleCallback('stripe_connect_pending');
            })
            .catch(() => {
                handleCallback('stripe_connect_not_verified');
            });
    };

    useEffect(() => {
        verifyAccount();
        const timer = setTimeout(() => {
            handleCallback('stripe_connect_pending');
        }, 30000);

        return () => clearTimeout(timer);
    }, []);

    return <h1>Loading...</h1>;
};

export default PaymentReturn;
