import React, { ReactNode } from 'react';
import { GoDotFill } from 'react-icons/go';

interface ITypeProp {
    children: ReactNode;
    status?: string;
    icon?: boolean;
    isLoading?: boolean;
}

const Badge = ({ children, icon, status = 'default', isLoading = false }: ITypeProp) => {
    const getColor = () => {
        switch (status) {
            case 'regular':
                return '#2E90FA';
            case 'offline':
                return '#f00';
            case 'confirmed':
                return '#1642C5';
            case 'completed':
            case 'online':
            case 'variable':
                return '#17B26A';
            case 'canceled':
            case 'request_canceled':
                return '#F04438';
            case 'expired':
                return '#F79009';
            case 'no_show':
                return '#F79009';
            default:
                return '#17B26A';
        }
    };
    return (
        <div className={`userCounter ${isLoading ? 'custom-loading' : ''}`}>
            {icon && <GoDotFill size={12} color={getColor()} className="h-3 w-3 rounded-full flex mr-0.5" />}
            {children}
        </div>
    );
};

export default Badge;
