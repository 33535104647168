import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5percent from '@amcharts/amcharts5/percent';

const AdvAnalyticsChartPie = (props: any) => {
    const { data } = props;
    const chartRef = useRef(null);

    useLayoutEffect(() => {
        if (chartRef.current) {
            const root = am5.Root.new(chartRef.current);
            if (root._logo) {
                root._logo.dispose();
            }
            root.setThemes([am5themes_Animated.new(root)]);

            const chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    layout: root.verticalLayout,
                }),
            );

            const series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    name: 'Series',
                    valueField: 'value',
                    categoryField: 'name',
                }),
            );

            const tooltip = am5.Tooltip.new(root, {
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 5,
                paddingBottom: 5,
            });
            tooltip.label.setAll({
                fontSize: 12,
            });

            series.slices.template.set('tooltip', tooltip);

            series.data.setAll(data);

            series.labels.template.set('visible', false);
            series.labels.template.set('disabled', true);
            series.ticks.template.set('disabled', false);
            series.slices.template.set('toggleKey', 'none');
            series.ticks.template.set('visible', false);
            series.labels.template.set('forceHidden', true);
            series.ticks.template.set('forceHidden', true);
            series.slices.template.set('hoverOnFocus', false);

            series.slices.template.states.create('hover', {
                scale: 1,
            });

            const legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.percent(50),
                    x: am5.percent(50),
                    layout: root.horizontalLayout,
                }),
            );
            legend.itemContainers.template.set('setStateOnChildren', false);

            return () => {
                root.dispose();
            };
        }
    }, [data]);

    return <div ref={chartRef} className="w-[160px] h-[140px] 2xl:w-[100px] 2xl:h-[100px] xxl:w-[140px] xxl:max-w-[140px] xxl:min-w-[140px] xxl:min-h-[140px] xxl:max-h-[140px] xxl:h-[140px]"></div>;
};

export default AdvAnalyticsChartPie;
