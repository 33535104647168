import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import { useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { dateFormate, getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import { allShopLocations, allShopStaff, currentShop } from 'src/redux/services/common/Common.slice';
import { Plus, Users01, ArrowUp, ArrowDown, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import NoDataMessage from 'src/components/NoDataMessage';
import { ROUTES } from 'src/constants/routes';
import { useNavigate } from 'react-router-dom';
import { usePhone } from 'src/hooks/usePhone';
import { PATH } from 'src/constants/path';
import moment from 'moment';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import { IclientStatusList, initialState } from './Client.interface';

const selectAllOption: { value: null | number; label: string } = {
    value: null,
    label: 'All Locations ',
};
const selectAllTeamOption: { value: null | number; label: string } = {
    value: null,
    label: 'All Team Members',
};
const ClientTable = ({ setActiveTab }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getFormatPhone } = usePhone();
    const shop = useAppSelector(currentShop);
    const shopLocationList = useAppSelector(allShopLocations);
    const allShopStaffList = useAppSelector(allShopStaff);
    const [clientArrayList, setClientArrayList] = useState<any>([]);
    const [totalClients, setTotalClients] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isImageError, setIsImageError] = useState<{ [key: number]: boolean }>({});
    const [clickedIndicator, setClickedIndicator] = useState(initialState[0]);
    const [clientStatusList, setClientStatusList] = useState<IclientStatusList[]>(initialState);
    const INIT_STATE = {
        FILTER: {
            shop_location_id: null,
            shop_admin_id: null,
        },
        LAZY_STATE: {
            first: 0,
            rows: 25,
            page: 1,
            sortField: null,
            sortOrder: null,
            filters: {
                global: { value: null },
                type: { value: clickedIndicator.value },
                shop_location_id: { value: null },
                shop_admin_id: { value: null },
            },
        },
    };
    const [lazyState, setLazyState] = useState<any>(INIT_STATE.LAZY_STATE);
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        type: { value: clickedIndicator.value },
        shop_location_id: { value: null },
        shop_admin_id: { value: null },
    });
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [selectedFilter, setSelectedFilter] = useState(INIT_STATE.FILTER);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        const updatedLocationData = [selectAllOption, ...locationData];
        setLocationOptions(updatedLocationData);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        const updatedTeamData = [selectAllTeamOption, ...staff];
        setStaffOptions(updatedTeamData);
        setSelectedFilter(INIT_STATE.FILTER);
        setLazyState(INIT_STATE.LAZY_STATE);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        listClients();
    }, [lazyState]);

    useEffect(() => {
        clientStatus();
    }, []);

    const listClients = () => {
        setIsLoading(true);
        axiosGet(API.CLIENT.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setClientArrayList(response.data.data.data);
                setTotalClients(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const clientStatus = async () => {
        axiosGet(API.CLIENT.STATUSCOUNT, { shop_id: shop.id }).then((response) => {
            const updatedState = initialState.map((item: any) => ({
                ...item,
                count: response.data.data[item.value] !== undefined ? response.data.data[item.value] : item.count,
            }));
            setClientStatusList(updatedState);
        });
    };

    useEffect(() => {
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, type: { value: clickedIndicator.value } } }));
    }, [clickedIndicator]);

    const handleClick = () => {
        navigate(ROUTES.CLIENT.CREATE);
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const handleImageError = (id: number) => () => {
        setIsImageError((old: any) => ({ ...old, [id]: true }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const getFullName = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.profile_image_url && !isImageError[row.id] ? (
                    <img src={row.profile_image_url} alt="" title={row.full_name} className="w-full h-full object-cover" onError={handleImageError(row.id)} />
                ) : (
                    getShortName(row.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-medium w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate capitalize">
                    {row.full_name}
                </p>
                <p className="text-xs font-normal text-secondaryTxtColor w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {row.created_at ? `Since ${moment(row.created_at).format('MMMM YYYY')}` : '-'}
                </p>
            </div>
        </div>
    );
    const getPhone = (row: any) => <div>{row.phone && getFormatPhone(row.phone, row.phone_country_code)}</div>;

    const handleRowClick = (row: any) => {
        navigate(`${PATH.CLIENT.VIEW}/${row.data.id}`);
    };

    const getAverageAmount = (row: any) => `$${row.average_amount}`;

    const getLastAppointment = (row: any) => `${row.booking_date ? dateFormate(row.booking_date) : '-'}`;

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                first_name: 'loading',
                phone: 'loading',
                email: 'loading',
                average_days: 'loading',
                average_amount: 'loading',
                bookings_count: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'active':
                return 'text-[#175CD3]';
            case 'loyal':
                return 'text-[#067647]';
            case 'recent':
                return 'text-[#F38744]';
            case 'new':
                return 'text-[#EE46BC]';
            case 'lapsed':
                return 'text-[#B54708]';
            default:
                return 'text-blueLight-700';
        }
    };

    const GetStatus = (row: any) => {
        const statusClass = getStatusColor(row.status);
        return (
            <div className="flex items-center">
                <span
                    className={`px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max `}
                >
                    <GoDotFill size={12} className={`${statusClass}`} />
                    {row.status}
                </span>
            </div>
        );
    };
    const handleTag = (tag: any) => {
        setClickedIndicator(tag);
    };
    return (
        <div
            className={`w-full border rounded-xl flex-1 flex-col flex shadow staff_service_table datatable-custom-service ${
                clientArrayList.length === 0 ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className={`flex items-center w-full flex-col `}>
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                    <div className="flex flex-col flex-1">
                        <div className="flex justify-start items-center gap-1.5 xxl:gap-1.5">
                            <h2 className="table-title">
                                {clickedIndicator.value === 'all' ? t('All') : clickedIndicator.label} {` Clients`}
                            </h2>
                            <p className={`userCounter ${isLoading ? 'custom-loading' : ''}`}>
                                <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                {totalClients} {t('Clients')}
                            </p>
                        </div>
                        <p className="table-subtitle">{t('View and manage your client list.')}</p>
                    </div>
                    <div className="flex gap-3">
                        <CustomButton outlinePrimary onClick={handleClick} icon={<Plus width="16" />} className="!px-4 py-[9px] w-full rounded-lg shadow-InputAndButton">
                            {t('Add new client')}{' '}
                        </CustomButton>
                    </div>
                </div>
                <div className="flex items-center px-5 py-3 justify-between w-full ">
                    <div className="table-searchInput">
                        <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                    </div>
                    <div className="flex gap-3">
                        <div className="w-[150px]">
                            <SelectBox
                                options={clientStatusList}
                                value={clientStatusList.find((option) => option.value === clickedIndicator.value)}
                                noOptionsMessage="No  Found"
                                placeholder={t('All Tags')}
                                onChangeFunc={handleTag}
                                classComp=""
                                isClearable={false}
                                isSearchable={false}
                            />
                        </div>
                        <div className="w-[170px] xl:w-[200px]">
                            <SelectBox
                                options={locationOptions}
                                value={locationOptions.find((option) => option.value === selectedFilter.shop_location_id)}
                                noOptionsMessage="No Locations Found"
                                placeholder={t('All locations')}
                                onChangeFunc={handleFilter('shop_location_id')}
                                classComp=""
                                isClearable={false}
                                isSearchable={false}
                            />
                        </div>

                        <div className="w-[170px] xl:w-[200px]">
                            <SelectBox
                                options={staffOptions}
                                noOptionsMessage="No Team Found"
                                value={staffOptions.find((option) => option.value === selectedFilter.shop_admin_id)}
                                placeholder={t('All Team members')}
                                onChangeFunc={handleFilter('shop_admin_id')}
                                classComp="outline-select-box"
                                isClearable={false}
                                isSearchable={false}
                            />
                        </div>
                        <div className="w-[70px] xl:w-[70px] page-dropdown">
                            <SelectBox
                                name="page"
                                options={perPageOptions}
                                isClearable={false}
                                onChangeFunc={onPageHandle}
                                value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                classComp="outline-select-box"
                                isSearchable={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col flex-1">
                <DataTable
                    lazy
                    paginatorTemplate={customPaginatorTemplate}
                    value={isLoading ? skeletons : clientArrayList}
                    totalRecords={totalClients}
                    paginator={!isLoading && totalClients > lazyState.rows}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    filters={lazyState.filters}
                    onRowClick={handleRowClick}
                    onPage={onPage}
                    onSort={onSort}
                    onFilter={onFilter}
                    selectionMode="single"
                    dataKey="id"
                    className="border-t rounded-b-xl overflow-hidden w-full"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <NoDataMessage
                            title={`${filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.type.value ? 'No clients found.' : 'No clients added.'}`}
                            description={`${
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.type.value
                                    ? 'Try adjusting your filters or add new clients.'
                                    : 'Add a clients, to start using the clients collection.'
                            }`}
                            iconComponent={
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value || filters.type.value ? (
                                    <SearchLg className="text-gray-700" />
                                ) : (
                                    <Users01 className="text-gray-700" />
                                )
                            }
                        />
                    }
                    rowClassName={(data) => 'cursor-pointer'}
                >
                    <Column
                        field="first_name"
                        header={renderHeader(t('Client'), 'first_name')}
                        className="text-xs leading-[18px] font-medium text-mainTextColor"
                        body={isLoading ? <Skeleton /> : getFullName}
                        style={{ width: '170px', minWidth: '170px', maxWidth: '170px' }}
                        sortable
                    ></Column>
                    <Column
                        field="status"
                        header={renderHeader(t('Status'), 'status')}
                        body={isLoading ? <Skeleton /> : GetStatus}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        className="text-xs font-medium text-secondaryTxtColor"
                        sortable
                    ></Column>
                    <Column
                        field="phone"
                        header={renderHeader(t('Phone Number'), 'phone')}
                        body={isLoading ? <Skeleton /> : getPhone}
                        className="font-medium text-secondaryTxtColor text-xs"
                        // style={{ width: '12%' }}
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        sortable
                    ></Column>
                    {/* <Column
                        field="email"
                        header={renderHeader(t('Email'), 'email')}
                        body={isLoading ? <Skeleton /> : undefined}
                        className="text-xs leading-[18px] font-medium text-secondaryTxtColor truncate"
                        // style={{ width: '14%' }}
                        style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                        sortable
                    ></Column> */}
                    <Column
                        field="average_days"
                        header={renderHeader(t('Last Appointment'), 'average_days')}
                        body={isLoading ? <Skeleton /> : getLastAppointment}
                        className="text-xs leading-[18px] font-medium text-secondaryTxtColor"
                        // style={{ width: '12%' }}
                        style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                        sortable
                    ></Column>
                    <Column
                        field="bookings_count"
                        body={isLoading ? <Skeleton /> : undefined}
                        className="text-xs leading-[18px] font-medium text-secondaryTxtColor"
                        // style={{ width: '12%' }}
                        header={renderHeader(t('Total Bookings'), 'bookings_count')}
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        sortable
                    ></Column>
                    <Column
                        field="average_amount"
                        header={renderHeader(t('Average spend'), 'average_amount')}
                        className="text-xs font-medium text-secondaryTxtColor"
                        body={isLoading ? <Skeleton /> : getAverageAmount}
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        // style={{ width: '10%' }}
                        sortable
                    ></Column>
                </DataTable>
            </div>
        </div>
    );
};

export default ClientTable;
