import React, { memo, FC, forwardRef } from 'react';
import { IProps } from './PopupModal.interface';
import { Modal } from 'flowbite-react';
import CustomButton from '../CustomButton';

const PopupModal: FC<IProps> = forwardRef(
    (
        {
            children,
            size = 'md',
            dismissible = false,
            position = 'center',
            onClose,
            title = '',
            className = '',
            primaryButton = '',
            secondaryButton = '',
            acceptAction = () => {},
            declineAction = () => {},
            isLoading = false,
            isCrossSign = true,
            isDisabled = false,
            fixedHeightClass = '',
        },
        ref: any,
    ) => (
        <Modal
            show
            popup
            dismissible={dismissible}
            size={size}
            position={position}
            className="modal_backdrop"
            theme={{ content: { inner: `bg-white rounded-xl shadow dark:bg-gray-700`, base: `${size}` } }}
            onClose={onClose}
            ref={ref}
        >
            {title ? <Modal.Header className="border-b px-5 py-4 model-header-btn">{title}</Modal.Header> : isCrossSign ? <Modal.Header /> : ''}
            <Modal.Body className={fixedHeightClass}>{children}</Modal.Body>
            {primaryButton || secondaryButton ? (
                <Modal.Footer className="border-t px-5 py-4">
                    <div className="flex gap-4 w-full">
                        {secondaryButton && (
                            <CustomButton disabled={isLoading} className="w-1/2" secondary onClick={declineAction}>
                                {secondaryButton}
                            </CustomButton>
                        )}
                        <CustomButton isLoading={isLoading} disabled={isLoading || isDisabled} className={secondaryButton ? 'w-1/2' : 'w-full'} primary onClick={acceptAction}>
                            {primaryButton}
                        </CustomButton>
                    </div>
                </Modal.Footer>
            ) : (
                ''
            )}
        </Modal>
    ),
);

export default memo(PopupModal);
