import React, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import DropDownWithIcon from 'src/components/DropDownWithIcon';
import InputWithLabel from 'src/components/InputWithLabel';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import * as Yup from 'yup';
import { IBlockTime } from './Sidebar.Interface';
import { initAction, selectCalendarData, setData } from '../Calendar.slice';
import moment from 'moment-timezone';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { axiosDelete, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { currentShop } from 'src/redux/services/common/Common.slice';
import DateSelect from 'src/components/DateSelect/DateSelect';
import { useTranslation } from 'react-i18next';
import { ChevronDown, DotsVertical, Edit02, Trash01, X } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { IActionType } from '../Calendar.Interface';
import Switch from 'src/components/Switch/Switch';
type FieldKey = 'name';
type ActionType = 'new' | 'edit' | 'delete' | 'info' | null;

const BlockTime = () => {
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const calendar = useAppSelector(selectCalendarData);
    const [fromDate, setFromDate] = useState(moment(calendar.selectedDate).toDate());
    const [fromTime, setFromTime] = useState(moment(calendar.selectedTime).toDate());
    const [toDate, setToDate] = useState(moment(calendar.selectedDate).toDate());
    const [toTime, setToTime] = useState(moment(calendar.selectedTime).toDate());
    const [staffId, setStaffId] = useState(null);
    const [deletePopup, setDeletePopup] = useState(false);
    const [action, setAction] = useState<ActionType>(null);
    const { t } = useTranslation();
    const schema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
        fromTime: Yup.date().required('This field is required'),
        toTime: Yup.date().required('This field is required'),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
    } = useForm<IBlockTime>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            fromTime: fromTime,
            toTime: toTime,
        },
    });
    const [switch2, setSwitch2] = useState(false);
    const handleToggle = () => {
        setSwitch2(!switch2);
    };
    useEffect(() => {
        if (calendar.bookedSlotInfo !== null) {
            setFromDate(new Date(calendar.bookedSlotInfo.from));
            setFromTime(new Date(calendar.bookedSlotInfo.from));
            setToTime(new Date(calendar.bookedSlotInfo.to));
            setToDate(new Date(calendar.bookedSlotInfo.to));
            setAction('info');
            setStaffId(calendar.bookedSlotInfo.staff_id);
            setValue('name', calendar.bookedSlotInfo.name);
        } else {
            setAction('new');
        }
    }, [calendar.bookedSlotInfo]);

    const onSubmit = (data: any) => {
        const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
        const formattedFromTime = format(fromTime, 'HH:mm:ss');
        const fromDateTime = `${formattedFromDate} ${formattedFromTime}`;
        const formattedToDate = format(toDate, 'yyyy-MM-dd');
        const formattedToTime = format(toTime, 'HH:mm:ss');
        const toDateTime = `${formattedToDate} ${formattedToTime}`;

        const payload = {
            is_full_day: false,
            name: data.name,
            from: fromDateTime,
            to: toDateTime,
        };

        if (action === 'edit') {
            const params = {
                shop_id: shop.id,
                staff_id: staffId,
                id: calendar.bookedSlotInfo.id,
            };
            axiosPatch(API.STAFF_BLOCK_TIME.UPDATE, payload, params)
                .then((response) => {
                    const datas = response.data.data;
                    dispatch(
                        setData({
                            getBookingList: true,
                            calendarStep: updatedCalendarStep('blockTime'),
                            bookedSlotInfo: { ...calendar.bookedSlotInfo, ...datas },
                            selectedSlotStaffId: Number(datas.staff_id),
                        }),
                    );
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as FieldKey, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                });
        } else {
            const params = {
                shop_id: shop.id,
                staff_id: calendar.selectedSlotStaffId,
            };
            axiosPost(API.STAFF_BLOCK_TIME.CREATE, payload, params)
                .then((response) => {
                    const datas = response.data.data;
                    dispatch(
                        setData({
                            getBookingList: true,
                            calendarStep: updatedCalendarStep('blockTime'),
                            bookedSlotInfo: { ...calendar.bookedSlotInfo, ...datas },
                            selectedSlotStaffId: Number(datas.staff_id),
                        }),
                    );
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as FieldKey, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                });
        }
    };

    const updatedCalendarStep = (activeKey: keyof IActionType) =>
        Object.keys(initAction).reduce((acc, key) => {
            acc[key as keyof IActionType] = key === activeKey; // Only the activeKey is true, others false
            return acc;
        }, {} as IActionType);

    const actionBtnIcon = [
        {
            label: <span>Edit</span>,
            value: 'edit_blocktime',
            icon1: <Edit02 className="text-secondaryTxtColor w-4 h-4" />,
            icon1Position: 'left',
            // icon2: <Trash01 className="text-secondaryTxtColor" />,
            // icon2Position: 'right',
        },

        {
            label: <span> Delete</span>,
            value: 'delete_blocktime',
            icon1: <Trash01 className="text-secondaryTxtColor w-4 h-4" />,
            icon1Position: 'left',
            // icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            // icon2Position: 'right',
        },
    ];
    const handleOption = (e: any) => {
        if (e.value === 'edit_blocktime') {
            setAction('edit');
        } else if (e.value === 'delete_blocktime') {
            setDeletePopup(true);
        }
    };
    const handleClose = () => {
        dispatch(setData({ isSidebarOpen: false }));
    };

    const handleModalClose = useCallback(
        (status: boolean = false) =>
            () => {
                setDeletePopup(false);
                if (status) {
                    axiosDelete(
                        API.STAFF_BLOCK_TIME.DELETE,
                        {},
                        {
                            shop_id: shop.id,
                            id: calendar.bookedSlotInfo.id,
                            staff_id: calendar.bookedSlotInfo.staff_id,
                        },
                    )
                        .then(() => {
                            dispatch(setData({ getBookingList: true }));
                            handleClose();
                        })
                        .catch((error) => {
                            toast.error(error?.message);
                        });
                }
            },
        [],
    );

    const handleSelectedTime = (type: 'from' | 'to') => async (time: any) => {
        if (type === 'from') {
            setFromTime(moment(time).toDate());
        } else {
            setToTime(moment(time).toDate());
        }
    };
    const handleNewAppointment = () => {
        dispatch(setData({ calendarStep: updatedCalendarStep('newAppointment') }));
    };
    return (
        <div className="h-full">
            <form className="h-full flex flex-col">
                <div className="header flex justify-between px-4 py-4 items-center border-b">
                    <div>
                        <div className="">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">
                                {action === 'new' ? 'Add New Block Time' : action === 'edit' ? 'Edit Block Time' : 'Block Time Details'}
                            </h1>
                            {action === 'new' && (
                                <div className="text-xs font-normal leading-[18px] text-mainTextColor flex items-center">
                                    <div className="flex items-center">
                                        <span className="mr-1.5 text-gray-600 leading-[18px] font-medium text-xs">Or</span>
                                        <div className="text-primary cursor-pointer font-semibold text-xs leading-[18px]" onClick={handleNewAppointment}>
                                            New Appointment
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {action !== 'info' && (
                        <div>
                            <button className="close-btn" type="button" onClick={() => handleClose()}>
                                <X className="text-gray-700" />
                            </button>
                        </div>
                    )}

                    {action === 'info' && (
                        <DropDownWithIcon
                            dropwDownContainerClass="ml-auto close-btn"
                            className=""
                            labelIcon={<DotsVertical className="w-[18px]" />}
                            labelActiveIcon={<DotsVertical className="w-[18px]" />}
                            dropDownMenuClassName="top-[39px] w-[140px]"
                            dropDownMenuItemClassName="border-b text-left last:border-0"
                            dropDownMenuLinkClassName="pl-2"
                            data={actionBtnIcon}
                            setselected={handleOption}
                        />
                    )}
                </div>
                <div className="flex-1 overflow-y-auto py-4">
                    {action === 'info' && (
                        <div className="">
                            <div className="px-5 pb-5 border-b border-borderSecondary">
                                <div className=" border border-borderSecondary rounded-lg">
                                    <h3 className="p-2.5 border-b border-borderSecondary font-semibold text-base ">{calendar.bookedSlotInfo.name}</h3>
                                    <div className="flex flex-col gap-1 p-2.5  border-b border-borderSecondary">
                                        <div className=" text-secondaryTxtColor w-full text-xs font-medium ">FROM:</div>
                                        <p className="font-medium text-sm text-mainTextColor">{`${moment(calendar.bookedSlotInfo.from, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}</p>
                                    </div>
                                    <div className="flex flex-col gap-1 p-2.5  ">
                                        <div className="text-secondaryTxtColor w-full text-xs font-medium">TO:</div>
                                        <p className="font-medium text-sm text-mainTextColor">{`${moment(calendar.bookedSlotInfo.to, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 border-b border-borderSecondary">
                                <div className=" border border-borderSecondary rounded-lg">
                                    <div className="flex flex-col gap-1 p-2.5  border-b border-borderSecondary">
                                        <span className="text-secondaryTxtColor w-full text-xs font-medium ">CREATED ON:</span>
                                        <span className="font-medium text-sm text-mainTextColor">{` ${moment(calendar.bookedSlotInfo.created_at, 'YYYY-MM-DD HH:mm:ss').format(
                                            'ddd, MMM D [at] h:mm A',
                                        )}`}</span>
                                    </div>
                                    {calendar.bookedSlotInfo.created_by_admin && (
                                        <div className="flex flex-col gap-1 p-2.5  border-b border-borderSecondary">
                                            <span className="text-secondaryTxtColor w-full text-xs font-medium ">CREATED BY:</span>
                                            <span className="font-medium text-sm text-mainTextColor">{calendar.bookedSlotInfo.created_by_admin.full_name}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {(action === 'edit' || action === 'new') && (
                        <>
                            <div className="px-4">
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="mb-4">
                                            <InputWithLabel label="Title" placeholder="Enter Title" onChange={onChange} value={value} name="name" error={!!errors.name} />
                                            {errors?.name && <p className="text-error">{errors?.name?.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-full px-4 mb-4 ">
                                <DateSelect
                                    inputName="start_date"
                                    label="Select date range"
                                    className={`form_control_date  font-medium !text-[12px] relative pr-7 `}
                                    placeholder={t('Select date')}
                                    dateFormat="MMM do, yyyy"
                                    isFormate={false}
                                    // InputClass={`${fieldState.error && 'is-invalid'}`}
                                    // value={moment(date).toDate()}
                                    onChangeValue={(date) => {
                                        setFromDate(date[0]);
                                        setToDate(date[1]);
                                    }}
                                    isRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    labelEndIcon={<ChevronDown className="text-gray-700 w-5 ml-1 h-[22px] right-[-6px]" />}
                                />
                            </div>
                            <div className="flex justify-between mb-4 px-4 gap-4 items-center w-full date-range-select">
                                <div className="w-1/2">
                                    <DateSelect
                                        inputName="start_date"
                                        label="Select duration"
                                        className={`form_control_date  font-medium text-xs relative w-full `}
                                        placeholder={t('Select date')}
                                        isFormate={false}
                                        isTime={true}
                                        timeIntervals={15}
                                        timeCaption="Time Selected"
                                        dateFormat="h:mm aa"
                                        // InputClass={`${fieldState.error && 'is-invalid'}`}
                                        value={fromTime}
                                        onChangeValue={handleSelectedTime('from')}
                                        labelEndIcon={<ChevronDown className="text-gray-700 w-5 ml-1 h-[22px] right-[-6px]" />}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <DateSelect
                                        inputName="start_date"
                                        label="Select duration"
                                        labelClassName="opacity-0"
                                        className={`form_control_date  font-medium text-xs relative w-full `}
                                        placeholder={t('Select date')}
                                        isFormate={false}
                                        isTime={true}
                                        timeIntervals={15}
                                        timeCaption="Time Selected"
                                        dateFormat="h:mm aa"
                                        // InputClass={`${fieldState.error && 'is-invalid'}`}
                                        value={toTime}
                                        onChangeValue={handleSelectedTime('to')}
                                        labelEndIcon={<ChevronDown className="text-gray-700 w-5 ml-1 h-[22px] right-[-6px]" />}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-end px-4 border-b pb-4">
                                <div className="flex items-center justify-end">
                                    <p className="text-gray-700 font-medium text-xs leading-[18px] mr-2">Full Day</p>
                                    <Switch id="recurring_appointment" isChecked={switch2} onChange={handleToggle} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {(action === 'edit' || action === 'new') && (
                    <div className="border-t px-5 py-4">
                        <div className="flex gap-4 w-full">
                            <CustomButton className="w-1/2" secondary onClick={handleClose}>
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton type="button" className="w-1/2" primary onClick={handleSubmit(onSubmit)}>
                                {t('Save Changes')}
                            </CustomButton>
                        </div>
                    </div>
                )}
                {action === 'info' && (
                    <div className="border-t px-5 py-4">
                        <div className="flex gap-4 w-full">
                            <CustomButton className="w-full" secondary onClick={handleClose}>
                                {t('Close')}
                            </CustomButton>
                        </div>
                    </div>
                )}
            </form>
            {deletePopup && <DeletePopupModal onClose={handleModalClose} size="w-[400px]" title={t('Delete Time Block')} description={t('Are you sure you want to delete Time Block?')} />}
        </div>
    );
};

export default BlockTime;
