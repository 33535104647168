import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import CustomButton from 'src/components/CustomButton';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, currentShop } from 'src/redux/services/common/Common.slice';
import { dateTimeFormate, getSelectBoxOptions } from 'src/utils/global-functions';
import { AllLocationsOptions, perPageOptions } from 'src/utils/global-variables';
import { Plus, SearchLg, ArrowUp, ArrowDown, Edit01 } from '@untitled-ui/icons-react/build/cjs';
import { DataTable } from 'primereact/datatable';
import NoDataMessage from 'src/components/NoDataMessage';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useParams } from 'react-router-dom';
import InventoryForm from './InventoryForm';
import { InventoryAction } from './Products.interface';

const Inventory = ({ product }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const shopLocationList = useAppSelector(allShopLocations);
    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState(InventoryAction);
    const [inventory, setInventory] = useState([]);
    const [totalInventories, setTotalInventories] = useState<number>(0);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [inventoryDetails, setInventoryDetails] = useState<any>(null);
    const [selectedFilter, setSelectedFilter] = useState({
        shop_location_id: null,
    });
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            shop_location_id: { value: null },
        },
    });
    const [filters, setFilters] = useState<any>({
        shop_location_id: { value: null },
    });

    const listInventories = async () => {
        setIsLoading(true);
        await axiosGet(API.PRODUCT.INVENTORY, { shop_id: shop.id, id }, lazyState)
            .then((response) => {
                setInventory(response.data.data.data);
                setTotalInventories(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const getInventoriesByLocation = async () => {
        setIsLoading(true);
        await axiosGet(API.PRODUCT.LOCATION_VARIANT, { shop_id: shop.id, id })
            .then((response) => {
                setInventoryDetails(response.data.data);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getInventoriesByLocation();
    }, []);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);
    }, []);

    useEffect(() => {
        listInventories();
    }, [lazyState, shop]);

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleStock =
        (type: string = '', row: any = null) =>
        () => {
            setAction((old) => ({ ...old, [type]: true }));
            if (type === 'update' && row) {
                setAction((old) => ({ ...old, row }));
            }
        };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : null;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const getSize = (row: any) => `${row.variant.size} ${product.unit_of_measure}`;
    const getLocation = (row: any) => row.location.name;
    // const getDate = (row: any) => (row.created_at ? moment(row.created_at, moment.ISO_8601).format('DD MMM YYYY') : '-');
    const getDate = (row: any) => (row.created_at ? dateTimeFormate(row.created_at) : '-');
    const getPrice = (row: any) => `$${row.price}`;
    const getCost = (row: any) => `$${row.cost}`;
    const getStock = (row: any) => `${row.in_stock_quantity}`;
    const getTotalSold = (row: any) => `${row.total_booking_quantity}`;
    const getAction = (row: any) => (
        <div className="flex items-center gap-1">
            <CustomButton className="shadow-none w-4 !px-0" onClick={handleStock('update', row)}>
                <Edit01 className="h-4 w-4 text-gray-600" />
            </CustomButton>
        </div>
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                first_name: 'loading',
                phone: 'loading',
                email: 'loading',
                average_days: 'loading',
                average_amount: 'loading',
                bookings_count: 'loading',
                status: 'loading',
                action: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const handleModalClose = useCallback(() => {
        setAction(InventoryAction);
    }, []);

    return (
        <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow  datatable-custom">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div className="flex flex-col">
                    <div className="flex justify-start items-center">
                        <h2 className="view-table-title capitalize-first ">
                            {t('Inventory Management')}
                            <p className="userCounter ml-1.5">
                                <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                {totalInventories}
                                {t(' ')}
                                {t('Products')}
                            </p>
                        </h2>
                    </div>
                    <p className="view-table-subtitle">{t('Add, edit, and manage product stock with price and cost.')}</p>
                </div>

                <div className="flex flex-row gap-4">
                    <div className="w-[200px] location-dropdown">
                        <SelectBox
                            options={locationOptions}
                            value={locationOptions.find((l) => l.value === selectedFilter.shop_location_id)}
                            noOptionsMessage="No Locations Found"
                            placeholder={t('All locations')}
                            onChangeFunc={handleFilter('shop_location_id')}
                            classComp=""
                            isClearable={false}
                            isSearchable={false}
                        />
                    </div>
                    <div>
                        <CustomButton outlinePrimary onClick={handleStock('create')} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                            {t('Add new stock')}
                        </CustomButton>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <DataTable
                    lazy
                    value={isLoading ? skeletons : inventory}
                    totalRecords={totalInventories}
                    paginator={!isLoading && totalInventories > lazyState.rows}
                    paginatorTemplate={customPaginatorTemplate}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    filters={lazyState.filters}
                    onPage={onPage}
                    onSort={onSort}
                    onFilter={onFilter}
                    dataKey="id"
                    className="rounded-b-xl overflow-hidden"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                            <NoDataMessage
                                title={`${filters.shop_location_id.value ? 'No Inventory Found.' : 'No Inventory Added.'}`}
                                description={`${
                                    filters.shop_location_id.value ? 'Try adjusting your filters or add new inventories.' : 'Add a inventories, to start using the inventories collection.'
                                }`}
                                iconComponent={<SearchLg className="text-gray-700" />}
                            />
                        </div>
                    }
                >
                    <Column
                        field="shop_product_variants.size"
                        header={renderHeader(t('Unit'), 'shop_product_variants.size')}
                        className="text-xs leading-[18px] font-medium text-mainTextColor  truncate"
                        style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                        body={isLoading ? <Skeleton /> : getSize}
                        sortable
                    ></Column>
                    <Column
                        field="shop_locations.name"
                        header={renderHeader(t('Location'), 'shop_locations.name')}
                        style={{ width: '110px', minWidth: '110px', maxWidth: '110px' }}
                        body={isLoading ? <Skeleton /> : getLocation}
                        className="font-medium text-secondaryTxtColor text-xs  truncate"
                        sortable
                    ></Column>
                    <Column
                        field="created_at"
                        header={renderHeader(t('Date'), 'created_at')}
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        body={isLoading ? <Skeleton /> : getDate}
                        className="text-xs leading-[18px] font-medium text-secondaryTxtColor truncate "
                        sortable
                    ></Column>
                    <Column
                        field="total_booking_quantity"
                        header={renderHeader(t('Total Sold'), 'total_booking_quantity')}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        body={isLoading ? <Skeleton /> : getTotalSold}
                        className="text-xs leading-[18px] font-medium text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="in_stock_quantity"
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        body={isLoading ? <Skeleton /> : getStock}
                        className="text-xs leading-[18px] font-medium text-secondaryTxtColor  truncate"
                        header={renderHeader(t('In Stock'), 'in_stock_quantity')}
                        sortable
                    ></Column>
                    <Column
                        field="cost"
                        header={renderHeader(t('Unit Cost'), 'cost')}
                        className="text-xs font-medium text-secondaryTxtColor  truncate"
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        body={isLoading ? <Skeleton /> : getCost}
                        sortable
                    ></Column>
                    <Column
                        field="price"
                        header={renderHeader(t('Unit Sell Price'), 'price')}
                        body={isLoading ? <Skeleton /> : getPrice}
                        style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                        className="text-xs font-medium text-secondaryTxtColor  truncate"
                        sortable
                    ></Column>
                    <Column field="action" header="" className="text-xs font-medium" style={{ width: '25px', minWidth: '25px', maxWidth: '25px' }} body={isLoading ? <Skeleton /> : getAction}></Column>
                </DataTable>
            </div>
            {(action.create || action.update) && (
                <InventoryForm
                    handleClose={handleModalClose}
                    inventoryDetails={inventoryDetails}
                    product={product}
                    listInventories={listInventories}
                    getInventoriesByLocation={getInventoriesByLocation}
                    targetInventory={action.row}
                />
            )}
        </div>
    );
};

export default Inventory;
