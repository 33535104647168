import React from 'react';

const SSidebar = () => (
    <div className="h-full flex flex-col">
        <div className="p-4 flex items-center justify-between border-b">
            <div className="flex flex-col gap-1.5">
                <div className="custom-loading">Loading Loading Loading</div>
                <div className="w-1/2 custom-loading">Loading</div>
            </div>
            <div className="custom-loading w-[40px] h-[40px]">Loading</div>
        </div>
        <div className="p-4 flex-1 overflow-y-auto">
            <div className="mb-2">
                <div className="mb-1.5 w-1/3 custom-loading">Loading</div>
                <div className="mb-3 pb-3 flex flex-row border-b border-borderSecondary items-center">
                    <div className="flex flex-col w-1/2 custom-loading">
                        <div className="flex items-center">
                            <figure className="w-[20px] h-[20px] mr-2"></figure>
                            <div className="flex">
                                <p className="max-w-[110px]"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div className="mb-1.5 w-1/3 custom-loading">Loading</div>
                <div className="mb-3 pb-3 flex flex-row border-b border-borderSecondary items-center">
                    <div className="flex flex-col w-1/2 custom-loading">
                        <div className="flex items-center">
                            <figure className="w-[20px] h-[20px] mr-2"></figure>
                            <div className="flex">
                                <p className="max-w-[110px]"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div className="mb-1.5 w-1/3 custom-loading">Loading</div>
                <div className="mb-3 pb-3 flex flex-row border-b border-borderSecondary items-center">
                    <div className="flex flex-col w-1/2 custom-loading">
                        <div className="flex items-center">
                            <figure className="w-[20px] h-[20px] mr-2"></figure>
                            <div className="flex">
                                <p className="max-w-[110px]"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div className="mb-1.5 w-1/3 custom-loading">Loading</div>
                <div className="mb-3 pb-3 flex flex-row border-b border-borderSecondary items-center">
                    <div className="flex flex-col w-1/2 custom-loading">
                        <div className="flex items-center">
                            <figure className="w-[20px] h-[20px] mr-2"></figure>
                            <div className="flex">
                                <p className="max-w-[110px]"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div className="mb-1.5 w-1/3 custom-loading">Loading</div>
                <div className="mb-3 pb-3 flex flex-row border-b border-borderSecondary items-center">
                    <div className="flex flex-col w-1/2 custom-loading">
                        <div className="flex items-center">
                            <figure className="w-[20px] h-[20px] mr-2"></figure>
                            <div className="flex">
                                <p className="max-w-[110px]"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div className="mb-1.5 w-1/3 custom-loading">Loading</div>
                <div className="mb-3 pb-3 flex flex-row items-center">
                    <div className="flex flex-col w-1/2 custom-loading">
                        <div className="flex items-center">
                            <figure className="w-[20px] h-[20px] mr-2"></figure>
                            <div className="flex">
                                <p className="max-w-[110px]"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="border-t px-5 py-4">
            <div className="flex gap-4 w-full">
                <div className="w-1/2 custom-loading">Loading</div>
                <div className="w-1/2 custom-loading">Loading</div>
            </div>
        </div>
    </div>
);

export default SSidebar;
