import { SECRET_KEY } from 'src/constants/encryptDecryptKey';

export const encryptEnvVariable = async (value: string): Promise<string> => {
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const alg = { name: 'AES-GCM', iv };
    const key = await crypto.subtle.importKey('raw', stringToBuffer(SECRET_KEY, 16), alg, false, ['encrypt']);
    const encrypted = await crypto.subtle.encrypt(alg, key, stringToBuffer(value, value.length));

    const combined = new Uint8Array(iv.byteLength + encrypted.byteLength);
    combined.set(iv, 0);
    combined.set(new Uint8Array(encrypted), iv.byteLength);
    const response = arrayBufferToBase64(combined.buffer);
    return response;
};

export const decryptEnvVariable = async (encryptedData: string): Promise<string> => {
    const combinedBuffer = base64ToArrayBuffer(encryptedData);
    const combined = new Uint8Array(combinedBuffer);
    const iv = combined.slice(0, 12); // First 12 bytes are IV
    const encrypted = combined.slice(12);

    const alg = { name: 'AES-GCM', iv };
    const key = await crypto.subtle.importKey('raw', stringToBuffer(SECRET_KEY, 16), alg, false, ['decrypt']);
    const decrypted = await crypto.subtle.decrypt(alg, key, encrypted);
    return new TextDecoder().decode(decrypted);
};

const stringToBuffer = (str: string, length: number): ArrayBuffer => {
    const encoder = new TextEncoder();
    const encoded = encoder.encode(str);
    if (encoded.length > length) {
        return encoded.slice(0, length);
    }
    const padded = new Uint8Array(length);
    padded.set(encoded); // Pad with 0s if too short
    return padded.buffer;
};

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    const uint8Array = new Uint8Array(buffer);
    return btoa(String.fromCharCode(...Array.from(uint8Array)));
};

const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};
