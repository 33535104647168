import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Switch from 'src/components/Switch/Switch';

const General = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="flex flex-col flex-1">
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Daily Checkout Reminder')}</p>
                    <p className="subtitle-text">{t('Reminder to complete daily checkouts.')}</p>
                </div>
                <Controller
                    name="daily_checkout"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('New Client')}</p>
                    <p className="subtitle-text">{t('Notify when a new client signs up.')}</p>
                </div>
                <Controller
                    name="new_user"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Monthly Statement Ready')}</p>
                    <p className="subtitle-text">{t('Alert for the availability of your monthly statement.')}</p>
                </div>
                <div className="flex flex-col w-[636px] gap-4 ">
                    <Controller
                        name="monthly_statement"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-[512px] gap-4 ">
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                    <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                                </div>
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Commission Statement Ready')}</p>
                    <p className="subtitle-text">{t('Get notified when your commission statement is available.')}</p>
                </div>
                <div className="flex flex-col w-[636px] gap-4 ">
                    <Controller
                        name="commission_statement"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                    <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                                </div>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Rent Collection Statement')}</p>
                    <p className="subtitle-text">{t('Notification when rent collection statements are ready.')}</p>
                </div>
                <div className="flex flex-col w-[636px] gap-4 ">
                    <Controller
                        name="rent_statement"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                    <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                                </div>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Tip Payouts Statement Ready')}</p>
                    <p className="subtitle-text">{t('Notification for available tip payouts statements.”')}</p>
                </div>
                <div className="flex flex-col w-[636px] gap-4 ">
                    <Controller
                        name="tip_statement"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                    <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                                </div>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Product Commission Statement')}</p>
                    <p className="subtitle-text">{t('Be alerted when product commission statements are available.')}</p>
                </div>
                <div className="flex flex-col w-[636px] gap-4 ">
                    <Controller
                        name="product_commission_statement"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                    <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                    <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                                </div>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Team Member Onboarding')}</p>
                    <p className="subtitle-text">{t('Receive alerts when new team members complete onboarding.')}</p>
                </div>
                <Controller
                    name="staff_completed_registration"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b border-borderSecondary py-4">
                <div className=" w-[310px]">
                    <p className="title-text">{t('Minimum Stock Alert')}</p>
                    <p className="subtitle-text">{t('Stay updated on low stock levels.')}</p>
                </div>
                <Controller
                    name="low_stock"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-[512px] gap-4 ">
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, push: e.target.value })} isChecked={value.push} />
                                <label className="text-[#344054] text-sm font-medium">{t('Push')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, email: e.target.value })} isChecked={value.email} />
                                <label className="text-[#344054] text-sm font-medium">{t('Email')}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Switch onChange={(e) => onChange({ ...value, sms: e.target.value })} isChecked={value.sms} />
                                <label className="text-[#344054] text-sm font-medium">{t('SMS')}</label>
                            </div>
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default General;
