import React, { FC, memo, useState, useMemo } from 'react';
import { IResourceHeader } from './Calendar.Interface';
import { capitalizeFirstLetterSpace, getShortName } from 'src/utils/global-functions';

const ResourceHeader: FC<IResourceHeader> = ({ eventInfo }) => {
    const [isImageError, setIsImageError] = useState<boolean>(false);

    const handleImageError = () => setIsImageError(true);

    const resourceData = useMemo(
        () => ({
            profileImage: eventInfo.resource.profile_image_url,
            shortName: getShortName(eventInfo.resource.name),
            name: capitalizeFirstLetterSpace(eventInfo.resource.name),
            role: eventInfo.resource.role,
        }),
        [eventInfo.resource],
    );

    return (
        <div className="flex px-1.5 pb-[12px] pt-[10px] items-center">
            <figure className="NoImgName h-10 w-10 mr-2">
                {resourceData.profileImage && !isImageError ? <img src={resourceData.profileImage} alt="" className="w-full h-full object-cover" onError={handleImageError} /> : resourceData.shortName}
            </figure>
            <div className="flex-1 text-left">
                <h3 className="text-gray-900 font-medium text-xs leading-[18px] truncate max-w-[86px] xlm:max-w-[90px] 2xlm:max-w-[128px] xxl:max-w-[136px]">{resourceData.name}</h3>
                <p className="text-gray-600 font-normal text-xs leading-[18px] truncate max-w-[86px] xlm:max-w-[90px] 2xlm:max-w-[128px] xxl:max-w-[136px]">{resourceData.role}</p>
            </div>
        </div>
    );
};

export default memo(ResourceHeader);
