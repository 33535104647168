import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import PageHeader from 'src/components/PageHeader';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, allShopStaff, currentRole, currentShop } from 'src/redux/services/common/Common.slice';
import { getDateRange, getSelectBoxOptions } from 'src/utils/global-functions';
import moment from 'moment';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import Overview from './Overview';
import SalesChart from './SalesChart';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import AdvAnalytics from './AdvAnalytics';
import { OWNER_RIGHTS_ROLES, ROLES } from 'src/constants/common';
import { graphOptions, IAnalyticsDetails } from './Analytics.interface';
import Performance from './Performance';
import { AllLocationsOptions, AllTeamMembersOptions } from 'src/utils/global-variables';
import ServiceTable from './ServiceTable';
import ProductTable from './ProductTable';
import ClientSplitTable from './ClientSplitTable';
import PaymentSplitTable from './PaymentSplitTable';
import SChart from '../Skeleton/Analytics/SChart';
import ServiceProductChart from './ServiceProductChart';

const Analytics = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const shopLocationList: any = useAppSelector(allShopLocations);
    const allShopStaffList = useAppSelector(allShopStaff);
    const role = useAppSelector(currentRole);
    const [isLoading, setIsLoading] = useState(true);
    const [AnalyticsDetails, setAnalyticsDetails] = useState<IAnalyticsDetails | null>(null);
    const [currentTab, setCurrentTab] = useState('daily');
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
    const hasPermission = OWNER_RIGHTS_ROLES.includes(role?.name);
    const [selectedFilter, setSelectedFilter] = useState({
        location_id: null,
        staff_id: null,
    });
    const [boxLabel, setBoxLabel] = useState('Yesterday');
    const [activeTab, setActiveTab] = useState({
        tab1: 'service',
        tab2: 'client',
    });

    const timePeriodTabs = useMemo(
        () => [
            {
                name: t('Today'),
                value: 'daily',
            },
            {
                name: t('This Week'),
                value: 'weekly',
            },
            {
                name: t('This Month'),
                value: 'monthly',
            },
            {
                name: t('This Year'),
                value: 'yearly',
            },
        ],
        [],
    );

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');

        setStaffOptions([AllTeamMembersOptions, ...staff]);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        getAnalytics();
    }, [selectedFilter, startDate, endDate]);

    useEffect(() => {
        if (startDate && endDate) {
            if (selectedTimeRange === 'all_time') {
                setBoxLabel('Previous period');
            } else if (selectedTimeRange === 'quarterly') {
                setBoxLabel('last quarter');
            } else if (currentTab === '') {
                let days = moment(endDate).diff(moment(startDate), 'days');
                days = selectedTimeRange === 'last_30_days' || selectedTimeRange === 'last_90_days' || selectedTimeRange === 'last_7_days' || selectedTimeRange === 'custom' ? days + 1 : days;

                setBoxLabel(`Previous ${days} days`);
            }
        }
    }, [startDate, endDate]);

    const getAnalytics = () => {
        const payload = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            type: 'custom',
            ...(selectedFilter.location_id !== null && { location_id: selectedFilter.location_id }),
            ...(selectedFilter.staff_id !== null && { staff_id: selectedFilter.staff_id }),
        };
        axiosGet(API.ANALYTICS.GET, { shop_id: shop.id }, payload)
            .then((response) => {
                const data = response.data.data;
                if (data.services_graph) {
                    data.services_graph = data.services_graph.map((service: any) => ({
                        ...service,
                        percentage: data.total.total_service_amount ? (service.total_amount / data.total.total_service_amount) * 100 : 0,
                    }));
                }
                if (data.products_graph) {
                    data.products_graph = data.products_graph.map((product: any) => ({
                        ...product,
                        percentage: data.total.total_product_amount ? (product.total_amount / data.total.total_product_amount) * 100 : 0,
                    }));
                }
                setAnalyticsDetails(data);
            })
            .finally(() => setIsLoading(false));
    };

    const handleTab = (val: string) => () => {
        setCurrentTab(val);
    };

    useEffect(() => {
        if (currentTab !== '') {
            const { start, end } = getDateRange(currentTab);
            setStartDate(start);
            setEndDate(end);
        }
        if (currentTab === 'weekly') {
            setBoxLabel('last week');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'monthly') {
            setBoxLabel('last month');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'yearly') {
            setBoxLabel('last year');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'daily') {
            setBoxLabel('yesterday');
            setSelectedTimeRange('custom');
        }
    }, [currentTab]);
    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setSelectedFilter((old: any) => ({ ...old, [filter]: value }));
    };
    const handleDatePickerChange = (date: any, timeRange?: string) => {
        if (timeRange === 'weekly' || timeRange === 'monthly' || timeRange === 'yearly') {
            setCurrentTab(timeRange);
        } else {
            setCurrentTab('');
            const [start, end] = date;

            setStartDate(start);
            setEndDate(end);
            if (!end) {
                setEndDate(start);
            }
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
    };

    const handleTabClick = (value: string, tab: string) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab((old) => ({ ...old, [tab]: value }));
    };

    const tabs1 = useMemo(
        () => [
            {
                name: 'Service Sales',
                type: 'service',
            },
            {
                name: 'Product Sales',
                type: 'product',
            },
        ],
        [],
    );

    const tabs2 = useMemo(
        () => [
            {
                name: 'Client Split',
                type: 'client',
            },
            {
                name: 'Payment Options',
                type: 'payment',
            },
        ],
        [],
    );

    const legends = useMemo(() => (role.name === ROLES.STAFF_RENT ? graphOptions.rent : graphOptions.owner), [role]);

    return (
        <div className="inner-page-wrape">
            <PageHeader title="Analytics" subtitle="Here's an analytics of your week so far"></PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full">
                <div className="flex flex-row  items-center mb-3 xxl:mb-4 gap-3">
                    <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-4 gap-3 xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] xls:w-[calc(50%_-_6px)] w-full">
                        <div className="grid max-2xl:grid-cols-2 grid-cols-3 w-full gap-3 xxl:gap-4">
                            <div className="fl-tab-btn-view2 h-[36px] min-h-[36px] flex shadow-custom-sm  w-full">
                                {timePeriodTabs.map((tab: any, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        onClick={handleTab(tab.value)}
                                        className={`w-full fl-tab-link2 min-w-fit  !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px] lg:min-h-[34px] min-h-[34px] flex justify-center items-center lg:py-1 py-2 xl:px-4 lg:px-4 sm:px-3 ${
                                            tab.value === currentTab ? 'active !bg-gray-100' : ''
                                        }`}
                                    >
                                        {tab.name}
                                    </button>
                                ))}
                            </div>
                            <DateRangePicker
                                isDisplayAsBox={false}
                                handleDatePickerChange={handleDatePickerChange}
                                selectedTimeRange={selectedTimeRange}
                                startDate={startDate}
                                endDate={endDate}
                                allTimeStartDate={shop.created_at}
                                showYearDropdown={false}
                                isToday={currentTab === 'daily'}
                                isShowDropDownIcon={false}
                                containerClassName="left-0 "
                                parentClassName="sales-datepicker w-full"
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                activeFilter={currentTab}
                                setActiveFilter={setCurrentTab}
                            />
                        </div>
                    </div>
                    <div className="flex sm:flex-row  flex-wrap xxl:gap-4 gap-3 sm:justify-end xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xls:w-[calc(50%_-_6px)] w-full empty:hidden ">
                        <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                            <SelectBox
                                options={locationOptions}
                                value={locationOptions.find((option) => option.value === selectedFilter.location_id)}
                                noOptionsMessage="No Locations Found"
                                placeholder={t('All locations')}
                                onChangeFunc={handleFilter('location_id')}
                                classComp="outline-select-box"
                                isSearchable={false}
                                isClearable={false}
                            />
                        </div>
                        {hasPermission && (
                            <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                                <SelectBox
                                    options={staffOptions}
                                    noOptionsMessage="No Team Found"
                                    value={staffOptions.find((option) => option.value === selectedFilter.staff_id)}
                                    placeholder={t('All Team members')}
                                    onChangeFunc={handleFilter('staff_id')}
                                    classComp="outline-select-box"
                                    isSearchable={false}
                                    isClearable={false}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full flex gap-3 mb-4 flex-1 flex-wrap xxl:gap-4">
                    <div className="flex flex-col gap-3 sm:flex-row flex-wrap xxl:gap-4  xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] xls:w-[calc(50%_-_6px)] w-full">
                        <Overview AnalyticsDetails={AnalyticsDetails} boxLabel={boxLabel} isLoading={isLoading} />
                        <div className="w-full flex flex-col flex-1 p-0 border border-gray-200 rounded-xl 2xl:min-h-[389px] xxl:min-h-[369px] xxl:max-h-[369px] shadow-default relative pb-1">
                            <div className="w-full p-5 pb-0 justify-between xls:items-center items-start flex sm:flex-row flex-col xxl:gap-4 gap-2 relative">
                                <div className=" text-gray-900 sm:text-lg text-base font-semibold gap-2 xs:justify-between xs:items-center xl:pr-4 mdm:flex-none flex-1">
                                    <div className="flex xs:flex-row flex-col xs:items-center justify-between">Total Sales</div>
                                    <p className="text-gray-600 text-sm font-normal">Shop total sales over time</p>
                                </div>
                                <div className="mdm:justify-center justify-end items-center mdm:gap-5 gap-2 sms:order-none -order-1 flex mr-1 sms:w-auto w-full sm:relative absolute sm:top-0 sm:right-[unset] xs:top-1 right-0 -top-5">
                                    {legends.map((option: any, index: number) => (
                                        <div key={index} className="rounded-[10px] justify-end items-center gap-2 flex">
                                            <div className="w-2 h-2 rounded-[10px]" style={{ backgroundColor: option.color }}></div>
                                            <div className="text-textSecondary text-xs font-normal leading-none">{option.label}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {isLoading ? <SChart /> : <SalesChart graph={AnalyticsDetails?.graph || []} currentTab={currentTab} />}
                        </div>
                    </div>
                    <div className="xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xls:w-[calc(50%_-_6px)] w-full flex flex-1 analytics dashboard-table">
                        <div className="w-full border border-borderSecondary rounded-xl flex flex-1 flex-col shadow-default">
                            <div className="w-full p-4 justify-between items-center flex flex-wrap gap-2">
                                <div className="text-mainTextColor text-grayLight900 text-base font-semibold leading-7">
                                    {role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT ? 'Top Clients' : 'Team Performance'}
                                </div>
                                {/* <div className="flex flex-row gap-1">
                                    <h4 className="font-semibold text-xs text-[#143CB3]">View All</h4>
                                    <ArrowUpRight className="w-4 h-4  text-[#143CB3]" />
                                </div> */}
                            </div>
                            <Performance startDate={moment(startDate).format('YYYY-MM-DD')} endDate={moment(endDate).format('YYYY-MM-DD')} selectedFilter={selectedFilter} />
                        </div>
                    </div>
                </div>
                <div className="w-full flex gap-3 mb-4 flex-1 flex-wrap xxl:gap-4">
                    <div className="flex flex-col gap-3 xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] xls:w-[calc(50%_-_6px)] w-full">
                        <div className="fl-tab-btn-view3 w-full">
                            {tabs1.map((tab, index) => (
                                <button key={index} type="button" className={`fl-tab-link3 !max-w-[182px] ${activeTab.tab1 === tab.type ? 'active' : ''}`} onClick={handleTabClick(tab.type, 'tab1')}>
                                    {tab.name}
                                </button>
                            ))}
                        </div>
                        {activeTab.tab1 === 'product' ? (
                            <div className="flex flex-row gap-5 w-full">
                                <div className="border border-borderSecondary rounded-lg shadow-default min-w-[320px] max-w-[320px] min-h-[364px] max-h-[364px]">
                                    <ServiceProductChart
                                        data={AnalyticsDetails ? AnalyticsDetails.products_graph : []}
                                        totalAmount={AnalyticsDetails ? AnalyticsDetails.total.total_product_amount : 0}
                                        label="Product Sales"
                                    />
                                </div>
                                <div className="w-full border border-borderSecondary rounded-lg shadow-default flex">
                                    <ProductTable data={AnalyticsDetails ? AnalyticsDetails.products_graph : []} isLoading={isLoading} />
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-row gap-5 w-full">
                                <div className="border border-borderSecondary rounded-lg shadow-default min-w-[320px] max-w-[320px] min-h-[364px] max-h-[364px]">
                                    <ServiceProductChart
                                        data={AnalyticsDetails ? AnalyticsDetails.services_graph : []}
                                        totalAmount={AnalyticsDetails ? AnalyticsDetails.total.total_service_amount : 0}
                                        label="Service Sales"
                                    />
                                </div>
                                <div className="w-full border border-borderSecondary rounded-lg shadow-default flex">
                                    <ServiceTable data={AnalyticsDetails ? AnalyticsDetails.services_graph : []} isLoading={isLoading} />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col gap-3 xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xls:w-[calc(50%_-_6px)] w-full">
                        <div className="fl-tab-btn-view3 w-full">
                            {tabs2.map((tab, index) => (
                                <button key={index} type="button" className={`fl-tab-link3 !max-w-[182px] ${activeTab.tab2 === tab.type ? 'active' : ''}`} onClick={handleTabClick(tab.type, 'tab2')}>
                                    {tab.name}
                                </button>
                            ))}
                        </div>
                        <div className="w-full border border-borderSecondary rounded-lg shadow-default flex flex-1 min-h-[364px] max-h-[364px]">
                            {activeTab.tab2 === 'payment' ? (
                                <PaymentSplitTable data={AnalyticsDetails?.payment_method_split || []} isLoading={isLoading} />
                            ) : (
                                <ClientSplitTable data={AnalyticsDetails?.user_split || []} isLoading={isLoading} />
                            )}
                        </div>
                    </div>
                </div>
                {role.name !== ROLES.STAFF_COMMISSION && role.name !== ROLES.STAFF_RENT && (
                    <div className="w-full flex flex-row mb-5">
                        <AdvAnalytics selectedFilter={selectedFilter} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Analytics;
