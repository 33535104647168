import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconCheckdActive, Light } from 'src/theme/Images';
import TemplateSettings from './Sections/TemplateSettings';
import { ITemplatePayload, TemplateType } from './Sections/TemplateSettings.interface';
import { axiosPost } from 'src/utils/requestClient';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { API } from 'src/constants/api';
import { allShopSettings, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import { CheckCircle, Eye } from '@untitled-ui/icons-react/build/cjs';
import { toast } from 'react-toastify';
const Template = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const templateData: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template')?.value;
    const templateInfo: any = templateData?.template;
    const settingIsActiveTemplate: any = templateData?.is_active_template;
    const [template, setTemplate] = useState<TemplateType>(templateInfo || 'template_1');
    const [isActiveTemplate, setIsActiveTemplate] = useState<boolean>(settingIsActiveTemplate || false);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleTemplate =
        (value: TemplateType, isPreview: boolean = false) =>
        async () => {
            if (!isPreview) {
                setTemplate(value);
            }
            const payload: ITemplatePayload = {
                template: value,
                is_active_template: isActiveTemplate,
                type: isPreview ? 'template_preview' : 'template',
            };
            await handleTemplateApi(payload);
        };
    const handleActiveTemplate = async () => {
        setIsActiveTemplate(!isActiveTemplate);
        const payload: ITemplatePayload = {
            template: template,
            is_active_template: !isActiveTemplate,
            type: 'template_preview',
        };
        await handleTemplateApi(payload, false);
    };

    const handleTemplateApi = async (payload: ITemplatePayload, isRedirect: boolean = true) => {
        if (payload.type === 'template') {
            setIsLoading(true);
        }

        await axiosPost(API.THEME.TEMPLATE.TEMPLATE, payload, {
            shop_id: shop.id,
        })
            .then(async () => {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
                if (payload.type === 'template_preview' && isRedirect) {
                    const url = `https://${shop.slug}.${process.env.REACT_APP_DOMAIN}?preview=true`;
                    window.open(url, '_blank');
                }
            })
            .catch((error) => {
                toast.error(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <div className="w-full pt-5 px-2 flex flex-col gap-5 ">
            <div className="fl-data-block border-b border-borderSecondary pb-5">
                <div className="title-block w-[310px]">
                    <h3 className="title-text">{t('Enable templates')}</h3>
                    <p className="subtitle-text">{t('Preface your website with a pre-made, modern marketing template to enhance your brand appearance.')}</p>
                </div>
                <div className="data-wrap-block max-w-[512px] xxl:max-w-[700px]">
                    <div className="flex gap-5">
                        <Switch onChange={handleActiveTemplate} isChecked={isActiveTemplate} />
                    </div>
                </div>
            </div>
            <div className="fl-data-block">
                <div className="title-block w-[310px]">
                    <h3 className="title-text">{t('Base template')}</h3>
                    <p className="subtitle-text">{t('Preview and select a base template for your marketing website.')}</p>
                </div>
                <div className="data-wrap-block max-w-[512px] xxl:max-w-[700px]">
                    <div className="flex gap-5">
                        <div className="flex flex-col gap-3">
                            <div className="w-[196px] relative">
                                <div className={`theme-box p-1  ${template === 'template_1' ? 'active' : ''}`}>
                                    <i className="icon">
                                        <img src={IconCheckdActive} alt="" className="" />
                                    </i>
                                    <div className="theme-block-thumb rounded-xl overflow-hidden">
                                        <img src={Light} height={'132px'} width={'192px'} alt="" />
                                    </div>
                                </div>

                                <div className="absolute inset-0 flex items-center justify-center gap-[10px] bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-all rounded-lg">
                                    <>
                                        <CustomButton
                                            type="button"
                                            className="outline-none  text-white w-10  h-10   rounded-lg bg-primary"
                                            onClick={handleTemplate('template_1', false)}
                                            icon={<CheckCircle className="w-5 h-5 " />}
                                            isLoading={isLoading}
                                            disabled={isLoading}
                                        />
                                        <CustomButton
                                            type="button"
                                            className="outline-none  text-gray-700 w-10  h-10 rounded-lg bg-white cursor-pointer"
                                            icon={isLoading ? '' : <Eye className="w-5" />}
                                            onClick={handleTemplate('template_1', true)}
                                        />
                                    </>
                                </div>
                            </div>
                            <p className="theme-box-title">Template 1</p>
                        </div>

                        <div className="w-[196px]">
                            <div className={`theme-box p-1 mb-3 ${template === 'template_2' ? 'active' : ''}`}>
                                <i className="icon">
                                    <img src={IconCheckdActive} alt="" className="" />
                                </i>
                                <div className="theme-block-thumb rounded-xl overflow-hidden">
                                    <img src={Light} height={'132px'} width={'192px'} alt="" />
                                </div>
                                <div className="absolute inset-0 flex items-center justify-center gap-[10px] bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-all rounded-lg">
                                    <>
                                        <CustomButton
                                            type="button"
                                            className="outline-none  text-white w-10 h-10 rounded-lg bg-primary"
                                            onClick={handleTemplate('template_2', false)}
                                            icon={isLoading ? '' : <CheckCircle className="w-5 " />}
                                            isLoading={isLoading}
                                            disabled={isLoading}
                                        />
                                        <CustomButton
                                            type="button"
                                            className="outline-none  text-gray-700 w-10 h-10 rounded-lg bg-white cursor-pointer"
                                            icon={<Eye className="w-5 text-gray-700" />}
                                            onClick={handleTemplate('template_2', true)}
                                        />
                                    </>
                                </div>
                            </div>
                            <p className="theme-box-title">Template 2</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${!isActiveTemplate ? 'hidden' : ''}`}>
                <TemplateSettings template={template} />
            </div>
        </div>
    );
};

export default Template;
