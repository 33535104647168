import AWS from 'aws-sdk';
import { decryptEnvVariable } from './EncryptDecryptEnvVariables';

export const s3Upload = (fileObj: any, path: any) =>
    new Promise(async (resolve) => {
        // const fileName = `${new Date().getTime().toString()}.${fileObj.name
        //     .split('.')
        //     .pop()}`;

        const accessKeyId = await decryptEnvVariable(process.env.REACT_APP_ACCESS_ID || '');
        const secretAccessKey = await decryptEnvVariable(process.env.REACT_APP_ACCESS_KEY || '');

        AWS.config.update({
            accessKeyId,
            secretAccessKey,
            region: process.env.REACT_APP_REGION,
        });
        const s3 = new AWS.S3();

        const params = {
            Bucket: 'flairmedia',
            Key: `${path}`,
            Body: fileObj,
            ContentType: fileObj.type,
        };

        const options = { partSize: 500 * 1024 * 1024, queueSize: 1 };

        s3.upload(params, options, (err, data) => {
            if (err) {
                resolve({ status: 500 });
            }
            if (data) {
                resolve({ status: 201, data });
            } else {
                resolve({ status: 500 });
            }
        });
    });
