import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { formatAmount } from 'src/utils/global-functions';
import { colorArray } from './Analytics.interface';

const ServiceProductChart = (props: any) => {
    const { data, totalAmount, label } = props;
    const chartRef = useRef(null);

    useLayoutEffect(() => {
        if (chartRef.current) {
            const root = am5.Root.new(chartRef.current);
            if (root._logo) {
                root._logo.dispose();
            }
            root.setThemes([am5themes_Animated.new(root)]);

            const chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    innerRadius: am5.percent(80),
                    layout: root.horizontalLayout,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingTop: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    // width: 140,
                    // height: 140,
                }),
            );

            const series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    alignLabels: false,
                    valueField: 'total_amount',
                    categoryField: 'name',
                    legendLabelText: '{category}',
                    legendValueText: '{value}',
                }),
            );

            let colors = colorArray.map((color) => am5.color(color));
            let seriesData = data;
            if (!seriesData.length) {
                seriesData = [{ name: 'No data', total_amount: 1 }];
                colors = [am5.color('#F2F4F7')];
                series.slices.template.set('tooltipText', '');
            }

            series?.get('colors')?.set('colors', colors);
            series.labels.template.set('forceHidden', true);
            series.ticks.template.set('forceHidden', true);
            series.ticks.template.set('visible', false);

            seriesData.sort((a: any, b: any) => b?.total_amount - a?.total_amount);
            series.data.setAll(seriesData);

            series.slices.template.setAll({
                toggleKey: 'none',
                hoverOnFocus: false,
            });
            series.slices.template.states.create('hover', {
                scale: 1,
            });

            series.appear(1000, 100);
            chart.seriesContainer.children.push(
                am5.Label.new(root, {
                    textAlign: 'center',
                    centerY: am5.p50,
                    centerX: am5.p50,
                    text: `[fontSize:14px]${label}[/]\n[bold fontSize:20px] ${formatAmount(totalAmount)}[/]`,
                }),
            );

            return () => {
                root.dispose();
            };
        }
    }, [data]);

    return <div ref={chartRef} className="w-full h-full"></div>;
};

export default ServiceProductChart;
