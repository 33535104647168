import React, { useCallback, useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import 'swiper/css';
import 'swiper/css/pagination';
import { CircleCheck, HomeSlider1, HomeSlider2, HomeSlider3 } from 'src/theme/Images';
import { Autoplay, Pagination } from 'swiper/modules';
import { CheckCircle } from '@untitled-ui/icons-react/build/cjs';
const SliderImage = [HomeSlider1, HomeSlider2, HomeSlider3];
const Sidebar = () => {
    const [swiperRef, setSwiperRef] = useState<SwiperType>();

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);
    const [swiperHeight, setSwiperHeight] = useState('h-screen');

    useEffect(() => {
        const handleResize = () => {
            const navbarHeight = 60; // Replace with the actual navbar height
            const windowHeight = window.innerHeight;
            const calculatedHeight = windowHeight - navbarHeight;
            setSwiperHeight(`${calculatedHeight}px`); // Set the calculated height
        };

        // Calculate the height on component mount
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array ensures this runs once on mount
    return (
        <div className={` ${swiperHeight} w-full rounded-md py-4 pe-4 h-full main_slider`}>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                    enabled: true,
                    clickable: true, // Enable clickable pagination bullets
                }}
                autoplay={{
                    delay: 5000,
                }}
                modules={[Pagination, Autoplay]}
                onSwiper={setSwiperRef}
                navigation={false} // Disable Swiper default navigation
                loop={true}
                className="mySwiper w-full h-full"
                // style={{ height: swiperHeight }} // Apply dynamic height here
            >
                {SliderImage.map((data, index) => (
                    <SwiperSlide key={index}>
                        <>
                            <div className="relative w-full h-full before:rounded-2xl before:content-{} before:absolute before:left-0 before:right-0 before:top-0 before:z-10 before:h-full before:w-full before:bg-black before:opacity-30">
                                <img src={data} alt="" className="w-full object-cover object-center rounded-2xl  h-full" />
                            </div>
                        </>
                    </SwiperSlide>
                ))}
                <div className="absolute bottom-0 left-0 xl:pl-14 lg:pl-9 pl-7 pb-4 z-50 lg:pe-0 pe-4  w-full  text-txtcolor">
                    <div className="flex justify-between">
                        <div className="lg:max-w-[640px] flex flex-col">
                            <h2 className=" font-semibold text-white text-[48px] mb-[10px] leading-[60px]  -tracking-[0.96px] ">An all-in-one platform to power your barbershop</h2>
                            <span className="mt-2.5 flex text-lg text-white font-medium">
                                Take control of operations, increase revenue, and enhance your brand experience with a single, easy-to-use platform.
                            </span>
                            <div className="flex  gap-4 flex-row mt-3">
                                <span className="flex items-center gap-2 text-white text-base font-medium">
                                    <img src={CircleCheck} className="w-[18px]" alt="" /> All-in-one
                                </span>
                                <span className="flex items-center gap-2 text-white text-base font-medium">
                                    <img src={CircleCheck} className="w-[18px]" alt="" /> Data-driven
                                </span>
                                <span className="flex items-center gap-2 text-white text-base font-medium">
                                    <img src={CircleCheck} className="w-[18px]" alt="" /> Client Experience
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex mt-7 relative ">
                        <button className="rounded-full flex items-center justify-center w-[50px] h-[50px] cursor-pointer mr-3 border border-white" onClick={handlePrevious}>
                            <FaChevronLeft className="text-md text-white text-[18px]" />
                        </button>
                        <button className="rounded-full flex items-center justify-center w-[50px] h-[50px] cursor-pointer border border-white " onClick={handleNext}>
                            <FaChevronRight className="text-md text-white text-[18px]" />
                        </button>
                    </div>
                </div>
            </Swiper>
        </div>
    );
};

export default Sidebar;
