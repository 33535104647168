import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PopupModal from 'src/components/PopupModal';
import * as Yup from 'yup';
import ExpenseLocation from './ExpenseLocation';
import { MarkerPin01, ChartBreakoutCircle } from '@untitled-ui/icons-react/build/cjs';
import { IAddExpense, IAddExpenseForm } from './Analytics.interface';
import { axiosPost } from 'src/utils/requestClient';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { useAppSelector } from 'src/redux/hooks';
import ExpenseForm from './ExpenseForm';

const AddExpense = ({ handleClose, fetchAnalytics }: IAddExpense) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [step, setStep] = useState<number>(1);
    const [title, setTitle] = useState(t('Select a Location'));
    const [description, setDescription] = useState(t('Select your location'));
    const [icon, setIcon] = useState(<MarkerPin01 className="text-gray-700" />);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [currentLocationExpense, setCurrentLocationExpense] = useState({});
    const [expense, setExpense] = useState<IAddExpenseForm>({
        shop_location_id: '',
        rent: null,
        bills: null,
        tools: null,
        insurance: null,
        maintenance: null,
        other: null,
        total: null,
        type: null,
    });

    useEffect(() => {
        switch (step) {
            case 1:
                setTitle(t('Select a Location'));
                setDescription(t('Select your location'));
                setIcon(<MarkerPin01 className="text-gray-700" />);
                break;
            case 2:
                setTitle(t('Add Expenses'));
                setDescription(t('Add your expenses to get your Net Profit.'));
                setIcon(<ChartBreakoutCircle className="text-gray-700" />);
                break;
            default:
                break;
        }
    }, [step]);

    const validationSchema = [
        Yup.object({
            shop_location_id: Yup.string().required(t('This field is required')),
        }).required(),

        Yup.object({
            rent: Yup.number().nullable().min(0, t('cost must be 0 or a positive value')).typeError('cost must be a number'),
            bills: Yup.number().nullable().min(0, t('cost must be 0 or a positive value')).typeError('cost must be a number'),
            tools: Yup.number().nullable().min(0, t('cost must be 0 or a positive value')).typeError('cost must be a number'),
            insurance: Yup.number().nullable().min(0, t('cost must be 0 or a positive value')).typeError('cost must be a number'),
            maintenance: Yup.number().nullable().min(0, t('cost must be 0 or a positive value')).typeError('cost must be a number'),
            other: Yup.number().nullable().min(0, t('cost must be 0 or a positive value')).typeError('cost must be a number'),
            total: Yup.number().required(t('This field is required')).min(0, t('cost must be 0 or a positive value')).typeError('cost must be a number'),
            type: Yup.string().required(t('This field is required')),
        }).required(),
    ];

    const schema = validationSchema[step - 1] as Yup.ObjectSchema<any>;
    const methods = useForm<IAddExpenseForm>({
        resolver: yupResolver(schema) as Resolver<IAddExpenseForm>,
        defaultValues: {
            ...expense,
        },
    });
    const { trigger, setError, handleSubmit } = methods;

    const handleSave = async (data: IAddExpenseForm) => {
        setIsFormLoading(true);
        const payload = {
            shop_location_id: data.shop_location_id,
            total: data.total,
            type: data.type,
            expenses: {
                rent: data.rent ?? 0,
                bills: data.bills ?? 0,
                tools: data.tools ?? 0,
                insurance: data.insurance ?? 0,
                maintenance: data.maintenance ?? 0,
                other: data.other ?? 0,
            },
        };
        axiosPost(API.ANALYTICS.EXPENSECREATE, payload, { shop_id: shop.id })
            .then(async (response) => {
                fetchAnalytics();
                handleClose();
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IAddExpenseForm, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsFormLoading(false));
    };

    const handleNext = async () => {
        const isValid = await trigger();

        if (isValid) {
            if (step === 2) {
                handleSubmit(handleSave)();
            } else {
                setStep((old) => old + 1);
            }
        }
    };

    const handleBack = () => {
        if (step === 2) {
            setStep((old) => old - 1);
        } else {
            handleClose();
        }
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">{icon}</div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{title}</p>

                <span className="text-xs font-normal text-gray-500">{description}</span>
            </div>
        </div>
    );

    return (
        <>
            <PopupModal
                dismissible
                onClose={handleClose}
                size="w-[700px]"
                className="px-5 py-5"
                title={<Title />}
                primaryButton={step === 2 ? t('Save') : t('Continue')}
                secondaryButton={t('Cancel')}
                acceptAction={handleNext}
                declineAction={handleBack}
                isLoading={isFormLoading}
                isDisabled={isFormLoading}
            >
                <FormProvider {...methods}>
                    {step === 1 && <ExpenseLocation setCurrentLocationExpense={setCurrentLocationExpense} />}
                    {step === 2 && <ExpenseForm currentLocationExpense={currentLocationExpense} />}
                </FormProvider>
            </PopupModal>
        </>
    );
};

export default AddExpense;
