import React, { useCallback, useEffect, useState } from 'react';
import { ISearchBar } from './SearchBar.interface';
import debounce from 'lodash/debounce';
import SearchLg from '@untitled-ui/icons-react/build/cjs/SearchLg';
import { XClose } from '@untitled-ui/icons-react/build/cjs';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';

const SearchBar = ({ placeholder, className, onChange, labelIconClassName }: ISearchBar) => {
    const [value, setValue] = useState('');
    const shop = useAppSelector(currentShop);
    useEffect(() => {
        setValue('');
    }, [shop]);
    const debouncedOnChange = useCallback(
        debounce((event) => {
            onChange(event);
        }, 300),
        [],
    );
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        debouncedOnChange(event);
    };

    const handleClear = () => {
        setValue('');
        onChange({ target: { value: '' } });
    };

    return (
        <div className="relative input-group search-input">
            <div className={`input-group-prepend text-gray-400 ${labelIconClassName}`}>
                <span className="text">
                    <SearchLg width={16} />
                </span>
            </div>

            <input type="text" placeholder={placeholder} value={value} onChange={handleInputChange} className={`form_input_control font-normal placeholder:text-gray-500 py-[9px] ${className}`} />
            {value && (
                <button onClick={handleClear}>
                    <XClose className="w-4 mr-[7px] text-[#333333]" />
                </button>
            )}
        </div>
    );
};

export default SearchBar;
