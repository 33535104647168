import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CustomButton from 'src/components/CustomButton';
import { ArrowUp, ArrowDown, Users01, SearchLg, UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import { GoDotFill } from 'react-icons/go';
import { dateFormate, getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, currentShop } from 'src/redux/services/common/Common.slice';
import { AllLocationsOptions, perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { usePhone } from 'src/hooks/usePhone';
import NoDataMessage from 'src/components/NoDataMessage';

const StaffUsers = ({ staff }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { getFormatPhone } = usePhone();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [users, setUsers] = useState([]);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const shopLocationList = useAppSelector(allShopLocations);
    const [selectedFilter, setSelectedFilter] = useState({
        shop_location_id: null,
    });

    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            type: { value: 'all' },
            shop_admin_id: { value: id },
            shop_location_id: { value: null },
        },
    });
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        type: { value: 'all' },
        shop_admin_id: { value: id },
        shop_location_id: { value: null },
    });

    const listClients = async () => {
        setIsLoading(true);
        await axiosGet(API.CLIENT.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setUsers(response.data.data.data);
                setTotalUsers(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions([AllLocationsOptions, ...locationData]);
    }, []);

    useEffect(() => {
        listClients();
    }, [lazyState, shop]);

    const handleBookingDownload = async () => {
        setIsDownloading(true);

        const payload = {
            filters: {
                shop_admin_id: { value: lazyState.filters.shop_admin_id.value },
            },
        };

        await axiosGet(API.CLIENT.EXPORT, { shop_id: shop.id }, payload)
            .then(async (response) => {
                const exportResponse = response.data.data;
                const byteCharacters = atob(exportResponse);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                    type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet',
                });

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `client_details_${staff.full_name}_${moment().format('YYYY-MM-DD')}.xlsx`;

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .finally(() => setIsDownloading(false));
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'active':
                return 'text-[#175CD3]';
            case 'loyal':
                return 'text-[#067647]';
            case 'recent':
                return 'text-[#F38744]';
            case 'new':
                return 'text-[#EE46BC]';
            case 'lapsed':
                return 'text-[#B54708]';
            default:
                return 'text-blueLight-700';
        }
    };
    const GetStatus = (row: any) => {
        const statusClass = getStatusColor(row.status);
        return (
            <div className="flex items-center">
                <span
                    className={`px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max  `}
                >
                    <GoDotFill size={12} className={statusClass} />
                    {row.status}
                </span>
            </div>
        );
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : null;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const getFullName = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.profile_image_url ? <img src={row.profile_image_url} alt="" title={row.full_name} className="w-full h-full object-cover" /> : getShortName(row.full_name)}
            </figure>
            <div>
                <p className="text-xs font-medium min-w-[110px] w-[110px] max-w-[110px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">{row.full_name}</p>
                <p className="text-xs font-normal text-secondaryTxtColor min-w-[110px] w-[110px] max-w-[110px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {row.created_at ? `Since ${moment(row.created_at).format('MMMM YYYY')}` : '-'}
                </p>
            </div>
        </div>
    );
    const getPhone = (row: any) => <div>{row.phone && getFormatPhone(row.phone, row.phone_country_code)}</div>;

    const getAverageAmount = (row: any) => `$${row.average_amount}`;

    const getLastAppointment = (row: any) => `${row.booking_date ? dateFormate(row.booking_date) : '-'}`;

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                first_name: 'loading',
                phone: 'loading',
                email: 'loading',
                average_days: 'loading',
                average_amount: 'loading',
                bookings_count: 'loading',
                status: 'loading',
                action: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    return (
        <div className="w-full border rounded-xl mt-6  flex-col mb-5 flex shadow datatable-custom">
            <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                <div className="flex flex-col">
                    <div className="flex items-center">
                        <h2 className="view-table-title capitalize-first ">
                            {t('name client list', { name: staff.first_name })}
                            <p className="userCounter ml-1.5">
                                <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                <span>{totalUsers} clients</span>
                            </p>
                        </h2>
                    </div>
                    <p className="view-table-subtitle">{t('View your client list.')}</p>
                </div>
                <div className="flex flex-row gap-4">
                    <div className="w-[180px] location-dropdown">
                        <SelectBox
                            options={locationOptions}
                            value={locationOptions.find((option: any) => option.value === selectedFilter.shop_location_id)}
                            noOptionsMessage="No Locations Found"
                            placeholder={t('All locations')}
                            onChangeFunc={handleFilter('shop_location_id')}
                            isClearable={false}
                            isSearchable={false}
                            classComp=""
                        />
                    </div>
                    <div className="location-dropdown">
                        <CustomButton
                            isLoading={isDownloading}
                            disabled={isDownloading || !totalUsers}
                            secondary
                            outlineSecondary
                            icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />}
                            onClick={handleBookingDownload}
                            className="flex items-center"
                        >
                            {t('Export')}
                        </CustomButton>
                    </div>
                    <div className="w-[70px]">
                        <SelectBox
                            name="page"
                            isClearable={false}
                            isSearchable={false}
                            options={perPageOptions}
                            onChangeFunc={onPageHandle}
                            value={perPageOptions.find((option: any) => option.value === lazyState.rows)}
                            classComp="w-[70px]"
                        />
                    </div>
                </div>
            </div>
            <DataTable
                lazy
                value={isLoading ? skeletons : users}
                totalRecords={totalUsers}
                paginator={!isLoading && totalUsers > lazyState.rows}
                paginatorTemplate={customPaginatorTemplate}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                dataKey="id"
                className=" rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl min-h-[380px] flex justify-center items-center">
                        <NoDataMessage
                            title={`${filters.global.value || filters.shop_location_id.value ? 'No clients found.' : 'No clients added.'}`}
                            description={`${
                                filters.global.value || filters.shop_location_id.value ? 'Try adjusting your filters or add new clients.' : 'Add a clients, to start using the clients collection.'
                            }`}
                            iconComponent={filters.global.value || filters.shop_location_id.value ? <SearchLg className="text-gray-700" /> : <Users01 className="text-gray-700" />}
                        />
                    </div>
                }
            >
                <Column
                    field="first_name"
                    header={renderHeader(t('Client'), 'first_name')}
                    className="text-xs leading-[18px] font-medium text-mainTextColor min-w-[180px] w-[180px] max-w-[180px] xlm:min-w-[200px] xlm:w-[200px] xlm:max-w-[200px] truncate"
                    style={{ width: '180px', minWidth: '180px', maxWidth: '180px' }}
                    body={isLoading ? <Skeleton /> : getFullName}
                    sortable
                ></Column>
                <Column
                    field="status"
                    header={renderHeader(t('Status'), 'status')}
                    body={isLoading ? <Skeleton /> : GetStatus}
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    className="text-xs font-medium text-secondaryTxtColor min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[100px] xlm:w-[100px] xlm:max-w-[100px] truncate"
                    sortable
                ></Column>
                <Column
                    field="phone"
                    header={renderHeader(t('Phone Number'), 'phone')}
                    style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                    body={isLoading ? <Skeleton /> : getPhone}
                    className="font-medium text-secondaryTxtColor text-xs min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[120px] xlm:w-[120px] xlm:max-w-[120px] truncate"
                    sortable
                ></Column>
                <Column
                    field="email"
                    header={renderHeader(t('Email'), 'email')}
                    style={{ width: '170px', minWidth: '170px', maxWidth: '170px' }}
                    body={isLoading ? <Skeleton /> : undefined}
                    className="text-xs leading-[18px] font-medium text-secondaryTxtColor min-w-[180px] w-[180px] max-w-[180px] xlm:min-w-[180px] xlm:w-[180px] xlm:max-w-[180px] truncate "
                    sortable
                ></Column>
                <Column
                    field="average_days"
                    header={renderHeader(t('Last Appointment'), 'average_days')}
                    style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                    body={isLoading ? <Skeleton /> : getLastAppointment}
                    className="text-xs leading-[18px] font-medium text-secondaryTxtColor min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[130px] xlm:w-[130px] xlm:max-w-[130px] truncate"
                    sortable
                ></Column>
                <Column
                    field="bookings_count"
                    style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                    body={isLoading ? <Skeleton /> : undefined}
                    className="text-xs leading-[18px] font-medium text-secondaryTxtColor min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[130px] xlm:w-[130px] xlm:max-w-[130px] truncate"
                    header={renderHeader(t('Total Bookings'), 'bookings_count')}
                    sortable
                ></Column>
                <Column
                    field="average_amount"
                    header={renderHeader(t('Average spend'), 'average_amount')}
                    className="text-xs font-medium text-secondaryTxtColor min-w-[100px] w-[100px] max-w-[100px] xlm:min-w-[130px] xlm:w-[130px] xlm:max-w-[130px] truncate"
                    style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                    body={isLoading ? <Skeleton /> : getAverageAmount}
                    sortable
                ></Column>
            </DataTable>
        </div>
    );
};

export default StaffUsers;
