import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { currentRole, currentShop, userMe } from 'src/redux/services/common/Common.slice';
import { SearchLg, MarkerPin04 } from '@untitled-ui/icons-react/build/cjs';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { axiosDelete, axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import NoDataMessage from 'src/components/NoDataMessage';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import LocationForm from './LocationForm';
import { initAction } from './Terminal.interface';
import Badge from 'src/components/Badge';
import SLocation from '../Skeleton/Location/SLocation';
import { onError } from 'src/utils/global-functions';
import { ROLES } from 'src/constants/common';
import { toast } from 'react-toastify';

const LocationTable = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const role = useAppSelector(currentRole);
    const user = useAppSelector(userMe);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [locationId, setLocationId] = useState('');
    const [locations, setLocations] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [action, setAction] = useState(initAction);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        listLocations();
    }, []);

    const listLocations = () => {
        setIsLoading(true);
        let URL = API.LOCATION.LIST;
        let payload: any = { shop_id: shop.id };
        if (role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT) {
            URL = API.STAFF.GET;
            payload.id = user.id;
        }
        axiosGet(URL, payload)
            .then((response) => {
                const data = response.data.data;
                setLocations(data);
                setTotal(data.length);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (searchValue) {
            const list = locations.filter((obj: any) => {
                const name = obj.name.toLowerCase();
                const searchParam = searchValue.toLowerCase();
                return name.includes(searchParam);
            });
            setFilteredLocations(list);
        } else {
            setFilteredLocations(locations);
        }
    }, [searchValue, locations]);

    const disconnect = (id: string) => () => {
        setLocationId(id);
        axiosPatch(API.LOCATION.STRIPE, { stripe_id: null }, { shop_id: shop.id, id })
            .then(listLocations)
            .catch((error) => {
                const response = error.response.data;
                toast.error(response.message);
            })
            .finally(() => setLocationId(''));
    };

    const handleAction =
        (type: string, data: any = null) =>
        async () => {
            if (type === 'create') {
                data = {
                    id: data.id,
                    display_name: data.name,
                    address: { line1: data.street, country: data.phone_country_code, postal_code: data.postal_code },
                };
            } else if (type === 'disconnect') {
                await axiosPatch(API.LOCATION.STRIPE, { stripe_id: null }, { shop_id: shop.id, id: data.id });
            }
            setAction((old: any) => ({ ...old, [type]: true, data }));
        };

    const handleActionClose = () => {
        setAction(initAction);
    };

    const handleModalClose = useCallback(
        (status: boolean = false) =>
            async () => {
                if (status) {
                    setIsLoading(true);
                    const params = {
                        shop_id: shop.id,
                        id: action.data,
                    };
                    await axiosDelete(API.STRIPE.LOCATION.DELETE, {}, params)
                        .then(listLocations)
                        .finally(() => setIsLoading(false));
                }
                setAction(initAction);
            },
        [action],
    );

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    return (
        <div
            className={`w-full border border-gray-200 rounded-xl shadow staff_service_table datatable-custom-service flex-1 flex flex-col ${
                !filteredLocations.length ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className={`flex items-center w-full flex-col`}>
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                    <div className="flex flex-col flex-1">
                        <div className="flex justify-start items-center gap-1.5 xxl:gap-1.5">
                            <h2 className="table-title">{t('All Terminal Locations')}</h2>
                            <Badge icon isLoading={isLoading}>
                                {total} {t('Locations')}
                            </Badge>
                        </div>
                        <p className="table-subtitle">{t('Add, view and manage your locations.')}</p>
                    </div>
                    <div className="flex gap-3">
                        <div className="table-searchInput">
                            <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
                    <SLocation />
                </div>
            ) : filteredLocations.length > 0 ? (
                <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
                    {filteredLocations.map((location: any, index) => (
                        <div key={index} className="w-full">
                            <div className="location-card p-4">
                                <div className="2xl:h-[200px] h-[194px] min-h-[194px] 2xl:min-h-[200px] w-full  grow-0 shrink-0  rounded-xl overflow-hidden ">
                                    <img src={location.profile_image_url} alt="" title={location.name} className="h-full w-full object-cover" onError={onError} />
                                </div>
                                <div className="flex-auto overflow-hidden">
                                    <div className="w-full flex flex-col gap-[6px]">
                                        <h2 className="text-base font-semibold leading-[140%] text-gray-700 truncate">{location.name}</h2>
                                        <p className="text-sm font-normal leading-[18px]  text-gray-500 truncate">
                                            {location.street}, {location.city}, {location.country}
                                        </p>
                                    </div>
                                    <div className="flex mt-auto pt-4 justify-between ">
                                        {location.stripe_id ? (
                                            <CustomButton
                                                outlineSecondary
                                                isLoading={location.id === locationId}
                                                disabled={location.id === locationId}
                                                onClick={disconnect(location.id)}
                                                className="w-full !py-[8px] rounded-lg shadow-InputAndButton"
                                            >
                                                {t('Disconnect Stripe')}
                                            </CustomButton>
                                        ) : (
                                            <CustomButton
                                                outlinePrimary
                                                disabled={!!locationId}
                                                icon={<Plus width="16" />}
                                                onClick={handleAction('create', location)}
                                                className="w-full !px-4 py-[9px] rounded-lg shadow-InputAndButton"
                                            >
                                                {t('Add to Stripe')}
                                            </CustomButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <NoDataMessage
                    title={`${searchValue ? 'No Locations Found' : 'No Locations Added.'}`}
                    description={`${
                        searchValue
                            ? 'There are no locations matching your search criteria. Try adjusting your filters or add a new location to list it here.'
                            : 'Start by adding your brand’s first location and make it available for client bookings.'
                    }`}
                    iconComponent={searchValue ? <SearchLg className="text-gray-700" /> : <MarkerPin04 className="text-gray-700" />}
                />
            )}
            {action.create && action.data && <LocationForm data={action.data} handleClose={handleActionClose} listLocations={listLocations} />}
            {action.delete && <DeletePopupModal onClose={handleModalClose} size="w-[400px]" title={t('Delete location')} description={t('Are you sure you want to delete location?')} />}
        </div>
    );
};

export default LocationTable;
