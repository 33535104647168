import React, { useMemo, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import CustomButton from 'src/components/CustomButton';
import { ArrowUp, ArrowDown, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import NoDataMessage from 'src/components/NoDataMessage';
import { formatAmount, formatPercentage } from 'src/utils/global-functions';

const ServiceTable = (props: any) => {
    const { data, isLoading } = props;
    const { t } = useTranslation();
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 4,
        page: 0,
        sortField: null,
        sortOrder: null,
    });

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ currentPage, totalPages }: { currentPage: number; totalPages: number }) => (
            <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                <span>
                    Page {currentPage} of {totalPages}
                </span>
            </div>
        ),
    };

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const getSplit = (row: any) => formatPercentage(row.percentage);

    const getAmount = (row: any) => formatAmount(row.total_amount);

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                name: 'loading',
                percentage: 'loading',
                total_amount: 'loading',
            })),
        [lazyState],
    );
    return (
        <div className={`w-full flex flex-col datatable-custom staff_service_table ${data.length ? 'flex-1' : 'datatable-full-height datatable-noshow'}`}>
            <DataTable
                paginatorTemplate={customPaginatorTemplate}
                value={isLoading ? skeletons : data}
                totalRecords={data.length}
                paginator={!isLoading && data.length > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                onPage={onPage}
                onSort={onSort}
                className="overflow-hidden scrollbar-hide rounded-lg"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl  flex justify-center items-center">
                        <NoDataMessage
                            title={t('No Service Data')}
                            description={t('Service analytics will be available once clients start utilizing the services.')}
                            iconComponent={<SearchLg className="text-gray-700" />}
                        />
                    </div>
                }
            >
                <Column
                    field="name"
                    header={renderHeader(t('Services'), 'name')}
                    body={isLoading ? <Skeleton /> : undefined}
                    style={{ width: '60%' }}
                    className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                    sortable
                ></Column>
                <Column
                    field="percentage"
                    header={renderHeader(t('Split'), 'percentage')}
                    body={isLoading ? <Skeleton /> : getSplit}
                    className="text-xs leading-[18px] font-normal text-gray-600"
                    sortable
                ></Column>
                <Column
                    field="total_amount"
                    header={renderHeader(t('Total'), 'total_amount')}
                    body={isLoading ? <Skeleton /> : getAmount}
                    className="text-xs leading-[18px] font-normal text-gray-600"
                    sortable
                ></Column>
            </DataTable>
        </div>
    );
};

export default ServiceTable;
