import React, { useEffect, useMemo, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import CustomButton from 'src/components/CustomButton';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentRole, currentShop } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import { ArrowUp, ArrowDown, SearchLg, Users01 } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import { formatName, getShortName } from 'src/utils/global-functions';
import NoDataMessage from 'src/components/NoDataMessage';
import { ITeamPerformance } from './Analytics.interface';
import { ROLES } from 'src/constants/common';

const Performance = ({ startDate, endDate, selectedFilter }: ITeamPerformance) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const role = useAppSelector(currentRole);
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setApiData] = useState<any[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const controller = new AbortController();
    const { signal } = controller;
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 8,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            location_id: { value: selectedFilter.location_id },
            role_id: { value: selectedFilter.staff_id },
            start_date: { value: startDate },
            end_date: { value: endDate },
        },
    });
    const [filters, setFilters] = useState<any>({
        location_id: { value: null },
        role_id: { value: null },
    });

    useEffect(() => {
        if (role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT) {
            getClientPerfomance();
        } else {
            getStaffPerfomance();
        }
        return () => {
            controller.abort();
        };
    }, [lazyState, role]);

    useEffect(() => {
        const updatedFilters = {
            ...lazyState.filters,
            location_id: { value: selectedFilter.location_id },
            role_id: { value: selectedFilter.staff_id },
            start_date: { value: startDate },
            end_date: { value: endDate },
        };

        if (JSON.stringify(lazyState.filters) !== JSON.stringify(updatedFilters)) {
            setLazyState((prev: any) => ({
                ...prev,
                filters: updatedFilters,
            }));
        }
    }, [selectedFilter, startDate, endDate]);

    const getStaffPerfomance = async () => {
        setIsLoading(true);
        await axiosGet(API.STAFF.PERFORMANCE, { shop_id: shop.id }, lazyState, signal)
            .then((response) => {
                const data = response.data.data;
                setApiData(data.data);
                setTotalRecords(data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const getClientPerfomance = async () => {
        setIsLoading(true);
        await axiosGet(API.CLIENT.PERFORMANCE, { shop_id: shop.id }, lazyState, signal)
            .then((response) => {
                const data = response.data.data;
                setApiData(data.data);
                setTotalRecords(data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };
    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ currentPage, totalPages }: { currentPage: number; totalPages: number }) => (
            <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                <span>
                    Page {currentPage} of {totalPages}
                </span>
            </div>
        ),
    };

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };
    const [isImageError, setIsImageError] = useState<{ [key: number]: boolean }>({});

    const handleImageError = (id: number) => {
        setIsImageError((prevState: any) => ({
            ...prevState,
            [id]: true,
        }));
    };

    const GetProfessional = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.profile_image_url && !isImageError[row.id] ? (
                    <img src={row.profile_image_url} alt="" className="w-full h-full object-cover" onError={() => handleImageError(row.id)} />
                ) : (
                    getShortName(`${row.full_name}`)
                )}
            </figure>
            <div className="">
                <p className="text-xs font-normal  leading-[18px] max-w-[80px] min-w-[80px] w-[80px] truncate capitalize-first">{formatName(`${row?.full_name}`)}</p>
            </div>
        </div>
    );

    const GetEarning = (row: any) => (
        <div className="flex items-center">
            <p className="text-xs font-normal  leading-[18px] ">${row.bookings_sum_total ?? 0}</p>
        </div>
    );

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                first_name: 'loading',
                bookings_sum_total: 'loading',
                bookings_count: 'loading',
            })),
        [lazyState],
    );
    return (
        // h-[562px]
        <div className={`w-full flex flex-col  datatable-custom staff_service_table  ${apiData.length === 0 ? 'datatable-full-height datatable-noshow ' : 'flex-1'}`}>
            <DataTable
                lazy
                paginatorTemplate={customPaginatorTemplate}
                value={isLoading ? skeletons : apiData}
                totalRecords={totalRecords}
                paginator={!isLoading && totalRecords > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                // onFilter={onFilter}
                // selectionMode="single"
                dataKey="id"
                className=" border-t rounded-b-xl overflow-hidden scrollbar-hide"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <div className="rounded-xl  flex justify-center items-center">
                        <NoDataMessage
                            title={`${filters.location_id.value || filters.role_id.value ? t('No team members found.') : t('No team members added.')}`}
                            description={`${
                                filters.location_id.value || filters.role_id.value
                                    ? t('Try adjusting your filters or add new team members.')
                                    : t('Start by adding new members to have them listed on your online booking website.')
                            }`}
                            iconComponent={filters.location_id.value || filters.role_id.value ? <SearchLg className="text-gray-700" /> : <Users01 className="text-gray-700" />}
                        />
                    </div>
                }
            >
                <Column
                    field="first_name"
                    header={renderHeader(role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT ? t('Client') : t('Professional'), 'first_name')}
                    body={isLoading ? <Skeleton /> : GetProfessional}
                    style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                    className=" font-normal text-mainTextColor"
                    sortable
                ></Column>
                <Column
                    field="bookings_sum_total"
                    header={renderHeader(t('Total Revenue'), 'bookings_sum_total')}
                    className=" text-secondaryTxtColor"
                    style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                    body={isLoading ? <Skeleton /> : GetEarning}
                    sortable
                ></Column>
                <Column
                    field="bookings_count"
                    header={renderHeader(t('Total Appointment'), 'bookings_count')}
                    style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                    className="text-secondaryTxtColor text-xs font-normal "
                    body={isLoading ? <Skeleton /> : undefined}
                    sortable
                ></Column>
            </DataTable>
        </div>
    );
};

export default Performance;
