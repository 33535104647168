import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import moment from 'moment';
import Transactions from './Transactions';
import { sortBy } from 'lodash';
import SChart from '../Skeleton/Payment/SChart';
import NoDataMessage from 'src/components/NoDataMessage';
import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { formatAmount } from 'src/utils/global-functions';

const MainPayment = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [currentTab, setCurrentTab] = useState('hours');
    const [paymentDetails, setPaymentDetails] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [balance, setBalance] = useState<number>(0);

    useEffect(() => {
        getBalance();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const { startDate, endDate } = getStartAndEndDate();
        getStatements(startDate, endDate);
    }, [currentTab]);

    useLayoutEffect(() => {
        if (paymentDetails.length) {
            const root = am5.Root.new('chartdiv');
            if (root._logo) {
                root._logo.dispose();
            }
            root.setThemes([am5themes_Animated.new(root)]);

            // Create chart
            const chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: false,
                    panY: false,
                    wheelX: 'none',
                    wheelY: 'none',
                    paddingLeft: 0,
                    paddingBottom: 15,
                }),
            );

            // Hide right-side zoom button
            chart.zoomOutButton.set('forceHidden', true);

            // Create X axis
            const xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    startLocation: 0.1,
                    endLocation: 0.9,
                    markUnitChange: false,
                    baseInterval: { timeUnit: 'hour', count: 2 }, // Default to 2-hour intervals
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 10,
                    }),
                }),
            );

            // Hide vertical grid lines (X-axis grid lines)
            xAxis.get('renderer').grid.template.setAll({
                forceHidden: true, // Hides the vertical grid lines
            });

            // Create Y axis
            const yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {
                        minGridDistance: 20,
                        strokeOpacity: 0, // Hides the Y-axis line itself
                    }),
                    extraMin: 0.1,
                    extraMax: 0.1,
                    min: 0,
                    strictMinMax: true,
                }),
            );

            // Show horizontal grid lines (Y-axis grid lines)
            yAxis.get('renderer').grid.template.setAll({
                forceHidden: false, // Shows horizontal grid lines
                strokeOpacity: 0.1, // Optional: Adjust transparency of grid lines
            });

            yAxis.get('renderer').labels.template.setAll({
                forceHidden: true, // Hides Y-axis labels
            });

            // Create series
            const series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    name: 'Series',
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: 'value',
                    valueXField: 'date',
                    stroke: am5.color('#0E34A5'),
                    tooltip: am5.Tooltip.new(root, {
                        labelText: undefined,
                        forceHidden: true,
                        animationDuration: 0,
                    }),
                }),
            );

            series.strokes.template.setAll({
                strokeWidth: 2,
            });

            series.fills.template.setAll({
                fillOpacity: 0.1,
                visible: true,
            });

            series.fills.template.set(
                'fillGradient',
                am5.LinearGradient.new(root, {
                    stops: [
                        {
                            color: am5.color('#0E34A5'),
                            brighten: 0.1,
                        },
                        {
                            color: am5.color('#0E34A5'),
                            lighten: 1,
                        },
                    ],
                    rotation: 90,
                }),
            );

            const tooltip = am5.Tooltip.new(root, {});
            tooltip.set(
                'background',
                am5.RoundedRectangle.new(root, {
                    fill: am5.color('#EFF1F5'),
                    fillOpacity: 1,
                    cornerRadiusBL: 12,
                    cornerRadiusBR: 12,
                    cornerRadiusTL: 12,
                    cornerRadiusTR: 12,
                }),
            );
            chart.plotContainer.set('tooltipPosition', 'pointer');
            // chart.plotContainer.set('tooltipHTML', 'div');
            chart.plotContainer.set('tooltipText', 'a');
            chart.plotContainer.set('tooltip', tooltip);
            tooltip.label.adapters.add('text', function (text, target) {
                text = '';
                chart.series.each(function (seriesItem) {
                    const tooltipDataItem: any = seriesItem.get('tooltipDataItem');
                    if (tooltipDataItem) {
                        const valueX = tooltipDataItem.get('valueX')!;
                        const valueY = tooltipDataItem.get('valueY')!;
                        let date = '';

                        switch (currentTab) {
                            case 'hours':
                                date = moment(valueX).format('HH:mm');
                                break;
                            case 'week':
                                date = moment(valueX).format('dddd');
                                break;
                            case 'months':
                                date = moment(valueX).format('MMM yyyy');
                                break;
                            case 'days':
                                date = moment(valueX).format('L');
                                break;
                            default:
                                return;
                        }
                        text += `${date}\n[${seriesItem?.get('stroke')}]●[/] [bold width:100px class]Balance:[/] $${valueY.toFixed(2)}`;
                    }
                });
                return text;
            });

            series.bullets.push(function () {
                const circle = am5.Circle.new(root, {
                    radius: 5,
                    interactive: true,
                    fill: series.get('stroke'),
                    opacity: 0,
                });

                circle.states.create('default', {
                    opacity: 0,
                });

                circle.states.create('hover', {
                    opacity: 1,
                });

                return am5.Bullet.new(root, {
                    sprite: circle,
                });
            });

            // Cursor and Bullets
            const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
            cursor.lineY.set('visible', false);

            let previousBulletSprites: any = [];
            const cursorMoved = () => {
                for (let i = 0; i < previousBulletSprites.length; i++) {
                    previousBulletSprites[i].unhover();
                }
                previousBulletSprites = [];
                chart.series.each(function (seriess: any) {
                    const dataItem = seriess.get('tooltip').dataItem;
                    if (dataItem) {
                        const bulletSprite = dataItem.bullets[0].get('sprite');
                        bulletSprite.hover();
                        previousBulletSprites.push(bulletSprite);
                    }
                });
            };
            cursor.events.on('cursormoved', cursorMoved);

            const removeBullets = () => {
                for (let i = 0; i < previousBulletSprites.length; i++) {
                    previousBulletSprites[i].unhover();
                }
                previousBulletSprites = [];
            };
            chart.plotContainer.events.on('pointerout', removeBullets);

            let timeUnit: am5.time.TimeUnit;
            let count: number;
            let dateFormats: Record<string, string> = {};
            switch (currentTab) {
                case 'hours':
                    timeUnit = 'hour';
                    count = 1; // Ensure 2-hour gaps
                    dateFormats.hour = 'HH:mm';
                    series.data.setAll(paymentDetails);
                    break;
                case 'week':
                    timeUnit = 'day';
                    count = 1;
                    dateFormats.day = 'EEE';
                    series.data.setAll(paymentDetails);
                    break;
                case 'months':
                    timeUnit = 'month';
                    count = 1;
                    dateFormats.month = 'MMM';
                    series.data.setAll(paymentDetails);
                    break;
                case 'days':
                    timeUnit = 'day';
                    count = 2; // Ensure 2-day gaps
                    dateFormats.day = 'MMM dd';
                    series.data.setAll(paymentDetails);
                    break;
                default:
                    return;
            }

            // Set the axis configuration
            xAxis.set('baseInterval', { timeUnit, count });
            xAxis.set('dateFormats', dateFormats);

            // Animations
            series.appear(1000);
            chart.appear(1000, 100);

            // Cleanup on unmount
            return () => {
                root.dispose();
            };
        }
    }, [paymentDetails]);

    const timePeriodTabs = useMemo(
        () => [
            {
                name: t('24 Hours'),
                value: 'hours',
            },
            {
                name: t('7 Days'),
                value: 'week',
            },
            {
                name: t('30 Days'),
                value: 'days',
            },
            {
                name: t('12 Months'),
                value: 'months',
            },
        ],
        [],
    );

    const getStartAndEndDate = () => {
        let startDate = moment();
        let endDate = moment();

        switch (currentTab) {
            case 'hours':
                startDate = startDate.subtract('24', 'hours');
                break;

            case 'week':
                startDate = startDate.subtract('7', 'days');
                break;

            case 'days':
                startDate = startDate.subtract('30', 'days');
                break;

            case 'months':
                startDate = startDate.subtract('12', 'months');
                break;
        }

        return {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
        };
    };

    /* const processArray = (array: any) => {
        const resultMap: Record<string, DataItem> = {};

        array = sortBy(array, (item) => moment(item.created_at).valueOf());

        array.forEach((item: any) => {
            const { created_at: createdAt, amount, balance: value, object } = item;

            let dateFormat: string;
            let date: number;

            switch (currentTab) {
                case 'months':
                    dateFormat = moment(createdAt).format('YYYY-MM');
                    date = moment(createdAt).startOf('month').toDate().getTime();
                    break;
                case 'hours':
                    dateFormat = moment(createdAt).format('YYYY-MM-DD-HH');
                    date = moment(createdAt).startOf('hour').toDate().getTime();
                    break;
                default:
                    dateFormat = moment(createdAt).format('YYYY-MM-DD');
                    date = moment(createdAt).toDate().getTime();
                    break;
            }

            if (!resultMap[dateFormat]) {
                resultMap[dateFormat] = {
                    date,
                    originalDate: dateFormat,
                    value,
                };
            } else {
                if (object === 'transfer') {
                    resultMap[dateFormat].value += amount;
                } else if (object === 'payout') {
                    resultMap[dateFormat].value -= amount;
                }
            }
        });
        const sortedArray = Object.entries(resultMap).sort(([, a], [, b]: [string, DataItem]) => moment(a.date).valueOf() - moment(b.date).valueOf());
        const sortedObject = Object.fromEntries(sortedArray);
        const response = Object.values(sortedObject);
        return response;
    }; */

    const getBalance = () => {
        axiosGet(API.STRIPE.BALANCE).then((response) => {
            if (response.data.data) {
                setBalance(response.data.data.total_amount);
            }
        });
    };

    const getStatements = (start_date: any, end_date: any) => {
        axiosGet(API.STRIPE.STATEMENTS, { shop_id: shop.id }, { start_date, end_date })
            .then((response) => {
                const data = response.data.data;
                setPaymentDetails(processArray(data));
            })
            .finally(() => setIsLoading(false));
    };

    const processArray = (array: any) => {
        const data = [];
        const amountMap: { [key: string]: number } = {};

        switch (currentTab) {
            case 'hours':
                array = sortBy(array, (item) => moment(item.created_at).valueOf());
                array.forEach((item: any) => {
                    const { created_at: createdAt, balance: balances, amount, object } = item;
                    const createdAtDate = moment(createdAt).format('YYYY-MM-DD-HH');
                    if (!amountMap[createdAtDate]) {
                        amountMap[createdAtDate] = balances;
                    } else if (object === 'transfer') {
                        amountMap[createdAtDate] += amount;
                    } else if (object === 'payout') {
                        amountMap[createdAtDate] -= amount;
                    }
                });

                for (let i = 1; i <= 24; i++) {
                    const targetDate = moment()
                        .subtract(24 - i, 'hours')
                        .startOf('hour');
                    const originalDate = targetDate.format('YYYY-MM-DD-HH');
                    data.push({
                        date: targetDate.toDate().getTime(),
                        originalDate,
                        value: amountMap[originalDate] || 0,
                    });
                }
                break;
            case 'week':
                array = sortBy(array, (item) => moment(item.created_at).valueOf());
                array.forEach((item: any) => {
                    const { created_at: createdAt, balance: balances, amount, object } = item;
                    const createdAtDate = moment(createdAt).format('YYYY-MM-DD');
                    if (!amountMap[createdAtDate]) {
                        amountMap[createdAtDate] = balances;
                    } else if (object === 'transfer') {
                        amountMap[createdAtDate] += amount;
                    } else if (object === 'payout') {
                        amountMap[createdAtDate] -= amount;
                    }
                });

                for (let i = 1; i <= 7; i++) {
                    const targetDate = moment()
                        .subtract(7 - i, 'days')
                        .startOf('day');
                    const originalDate = targetDate.format('YYYY-MM-DD');
                    data.push({
                        date: targetDate.toDate().getTime(),
                        originalDate,
                        value: amountMap[originalDate] || 0,
                    });
                }
                break;
            case 'days':
                array = sortBy(array, (item) => moment(item.created_at).valueOf());
                array.forEach((item: any) => {
                    const { created_at: createdAt, balance: balances, amount, object } = item;
                    const createdAtDate = moment(createdAt).format('YYYY-MM-DD');
                    if (!amountMap[createdAtDate]) {
                        amountMap[createdAtDate] = balances;
                    } else if (object === 'transfer') {
                        amountMap[createdAtDate] += amount;
                    } else if (object === 'payout') {
                        amountMap[createdAtDate] -= amount;
                    }
                });

                for (let i = 1; i <= 30; i++) {
                    const targetDate = moment()
                        .subtract(30 - i, 'days')
                        .startOf('day');
                    const originalDate = targetDate.format('YYYY-MM-DD');
                    data.push({
                        date: targetDate.toDate().getTime(),
                        originalDate,
                        value: amountMap[originalDate] || 0,
                    });
                }
                break;
            case 'months':
                array = sortBy(array, (item) => moment(item.created_at).valueOf());
                array.forEach((item: any) => {
                    const { created_at: createdAt, balance: balances, amount, object } = item;
                    const createdAtDate = moment(createdAt).format('YYYY-MM');
                    if (!amountMap[createdAtDate]) {
                        amountMap[createdAtDate] = balances;
                    } else if (object === 'transfer') {
                        amountMap[createdAtDate] += amount;
                    } else if (object === 'payout') {
                        amountMap[createdAtDate] -= amount;
                    }
                });

                for (let i = 1; i <= 12; i++) {
                    const targetDate = moment()
                        .subtract(12 - i, 'months')
                        .startOf('month');
                    const originalDate = targetDate.format('YYYY-MM');
                    data.push({
                        date: targetDate.toDate().getTime(),
                        originalDate,
                        value: amountMap[originalDate] || 0,
                    });
                }
                break;
        }

        return data;
    };

    const handleTab = (val: string) => () => {
        if (currentTab === val) return;
        setCurrentTab(val);
        setPaymentDetails([]);
    };

    return (
        <div className="flex flex-col flex-1 w-full px-5">
            <div className="flex flex-row items-center justify-between text-center w-full pb-4">
                <div className="flex flex-row">
                    <div className="font-semibold text-xl font-[#000000]">Account Balance</div>
                    <div className="text-xl font-semibold ml-2 text-[#079455]">{formatAmount(balance)}</div>
                </div>
                <div className="flex flex-row gap-3">
                    <div className="fl-tab-btn-view2 min-h-[36px] w-full">
                        {timePeriodTabs.map((tab: any, index) => (
                            <button
                                key={index}
                                type="button"
                                onClick={handleTab(tab.value)}
                                className={`w-full fl-tab-link2 justify-center items-center min-h-[34px] min-w-fit cursor-pointer ${tab.value === currentTab ? 'active' : ''}`}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="h-[226px] max-h-[226px]">
                {isLoading ? (
                    <SChart />
                ) : paymentDetails.length > 0 ? (
                    <div id="chartdiv" className="h-[226px]"></div>
                ) : (
                    <div className="pie-chart-wrapper border max-h-[226px] h-[226px] flex justify-center items-center border-borderSecondary rounded-xl shadow">
                        <NoDataMessage
                            title="No Payments Data"
                            description={'Payments will be available once clients start booking appointments online'}
                            iconComponent={<SearchLg className="text-gray-700" />}
                            buttonText={t('Create Booking')}
                            // onButtonClick={createBooking}
                        />
                    </div>
                )}
            </div>
            <Transactions />
        </div>
    );
};

export default MainPayment;
