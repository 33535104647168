import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Radio } from 'flowbite-react';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, currentShop } from 'src/redux/services/common/Common.slice';
import { formatKpiContentLable, onError } from 'src/utils/global-functions';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';

const ExpenseLocation = ({ setCurrentLocationExpense }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const { control, clearErrors } = useFormContext();
    const shopLocationList = useAppSelector(allShopLocations);
    const [locationList, setLocationList] = useState<any[]>([]);
    const [locationWiseExpenses, setLocationWiseExpenses] = useState([]);

    const getExpenses = async () => {
        await axiosGet(API.ANALYTICS.EXPENSELIST, { shop_id: shop.id }).then((response) => {
            const data = response.data.data;
            setLocationWiseExpenses(data);
        });
    };

    useEffect(() => {
        getExpenses();
    }, []);

    useEffect(() => {
        if (shopLocationList.length) {
            if (locationWiseExpenses.length) {
                const locations = shopLocationList.map((locationData: any) => {
                    const expense: any = locationWiseExpenses.find((e: any) => e.shop_location_id === locationData.id);
                    return {
                        ...locationData,
                        expense: expense || null,
                    };
                });
                setLocationList(locations);
            } else {
                setLocationList(shopLocationList);
            }
        }
    }, [shopLocationList, locationWiseExpenses]);

    const handleChange = (newValue: string, expense: any, onChange: any) => {
        onChange(newValue);
        clearErrors('shop_location_id');
        setCurrentLocationExpense(expense);
    };

    return (
        <>
            <Controller
                name="shop_location_id"
                control={control}
                render={({ field: { onChange, value }, fieldState }: any) => (
                    <>
                        <div className="grid grid-cols-2 gap-4 max-h-[511px] min-h-[511px] overflow-y-auto">
                            {locationList.map((item: any, index: number) => (
                                <label
                                    key={index}
                                    className={`border rounded-xl p-4 cursor-pointer min-h-[247px] max-h-[247px] h-[247px] relative hover:shadow  ${
                                        Number(value) === item.value ? 'border-primary bg-[#F0F3FF]' : ''
                                    } ${fieldState.error ? 'is-invalid' : ''}`}
                                    htmlFor={item.value}
                                >
                                    <img src={item.profile_image_url} alt="" className="h-[163px] min-h-[163px] w-full  rounded-xl object-cover" onError={onError} />
                                    <div className="flex flex-col gap-1 mt-[15px]">
                                        <span className="text-sm leading-[16.94px] font-medium text-mainTextColor">{item.name}</span>
                                        <div className="text-xs  leading-[14.52px] font-medium text-secondaryTxtColor">
                                            {t('Total Expenses')}: <span className="font-normal">${formatKpiContentLable(item.expense ? item.expense.total : 0)}</span>
                                        </div>
                                    </div>
                                    <Radio
                                        className="custom-round-radio d-none"
                                        name="shop_location_id "
                                        id={item.value}
                                        value={item.value}
                                        checked={value === item.value}
                                        onChange={() => handleChange(item.value, item.expense, onChange)}
                                    />
                                </label>
                            ))}
                        </div>
                    </>
                )}
            />
        </>
    );
};

export default ExpenseLocation;
