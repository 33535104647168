import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReaderTable from './ReaderTable';
import PopupModal from 'src/components/PopupModal';
import { Monitor05 } from '@untitled-ui/icons-react/build/cjs';
import LocationTable from './LocationTable';

const Terminal = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('location');
    const [activeTabComponent, setActiveTabComponent] = useState(<LocationTable />);

    useEffect(() => {
        switch (activeTab) {
            case 'location':
                setActiveTabComponent(<LocationTable />);
                break;
            case 'reader':
                setActiveTabComponent(<ReaderTable />);
                break;
            default:
                break;
        }
    }, [activeTab]);

    const handleTab = (type: string) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(type);
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <Monitor05 className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900">{t('Terminal')}</p>
                <span className="text-xs font-normal text-gray-500">{t('View and manage your terminals.')}</span>
            </div>
        </div>
    );

    const tabs = useMemo(
        () => [
            {
                name: 'Location',
                type: 'location',
            },
            {
                name: 'Reader',
                type: 'reader',
            },
        ],
        [],
    );

    return (
        <PopupModal dismissible onClose={handleClose} size="w-[1280px]" fixedHeightClass="h-[750px] overflow-auto" title={<Title />}>
            <div className="flex flex-col flex-1">
                <div className="fl-tab-btn-view w-full mb-4">
                    {tabs.map((tab, index) => (
                        <button key={index} type="button" className={`fl-tab-link !max-w-[182px] ${activeTab === tab.type ? 'active' : ''}`} onClick={handleTab(tab.type)}>
                            {tab.name}
                        </button>
                    ))}
                </div>
                {activeTabComponent}
            </div>
        </PopupModal>
    );
};

export default Terminal;
