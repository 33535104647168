import React, { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import InputWithLabel from 'src/components/InputWithLabel';
import { convertBase64ToFile } from 'src/utils/global-functions';
import ImageBoxView from '../../Image/ImageBoxView';
import ImageInput from '../../Image/ImageInput';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { InitTestimonialInfoData } from './Testimonial.interface';
import Switch from 'src/components/Switch/Switch';
import { Collapse } from '../Collapse';
import CropperModal from 'src/components/CropperModal/CropperModal';

const Form = () => {
    const { control, setValue, watch } = useFormContext();
    const { t } = useTranslation();
    const { remove, append, fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'testimonials',
    });
    const [image, setImage] = useState<any>('');
    const [isUpload, setIsUpload] = useState(false);
    const [fieldName, setFieldName] = useState<string>('');

    const onChangeCrop = (field: any) => (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const reader = new FileReader();
        reader.onloadstart = () => {
            setImage('');
            setIsUpload(false);
        };

        reader.onloadend = () => {
            setFieldName(field);
            setImage(reader.result as any);
            setIsUpload(true);
            const file = convertBase64ToFile(reader.result);
            if (file && file.filename && file.convertedFile) {
                setValue(field, {
                    file: file.convertedFile as File,
                    url: reader.result as string,
                    name: file.filename,
                });
            }
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };
    const handleRemoveImage = (field: string) => () => {
        setValue(field, null);
    };
    const addTestimonial = () => {
        InitTestimonialInfoData && append(InitTestimonialInfoData);
    };
    const removeTestimonial = (index: number) => () => {
        remove(index);
    };
    const handleCropData = (data: any) => {
        const convertedFile = convertBase64ToFile(data);
        if (convertedFile && convertedFile.filename && fieldName) {
            setValue(fieldName, {
                file: convertedFile.convertedFile as File,
                url: data,
                name: convertedFile.filename,
            });
        }
    };

    return (
        <div className="data-wrap-block space-y-4">
            {fields?.map((item: any, index: number) => (
                <div key={item?.uuid} className="rounded-xl border border-borderSecondary">
                    <Collapse title={watch(`testimonials.${index}.name`) || `${t('Testimonial Name')}`} classNames="!p-4 !border-borderSecondary" handleRemove={removeTestimonial(index)}>
                        {/* <div className="text-right">
                        <Button type="button" onClick={() => remove(index)} className="text-dangerErrorText text-xs font-medium">
                            Remove
                        </Button>
                    </div> */}
                        <div className="flex justify-end  gap-4 p-4">
                            <div className="flex gap-y-4">
                                <Controller
                                    name={`testimonials.${index}.image`}
                                    control={control}
                                    render={({ field: { value }, fieldState: { error } }: any) => (
                                        <div id={`testimonials.${index}.image`}>
                                            {value && value.url ? (
                                                <ImageBoxView
                                                    item={value}
                                                    name={`testimonials.${index}.image`}
                                                    onChangeCrop={onChangeCrop}
                                                    handleRemoveImage={handleRemoveImage(`testimonials.${index}.image`)}
                                                    key={index}
                                                />
                                            ) : (
                                                <ImageInput error={error} onChangeCrop={onChangeCrop} name={`testimonials.${index}.image`} title="Testimonial Picture" />
                                            )}

                                            {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className=" w-full space-y-4" key={index}>
                                <Controller
                                    name={`testimonials.${index}.name`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <>
                                            <InputWithLabel
                                                name={`staff.${index}.name`}
                                                label={t('Name')}
                                                id={`testimonials.${index}.name`}
                                                placeholder={t('Enter testimonial name')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                required
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </>
                                    )}
                                />
                                <Controller
                                    name={`testimonials.${index}.description`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name={`testimonials.${index}.description`}
                                                label={t('Description')}
                                                id={`testimonials.${index}.description`}
                                                textArea
                                                placeholder={t('Enter testimonial Description')}
                                                onChange={onChange}
                                                value={value}
                                                error={!!error}
                                                rows={15}
                                                maxLenght={275}
                                                textAearClassName="rounded-md h-[100px]"
                                                required
                                                toggle={
                                                    <Controller
                                                        name={`testimonials.${index}.is_description`}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </Collapse>
                </div>
            ))}
            {isUpload && fieldName && (
                <CropperModal
                    imageUrl={image}
                    setUpload={setIsUpload}
                    setCropData={handleCropData}
                    defaultCropType="Square"
                    title={t('Brand Logo')}
                    description={t('Upload a  max 800Kb image for best results.')}
                    isCropType={false}
                    btnTitle={t('Confirm')}
                />
            )}
            <div className="flex justify-end items-center !mt-2 !mb-2">
                <Button type="button" className="text-xs font-medium text-blue-600 flex gap-1 focus-visible:outline-none focus:outline-none focus:shadow-none" onClick={addTestimonial}>
                    <Plus width={16} />
                    {t('Add new testimonial')}
                </Button>
            </div>
        </div>
    );
};
export default Form;
