import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoDotFill } from 'react-icons/go';
import { useAppSelector } from 'src/redux/hooks';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { capitalizeFirstLetterAndUnderscore, formatAmount, formatDateTime, getDateRange, getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import { allShopLocations, allShopStaff, currentShop } from 'src/redux/services/common/Common.slice';
import { Users01, ArrowUp, ArrowDown, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import NoDataMessage from 'src/components/NoDataMessage';
import moment from 'moment';
import { AllLocationsOptions, AllTeamMembersOptions, perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import Badge from 'src/components/Badge';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';

const SalesTable = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const shopLocationList = useAppSelector(allShopLocations);
    const allShopStaffList = useAppSelector(allShopStaff);
    const [bookings, setBookings] = useState<any>([]);
    const [totalBookings, setTotalBookings] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentTab, setCurrentTab] = useState('daily');
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const controller = new AbortController();
    const { signal } = controller;
    const [isImageError, setIsImageError] = useState<{ [type: string]: { [key: number]: boolean } }>({
        staff: {},
        user: {},
    });

    const INIT_STATE = {
        FILTER: {
            shop_location_id: null,
            shop_admin_id: null,
        },
        LAZY_STATE: {
            first: 0,
            rows: 25,
            page: 1,
            sortField: null,
            sortOrder: null,
            filters: {
                global: { value: null },
                shop_location_id: { value: null },
                shop_admin_id: { value: null },
            },
        },
    };
    const [lazyState, setLazyState] = useState<any>(INIT_STATE.LAZY_STATE);
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        shop_location_id: { value: null },
        shop_admin_id: { value: null },
    });
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [selectedFilter, setSelectedFilter] = useState(INIT_STATE.FILTER);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        const updateLocationData = [AllLocationsOptions, ...locationData];
        setLocationOptions(updateLocationData);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        const updateTeamData = [AllTeamMembersOptions, ...staff];
        setStaffOptions(updateTeamData);
        setSelectedFilter(INIT_STATE.FILTER);
        setLazyState(INIT_STATE.LAZY_STATE);
    }, [shop]);

    useEffect(() => {
        listBookings();
        return () => {
            controller.abort();
        };
    }, [lazyState, shop, startDate, endDate]);

    useEffect(() => {
        if (currentTab !== '') {
            const { start, end } = getDateRange(currentTab);
            setStartDate(start);
            setEndDate(end);
        }
        switch (currentTab) {
            case 'weekly':
            case 'monthly':
            case 'yearly':
                setSelectedTimeRange(currentTab);
                break;
            case 'daily':
                setSelectedTimeRange('custom');
                break;
        }
    }, [currentTab]);

    const listBookings = () => {
        setIsLoading(true);
        const payload = {
            ...lazyState,
            type: 'all',
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        };
        axiosPost(API.BOOKING.LIST, payload, { shop_id: shop.id }, signal)
            .then((response) => {
                setBookings(response.data.data.data);
                setTotalBookings(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const handleDatePickerChange = (date: any, timeRange?: string) => {
        if (timeRange === 'weekly' || timeRange === 'monthly' || timeRange === 'yearly') {
            setCurrentTab(timeRange);
        } else {
            setCurrentTab('');
            const [start, end] = date;
            setStartDate(start);
            setEndDate(end);
            if (!end) {
                setEndDate(start);
            }
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
    };

    const handleTab = (val: string) => () => {
        setCurrentTab(val);
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, first: 0, filters: { ...old.filters, global: { value } } }));
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setLazyState((old: any) => ({ ...old, first: 0, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const handleImageError = (id: number, type: string) => () => {
        setIsImageError((old: any) => ({ ...old, [type]: { ...old[type], [id]: true } }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, first: 0, rows: event.value }));
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const GetStylist = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.staff.profile_image_url && !isImageError.staff[row.id] ? (
                    <img src={row.staff.profile_image_url} alt="" className="w-full h-full object-cover" onError={handleImageError(row.id, 'staff')} />
                ) : (
                    getShortName(row.staff.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px] w-[80px] min-w-[80px] 2xl:w-[85px] 2xl:min-w-[85px] 2xlm:w-[100px] 2xlm:min-w-[100px] xxl:w-[110px] xxl:min-w-[110px] truncate">
                    {row.staff.full_name}
                </p>
                {/* <p className="text-xs font-normal text-gray-600 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {moment(row.created_at).fromNow()}
                </p> */}
            </div>
        </div>
    );
    const getCheckoutMethod = (row: any) => {
        const transactionArray = row.booking_transaction;
        return transactionArray.length > 0 ? (
            <p className="text-xs font-normal  w-[80px] min-w-[80px] 2xl:w-[85px] 2xl:min-w-[85px] 2xlm:w-[100px] 2xlm:min-w-[100px] xxl:w-[110px] xxl:min-w-[110px] truncate capitalize">
                {`${transactionArray[0].payment_method} ${transactionArray.length > 1 ? `+${transactionArray.length - 1}` : ''}`}
            </p>
        ) : (
            <>-</>
        );
    };
    const GetClient = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName">
                {row.user.profile_image_url && !isImageError.user[row.id] ? (
                    <img src={row.user.profile_image_url} alt="" className="w-full h-full object-cover" onError={handleImageError(row.id, 'user')} />
                ) : (
                    getShortName(row.user.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-medium text-gray-900 leading-[18px]  w-[80px] min-w-[80px] 2xl:w-[85px] 2xl:min-w-[85px] 2xlm:w-[100px] 2xlm:min-w-[100px] xxl:w-[110px] xxl:min-w-[110px] truncate capitalize-first">
                    {row.user.full_name}
                </p>
                {/* <p className="text-xs font-normal text-gray-600 leading-[18px] w-[80px] min-w-[80px]  max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                    {moment(row.created_at).fromNow()}
                </p> */}
            </div>
        </div>
    );
    const GetTotal = (row: any) => formatAmount(row.total);
    const GetSubtotal = (row: any) => formatAmount(row.subtotal);
    const GetTip = (row: any) => formatAmount(row.tip_transaction[0] ? row.tip_transaction[0].total_amount : 0);
    const GetTaxAndFees = (row: any) => formatAmount(row.taxes_and_fees);

    const GetDateTime = (row: any) => {
        const dateTime = `${row.booking_date} ${row.booking_start_time}`;
        return formatDateTime(dateTime);
    };

    const GetStatus = (row: any) => (
        <Badge icon status={row.status}>
            {capitalizeFirstLetterAndUnderscore(row.status)}
        </Badge>
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map((_, index) => ({
                id: index,
                first_name: 'loading',
                phone: 'loading',
                email: 'loading',
                average_days: 'loading',
                average_amount: 'loading',
                bookings_count: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    const timePeriodTabs = useMemo(
        () => [
            {
                name: t('Today'),
                value: 'daily',
            },
            {
                name: t('This Week'),
                value: 'weekly',
            },
            {
                name: t('This Month'),
                value: 'monthly',
            },
            {
                name: t('This Year'),
                value: 'yearly',
            },
        ],
        [],
    );

    return (
        <div
            className={`w-full border rounded-xl flex-1 flex-col flex shadow  staff_service_table datatable-custom-service ${!bookings.length ? 'datatable-full-height datatable-noshow h-full' : ''}`}
        >
            <div className={`flex items-center w-full flex-col `}>
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                    <div className="flex flex-col flex-1">
                        <div className="flex justify-start items-center gap-1.5 xxl:gap-1.5">
                            <h2 className="table-title">{t('All Sales')}</h2>
                            <p className={`userCounter ${isLoading ? 'custom-loading' : ''}`}>
                                <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                {totalBookings} {t('sales')}
                            </p>
                        </div>
                        <p className="table-subtitle">{t('View and manage your sales list.')}</p>
                    </div>
                </div>
                <div className="flex max-2xl:flex-col items-start  justify-between w-full  ">
                    <div className="flex flex-row max-2xl:w-[100%] px-5 py-3 max-2xl:border-b max-2xl:border-gray-200 w-[46%] gap-3 xxl:gap-4">
                        <div className="fl-tab-btn-view2 h-[36px] flex shadow-custom-sm  w-full">
                            {timePeriodTabs.map((tab: any, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    onClick={handleTab(tab.value)}
                                    className={`w-full fl-tab-link2 min-w-fit  !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px] lg:min-h-[34px] min-h-[34px] flex justify-center items-center lg:py-1 py-2 xl:px-4 lg:px-4 sm:px-3  ${
                                        tab.value === currentTab ? 'active !bg-gray-100' : ''
                                    }`}
                                >
                                    {tab.name}
                                </button>
                            ))}
                        </div>
                        <DateRangePicker
                            isDisplayAsBox={false}
                            handleDatePickerChange={handleDatePickerChange}
                            selectedTimeRange={selectedTimeRange}
                            startDate={startDate}
                            endDate={endDate}
                            allTimeStartDate={shop.created_at}
                            showYearDropdown={false}
                            isToday={currentTab === 'daily'}
                            isShowDropDownIcon={false}
                            containerClassName="max-2xl:right-0 2xl:left-0 "
                            parentClassName="sales-datepicker w-full  "
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            activeFilter={currentTab}
                            setActiveFilter={setCurrentTab}
                        />
                    </div>
                    <div className="flex gap-3 max-2xl:w-[100%] px-5 py-3 w-[50%] justify-end">
                        <div className="w-[30%] 2xl:w-[170px] xlm:w-[185px] xxl:w-[200px]  table-searchInput ">
                            <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                        </div>
                        <div className="w-[30%] 2xl:w-[170px] xlm:w-[185px] xxl:w-[200px]">
                            <SelectBox
                                options={locationOptions}
                                value={locationOptions.find((option) => option.value === selectedFilter.shop_location_id)}
                                noOptionsMessage="No Locations Found"
                                placeholder={t('All locations')}
                                onChangeFunc={handleFilter('shop_location_id')}
                                classComp=""
                                isSearchable={false}
                                isClearable={false}
                            />
                        </div>
                        <div className="w-[30%] 2xl:w-[170px] xlm:w-[185px] xxl:w-[200px]">
                            <SelectBox
                                options={staffOptions}
                                noOptionsMessage="No Team Found"
                                value={staffOptions.find((option) => option.value === selectedFilter.shop_admin_id)}
                                placeholder={t('All Team members')}
                                onChangeFunc={handleFilter('shop_admin_id')}
                                classComp="outline-select-box"
                                isSearchable={false}
                                isClearable={false}
                            />
                        </div>
                        <div className="w-[10%] 2xl:w-[70px] page-dropdown">
                            <SelectBox
                                name="page"
                                options={perPageOptions}
                                isClearable={false}
                                onChangeFunc={onPageHandle}
                                value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                classComp="outline-select-box"
                                isSearchable={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col flex-1">
                <DataTable
                    lazy
                    paginatorTemplate={customPaginatorTemplate}
                    value={isLoading ? skeletons : bookings}
                    totalRecords={totalBookings}
                    paginator={!isLoading && totalBookings > lazyState.rows}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    filters={lazyState.filters}
                    onPage={onPage}
                    onSort={onSort}
                    onFilter={onFilter}
                    dataKey="id"
                    className="border-t rounded-b-xl overflow-hidden w-full overflow-x-auto"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <NoDataMessage
                            title={`${filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value ? 'No Sales Found.' : 'Sales Unavailable.'}`}
                            description={`${
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value
                                    ? 'It looks like there are no sales matching your search criteria. Try adjusting your filters or search parameters to find available sales.'
                                    : 'To begin tracking sales, set up your shop by adding team members, services, and products. Once these are in place, you can monitor your sales here.'
                            }`}
                            iconComponent={
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value ? <SearchLg className="text-gray-700" /> : <Users01 className="text-gray-700" />
                            }
                        />
                    }
                >
                    <Column
                        field="user_name"
                        header={renderHeader(t('Client'), 'user_name')}
                        className="text-xs leading-[18px] font-medium text-mainTextColor"
                        body={isLoading ? <Skeleton /> : GetClient}
                        style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                        sortable
                    ></Column>
                    <Column
                        field="status"
                        header={renderHeader(t('Status'), 'status')}
                        body={isLoading ? <Skeleton /> : GetStatus}
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        className="text-xs font-medium text-secondaryTxtColor"
                        sortable
                    ></Column>
                    <Column
                        field="staff_name"
                        header={renderHeader(t('Team Member'), 'staff_name')}
                        className="text-xs leading-[18px] font-medium text-mainTextColor"
                        body={isLoading ? <Skeleton /> : GetStylist}
                        style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                        sortable
                    ></Column>
                    <Column
                        field="booking_date"
                        header={renderHeader(t('Date and Time'), 'booking_date')}
                        body={isLoading ? <Skeleton /> : GetDateTime}
                        className="text-xs text-secondaryTxtColor font-normal truncate"
                        style={{ width: '180px', minWidth: '180px', maxWidth: '180px' }}
                        sortable
                    ></Column>
                    <Column
                        field="subtotal"
                        header={renderHeader(t('Subtotal'), 'subtotal')}
                        body={isLoading ? <Skeleton /> : GetSubtotal}
                        style={{ width: '75px', minWidth: '75px', maxWidth: '75px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="tips"
                        header={renderHeader(t('Tips'), 'tips')}
                        body={isLoading ? <Skeleton /> : GetTip}
                        style={{ width: '75px', minWidth: '75px', maxWidth: '75px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="taxes_and_fees"
                        header={renderHeader(t('Taxes '), 'taxes_and_fees')}
                        body={isLoading ? <Skeleton /> : GetTaxAndFees}
                        style={{ width: '75px', minWidth: '75px', maxWidth: '75px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="total"
                        header={renderHeader(t('Total'), 'total')}
                        body={isLoading ? <Skeleton /> : GetTotal}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="payment_method"
                        header={renderHeader(t('Checkout Method'), 'payment_method')}
                        body={isLoading ? <Skeleton /> : getCheckoutMethod}
                        style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                </DataTable>
            </div>
        </div>
    );
};

export default SalesTable;
