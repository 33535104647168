import React from 'react';
import { CheckCircle, XClose } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import { LoadingRound } from 'src/theme/Images';
import { IInitPaymentStatus } from './Payment/Payment.interface';

const AppointmentStatus = ({ paymentStatus, handleContinueOrBack, handleRetry }: { paymentStatus: IInitPaymentStatus; handleContinueOrBack: () => void; handleRetry: () => void }) => (
    <div className="h-full p-4 flex justify-center items-center w-full">
        {paymentStatus.processing && (
            <div className="h-[176px] flex flex-col justify-between items-center w-full">
                <div className="flex border w-[50px] h-[50px] justify-center  border-[#EAECF0] rounded-[10px] shadow-sm ">
                    <LoadingRound className="w-12 h-12 rounded-[10px]" />
                </div>
                <div className="font-semibold text-mainTextColor text-center text-lg w-full">Processing payment</div>
                <div className="font-normal text-secondaryTxtColor text-center text-sm w-full">Processing payment, please wait until completed</div>
                <CustomButton secondary className=" !h-[36px] !w-[156px]" type="button" onClick={handleContinueOrBack}>
                    Cancel
                </CustomButton>
            </div>
        )}

        {paymentStatus.retry && (
            <div className="h-[246px] flex flex-col justify-between items-center w-full">
                <div className="flex items-center border w-[48px] h-[48px] justify-center  border-[#D92D20] rounded-[10px]">
                    <XClose className="w-5 h-5 rounded-[10px] text-[#D92D20]" />
                </div>
                <div className="h-[52px] gap-1 flex flex-col justify-between items-center">
                    <div className="font-semibold text-mainTextColor text-center text-lg w-full">Something went wrong</div>
                    <div className="font-normal text-secondaryTxtColor text-center text-sm w-full">Please try again.</div>
                </div>
                <div className="h-[82px] gap-2.5 flex flex-col justify-between items-center">
                    <CustomButton outlinePrimary className=" !h-[36px] !w-[156px]" type="button" onClick={handleRetry}>
                        Retry
                    </CustomButton>
                    <CustomButton secondary className=" !h-[36px] !w-[156px]" type="button" onClick={handleContinueOrBack}>
                        Go back
                    </CustomButton>
                </div>
            </div>
        )}
        {paymentStatus.success && (
            <div className="h-[176px] flex flex-col justify-between items-center w-full">
                <div className="flex items-center border w-[48px] h-[48px] justify-center  border-[#079455] rounded-[10px]">
                    <CheckCircle className="w-5 h-5  text-[#079455]" />
                </div>
                <div className="font-semibold text-mainTextColor text-center text-lg w-full">Payment completed</div>
                <CustomButton secondary className=" !h-[36px] !w-[156px]" type="button" onClick={handleContinueOrBack}>
                    Continue
                </CustomButton>
            </div>
        )}

        {paymentStatus.error && (
            <div className="h-[246px] flex flex-col justify-between items-center w-full">
                <div className="flex items-center border w-[48px] h-[48px] justify-center  border-[#D92D20] rounded-[10px]">
                    <XClose className="w-5 h-5 rounded-[10px] text-[#D92D20]" />
                </div>
                <div className="h-[52px] gap-1 flex flex-col justify-between items-center">
                    <div className="font-semibold text-mainTextColor text-center text-lg w-full">Awaiting payment completion</div>
                    <div className="font-normal text-secondaryTxtColor text-center text-sm w-full">Awaiting payment completion</div>
                </div>
                <div className="h-[82px] gap-2.5 flex flex-col justify-between items-center">
                    <CustomButton outlinePrimary className=" !h-[36px] !w-[156px]" type="button" onClick={handleRetry}>
                        Retry
                    </CustomButton>
                    <CustomButton secondary className=" !h-[36px] !w-[156px]" type="button" onClick={handleContinueOrBack}>
                        Go back
                    </CustomButton>
                </div>
            </div>
        )}
    </div>
);

export default AppointmentStatus;
