import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import InputPasswordWithLabel from 'src/components/InputPasswordWithLabel';
import { useAppDispatch } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { useTranslation } from 'react-i18next';
import { IPasswordFiled, IPasswordVerificationProps } from './Password.interface';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { me } from 'src/redux/services/common/Common.slice';
import { LOCAL_STORAGE_KEYS } from 'src/constants/common';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { capitalizeFirstLetter } from 'src/utils/global-functions';
import CustomButton from 'src/components/CustomButton';

const PasswordVerification: FC<IPasswordVerificationProps> = ({ authData, resetAuthAction }) => {
    const schema = Yup.object({
        password: Yup.string().required('This field is required'),
    }).required();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [loading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<IPasswordFiled>({
        resolver: yupResolver(schema),
        defaultValues: {
            password: '',
        },
    });

    const handleChange = async (data: IPasswordFiled) => {
        setIsLoading(true);
        let payload = {};
        if (authData.isEmail) {
            payload = {
                password: data.password,
                email: authData.email,
                otp: authData.otp,
            };
        } else {
            const mainNumber = `+${authData.phone.code}${authData.phone.number}`;
            payload = {
                password: data.password,
                phone: mainNumber,
                phone_country_code: authData.phone.country,
                otp: authData.otp,
            };
        }
        try {
            const response = await axiosPost(API.USER.VERIFY_PASSWORD, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, response.data.data.token);
                await dispatch(me());
                return;
            }

            throw response.data;
        } catch (err: any) {
            const response = err.response.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as keyof IPasswordFiled, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="content pt-[108px] px-[100px]">
            <h1 className="mt-10 mb-1 text-[30px] -tracking-[0.384px] leading-10 font-semibold">
                {t('Welcome back')}, {capitalizeFirstLetter(authData.name)}
            </h1>
            <p className="text-[14px] font-normal opacity-70 -tracking-[0.3px] text-secondaryTxtColor leading-[140%]">{t('Please enter your password to continue.')}</p>
            <div className="mt-6">
                <form onSubmit={handleSubmit(handleChange)}>
                    <div className="flex flex-wrap">
                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="flex flex-col w-full">
                                    <InputPasswordWithLabel
                                        label={t('Password')}
                                        required
                                        placeholder={t('Enter your password')}
                                        onChange={onChange}
                                        inputClass={`${errors.password ? 'is-invalid' : ''}`}
                                        value={value.password}
                                        name="password"
                                        isFocus={true}
                                    />
                                    {/* {errors.password && <p className="text-error">{errors.password && errors.password.message}</p>} */}
                                </div>
                            )}
                        />
                    </div>
                    <CustomButton type="submit" isLoading={loading} disabled={loading} primary size="w-full" className="!text-base mt-[20px]">
                        {'Continue'}
                    </CustomButton>
                </form>
                <div className="mt-[10px] -tracking-[0.384px] leading-10 text-center">
                    <Link to={ROUTES.FORGOT_PASSWORD} className="text-primary font-medium">
                        {t('forgot your password')}
                    </Link>
                </div>
                <div className="">
                    <Button type="button" className="mt-3 text-sm font-bold text-center w-full" onClick={() => resetAuthAction()}>
                        <FaArrowLeftLong size={14} className="mr-2" /> {t('Back to log in')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PasswordVerification;
