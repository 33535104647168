import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { dayOptions, frequencyOptions, IFormData, monthOptions } from './PaymentSettings.interface';
import * as Yup from 'yup';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentRole, currentShop, me } from 'src/redux/services/common/Common.slice';
import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import DateSelect from 'src/components/DateSelect/DateSelect';
import moment from 'moment';
import { calculateDueDate, getDayNumber } from 'src/utils/global-functions';
import { ROLES } from 'src/constants/common';
import { GoDotFill } from 'react-icons/go';
import { Tooltip } from 'primereact/tooltip';

const PaymentSettings = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const role = useAppSelector(currentRole);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [commissionPayout, setCommissionPayout] = useState<any>(null);
    const [TipPayout, setTipPayout] = useState<any>(null);
    const [productPayout, setProductPayout] = useState<any>(null);
    const [rentPayout, setRentPayout] = useState<any>(null);

    const schema = Yup.object({
        commission_frequency: Yup.string().required(t('This field is required')),
        commission_day: Yup.string().when('commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when commission frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        commission_start_date: Yup.string().when('commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema
                    .required(t('This field is required when commission frequency is Weekly or Bi-Weekly'))
                    .test('commission_day', t('The date must be the same as the payment day'), function (value) {
                        const { commission_day: day } = this.parent;
                        if (!value || !day) {
                            return true;
                        }
                        const dayNumber = getDayNumber(day);
                        const currentDay = moment(value).day();
                        return currentDay === dayNumber;
                    }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        commission_month_day: Yup.string().when('commission_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when commission frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        rent_frequency: Yup.string().required(t('This field is required')),
        rent_day: Yup.string().when('rent_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when rent frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        rent_start_date: Yup.string().when('rent_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema.required(t('This field is required when rent frequency is Weekly or Bi-Weekly')).test('rent_day', t('The date must be the same as the payment day'), function (value) {
                    const { rent_day: day } = this.parent;
                    if (!value || !day) {
                        return true;
                    }
                    const dayNumber = getDayNumber(day);
                    const currentDay = moment(value).day();
                    return currentDay === dayNumber;
                }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        rent_month_day: Yup.string().when('rent_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when rent frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        tip_frequency: Yup.string().required(t('This field is required')),
        tip_day: Yup.string().when('tip_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when tip frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        tip_start_date: Yup.string().when('tip_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema.required(t('This field is required when tip frequency is Weekly or Bi-Weekly')).test('tip_day', t('The date must be the same as the payment day'), function (value) {
                    const { tip_day: day } = this.parent;
                    if (!value || !day) {
                        return true;
                    }
                    const dayNumber = getDayNumber(day);
                    const currentDay = moment(value).day();
                    return currentDay === dayNumber;
                }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        tip_month_day: Yup.string().when('tip_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when tip frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        product_commission_frequency: Yup.string().required(t('This field is required')),
        product_commission_day: Yup.string().when('product_commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when product commission frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        product_commission_start_date: Yup.string().when('product_commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema
                    .required(t('This field is required when product commission frequency is Weekly or Bi-Weekly'))
                    .test('product_commission_day', t('The date must be the same as the payment day'), function (value) {
                        const { product_commission_day: day } = this.parent;
                        if (!value || !day) {
                            return true;
                        }
                        const dayNumber = getDayNumber(day);
                        const currentDay = moment(value).day();
                        return currentDay === dayNumber;
                    }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        product_commission_month_day: Yup.string().when('product_commission_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when product commission frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            commission_frequency: shop.shop_payment.commission_frequency || null,
            commission_day: shop.shop_payment.commission_day || null,
            commission_start_date: shop.shop_payment.commission_start_date || null,
            commission_month_day: shop.shop_payment.commission_month_day || null,
            rent_frequency: shop.shop_payment.rent_frequency || null,
            rent_start_date: shop.shop_payment.rent_start_date || null,
            rent_day: shop.shop_payment.rent_day || null,
            rent_month_day: shop.shop_payment.rent_month_day || null,
            tip_frequency: shop.shop_payment.tip_frequency || null,
            tip_day: shop.shop_payment.tip_day || null,
            tip_start_date: shop.shop_payment.tip_start_date || null,
            tip_month_day: shop.shop_payment.tip_month_day || null,
            product_commission_frequency: shop.shop_payment.product_commission_frequency || null,
            product_commission_day: shop.shop_payment.product_commission_day || null,
            product_commission_start_date: shop.shop_payment.product_commission_start_date || null,
            product_commission_month_day: shop.shop_payment.product_commission_month_day || null,
        },
    });

    const { handleSubmit, watch, setError, control, reset } = methods;
    const commissionDay = watch('commission_day');
    const commissionFrequency = watch('commission_frequency');
    const commissionStartDate = watch('commission_start_date');
    const commissionMonthDay = watch('commission_month_day');

    const rentDay = watch('rent_day');
    const rentFrequency = watch('rent_frequency');
    const rentStartDate = watch('rent_start_date');
    const rentMonthDay = watch('rent_month_day');

    const productDay = watch('product_commission_day');
    const productFrequency = watch('product_commission_frequency');
    const productStartDate = watch('product_commission_start_date');
    const productMonthDay = watch('product_commission_month_day');

    const tipDay = watch('tip_day');
    const tipFrequency = watch('tip_frequency');
    const tipStartDate = watch('tip_start_date');
    const tipMonthDay = watch('tip_month_day');
    const productCommissionDay = watch('product_commission_day');
    const productCommissionFrequency = watch('product_commission_frequency');

    useEffect(() => {
        const commissionDue = calculateDueDate({
            frequency: commissionFrequency,
            day: commissionDay,
            currentDateTime: moment(commissionStartDate),
            monthDay: commissionMonthDay,
        });
        const data = {
            startDate: commissionDue.startDate,
            endDate: commissionDue.endDate,
        };
        setCommissionPayout(data);
    }, [commissionFrequency, commissionStartDate, commissionDay, commissionMonthDay]);

    useEffect(() => {
        const rentDue = calculateDueDate({
            frequency: rentFrequency,
            day: rentDay,
            currentDateTime: moment(rentStartDate),
            monthDay: rentMonthDay,
        });
        const data = {
            startDate: rentDue.startDate,
            endDate: rentDue.endDate,
        };
        setRentPayout(data);
    }, [rentFrequency, rentStartDate, rentDay, rentMonthDay]);
    useEffect(() => {
        const productDue = calculateDueDate({
            frequency: productFrequency,
            day: productDay,
            currentDateTime: moment(productStartDate),
            monthDay: productMonthDay,
        });
        const data = {
            startDate: productDue.startDate,
            endDate: productDue.endDate,
        };
        setProductPayout(data);
    }, [productFrequency, productStartDate, productDay, productMonthDay]);
    useEffect(() => {
        const tipDue = calculateDueDate({
            frequency: tipFrequency,
            day: tipDay,
            currentDateTime: moment(tipStartDate),
            monthDay: tipMonthDay,
        });
        const data = {
            startDate: tipDue.startDate,
            endDate: tipDue.endDate,
        };
        setTipPayout(data);
    }, [tipFrequency, tipStartDate, tipDay, tipMonthDay]);

    const handleSave = async (data: IFormData) => {
        setIsLoading(true);
        await axiosPost(API.PAYMENT.UPDATE, data, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const isWeekday = (day: string) => (date: Date) => {
        const dayNumber = getDayNumber(day);
        const currentDay = moment(date).day();
        return currentDay === dayNumber;
    };

    const resetForm = () => {
        reset();
    };

    return (
        <div className="w-full flex flex-col flex-1">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)} className="w-full px-2 py-1">
                    <div className="detail-page-title-block !py-4 !px-0 border-borderSecondary">
                        <div className="flex flex-col gap-0">
                            <Tooltip target="#payment-setting" position="top" />
                            <h2 className="flex flex-row gap-1.5 items-center">
                                <div className="title-text-header">{t('Payment Structure Settings')}</div>
                                <InfoCircle
                                    className="text-[#98A2B3] w-4 h-4 custom-tooltip-btn"
                                    id="payment-setting"
                                    data-pr-tooltip={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}
                                />
                            </h2>
                            {/* <Tooltip className="bg-black text-white" content={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}>
                                    <InfoCircle className="text-[#98A2B3] w-4 h-4 custom-tooltip-btn" />
                                </Tooltip> */}
                            <p className="subtitle-text">{t('Manage automatic payouts and rent collection schedules for your staff.')}</p>
                        </div>
                        {/* <div className="flex flex-row gap-3">
                            <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" className="!h-[36px]">
                                {t('Save Changes')}
                            </CustomButton>
                        </div> */}
                    </div>
                    <div className="fl-data-block border-b border-borderSecondary py-4">
                        <div className="title-block min-w-[310px] max-w-[310px] w-[310px] flex flex-col gap-1">
                            <h3 className="title-text flex flex-row items-center gap-1">{t('Commission Payout Frequency')}</h3>
                            <p className="subtitle-text">{t('Specify how often staff receive payments based on commission.')}</p>
                        </div>
                        <div className="data-wrap-block w-full ">
                            <div className="  pb-4 ">
                                <div className="w-[600px] flex flex-row gap-4 justify-between  items-center">
                                    <h3 className="title-text">{t('Frequency')}</h3>
                                    <Controller
                                        name="commission_frequency"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="max-w-[320px] w-[320px]">
                                                <SelectBox
                                                    name="commission_frequency"
                                                    // label={t('Frequency')}
                                                    options={frequencyOptions}
                                                    onChangeFunc={(option: any) => onChange(option.value)}
                                                    value={frequencyOptions.find((option) => option.value === value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                                {error && <p className="text-error">{error?.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            {commissionFrequency === 'monthly' && (
                                <div className="  pb-4 ">
                                    <div className="w-[600px] flex flex-row gap-4 justify-between  items-center">
                                        <h3 className="title-text">{t('Select day of month')}</h3>
                                        <Controller
                                            name="commission_month_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <SelectBox
                                                        name="commission_month_day"
                                                        // label={t('Select day of month')}
                                                        options={monthOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={monthOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            )}

                            {(commissionFrequency === 'weekly' || commissionFrequency === 'bi_weekly') && (
                                <>
                                    <div className="pb-4 ">
                                        <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                            <h3 className="title-text">{t('Payment day')}</h3>

                                            <Controller
                                                name="commission_day"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div className="max-w-[320px] w-[320px]">
                                                        <SelectBox
                                                            placeholder={t('Select Payment day')}
                                                            name="commission_day"
                                                            // label={t('Payment day')}

                                                            options={dayOptions}
                                                            onChangeFunc={(option: any) => onChange(option.value)}
                                                            value={dayOptions.find((option) => option.value === value)}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                        />
                                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="pb-4 ">
                                        <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                            <h3 className="title-text">{t('Window start date')}</h3>

                                            <Controller
                                                name="commission_start_date"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div className="max-w-[320px] w-[320px] !h-[36px]">
                                                        <DateSelect
                                                            inputName="commission_start_date"
                                                            className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                            placeholder={t('Select date')}
                                                            dateFormat="yyyy-MM-dd"
                                                            popperPlacement="auto"
                                                            minDate={new Date()}
                                                            value={value}
                                                            onChangeValue={onChange}
                                                            filterDate={isWeekday(commissionDay || '')}
                                                        />
                                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {commissionPayout?.endDate.isValid() && (
                                <div className=" flex flex-row bg-white  items-center w-fit  px-2 py-1 shadow-sm rounded-[8px]  border border-borderSecondary ">
                                    <span className="mr-[2px] font-medium text-xs ">
                                        <GoDotFill size={12} color="#3570F8" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    </span>
                                    <div className="title-text !text-sm !font-medium !leading-[18px]">
                                        {commissionFrequency === 'monthly'
                                            ? t('Next payout will be', {
                                                  date: commissionPayout?.endDate?.format('Do MMMM, YYYY'),
                                              })
                                            : t('Next payout will be from', {
                                                  start_date: commissionPayout?.startDate?.format('Do MMMM, YYYY'),
                                                  end_date: commissionPayout?.endDate?.format('Do MMMM, YYYY'),
                                              })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="fl-data-block border-b border-borderSecondary py-4">
                        <div className="title-block w-[310px] max-w-[310px] min-w-[310px] flex flex-col  gap-1 ">
                            <h3 className="title-text flex flex-row gap-1 text-start">{t('Tip Payout Frequency')}</h3>
                            <p className="subtitle-text">{t('Set the frequency for automatic payout of staff tips.')}</p>
                        </div>

                        <div className="data-wrap-block w-full max-2xl:w-[480px] max-w-[636px]">
                            <div className="flex flex-col gap-4">
                                <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                    <h3 className="title-text">{t('Frequency')}</h3>
                                    <Controller
                                        name="tip_frequency"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="max-w-[320px] w-[320px]">
                                                <SelectBox
                                                    name="tip_frequency"
                                                    // label={t('Frequency')}
                                                    options={[{ value: 'daily', label: t('Daily') }].concat(frequencyOptions)}
                                                    onChangeFunc={(option: any) => onChange(option.value)}
                                                    value={[{ value: 'daily', label: t('Daily') }].concat(frequencyOptions).find((option) => option.value === value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                                {error && <p className="text-error">{error?.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                                {tipFrequency === 'monthly' && (
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Select day of month')}</h3>
                                        <Controller
                                            name="tip_month_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <SelectBox
                                                        name="tip_month_day"
                                                        // label={t('Select day of month')}
                                                        options={monthOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={monthOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                )}
                                {(tipFrequency === 'weekly' || tipFrequency === 'bi_weekly') && (
                                    <>
                                        <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                            <h3 className="title-text">{t('Payment day')}</h3>
                                            <Controller
                                                name="tip_day"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="max-w-[320px] w-[320px]">
                                                        <SelectBox
                                                            name="tip_day"
                                                            // label={t('Payment day')}
                                                            placeholder={t('Select payment day')}
                                                            options={dayOptions}
                                                            onChangeFunc={(option: any) => onChange(option.value)}
                                                            value={dayOptions.find((option) => option.value === value)}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                        />
                                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                            <h3 className="title-text">{t('Window start date')}</h3>
                                            <Controller
                                                name="tip_start_date"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="max-w-[320px] w-[320px]">
                                                        <DateSelect
                                                            inputName="tip_start_date"
                                                            className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                            placeholder={t('Select date')}
                                                            dateFormat="yyyy-MM-dd"
                                                            popperPlacement="auto"
                                                            minDate={new Date()}
                                                            value={value}
                                                            onChangeValue={onChange}
                                                            filterDate={isWeekday(tipDay || '')}
                                                        />
                                                        {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </>
                                )}
                                {TipPayout?.endDate.isValid() && (
                                    <div className="  flex flex-row bg-white  items-center w-fit  px-2 py-1 shadow-sm rounded-[8px]  border border-borderSecondary">
                                        <span className="mr-[2px] font-medium text-xs ">
                                            <GoDotFill size={12} color="#3570F8" className="h-3 w-3 rounded-full flex mr-0.5" />
                                        </span>
                                        <div className="title-text !text-sm !font-medium !leading-[18px]">
                                            {tipFrequency === 'monthly' || tipFrequency === 'daily'
                                                ? t('Next payout will be', {
                                                      date: TipPayout?.endDate?.format('Do MMMM, YYYY'),
                                                  })
                                                : t('Next payout will be from', {
                                                      start_date: TipPayout?.startDate?.format('Do MMMM, YYYY'),
                                                      end_date: TipPayout?.endDate?.format('Do MMMM, YYYY'),
                                                  })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="fl-data-block border-b border-borderSecondary py-4">
                        <div className="title-block min-w-[310px] max-w-[310px] w-[310px]">
                            <h3 className="title-text flex flex-row gap-1.5">{t('Rent Collection Frequency')}</h3>
                            <p className="subtitle-text">{t('Determine how often rent payments are automatically collected from staff.')}</p>
                        </div>

                        <div className="flex flex-col gap-4">
                            <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                <h3 className="title-text">{t('Frequency')}</h3>
                                <Controller
                                    name="rent_frequency"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="max-w-[320px] w-[320px]">
                                            <SelectBox
                                                name="rent_frequency"
                                                options={frequencyOptions}
                                                onChangeFunc={(option: any) => onChange(option.value)}
                                                value={frequencyOptions.find((option) => option.value === value)}
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                            {error && <p className="text-error">{error?.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            {rentFrequency === 'monthly' && (
                                <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                    <h3 className="title-text">{t('Select day of month')}</h3>
                                    <Controller
                                        name="rent_month_day"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="max-w-[320px] w-[320px]">
                                                <SelectBox
                                                    name="rent_month_day"
                                                    options={monthOptions}
                                                    onChangeFunc={(option: any) => onChange(option.value)}
                                                    value={monthOptions.find((option) => option.value === value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            )}
                            {(rentFrequency === 'weekly' || rentFrequency === 'bi_weekly') && (
                                <>
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Payment day')}</h3>
                                        <Controller
                                            name="rent_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <SelectBox
                                                        name="rent_day"
                                                        placeholder={t('Select Payment day')}
                                                        options={dayOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={dayOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Window start date')}</h3>
                                        <Controller
                                            name="rent_start_date"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <DateSelect
                                                        inputName="rent_start_date"
                                                        className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                        placeholder={t('Select date')}
                                                        dateFormat="yyyy-MM-dd"
                                                        popperPlacement="auto"
                                                        minDate={new Date()}
                                                        value={value}
                                                        onChangeValue={onChange}
                                                        filterDate={isWeekday(rentDay || '')}
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </>
                            )}
                            {rentPayout?.endDate.isValid() && (
                                <div className="flex flex-row bg-white  items-center w-fit  px-2 py-1 shadow-sm rounded-[8px]  border border-borderSecondary ">
                                    <span className="mr-[2px] font-medium text-xs ">
                                        <GoDotFill size={12} color="#3570F8" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    </span>
                                    <div className="title-text !font-medium">
                                        {rentFrequency === 'monthly'
                                            ? t('Next payout will be', {
                                                  date: rentPayout?.endDate?.format('Do MMMM, YYYY'),
                                              })
                                            : t('Next payout will be from', {
                                                  start_date: rentPayout?.startDate?.format('Do MMMM, YYYY'),
                                                  end_date: rentPayout?.endDate?.format('Do MMMM, YYYY'),
                                              })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="fl-data-block border-b border-borderSecondary py-4">
                        {productCommissionFrequency && (
                            <div className="title-block w-[310px]">
                                <h3 className="title-text">{t('Product Commission Frequency')}</h3>
                                <p className="subtitle-text">{t('Specify the payout schedule for staff product commissions.')}</p>
                            </div>
                        )}

                        <div className="flex flex-col gap-4">
                            <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                <h3 className="title-text">{t('Frequency')}</h3>
                                <Controller
                                    name="product_commission_frequency"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="max-w-[320px] w-[320px]">
                                            <SelectBox
                                                name="product_commission_frequency"
                                                options={frequencyOptions}
                                                onChangeFunc={(option: any) => onChange(option.value)}
                                                value={frequencyOptions.find((option) => option.value === value)}
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                            {error && <p className="text-error">{error?.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            {productCommissionFrequency === 'monthly' && (
                                <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                    <h3 className="title-text">{t('Select day of month')}</h3>
                                    <Controller
                                        name="product_commission_month_day"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="max-w-[320px] w-[320px]">
                                                <SelectBox
                                                    name="product_commission_month_day"
                                                    options={monthOptions}
                                                    onChangeFunc={(option: any) => onChange(option.value)}
                                                    value={monthOptions.find((option) => option.value === value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            )}

                            {(productCommissionFrequency === 'weekly' || productCommissionFrequency === 'bi_weekly') && (
                                <>
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Payment day')}</h3>
                                        <Controller
                                            name="product_commission_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <SelectBox
                                                        name="product_commission_day"
                                                        placeholder={t('Select Payment day')}
                                                        options={dayOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={dayOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="w-[600px] flex flex-row gap-4 justify-between items-center">
                                        <h3 className="title-text">{t('Window start date')}</h3>
                                        <Controller
                                            name="product_commission_start_date"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="max-w-[320px] w-[320px]">
                                                    <DateSelect
                                                        inputName="product_commission_start_date"
                                                        className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                        placeholder={t('Select date')}
                                                        dateFormat="yyyy-MM-dd"
                                                        popperPlacement="auto"
                                                        minDate={new Date()}
                                                        value={value}
                                                        onChangeValue={onChange}
                                                        filterDate={isWeekday(productCommissionDay || '')}
                                                    />
                                                    {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </>
                            )}
                            {productPayout?.endDate.isValid() && (
                                <div className="flex flex-row bg-white  items-center w-fit  px-2 py-1 shadow-sm rounded-[8px]  border border-borderSecondary ">
                                    <span className="mr-[2px] font-medium text-xs ">
                                        <GoDotFill size={12} color="#3570F8" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    </span>
                                    <div className="title-text !font-medium">
                                        {productCommissionFrequency === 'monthly'
                                            ? t('Next payout will be', {
                                                  date: productPayout?.endDate?.format('Do MMMM, YYYY'),
                                              })
                                            : t('Next payout will be from', {
                                                  start_date: productPayout?.startDate?.format('Do MMMM, YYYY'),
                                                  end_date: productPayout?.endDate?.format('Do MMMM, YYYY'),
                                              })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row gap-3 justify-end pt-5 mb-36 w-full">
                        <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default PaymentSettings;
