import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircle, SearchLg, Box } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import { GoDotFill } from 'react-icons/go';
import { DataTable } from 'primereact/datatable';
import NoDataMessage from 'src/components/NoDataMessage';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { currentRole, currentShop } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { axiosGet, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getShortName } from 'src/utils/global-functions';
import Switch from 'src/components/Switch/Switch';
import SelectBox from 'src/components/SelectBox/SelectBox';
import InputWithLabel from 'src/components/InputWithLabel';
import * as Yup from 'yup';
import { errorCode } from 'src/constants/errorCode';
// import { Tooltip } from 'flowbite-react';
import { OWNER_RIGHTS_ROLES, ROLES } from 'src/constants/common';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { IFormData } from './Product.interface';
import { perPageOptions } from 'src/utils/global-variables';
import { Tooltip } from 'primereact/tooltip';

const comissionTypeOption = [
    { label: 'Fixed Percentage', value: 'percentage' },
    // { label: 'Fixed Amount', value: 'amount' },
];

const Product = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const role = useAppSelector(currentRole);
    const hasEditPermission = OWNER_RIGHTS_ROLES.includes(role?.name);
    const shop = useAppSelector(currentShop);
    const [totalStaff, setTotalStaff] = useState<number>(0);
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });
    const [staffData, setStaffData] = useState<any>(null);
    const [filters, setFilters] = useState<any>({
        global: { value: null },
    });
    const [isSaveEnabled, setSaveEnabled] = useState(false);
    const [isImageError, setIsImageError] = useState<{ [key: number]: boolean }>({});

    const schema = Yup.object({
        data: Yup.array().of(
            Yup.object({
                full_name: Yup.string(),
                profile_image_url: Yup.string(),
                shop_admin_id: Yup.string(),
                type: Yup.string(),
                status: Yup.boolean().required('Status is required'),
                value: Yup.string()
                    .when('type', (type: any, sch) => {
                        if (type[0] === 'percentage') {
                            return sch.test('is-valid-percentage', 'Percentage cannot exceed 100', (value: any) => {
                                const numValue = parseInt(value, 10);
                                return !isNaN(numValue) && numValue <= 100;
                            });
                        }
                        return sch;
                    })
                    .test('is-required', 'Value is required', function (value) {
                        const { status } = this.parent;
                        return status ? !!value : true;
                    }),
            }),
        ),
    });

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        watch,
        setError,
        reset,
        formState: { isDirty },
    } = useForm<any>({
        resolver: yupResolver<any>(schema),
        defaultValues: {
            data: [],
        },
    });

    const watchAllFields = watch();

    useEffect(() => {
        setSaveEnabled(isDirty);
    }, [watchAllFields, isDirty]);

    useEffect(() => {
        listStaff();
    }, [lazyState, shop]);

    const listStaff = async () => {
        setIsLoading(true);
        await axiosGet(API.PRODUCT.COMMISSION_LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setTotalStaff(response.data.data.totalRecords);
                const staff = response.data.data.data;
                const data = staff.map((staffDetails: any) => ({
                    full_name: staffDetails.full_name,
                    profile_image_url: staffDetails.profile_image_url ? staffDetails.profile_image_url : getShortName(staffDetails.full_name),
                    shop_admin_id: staffDetails.id,
                    type: staffDetails.product_commission ? staffDetails.product_commission.type : 'percentage',
                    status: staffDetails.product_commission ? staffDetails.product_commission.status : false,
                    value: staffDetails.product_commission ? staffDetails.product_commission.value : '',
                }));
                setStaffData(data);
                setValue('data', data);
                reset({
                    data: data,
                });
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const handleImageError = (id: number) => () => {
        setIsImageError((old: any) => ({ ...old, [id]: true }));
    };

    const getStaff = (row: any) => (
        <div className="flex items-center">
            <figure className="NoImgName capitalize-first">
                {row.profile_image_url.startsWith('https') && !isImageError[row.id] ? (
                    <img src={row.profile_image_url} alt="" title={row.full_name} className="w-full h-full object-cover" onError={handleImageError(row.id)} />
                ) : (
                    getShortName(row.full_name)
                )}
            </figure>
            <div>
                <p className="text-xs font-medium capitalize-first">{row.full_name}</p>
            </div>
        </div>
    );

    const getFields = (name: string, type: string, disabled: boolean = true, placeholder: string = '0%') => {
        switch (type) {
            case 'enable_comission':
                return <Controller name={name} control={control} render={({ field: { onChange, value }, fieldState: { error } }) => <Switch onChange={onChange} isChecked={value} />} />;
            case 'type':
                return (
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="max-w-[156px]">
                                <SelectBox
                                    name={name}
                                    isClearable={false}
                                    isSearchable={false}
                                    options={comissionTypeOption}
                                    value={comissionTypeOption.find((typeDetails: any) => typeDetails.value === value)}
                                    onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                    classComp="outline-select-box"
                                    placeholder={t('commission Type')}
                                />
                            </div>
                        )}
                    />
                );
            case 'amount':
                return (
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="max-w-[131px]">
                                <InputWithLabel type="number" onChange={onChange} value={value} name={name} placeholder={placeholder} disabled={disabled} error={!!error} />
                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                );
            default:
                break;
        }
    };

    const getComission = (data: any, row: any) => getFields(`data.${row.rowIndex}.${row.field}`, 'enable_comission');

    const getType = (data: any, row: any) => getFields(`data.${row.rowIndex}.${row.field}`, 'type');

    const getComissionValue = (data: any, row: any) => {
        const enableComissionField = watch(`data.${row.rowIndex}.status`);
        const comissionType = watch(`data.${row.rowIndex}.type`);
        return getFields(`data.${row.rowIndex}.${row.field}`, 'amount', !enableComissionField, comissionType === 'percentage' ? '0%' : '0$');
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                type: 'loading',
                staff_member: 'loading',
                status: 'loading',
                value: 'loading',
            })),
        [lazyState],
    );

    const handleSave = (data: any) => {
        setIsLoading(true);
        axiosPost(API.PRODUCT.COMMISSION, data, { shop_id: shop.id })
            .then(async (response) => {
                listStaff();
            })
            .catch((error) => {
                if (error.data.status === errorCode.unprocessable) {
                    if (error.data.data) {
                        Object.keys(error.data.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: error.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const resetForm = () => {
        if (staffData) {
            reset({
                data: staffData,
            });
        } else {
            reset();
        }
    };
    return (
        <div className="w-[full] flex flex-col flex-1 px-2 py-1">
            <form className="flex-1 flex flex-col" onSubmit={handleSubmit(handleSave)}>
                <div className="detail-page-title-block !py-4 border-borderSecondary !px-0">
                    <div className="flex flex-col ">
                        <Tooltip target="#product-setting" position="top" />

                        <h2 className=" flex flex-row gap-1.5 items-center">
                            <div className="title-text-header">{t('Product Settings')}</div>

                            <InfoCircle
                                className="text-[#98A2B3] w-4 h-4 custom-tooltip-btn"
                                id="product-setting"
                                data-pr-tooltip={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}
                            />
                        </h2>
                        <p className="subtitle-text">{t('Manage and view product commission rates for team members.')}</p>
                    </div>
                    {/* <div className="flex flex-row gap-3">
                        <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading} className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div> */}
                </div>
                <div
                    className={`w-full border border-gray-200 rounded-xl shadow staff_service_table datatable-custom-service flex-1 flex flex-col mt-4 ${
                        getValues('data').length === 0 ? 'datatable-full-height datatable-noshow' : ''
                    }`}
                >
                    <div className="flex items-center flex-col ">
                        <div className="flex items-center px-5 py-3 justify-between w-full border-0 border-gray-200 gap-4">
                            <div className="flex flex-col flex-1">
                                <div className="flex justify-start items-center gap-1.5 xxl:gap-1.5">
                                    <h2 className="table-title">{t('Add Product Commission Rates')}</h2>
                                    <p className="userCounter">
                                        <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                        {totalStaff} {t('Team Member')}
                                    </p>
                                </div>
                                <p className="table-subtitle">{t('Set commission rates for products to incentivize your team.')}</p>
                            </div>
                            <div className="flex gap-3 xl:gap-4">
                                <div className="table-searchInput">
                                    <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                                </div>
                                <div className="w-[70px] xl:w-[70px] page-dropdown">
                                    <SelectBox
                                        name="page"
                                        options={perPageOptions}
                                        isClearable={false}
                                        onChangeFunc={onPageHandle}
                                        value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                        classComp="outline-select-box"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        lazy
                        paginatorTemplate={customPaginatorTemplate}
                        value={isLoading ? skeletons : getValues('data')}
                        totalRecords={totalStaff}
                        paginator={!isLoading && totalStaff > lazyState.rows}
                        first={lazyState.first}
                        rows={lazyState.rows}
                        sortOrder={lazyState.sortOrder}
                        sortField={lazyState.sortField}
                        filters={lazyState.filters}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        editMode="row"
                        dataKey="id"
                        className="border-t rounded-b-xl overflow-hidden"
                        paginatorClassName="table-pagination"
                        emptyMessage={
                            <NoDataMessage
                                title={`${filters.global.value ? 'No team members found.' : 'No team members added.'}`}
                                description={`${
                                    filters.global.value ? 'Try adjusting your filters or add new team members.' : 'Start by adding new members to have them listed on your online booking website.'
                                }`}
                                iconComponent={filters.global.value ? <SearchLg className="text-gray-700" /> : <Box className="text-gray-700" />}
                            />
                        }
                    >
                        <Column
                            field="staff_member"
                            body={isLoading ? <Skeleton /> : getStaff}
                            header={t('Team Member')}
                            className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                            style={{ width: '45%' }}
                            // style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                            sortable
                        ></Column>
                        <Column
                            field="status"
                            header={t('Enable Product commission')}
                            className="font-medium text-mainTextColor"
                            body={isLoading ? <Skeleton /> : getComission}
                            style={{ width: '17%' }}
                            // style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                        ></Column>
                        <Column
                            field="type"
                            body={isLoading ? <Skeleton /> : getType}
                            header={t('Product commission Type')}
                            className="text-xs leading-[18px] font-medium text-mainTextColor truncate"
                            style={{ width: '20%' }}
                            // style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                        ></Column>
                        <Column
                            field="value"
                            body={isLoading ? <Skeleton /> : getComissionValue}
                            header={t('Commission')}
                            className="text-xs leading-[18px] font-normal text-gray-600 "
                            style={{ width: '15%' }}
                            // style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                        ></Column>
                    </DataTable>
                </div>
                {hasEditPermission && (
                    <div className="flex flex-row gap-3 w-full pt-5 mb-5 justify-end ">
                        <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading || !isSaveEnabled} className="!h-[36px]">
                            {t('Save Changes')}
                        </CustomButton>
                    </div>
                )}
            </form>
        </div>
    );
};

export default Product;
