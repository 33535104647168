import React, { useEffect, useMemo, useState } from 'react';
import PageHeader from 'src/components/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import CategoryTable from './Category/CategoryTable';
import { PATH } from 'src/constants/path';
import { APP_FULL_NAME, PRODUCT_TABS } from 'src/constants/common';
import ProductsView from './ProductsView';
import { useTranslation } from 'react-i18next';

const Products = () => {
    const navigate = useNavigate();
    const { tab: currentTab } = useParams();
    const [activeTab, setActiveTab] = useState(<ProductsView />);
    const { t } = useTranslation();
    useEffect(() => {
        switch (currentTab) {
            case PRODUCT_TABS.PRODUCT:
                document.title = `${t('Product')} - ${APP_FULL_NAME}`;
                setActiveTab(<ProductsView />);
                break;
            case PRODUCT_TABS.CATEGORY:
                document.title = `${t('Product Category')} - ${APP_FULL_NAME}`;
                setActiveTab(<CategoryTable />);
                break;
            default:
                document.title = `${t('Product')} - ${APP_FULL_NAME}`;
                setActiveTab(<ProductsView />);
                break;
        }
    }, [currentTab]);

    const tabs = useMemo(
        () => [
            {
                name: 'Products',
                type: PRODUCT_TABS.PRODUCT,
            },
            {
                name: 'Product Categories',
                type: PRODUCT_TABS.CATEGORY,
            },
        ],
        [],
    );

    const handleTabClick = (type: string) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        document.title = type === PRODUCT_TABS.PRODUCT ? `${t('Product')} - ${APP_FULL_NAME}` : `${t('Product Category')} - ${APP_FULL_NAME}`;
        navigate(`${type === PRODUCT_TABS.PRODUCT ? PATH.PRODUCT.LIST : `${PATH.PRODUCT.LIST}/${type}`} `);
    };

    return (
        <div className="inner-page-wrape">
            <div className="flex-1 flex flex-col mb-4">
                <PageHeader title="Products" subtitle="Easily view, add, and manage all the products." />
                <div className="side-spaching flex flex-1 flex-col w-full">
                    <div className="fl-tab-btn-view w-full">
                        {tabs.map((tab, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`fl-tab-link !max-w-[182px] ${currentTab === tab.type || (!currentTab && tab.type === PRODUCT_TABS.PRODUCT) ? 'active' : ''}`}
                                onClick={handleTabClick(tab.type)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>
                    {activeTab}
                </div>
            </div>
        </div>
    );
};

export default Products;
