export interface IProps {
    graph: any[];
    currentTab: string;
}

export const initAction = {
    expense: false,
};

export interface IExpenseGraphData {
    name: string;
    value: number;
}
export interface IAddExpense {
    handleClose: () => void;
    fetchAnalytics: () => Promise<void>;
}

export interface IAddExpenseForm {
    shop_location_id: string;
    rent: null | number;
    bills: null | number;
    tools: null | number;
    insurance: null | number;
    maintenance: null | number;
    other: null | number;
    total: null | number;
    type: null | string;
}

export const colorArray = [
    '#0E34A5',
    '#1642C5',
    '#2C59DE',
    '#5279F0',
    '#819DF1',
    '#B4C6FC',
    '#D3DEFD',
    '#EBF0FF',
    '#D5D9EB',
    '#B3B8DB',
    '#717BBC',
    '#4E5BA6',
    '#3E4784',
    '#363F72',
    '#CFF9FE',
    '#A5F0FC',
    '#67E3F9',
    '#22CCEE',
    '#06AED4',
    '#088AB2',
    '#0E7090',
    '#155B75',
];

export const timeOption = [
    {
        value: 'weekly',
        label: 'Weekly',
    },
    {
        value: 'bi_weekly',
        label: 'Bi-Weekly',
    },

    {
        value: 'monthly',
        label: 'Monthly',
    },
    {
        value: 'yearly',
        label: 'Yearly',
    },
];

export const graphOptions = {
    owner: [
        { label: 'Service Sales', value: 'service_amount', color: colorArray[0] },
        { label: 'Product Sales', value: 'product_amount', color: colorArray[4] },
    ],
    rent: [
        { label: 'Service Sales', value: 'service_amount', color: colorArray[0] },
        { label: 'Product Sales', value: 'product_amount', color: colorArray[4] },
        { label: 'Tips', value: 'tip_amount', color: colorArray[5] },
        { label: 'Taxes', value: 'taxes_and_fees', color: colorArray[6] },
    ],
};

export interface IAnalyticsDetails {
    from_date: Date | null;
    total: {
        amount: number;
        amount_growth: number;
        client: number;
        client_growth: number;
        client_retention_rate: number;
        client_retention_rate_growth: number;
        client_repeat_rate: number;
        avg_bookings_frequency: number;
        avg_bookings_frequency_growth: number;
        productivity: number;
        productivity_growth: number;
        total_service_amount: number;
        total_product_amount: number;
    };
    sales_line_graph: any[];
    payment_method_split: any[];
    gross_revenue: any[];
    staff: any[];
    sales_by_services: any[];
    graph: any[];
    services_graph: any[];
    products_graph: any[];
    user_split: any[];
}
export interface ITeamPerformance {
    startDate: string;
    endDate: string;
    selectedFilter: {
        location_id: null;
        staff_id: null;
    };
}
export interface IAdvAnalyticsDetails {
    from_date: Date | null;
    total: {
        amount: number;
        amount_growth: number;
        commission: number;
        commission_growth: number;
        expense: number;
        expense_growth: number;
        rent: number;
        rent_growth: number;
    };
}
export interface IAdvAnalytics {
    selectedFilter: {
        location_id: null;
        staff_id: null;
    };
}
