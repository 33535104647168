import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, Radio } from 'flowbite-react';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { FONT_FAMILY, FONT_WEIGHT, IFormData, LogoType } from './Logo.interface';
import LogoDisplaySettings from './LogoDisplaySettings';
import CustomButton from 'src/components/CustomButton';
import { allShopSettings, currentShop, me } from 'src/redux/services/common/Common.slice';
import LogoDisplayScreen from './LogoDisplayScreen';
import { XClose } from '@untitled-ui/icons-react/build/cjs';
import { onError } from 'src/utils/global-functions';

const Logo = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const settings = useAppSelector(allShopSettings);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formDetails, setFormDetails] = useState<any>({
        logo_type: 'logo',
        name: shop.business_name,
        logo_font_family: FONT_FAMILY[0].value,
        font_weight: FONT_WEIGHT[0].value,
        font_size: '',
        logo_height: '',
    });
    const [initValues, setInitValues] = useState<any>({
        name: shop.business_name,
        logo_font_family: FONT_FAMILY[0].value,
        font_weight: FONT_WEIGHT[0].value,
    });

    const schema = Yup.object({
        logo_type: Yup.mixed<LogoType>().oneOf(['logo', 'logo_text', 'text'], 'Invalid logo type. Choose from logo, logo_text, or text.').required('This field is required'),
        name: Yup.string()
            .nullable()
            .when('logo_type', ([logoType], customSchema) =>
                logoType && (logoType === 'logo_text' || logoType === 'text') ? customSchema.required('This field is required') : customSchema.nullable(),
            ),
        logo_font_family: Yup.string()
            .nullable()
            .when('logo_type', ([logoType], customSchema) => (logoType === 'logo_text' || logoType === 'text' ? customSchema.required('This field is required') : customSchema)),
        font_weight: Yup.string()
            .nullable()
            .when('logo_type', ([logoType], customSchema) => (logoType === 'logo_text' || logoType === 'text' ? customSchema.required('This field is required') : customSchema)),
        logo_height: Yup.string()
            .nullable()
            .when('logo_type', ([logoType], customSchema) => (logoType === 'logo_text' || logoType === 'logo' ? customSchema.required('This field is required') : customSchema)),
        font_size: Yup.string()
            .nullable()
            .when('logo_type', ([logoType], customSchema) => (logoType === 'logo_text' || logoType === 'text' ? customSchema.required('This field is required') : customSchema)),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            logo_type: 'logo',
            name: shop.business_name,
            logo_font_family: FONT_FAMILY[0].value,
            font_weight: FONT_WEIGHT[0].value,
            logo_height: '',
            font_size: '',
        },
    });

    const {
        handleSubmit,
        control,
        formState: { defaultValues },
        setError,
        setValue,
        clearErrors,
        reset,
    } = methods;

    useEffect(() => {
        const appearance = settings.find((setting) => setting.type === 'appearance');
        if (appearance) {
            const value = appearance.value;
            setInitValues({
                name: value.name ?? shop.business_name,
                logo_font_family: value.logo_font_family || FONT_FAMILY[0].value,
                font_weight: value.font_weight || FONT_WEIGHT[0].value,
            });
            const values = {
                logo_type: value.logo_type || 'logo',
                name: value.name ?? shop.business_name,
                logo_font_family: value.logo_font_family || FONT_FAMILY[0].value,
                font_weight: value.font_weight || FONT_WEIGHT[0].value,
                font_size: value.font_size || '',
                logo_height: value.logo_height || '',
            };
            setFormDetails(values);
            Object.keys(values).forEach((key) => {
                setValue(key as keyof IFormData, values[key as keyof IFormData]);
            });
            reset(values);
        }
    }, [settings]);

    const handleLogoTypeChange = (value: LogoType) => () => {
        if (value === 'logo') {
            setValue('name', '');
            setValue('logo_font_family', FONT_FAMILY[0].value);
            setValue('font_weight', FONT_WEIGHT[0].value);
            setFormDetails((prevFormDetails: any) => ({
                ...prevFormDetails,
                logo_type: value,
                name: '',
                logo_font_family: FONT_FAMILY[0].value,
                font_weight: FONT_WEIGHT[0].value,
            }));
        } else {
            setFormDetails((prevFormDetails: any) => ({
                ...prevFormDetails,
                logo_type: value,
                ...initValues,
            }));
            Object.keys(initValues).forEach((field) => {
                setValue(field as keyof IFormData, initValues[field as keyof typeof initValues]);
            });
        }
        clearErrors(['name', 'logo_font_family', 'font_weight']);

        setValue('logo_type', value);
    };

    const submitForm = (data: IFormData) => {
        setIsLoading(true);
        const payload =
            data.logo_type === 'logo_text' || data.logo_type === 'text'
                ? {
                      logo_type: data.logo_type,
                      name: data.name,
                      logo_font_family: data.logo_font_family,
                      font_weight: data.font_weight,
                      shop_id: shop.id,
                      font_size: data.font_size,
                      ...(data.logo_type === 'logo_text' && { logo_height: data.logo_height }),
                  }
                : {
                      logo_type: data.logo_type,
                      shop_id: shop.id,
                      logo_height: data.logo_height,
                  };
        axiosPost(API.THEME.LOGO, payload, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };
    // const handleRemoveCrop = (type: 'logo_image_name' | 'favicon') => () => {
    //     if (isSameAsBrandLogo) {
    //         clearErrors(['logo_image_name', 'favicon']);
    //         setCropData((old: any) => ({ ...old, logo_image_name: { ...old.logo_image_name, data: null, file: null, name: null }, favicon: { ...old.favicon, data: null, file: null, name: null } }));
    //         setValue('logo_image_name', '');
    //         setValue('favicon', '');
    //     } else {
    //         clearErrors(type);
    //         setCropData((old: any) => ({ ...old, [type]: { ...old[type], data: null, file: null } }));
    //         setValue(type, '');
    //     }
    // };

    const resetForm = () => {
        reset(defaultValues);
    };

    return (
        <div className="w-full pt-5 px-2">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)} className="flex flex-col gap-5">
                    <div className="flex flex-row">
                        <div className="flex flex-col flex-1">
                            <div className="fl-data-block">
                                <div className="title-block w-[280px] xxl:w-[310px] min-w-[280px]">
                                    <h3 className="title-text">{t('Brand logo')}</h3>
                                    <p className="subtitle-text">{t('Your brand logo that will be displayed on your website.')}</p>
                                </div>
                                <div className="data-wrap-block max-w-[480px] min-w-[480px] w-[480px] ">
                                    <div className="flex items-center gap-5 w-full">
                                        <div className={`h-14 flex relative min-w-14 justify-center items-center ${shop.logo_image_type === 'rectangle' ? 'w-25' : 'w-14 '}"`}>
                                            {shop.logo_image_url && (
                                                <img
                                                    src={shop.logo_image_url}
                                                    alt=""
                                                    className={`h-14 border-[0.75px] border-[#F2F4F7] relative object-cover ${shop.logo_image_type === 'rectangle' ? 'w-25' : 'w-14 '} ${
                                                        shop.logo_image_type === 'circle' ? 'rounded-full' : 'rounded-md'
                                                    }`}
                                                    onError={onError}
                                                />
                                            )}
                                        </div>
                                        {/* <ImageUpload
                                            isIcon
                                            cropData={cropData.logo_image_name.data}
                                            onChangeCrop={onChangeCrop('logo_image_name')}
                                            id="logo_image_name"
                                            error={errors?.logo_image_name?.message}
                                        /> */}

                                        {/* <div className={`h-[80px] w-[80px] rounded-md object-contain ${cropData.logo_image_name.data ? '' : 'border'}`}>
                                            <img src={cropData.logo_image_name.data || defaultImage} alt="logo" className="w-full h-full object-contain" />
                                        </div>
                                        <div>
                                            <div className={`relative cursor-pointer border rounded-lg ${errors.logo_image_name ? 'is-invalid' : ''}`}>
                                                <input type="file" id="logo_image_name" className="w-full absolute opacity-0" onChange={onChangeCrop('logo_image_name')} />
                                                <label htmlFor="logo_image_name" className="w-full text-xs px-4 py-[7px] font-medium flex items-center justify-between">
                                                    <Edit02 className="w-5 h-5 mr-2 text-gray-700" />
                                                    {t('Update Logo')}
                                                </label>
                                            </div>
                                            {errors.logo_image_name && <p className="text-error">{errors.logo_image_name.message}</p>}
                                        </div> */}
                                        {/* {cropData.logo_image_name.data && (
                                    <Button className="w-9 h-9 bg-red-50 flex items-center justify-center rounded-lg" onClick={handleRemoveCrop('logo_image_name')}>
                                        <Trash01 color="red" width={16} />
                                    </Button>
                                )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <CustomButton secondary disabled={isLoading} onClick={resetForm}>
                                {t('Discard')}
                            </CustomButton>
                            <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                {t('Save changes')}
                            </CustomButton>
                        </div>
                    </div>
                    <hr className="divider" />
                    {/* <div className="fl-data-block">
                        <div className="title-block w-[280px] xxl:w-[310px] min-w-[280px]">
                            <h3 className="title-text">{t('Admin profile picture')}</h3>
                            <p className="subtitle-text">{t('Configure your admin picture that will be displayed as a profile picture.')}</p>
                        </div>
                        <div className="data-wrap-block max-w-[480px] min-w-[480px] w-[480px]">
                            <div className="flex items-center gap-5 w-full">
                                <ImageUpload
                                    isIcon
                                    cropData={cropData.admin_logo_image_name.data}
                                    onChangeCrop={onChangeCrop('admin_logo_image_name')}
                                    id="admin_logo_image_name"
                                    error={errors.admin_logo_image_name?.message}
                                />
                            </div>
                        </div>
                    </div>
                    <hr className="divider" /> */}
                    <div className="fl-data-block">
                        <div className="title-block w-[280px] xxl:w-[310px] min-w-[280px]">
                            <h3 className="title-text">{t('Brand Icon')}</h3>
                            <p className="subtitle-text">{t('Your brand icon that will be displayed in smaller resolutions.')}</p>
                        </div>
                        <div className="data-wrap-block flex flex-auto items-center">
                            <div className="max-w-[620px] w-full mr-5 ">
                                {shop.admin_logo_image_url && (
                                    <img
                                        src={shop.admin_logo_image_url}
                                        alt=""
                                        className={`w-14 h-14 border-[0.75px] border-[#F2F4F7] relative object-cover ${shop.admin_logo_image_type === 'circle' ? 'rounded-full' : 'rounded-md'}`}
                                        onError={onError}
                                    />
                                )}
                            </div>
                            <div className="flex items-center">
                                <div className="bg-gray-100 rounded-t-xl pt-[10px] px-[10px] flex gap-2 overflow-hidden w-[280px] xxl:w-[280px]">
                                    <div className="flex gap-1 items-center">
                                        <span className="w-[10px] h-[10px] bg-red-500 rounded-full"></span>
                                        <span className="w-[10px] h-[10px] bg-yellow-500 rounded-full"></span>
                                        <span className="w-[10px] h-[10px] bg-success-500 rounded-full"></span>
                                    </div>
                                    <div className="bg-white rounded-t-xl flex items-center justify-between px-3 py-1 h-10 shadow-lg w-full">
                                        {/* favicon logo is rectangular */}
                                        <div className="flex flex-row ">
                                            <div
                                                className={`${
                                                    !shop.admin_logo_image_url || shop.admin_logo_image_url === '' ? 'w-4 bg-gray-300 object-contain' : 'w-4 object-contain'
                                                } h-4 grow-0 shrink-0 flex items-center justify-center`}
                                            >
                                                {shop.admin_logo_image_url && shop.admin_logo_image_url !== '' && (
                                                    <img src={shop.admin_logo_image_url} alt="" className="w-full h-full" onError={onError} />
                                                )}
                                            </div>
                                            <div className="ml-[10px] text-xs font-semibold text-mainTextColor w-full  max-w-[132px] truncate text-clip">{shop.business_name}</div>
                                        </div>
                                        <div className="text-mainTextColor px-2">
                                            <XClose width={16} />
                                        </div>
                                    </div>
                                </div>
                                {/* {cropData.favicon.data && (
                                    <Button className="w-10 h-10 bg-red-50 flex items-center justify-center rounded-lg ml-[10px]" onClick={handleRemoveCrop('favicon')}>
                                        <Trash01 color="red" width={16} />
                                    </Button>
                                )} */}
                            </div>
                        </div>
                    </div>
                    <hr className="divider" />
                    <div className="fl-data-block">
                        <div className="title-block w-[280px] xxl:w-[310px] min-w-[280px]">
                            <h3 className="title-text">
                                {t('Logo display settings')}
                                <span className="asterisk">*</span>
                            </h3>
                            <p className="subtitle-text">
                                {t('Configure the display settings of your brand icon. For compact circular and square logo’s we recommend a combination of Logo + Typeface.')}
                            </p>
                        </div>
                        <div className="data-wrap-block flex flex-auto items-start">
                            <div className="max-w-[620px] mr-5 w-full">
                                <Controller
                                    name={`logo_type`}
                                    control={control}
                                    render={({ field: { value } }: any) => (
                                        <>
                                            <div className="flex mb-3">
                                                <Radio
                                                    className="custom-round-radio mt-[3px]"
                                                    id="logo-type-logo"
                                                    name="logo-type"
                                                    value={'logo'}
                                                    onChange={handleLogoTypeChange('logo')}
                                                    checked={value === 'logo'}
                                                />
                                                <Label htmlFor="logo-type-logo" className="ml-2 text-md text-gray-700">
                                                    {t('Logo')}
                                                    <span className="block text-xs text-gray-600 font-normal ">{t('Display only my logo.')}</span>
                                                </Label>
                                            </div>
                                            {value === 'logo' && <LogoDisplaySettings setFormDetails={setFormDetails} displayType={value} />}
                                            <div className="flex  mb-3">
                                                <Radio
                                                    className="custom-round-radio mt-[3px]"
                                                    id="logo-type-logo-text"
                                                    name="logo-type"
                                                    value={'logo_text'}
                                                    onChange={handleLogoTypeChange('logo_text')}
                                                    checked={value === 'logo_text'}
                                                />
                                                <Label htmlFor="logo-type-logo-text" className="ml-2 text-md text-gray-700">
                                                    {t('Logo + Typeface (Font)')}
                                                    <span className="block text-xs text-gray-600 font-normal ">{t('Display a combination of my logo and my brand name.')}</span>
                                                </Label>
                                            </div>
                                            {value === 'logo_text' && <LogoDisplaySettings setFormDetails={setFormDetails} displayType={value} />}
                                            <div className="flex mb-3 w-full">
                                                <Radio
                                                    className="custom-round-radio mt-[3px]"
                                                    id="logo-type-text"
                                                    name="logo-type"
                                                    value={'text'}
                                                    onChange={handleLogoTypeChange('text')}
                                                    checked={value === 'text'}
                                                />
                                                <Label htmlFor="logo-type-text" className="ml-2 text-md text-gray-700">
                                                    {t('Typeface (Font)')}
                                                    <span className="block text-xs text-gray-600 font-normal ">{t('Display only my brand name as a font.')}</span>
                                                </Label>
                                            </div>

                                            {value === 'text' && <LogoDisplaySettings setFormDetails={setFormDetails} displayType={value} />}
                                        </>
                                    )}
                                />
                            </div>
                            <div className="flex items-center">
                                <LogoDisplayScreen formDetails={formDetails} />

                                {/* <Button className="w-10 h-10 bg-red-50 flex items-center justify-center rounded-lg ml-[10px]" onClick={handleRemoveCrop('logo_image_name')}>
                                    <Trash01 color="red" width={16} />
                                </Button> */}
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-2 border-t pt-5 justify-end  mb-36">
                        <CustomButton secondary disabled={isLoading} onClick={resetForm}>
                            {t('Discard')}
                        </CustomButton>
                        <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default Logo;
