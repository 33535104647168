export const initAction = {
    create: false,
    delete: false,
    update: false,
    data: null,
};

export interface ExistingFormData {
    [key: string]: any;
}

export interface IFormData {
    display_name: string;
    line1: string;
    country: { label: string; value: string; id: string } | undefined;
    state: { label: string; value: string; id: string } | undefined;
    city: { label: string; value: string; id: string } | undefined;
    postal_code: string;
}

export interface IReaderFormData {
    label: string;
    registration_code: string;
    location: { label: string; value: string } | undefined;
}

export interface ISelectBox {
    label: string;
    value: string;
}
