import React from 'react';
import { defaultImage } from 'src/theme/Images';
import { GoDotFill } from 'react-icons/go';
import moment from 'moment';
import { onError } from 'src/utils/global-functions';

const NotificationAppointment = ({ notificationRecord, isLoading = false }: any) => (
    <div className={`flex   px-6    w-full ${notificationRecord.is_read && !isLoading ? 'opacity-[50%]' : 'cursor-pointer hover:bg-[#F9FAFC]'}`}>
        <div className="py-5 flex flex-row   gap-3  border-b border-borderSecondary relative">
            <div className={`${isLoading ? 'custom-loading w-[40px] h-[40px] rounded-full' : ''}`}>
                <img
                    src={notificationRecord.booking ? notificationRecord.booking.staff.profile_image_url : defaultImage}
                    alt=""
                    className="w-10 h-10 min-w-10 min-h-10 rounded-full object-cover"
                    onError={onError}
                ></img>
            </div>
            <div className="flex flex-col gap-[3px]">
                {isLoading ? (
                    <span className={`${isLoading ? 'custom-loading max-w-[350px] w-[350px] min-w-[350px]' : ''}`}>loading</span>
                ) : (
                    <div className="text-sm font-medium text-mainTextColor w-[94%]">{notificationRecord.description}</div>
                )}
                {isLoading ? (
                    <span className={`${isLoading ? 'custom-loading max-w-[250px] w-[250px] min-w-[250px]' : ''}`}>loading</span>
                ) : (
                    <div className="text-xs font-medium text-[#475467] flex  items-center">
                        {notificationRecord.name}
                        <GoDotFill size={8} color="#667085" className="h-2.5 w-2.5 rounded-full flex mx-1 mt-[1px]" />
                        <span className="text-xs font-normal text-secondaryTxtColor">{moment(notificationRecord.created_at).fromNow()}</span>
                    </div>
                )}
            </div>
            {!notificationRecord.is_read && (
                <div className="absolute top-[5px] right-[5px]">
                    <GoDotFill size={16} color="#17B26A" className="h-4 w-4 rounded-full flex mr-0.5" />
                </div>
            )}
        </div>
    </div>
);

export default NotificationAppointment;
