import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { IInitAction, ISidebar, ISidebarSchema, initAction } from './CheckoutSidebar.Interface';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import Loader from 'src/components/Loader/Loader';
import { allSalesTaxes, allShopStaff, currentShop, getAllSalesTaxes } from 'src/redux/services/common/Common.slice';
import { selectCalendarData } from '../../Calendar.slice';
import { X } from '@untitled-ui/icons-react/build/cjs';
import Service from './Service';
import Product from './Product';
import CustomButton from 'src/components/CustomButton';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import Client from './Client';
import Checkout from './Payment/Checkout';
import Payment from './Payment/Payment';
import { IPaymentState } from '../../Calendar.Interface';

const CheckoutSidebar: FC<ISidebar> = ({ isOpen, handleClose }) => {
    const dispatch = useAppDispatch();
    const shop: any = useAppSelector(currentShop);
    const slotInfo: any = useAppSelector(selectCalendarData);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const allShopStaffList = useAppSelector(allShopStaff);
    const [action, setAction] = useState(initAction);
    const [paymentArray, setPaymentArray] = useState<IPaymentState>({ payments: [], tip: 0, extra_payment_method: undefined, isInputTip: false });
    const calendarData: any = useAppSelector(selectCalendarData);
    const [cardList, setCardList] = useState<any[]>([]);
    const [loyalPointAmount, setLoyalPointAmount] = useState<any>(0);
    const [appointmentCalulation, setAppointmentCalulation] = useState<any>({
        subtotal: 0,
        taxes_and_fees: 0,
        total: 0,
        product: [],
        service: [],
    });
    const taxesAndFees = useAppSelector(allSalesTaxes);

    const schema = Yup.object().shape({
        staff_id: Yup.number().required('Staff is required'),
        user_id: Yup.number().required('Client is required'),
        services: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number().required('Service is required'),
                    qty: Yup.number().required('Quantity is required'),
                }),
            )
            .test({
                name: 'services-or-product',
                message: 'At least one of services or products must be provided',
                test: function (value) {
                    const { services, products } = this.parent;
                    const hasServices = Array.isArray(services) && services.length > 0;
                    const hasProducts = Array.isArray(products) && products.length > 0;
                    if (!hasServices && !hasProducts) {
                        return false;
                    }

                    return true;
                },
            }),
    });

    useEffect(() => {
        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        setStaffOptions(staff);
    }, [shop]);
    const methods = useForm<ISidebarSchema>({
        resolver: yupResolver(schema) as any,
        defaultValues: {
            services: null,
            staff_id: null,
            user_id: null,
            user_info: null,
            products: null,
        },
    });

    const { setValue, control, trigger, getValues, watch } = methods;

    const { remove } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'services',
    });

    const onHandleCloseSidebar = async () => {
        remove();
    };
    const servicesWatch = watch('services');
    const productsWatch = watch('products');

    const getCalculation = () => {
        const locationTaxes = taxesAndFees.find((tax: any) => tax.id === calendarData.selectedLocation.sales_tax_id);
        const productTotal = productsWatch ? Number(productsWatch.map((product: any) => product.variantInfo.price).reduce((a: any, b: any) => a + b, 0)) : 0;
        const serviceTotal = servicesWatch ? Number(servicesWatch.map((service: any) => service.price * service.qty).reduce((a: any, b: any) => a + b, 0)) : 0;
        const taxesAndFeesTotal = (+serviceTotal * (+locationTaxes?.gst + +locationTaxes?.pst + +locationTaxes?.hst)) / 100;

        const calculation = {
            product: productTotal,
            service: serviceTotal,
            subtotal: Number(+productTotal + +serviceTotal),
            taxes_and_fees: taxesAndFeesTotal,
            total: +productTotal + +serviceTotal + +taxesAndFeesTotal,
        };

        setAppointmentCalulation(calculation);
    };
    useEffect(() => {
        getCalculation();
    }, [calendarData.selectedLocation, servicesWatch, productsWatch]);

    useEffect(() => {
        onHandleCloseSidebar();
        // handleClose();
    }, [slotInfo.selectedLocation]);
    useEffect(() => {
        setValue('payment_method', 'pay_online');
        dispatch(getAllSalesTaxes());
    }, []);
    const handleTeam = (e: any) => {
        setValue('staff_id', e?.id ?? null);
    };

    const handleAction = (type: keyof IInitAction) => async () => {
        const isStepValid = await trigger();
        if (!isStepValid) return;

        setAction(() => {
            const resetActions = Object.keys(initAction).reduce((acc, key) => {
                acc[key as keyof IInitAction] = false;
                return acc;
            }, {} as IInitAction);
            return { ...resetActions, [type]: true };
        });
    };

    return (
        <div
            ref={sidebarRef}
            className={`sideModal calendar_sideModal bg-white z-[999] fixed bottom-0 top-[92px] xxl:top-[95px] right-[0px] border-t !transition-all !duration-[0.5s] shadow-lg border-l ${
                isOpen && 'w-[361px] xxl:w-[360px]'
            }`}
        >
            <div className={`h-full flex flex-col ${isOpen ? ' ' : 'hidden'}`}>
                {isOpen ? (
                    <FormProvider {...methods}>
                        <form className="h-full flex flex-col">
                            {action.checkout && (
                                <>
                                    <div className="header flex justify-between px-4 py-4 items-center border-b border-borderSecondary">
                                        <div className="">
                                            <h1 className="text-base font-semibold leading-6 text-gray-900">New Checkout</h1>
                                            <div className="font-medium text-xs leading-[18px] text-secondaryTxtColor">Register a New Sale</div>
                                        </div>
                                        <div>
                                            <button className="close-btn" type="button" onClick={() => handleClose()}>
                                                <X className="text-gray-700" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="p-4 flex-1 overflow-y-auto">
                                        <Client />
                                        <div className="mb-4">
                                            <div className="flex justify-between">
                                                <label htmlFor="newclient" className={`fl-field-title-label text-gray-700 text-xs font-semibold leading-[18px] mb-1.5`}>
                                                    Select Team Member
                                                </label>
                                            </div>

                                            <Controller
                                                name="staff_id"
                                                control={control}
                                                render={({ fieldState: { error }, field: { onChange, value } }: any) => (
                                                    <div className="w-full">
                                                        <SelectBox
                                                            name="staff_id"
                                                            id="staff_id"
                                                            options={staffOptions}
                                                            value={staffOptions.find((item: any) => item.value === value)}
                                                            onChangeFunc={handleTeam}
                                                            classComp="outline-select-box"
                                                            placeholder="Select Team member"
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            error={error}
                                                        />
                                                        {error?.type && error.type !== 'required' && error.type !== 'nullable' && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <Service />
                                        <Product />
                                    </div>
                                </>
                            )}
                            {action.payment && (
                                <Checkout
                                    handleAction={handleAction}
                                    setPaymentArray={setPaymentArray}
                                    appointmentCalulation={appointmentCalulation}
                                    setCardList={setCardList}
                                    setLoyalPointAmount={setLoyalPointAmount}
                                    loyalPointAmount={loyalPointAmount}
                                />
                            )}
                            {action.complate_payment && (
                                <Payment
                                    paymentArray={paymentArray}
                                    handleAction={handleAction}
                                    setPaymentArray={setPaymentArray}
                                    appointmentCalulation={appointmentCalulation}
                                    dataService={getValues()}
                                    cardList={cardList}
                                    loyalPointAmount={loyalPointAmount}
                                />
                            )}
                            {/* {appointmentCalulation && <CalulationDetails appointmentCalulation={appointmentCalulation.appointmentCalulation} />} */}

                            {action.checkout && (
                                <div className="border-t px-5 py-4">
                                    <div className="flex gap-4 w-full">
                                        <CustomButton className="w-1/2" secondary onClick={handleClose}>
                                            Cancel
                                        </CustomButton>
                                        <CustomButton type="button" className="w-1/2" primary onClick={handleAction('payment')}>
                                            Checkout
                                        </CustomButton>
                                    </div>
                                </div>
                            )}
                        </form>
                    </FormProvider>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

export default memo(CheckoutSidebar);
