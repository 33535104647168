import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from 'src/constants/api';
import { ROUTES } from 'src/constants/routes';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { ArrowLeft, DotsVertical, Trash01, Check } from '@untitled-ui/icons-react/build/cjs';
import { initAction } from './Client.interface';
import { Dropdown } from 'flowbite-react';
import { defaultImage } from 'src/theme/Images';
import { getCardTypeImage, getShortName, onError } from 'src/utils/global-functions';
import CustomButton from 'src/components/CustomButton';
import { PATH } from 'src/constants/path';
import moment from 'moment';
import { DATE_FORMAT } from 'src/constants/common';
import ClientTab from './ClientTab';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import SClientDetails from '../Skeleton/Client/SClientDetails';

const ClientView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [client, setClient] = useState<any>(null);
    const [cardList, setCardList] = useState<any>({ cards: [], default_card_id: null });
    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState(initAction);
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            getClient();
            getCardList();
        }
    }, [id, shop]);

    const getClient = () => {
        axiosGet(API.CLIENT.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;
                setClient(data);
            })
            .catch(() => navigate(ROUTES.CLIENT.LIST))
            .finally(() => setIsLoading(false));
    };

    const getCardList = () => {
        axiosGet(API.CLIENT.CARD_LIST, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;
                setCardList(data);
            })
            .finally(() => setIsLoading(false));
    };

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleEdit = () => {
        navigate(`${PATH.CLIENT.UPDATE}/${id}`);
    };

    const renderTrigger = () => (
        <div>
            <DotsVertical className="custom-icon z-20" fill="#475467" />
        </div>
    );

    const handleModalClose = useCallback(
        (type: boolean = false) =>
            async () => {
                if (type) {
                    if (action.delete) {
                        setIsLoading(true);
                        const params = {
                            shop_id: shop.id,
                            id,
                        };
                        await axiosDelete(API.CLIENT.DELETE, {}, params)
                            .then(() => navigate(ROUTES.CLIENT.LIST))
                            .finally(() => setIsLoading(false));
                    }
                }
                setAction(initAction);
            },
        [action],
    );

    return (
        <div className="inner-page-wrape !px-0">
            {isLoading ? (
                <SClientDetails />
            ) : (
                client && (
                    <div className="flex-1 flex flex-col mb-4">
                        <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                            <div className="flex items-center justify-between w-full">
                                <Link to={ROUTES.CLIENT.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5  group hover:text-gray-800">
                                    <ArrowLeft className="w-4 h-4 cursor-pointer text-gray-600 group-hover:text-gray-800 " /> {t('Back to Client')}
                                </Link>
                                <div className="z-[1] location_menu">
                                    <Dropdown label="" renderTrigger={renderTrigger} className="w-[200px] border border-gray-200 rounded-lg">
                                        <Dropdown.Item icon={Trash01} onClick={handleAction('delete')} className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                            <span>{t('Delete')}</span>
                                        </Dropdown.Item>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1">
                            <div className="xlm:px-8 px-5 relative mb-[26px] w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                                {/* <img
                                    src={client.profile_image_url || defaultImage}
                                    alt={client.full_name}
                                    title={client.full_name}
                                    onError={onError}
                                    className="w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                                /> */}
                                {client.profile_image_url ? (
                                    <img
                                        src={client.profile_image_url}
                                        alt=""
                                        title={client.full_name}
                                        onError={onError}
                                        className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                                    />
                                ) : client.full_name ? (
                                    <div className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl border-4 border-white drop-shadow-lg flex-none -mt-[62px] flex items-center justify-center bg-[#f2f4f7] max-2xl:text-4xl text-6xl text-primary font-semibold tracking-[-1.2px]">
                                        <span>{getShortName(client.full_name)}</span>
                                    </div>
                                ) : (
                                    <img
                                        src={defaultImage}
                                        alt=""
                                        className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                                    />
                                )}
                                <div className="flex justify-between items-center w-full">
                                    <div className="flex gap-2">
                                        <div className="flex flex-col">
                                            <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px]">{client.full_name}</h1>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <CustomButton outlinePrimary onClick={handleEdit} className="!py-2 !px-3">
                                            {t('Edit Client')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                            <div className="border-t border-[#EAECF0] py-[30px] bg-white flex flex-wrap flex-1 xlm:px-8 px-5">
                                <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_342px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8 flex-1">
                                    <ClientTab client={client} />
                                </div>
                                <div className="w-full xl:w-[306px] xls:w-[306px]  xl:ml-8 xls:ml-4 lg:ml-2 ml-0">
                                    <div className="flex flex-col">
                                        <div className="grid grid-cols-1 gap-4 mb-4">
                                            <div className="title-block">
                                                <p className="sideBarHeading">{t('Email')}</p>
                                                <h3 className="text-primary text-xs font-medium flex items-center truncate">{client.email}</h3>
                                            </div>

                                            <div className="title-block">
                                                <p className="sideBarHeading">{t('Account creation')}</p>
                                                <h3 className="sideBarSubHeading">{client.created_at ? moment(client.created_at, moment.ISO_8601).format(DATE_FORMAT) : '-'}</h3>
                                            </div>
                                            <div className="title-block">
                                                <p className="sideBarHeading">{t('Last Action')}</p>
                                                <h3 className="sideBarSubHeading">{client.login_at ? moment(client.login_at, moment.ISO_8601).format(DATE_FORMAT) : '-'}</h3>
                                            </div>
                                        </div>
                                        <div className="flex justify-between  items-center">
                                            <h5 className="sideBarHeading">Payment method</h5>
                                        </div>
                                        <div className="mb-0">
                                            {cardList && cardList.cards && cardList.cards.length ? (
                                                cardList.cards.map((card: any, index: any) => (
                                                    <div key={index} className="mx-auto bg-gray-50 border border-gray-300 rounded-xl mb-4">
                                                        <div className="p-3 flex flex-col">
                                                            <div className="flex items-center gap-3 h-[29px] mb-4">
                                                                <img src={getCardTypeImage(card.card.brand)} alt="" className="w-12 h-auto object-contain" />
                                                                <div className="flex gap-1 items-center">
                                                                    <p className="text-sm font-medium text-gray-900 capitalize leading-5">{`${card.card.brand} ending in ${card.card.last4} `}</p>
                                                                    <span className="text-xs leading-[20px] text-btnBg font-semibold">{card.id === cardList.default_card_id ? '(Default)' : ''}</span>
                                                                </div>
                                                            </div>
                                                            <div className="grid grid-cols-2 gap-4">
                                                                <div className="flex flex-col">
                                                                    <p className="text-xs leading-[18px] text-gray-600 font-normal mb-1">Expires</p>
                                                                    <p className="text-gray-600 font-semibold leading-[18px] text-xs">{`${card.card.exp_month}/${card.card.exp_year}`}</p>
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <p className="text-xs leading-[18px] mb-1 text-gray-600 font-normal">Date added</p>
                                                                    <p className="text-gray-600 font-semibold leading-[18px] text-xs">{moment(card.created * 1000).format('ddd, MMM D, h:mm A')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="flex rounded-xl justify-center items-center h-[60px] mx-auto border-borderSecondary bg-white shadow-sm  border ">
                                                    <div className="flex text-gray-500 font-semibold text-sm leading-6">No Payment Method Added</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-1">
                                            {/* <p className="text-sm  font-semibold text-gray-900 ">{t('Appointment Payment Settings')}</p>
                                            <p className="text-xs font-normal leading-[18px] text-gray-600">{t('Add client specific appointment booking settings')}</p> */}
                                            <div className="border rounded-xl p-3 mt-4 flex  space-x-4">
                                                {client.shop_client.is_book_with_card ? (
                                                    <div className="flex gap-3">
                                                        <div className="w-4">
                                                            <Check className=" text-primary w-[16px] " />
                                                        </div>
                                                        <div className="flex flex-col flex-1">
                                                            <p className="text-gray-900 font-semibold text-sm truncate max-w-[230px]">Reserve with card</p>
                                                            <p className="text-gray-600 text-xs font-normal">
                                                                Enable this setting in order to require this specific client to have a card on file to reserve an appointment.
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="flex gap-3">
                                                        <div className="w-5">
                                                            <Check className=" text-blue-600 w-6 gap-3 mt-[1px]" />
                                                        </div>
                                                        <div className="flex flex-col flex-1">
                                                            <p className="text-gray-900 font-semibold text-sm truncate max-w-[230px]">Reserve without card</p>
                                                            <p className="text-sm text-gray-600 font-normal mt-1 leading-5">
                                                                Enable this setting in order to not require this specific client to have a card on file to reserve an appointment.
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
            {action.delete && (
                <DeletePopupModal
                    isLoading={isLoading}
                    onClose={handleModalClose}
                    size="w-[400px]"
                    title={t('Delete Client')}
                    description={t('Are you sure you want to delete this client? This action cannot be undone.')}
                />
            )}
        </div>
    );
};

export default ClientView;
