import React, { FC } from 'react';
import { Control, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Tip from './Tip';
import { Gift01 } from '@untitled-ui/icons-react/build/cjs';

interface LoyaltyPaymentProps {
    handleTipWithoutCalculation: (value: any, isFixed?: boolean) => () => void;
    remainingPoints: number;
    errors: any;
    control: Control<FieldValues, any>;
    loyalPointAmount: any;
}

const LoyaltyPayment: FC<LoyaltyPaymentProps> = ({ control, handleTipWithoutCalculation, remainingPoints, errors, loyalPointAmount }) => {
    const { t } = useTranslation();
    const { getValues } = useFormContext();
    const bookingInfo = getValues();
    // const calendarData: any = useAppSelector(selectCalendarData);
    // const shopSettingInfo = useAppSelector(allShopSettings).find((setting: any) => setting.type === 'loyalty')?.value;
    const loyaltyPointValue = +(loyalPointAmount / bookingInfo.user_info.total_loyalty_points).toFixed(3);
    // const remainingPointsFun = (tipAmount = tip) => {
    //     if (shopSettingInfo === undefined) return;
    //     const loyaltyPointValue = +(shopSettingInfo.spend.amount / shopSettingInfo.spend.point).toFixed(3);

    //     const remainingPointsCount = Math.floor((calendarData.bookedSlotInfo.user.total_loyalty_amount - totalChargableAmount - tipAmount) / +loyaltyPointValue);
    //     setRemainingPoints(remainingPointsCount);
    //     setRemainingChargedAmount(0);
    // };

    return (
        <div className="flex flex-col border border-gray-200  w-full rounded-xl mt-3">
            <div className={`text-center flex items-center justify-between  gap-3 px-2.5 py-2 w-full`}>
                <div className="flex flex-row gap-3 items-center">
                    <div className="border border-[#7191EF] bg-[#EDF1FD] rounded-lg h-9 w-9 flex items-center justify-center">
                        <Gift01 className="text-[#143CB3] w-5" />
                    </div>
                    <p className="text-[#143CB3] font-semibold text-xs leading-[18px]">{t('Loyalty Points')}</p>
                </div>
                <p className=" text-secondaryTxtColor font-normal text-xs leading-[18px] ">{bookingInfo.user_info.total_loyalty_points} points</p>
            </div>
            <hr />
            <div className={` flex items-start gap-1    px-2.5 py-2 flex-row justify-between  w-full`}>
                <div className="flex flex-col gap-1">
                    <p className="text-mainTextColor font-medium text-xs leading-[18px] ">Cash Total Amount</p>
                    <p className=" text-secondaryTxtColor font-normal text-xs leading-[18px] ">
                        ({bookingInfo.user_info.total_loyalty_points} points X ${loyaltyPointValue})
                    </p>
                </div>
                <p className="text-mainTextColor font-medium text-xs leading-[18px] ">${loyalPointAmount}</p>
            </div>
            <hr />
            <Tip control={control} handleTipWithoutCalculation={handleTipWithoutCalculation} errors={errors.tip} />
            <hr />
            <div className={` flex items-start gap-1    px-2.5 py-2 flex-row justify-between  w-full`}>
                <div className="flex flex-col gap-1">
                    <p className="text-mainTextColor font-medium text-xs leading-[18px] ">Remaining Balance</p>
                    <p className=" text-secondaryTxtColor font-normal text-xs leading-[18px] ">
                        ${Math.round(remainingPoints * +loyaltyPointValue) > 0 ? Math.round(remainingPoints * +loyaltyPointValue) : 0}/ {loyaltyPointValue} =
                        <span className="text-mainTextColor font-medium text-xs leading-[18px] "> {Math.ceil(remainingPoints) > 0 ? Math.ceil(remainingPoints) : 0} Loyalty Points</span>
                    </p>
                </div>
                <p className="text-mainTextColor font-medium text-xs leading-[18px] ">${Math.ceil(remainingPoints * +loyaltyPointValue) > 0 ? Math.ceil(remainingPoints * +loyaltyPointValue) : 0}</p>
            </div>
        </div>
    );
};

export default LoyaltyPayment;
