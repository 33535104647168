import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import Number from 'src/components/Number';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { ExistingFormData, IFormData, accountTypeOptions, initAction } from './Staff.interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { allShopLocations, currentShop, getAllShopStaff } from 'src/redux/services/common/Common.slice';
import { createRole, removeRole, roleList } from './Staff.slice';
import { getSelectBoxOptions, onError } from 'src/utils/global-functions';
import CustomSelectDropdown from 'src/components/CustomSelectDropdown';
import { errorCode } from 'src/constants/errorCode';
import { useTranslation } from 'react-i18next';
import Checkbox from 'src/components/Checkbox';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowLeft, XClose } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import { FaPlus } from 'react-icons/fa6';
import StaffPaystructure from './StaffPaystructure';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import Switch from 'src/components/Switch/Switch';
import { usePhone } from 'src/hooks/usePhone';
import { CheckCircleIcon } from 'src/theme/Images';
import { DATE_FORMAT } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

const StaffForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { isPhoneValid } = usePhone();
    const dispatch = useAppDispatch();
    const shopLocationList: any = useAppSelector(allShopLocations);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    const [locationIds, setLocationIds] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [action, setAction] = useState(initAction);

    const [payStructure, setPayStructure] = useState({
        pay_structure_type: null,
        start_date: '',
        commission: '0',
        rent_money: null,
        is_sales_tax: false,
        tax_percentage: undefined,
        tax_number: '',
    });
    const schema = Yup.object({
        first_name: Yup.string().required(t('This field is required')),
        last_name: Yup.string().required(t('This field is required')),
        phone: Yup.string()
            .required(t('This field is required'))
            .test({
                message: t('Invalid phone number'),
                name: 'invalid',
                test: function (value) {
                    const { phone_country_code: phoneCountryCode } = this.parent;
                    const isValid = isPhoneValid(value, phoneCountryCode);
                    return isValid;
                },
            }),
        phone_country_code: Yup.string().required(t('This field is required')),
        email: Yup.string().email(t('Please enter valid email address')).required(t('This field is required')),
        account_type: Yup.string().required(t('This field is required')),
        shop_staff_role_id: Yup.number().nullable().required(t('This field is required')),
        shop_location_id: Yup.array().min(1, t('This field is required')),
        pay_structure_type: Yup.string().required(t('This field is required')),
        start_date: Yup.string().required(t('This field is required')),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            first_name: '',
            last_name: '',
            phone: '',
            phone_country_code: '',
            email: '',
            account_type: '',
            shop_staff_role_id: null,
            shop_location_id: [],
            ...payStructure,
        },
    });

    const {
        handleSubmit,
        setValue,
        getValues,
        watch,
        setError,
        trigger,
        control,
        formState: { errors },
    } = methods;
    const firstName = watch('first_name');
    const payStructureType = watch('pay_structure_type');
    const commission = watch('commission');
    const rentMoney = watch('rent_money');
    const startDate = watch('start_date');

    const [createRoleError, setCreateRoleError] = useState('');
    const [handleCloseDropdown, setHandleCloseDropdown] = useState(false);

    useEffect(() => {
        const ids = shopLocationList.map((item: any) => item.id);
        setLocationIds(ids);
        getRoleList();
    }, [shop]);

    useEffect(() => {
        if (id) {
            getLocation();
        }
    }, [id, shop]);

    useEffect(() => {
        setValue('pay_structure_type', payStructure.pay_structure_type);
        setValue('commission', payStructure.commission);
        setValue('start_date', payStructure.start_date);
        setValue('rent_money', payStructure.rent_money);
        setValue('is_sales_tax', payStructure.is_sales_tax);
        setValue('tax_percentage', payStructure.tax_percentage);
        setValue('tax_number', payStructure.tax_number);
        if (payStructure.pay_structure_type) {
            trigger(['pay_structure_type', 'commission', 'start_date', 'rent_money', 'is_sales_tax', 'tax_percentage', 'tax_number']);
        }
    }, [payStructure]);

    const getRoleList = async () => {
        const result = await dispatch(roleList({ shop_id: shop.id }));
        if (result.type === roleList.fulfilled.toString()) {
            const roles: any = getSelectBoxOptions(result.payload.data, 'id', 'name');
            setRoleOptions(roles);
            if (getValues('shop_staff_role_id')) {
                const matchedRole = roles.find((role: any) => role.id === getValues('shop_staff_role_id'));
                setValue('shop_staff_role_id', matchedRole.value);
                trigger('shop_staff_role_id');
            }
        }
        setIsLoading(false);
    };

    const getLocation = async () => {
        setIsLoading(true);
        await axiosGet(API.STAFF.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;
                const shopStaff = data.shop_staff.find((item: any) => item.shop_id === shop.id);
                const locations = data.locations.map((item: any) => item.id);
                const existingData: ExistingFormData = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    phone_country_code: data.phone_country_code,
                    phone: data.phone,
                    account_type: shopStaff.account_type,
                    shop_staff_role_id: shopStaff.shop_staff_role_id,
                    shop_location_id: locations,
                    pay_structure_type: data.pay_structure.pay_structure_type,
                    commission: data.pay_structure.commission || '0',
                    rent_money: data.pay_structure.rent_money || null,
                    start_date: data.pay_structure.start_date,
                    is_sales_tax: data.pay_structure.is_sales_tax || false,
                    tax_percentage: data.pay_structure.tax_percentage || undefined,
                    tax_number: data.pay_structure.tax_number || '',
                };
                Object.keys(existingData).forEach((key) => {
                    setValue(key as keyof IFormData, existingData[key]);
                });
            })
            .catch(() => navigate(ROUTES.STAFF.LIST))
            .finally(() => setIsLoading(false));
    };

    const handleSave = async (data: IFormData) => {
        setIsLoading(true);
        const payload = {
            ...data,
        };
        const isValid = isPhoneValid(data.phone, data.phone_country_code);
        if (!isValid) {
            setError('phone', {
                type: 'manual',
                message: t('Please enter valid phone number'),
            });
            setIsLoading(false);
            return;
        }

        if (id) {
            await axiosPatch(API.STAFF.UPDATE, payload, { shop_id: shop.id, id })
                .then(async () => {
                    await dispatch(getAllShopStaff({ shop_id: shop.id }));
                    navigate(ROUTES.STAFF.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        } else {
            await axiosPost(API.STAFF.CREATE, payload, { shop_id: shop.id })
                .then(async () => {
                    await dispatch(getAllShopStaff({ shop_id: shop.id }));
                    navigate(ROUTES.STAFF.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const resetForm = () => {
        navigate(`${PATH.STAFF.VIEW}/${id}`);
    };

    const handleLocationChange = (selectedId: number, onChange: (value: number[]) => void, value: number[]) => () => {
        const newChecked = value.includes(selectedId)
            ? value.filter((currentId) => currentId !== selectedId) // Remove unchecked item
            : [...value, selectedId]; // Add checked item

        onChange(newChecked);
    };

    const handleAddClick = async (value: string) => {
        const submitData = {
            payload: { name: value },
            shop_id: shop.id,
        };
        setCreateRoleError('');
        const result = await dispatch(createRole(submitData));
        if (result.type === createRole.fulfilled.toString()) {
            setValue('shop_staff_role_id', result.payload.data.id);
            trigger('shop_staff_role_id');
            getRoleList();
        }

        if (result.type === createRole.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                setCreateRoleError(response.data.name[0]);
            }
        }
    };

    const handleRemoveClick = async (val: number) => {
        const data = {
            shop_id: shop.id,
            id: val,
        };
        const result = await dispatch(removeRole(data));
        if (result.type === removeRole.fulfilled.toString()) {
            setValue('shop_staff_role_id', null);
            trigger('shop_staff_role_id');
            getRoleList();
            setHandleCloseDropdown(true);
        }
    };

    const handleItemClick = (value: any) => {
        setValue('shop_staff_role_id', value.value);
        trigger('shop_staff_role_id');
        setHandleCloseDropdown(true);
    };

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    useFormErrorFocus<IFormData>({ errors });

    return (
        <div className="inner-page-wrape !px-0">
            <div className="flex-1 flex flex-col mb-4">
                <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                    <Link
                        to={ROUTES.STAFF.LIST}
                        className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5 group group-hover:text-gray-900 transition-all duration-[0.3s]"
                    >
                        <ArrowLeft className="w-4 cursor-pointer text-gray-600 group-hover:text-gray-900" /> <div className="text-gray-600 group-hover:text-gray-900">{t('Back to Team')}</div>
                    </Link>
                    <Link to={ROUTES.STAFF.LIST} className="close-page-btn">
                        <XClose className="xl:w-5 xl:h-5 w-4 h-4 text-gray-900" />
                    </Link>
                </div>

                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleSave)} className="">
                        <div className="relative  w-full  xlm:px-8 px-5 pt-4 pb-4 flex sms:gap-4 gap-2 xs:items-end items-start">
                            {/* <img
                                src={profileImageUrl || defaultImage}
                                alt={id ? t('Add a New Team Member') : t('Add a New Team Member')}
                                title={watchFirstName || t('Staff Name')}
                                onError={onError}
                                className="w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                            /> */}
                            <div className="flex justify-between items-center w-full gap-4">
                                <div className=" line-clamp-2 xlm:text-3xl sm:text-xl text-base w-[80%]">
                                    <h1 className="mr-10 text-gray-900 xlm:text-3xl sm:text-[26px] text-base inline align-middle font-semibold tracking-[-0.72px]">
                                        {id ? t('Edit Team Member') : t('Add a New Team Member')}
                                    </h1>
                                </div>
                                <div className="flex-1 text-right flex justify-end w-[20%]">
                                    <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading} className="mr-4">
                                        {t('Cancel')}
                                    </CustomButton>
                                    <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                        {t('Save Changes')}
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                        <div className="detail-data-main border-t border-borderSecondary xlm:px-8 px-5">
                            <div className="title-block">
                                <h3>{t('User details')}</h3>
                                <p>{t('Enter the personal and contact information of the new team member. An onboarding invitation will be sent to the email provided.')}</p>
                            </div>
                            <div className="data-wrap-block max-w-[512px] xxl:max-w-[666px]">
                                <div className="grid grid-cols-2 gap-4">
                                    <Controller
                                        name="first_name"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <div>
                                                <InputWithLabel
                                                    name="first_name"
                                                    label={t('First name')}
                                                    required
                                                    id="first_name"
                                                    placeholder={t('Enter staff first name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!fieldState.error}
                                                />
                                                {fieldState.error && fieldState.error.type !== 'required' && <p className="text-error">{fieldState.error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="last_name"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <div>
                                                <InputWithLabel
                                                    name="last_name"
                                                    label={t('Last name')}
                                                    id="last_name"
                                                    required
                                                    placeholder={t('Enter staff last name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!fieldState.error}
                                                />
                                                {fieldState.error && fieldState.error.type !== 'required' && <p className="text-error">{fieldState.error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div>
                                                <label className="fl-field-title-label">
                                                    {t('Phone number')}
                                                    <span className="asterisk">*</span>
                                                </label>
                                                <Number
                                                    disabled={!!id}
                                                    errors={error}
                                                    value={value}
                                                    id="phone"
                                                    defaultValues={{ phone: getValues('phone'), phone_country_code: getValues('phone_country_code') }}
                                                    onNumberChange={(country: any, phone: any, code: any, isValid: any) => {
                                                        setValue('phone', `+${code}${phone}`);
                                                        setValue('phone_country_code', country);
                                                        trigger(['phone', 'phone_country_code']);
                                                    }}
                                                />
                                                {error && error.type !== 'required' && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <div>
                                                <InputWithLabel
                                                    name="email"
                                                    label={t('Email address')}
                                                    id="email"
                                                    required
                                                    placeholder={t('Enter staff email address')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!fieldState.error}
                                                    readOnly={!!id}
                                                />
                                                {fieldState.error && fieldState.error.type !== 'required' && <p className="text-error">{fieldState.error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="account_type"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <div>
                                                <SelectBox
                                                    name="account_type"
                                                    label={t('Account type')}
                                                    required
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    id="account_type"
                                                    placeholder={t('Select account type')}
                                                    options={accountTypeOptions}
                                                    onChangeFunc={(option: any) => {
                                                        onChange(option ? option.value : null);
                                                    }}
                                                    value={accountTypeOptions.find((option) => option.value === value)}
                                                    error={!!fieldState.error}
                                                />
                                                {fieldState.error && fieldState.error.type !== 'required' && <p className="text-error">{fieldState.error.message}</p>}
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="shop_staff_role_id"
                                        control={control}
                                        render={({ field: { value }, fieldState }: any) => (
                                            <div>
                                                <CustomSelectDropdown
                                                    data={roleOptions}
                                                    id="shop_staff_role_id"
                                                    className={`form_input_control placeholder:text-xs placeholder:leading-[19px]  placeholder:text-gray-500 text-gray-900 text-xs border border-gray-200 font-semibold  leading-[19px] flex  justify-between fwpo_select__placeholder
                                    `}
                                                    dropDownMenuClassName="w-full"
                                                    InputError={`${fieldState.error ? 'is-invalid border' : ''}`}
                                                    value={roleOptions.find((option: any) => option.value === value)}
                                                    handleItemClick={handleItemClick}
                                                    handleAddClick={(value2: string) => {
                                                        handleAddClick(value2);
                                                    }}
                                                    handleCloseDropdown={handleCloseDropdown}
                                                    createRoleError={createRoleError}
                                                    handleRemoveClick={(val: number) => handleRemoveClick(val)}
                                                />
                                                {fieldState.error && fieldState.error.type !== 'required' && fieldState.error.type !== 'nullable' && (
                                                    <p className="text-error">{fieldState.error.message}</p>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="detail-data-main xlm:px-8 px-5">
                            <div className="title-block">
                                <h3>
                                    {t('Working location(s)')}
                                    <span className="asterisk">*</span>
                                </h3>
                                <p>{t('Select the location(s) where the team member will be working.')}</p>
                            </div>
                            <div className="data-wrap-block ">
                                <Controller
                                    name="shop_location_id"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState }) => (
                                        <div id="shop_location_id">
                                            <div className="flex items-center gap-2">
                                                <Switch
                                                    onChange={(e: any) => {
                                                        const isChecked = e.value;
                                                        if (isChecked) {
                                                            onChange([...locationIds]);
                                                        } else {
                                                            onChange([]);
                                                        }
                                                    }}
                                                    isChecked={locationIds.length === value.length && locationIds.every((locationId) => value.includes(locationId))}
                                                />
                                                <span>{t('All locations')}</span>
                                            </div>
                                            <div className="gap-5 mt-5 grid grid-cols-3 xxl:grid-cols-4">
                                                {shopLocationList.map((location: any) => (
                                                    <label
                                                        className={`border rounded-xl p-3 xxl:p-4 cursor-pointer relative hover:shadow ${value.includes(location.id) ? 'border-[#5279F0]' : ''} ${
                                                            fieldState.error ? 'is-invalid' : ''
                                                        }`}
                                                        key={location.id}
                                                        htmlFor={`location_${location.id}`}
                                                    >
                                                        {''}
                                                        <img
                                                            src={location.profile_image_url}
                                                            alt=""
                                                            className="h-[150px]  xl:h-[190px] w-full xxl:h-[200px] rounded-xl object-cover"
                                                            onError={onError}
                                                        />
                                                        <img src={CheckCircleIcon} alt="" className={` absolute -top-[8px] -right-[8px] ${value.includes(location.id) ? 'block' : 'hidden'}`} />
                                                        <div className="text-wrap mt-4">
                                                            <h2 className="text-gray-700 mb-1.5 text-base font-semibold capitalize-first truncate">{location.name}</h2>
                                                            <p className="text-gray-500 text-sm font-normal">{`${location.street}, ${location.city}, ${location.country}`}</p>
                                                            <div className="d-none">
                                                                <Checkbox
                                                                    id={`location_${location.id}`}
                                                                    name="shop_location_id"
                                                                    value={location.id} //{Number(location.id)}
                                                                    checked={value ? value.includes(location.id) : false}
                                                                    onChange={handleLocationChange(location.id, onChange, value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </label>
                                                ))}
                                            </div>
                                            {fieldState.error && fieldState.error.type !== 'required' && fieldState.error.type !== 'min' && <p className="text-error">{fieldState.error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div id="start_date" className="detail-data-main xlm:px-8 px-5">
                            <div className="title-block">
                                <h3 className="font-bold text-[15px] leading-[35px] -tracking-[0.336px]">
                                    {t('Select a Payment Structure')}
                                    <span className="asterisk">*</span>
                                </h3>
                                <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">{t('Select under which plan this team member will be compensated.')}</p>
                            </div>
                            <div className="data-wrap-block max-w-[700px]">
                                <CustomButton outlinePrimary type="button" onClick={handleAction('pay_structure')} icon={<FaPlus />}>
                                    {payStructureType ? t('Edit a pay structure') : t('Add a pay structure')}
                                </CustomButton>
                                {/* {errors.pay_structure_type && <p className="text-error">{errors.pay_structure_type.message}</p>} */}
                                {payStructureType && (
                                    <div className="grid grid-cols-3 gap-5 mt-5">
                                        <div className="border rounded-md p-4">
                                            <p className="text-xs font-normal text-secondaryTxtColor">{t('Staff type')}</p>
                                            <p className="text-sm font-medium text-primary mt-2 capitalize truncate">{payStructureType.replace(/_/g, ' ')}</p>
                                        </div>
                                        {payStructureType === 'commission' && (
                                            <>
                                                <div className="border rounded-md p-4">
                                                    <p className="text-xs font-normal text-secondaryTxtColor">{t('Commission percentage')}</p>
                                                    <p className="text-sm font-medium text-primary mt-2 truncate">{commission}%</p>
                                                </div>
                                                <div className="border rounded-md p-4">
                                                    <p className="text-xs font-normal text-secondaryTxtColor">{t('Commission payout frequency')}</p>
                                                    <p className="text-sm font-medium text-primary mt-2 capitalize truncate">{shop.shop_payment.commission_frequency.replace(/_/g, '-')}</p>
                                                </div>
                                                <div className="border rounded-md p-4">
                                                    <p className="text-xs font-normal text-secondaryTxtColor">{t('Payment day')}</p>
                                                    <p className="text-sm font-medium text-primary mt-2  capitalize-first truncate">
                                                        {shop.shop_payment.commission_frequency !== 'monthly'
                                                            ? shop.shop_payment.commission_day
                                                            : t(`${shop.shop_payment.commission_month_day} Day of the month`)}
                                                    </p>
                                                </div>
                                                <div className="border rounded-md p-4">
                                                    <p className="text-xs font-normal text-secondaryTxtColor">{t('Tip payout frequency')}</p>
                                                    <p className="text-sm font-medium text-primary mt-2 capitalize-first truncate">{shop.shop_payment.tip_frequency.replace(/_/g, '-')}</p>
                                                </div>
                                            </>
                                        )}
                                        {payStructureType === 'rent' && (
                                            <>
                                                <div className="border rounded-md p-4">
                                                    <p className="text-xs font-normal text-secondaryTxtColor">{t('Rent amount')}</p>
                                                    <p className="text-sm font-medium text-primary mt-2 truncate">${rentMoney}</p>
                                                </div>
                                                <div className="border rounded-md p-4">
                                                    <p className="text-xs font-normal text-secondaryTxtColor">{t('Rent payout frequency')}</p>
                                                    <p className="text-sm font-medium text-primary mt-2 capitalize-first truncate">{shop.shop_payment.rent_frequency.replace(/_/g, '-')}</p>
                                                </div>
                                                <div className="border rounded-md p-4">
                                                    <p className="text-xs font-normal text-secondaryTxtColor">{t('Payment day')}</p>
                                                    <p className="text-sm font-medium text-primary mt-2 capitalize-first truncate">
                                                        {shop.shop_payment.rent_frequency !== 'monthly' ? shop.shop_payment.rent_day : t(`${shop.shop_payment.rent_month_day} day of the month`)}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        <div className="border rounded-md p-4">
                                            <p className="text-xs font-normal text-secondaryTxtColor">{t('Employee start date')}</p>
                                            <p className="text-sm font-medium text-primary mt-2 truncate">{moment(startDate).format(DATE_FORMAT)}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex-1 text-right pt-5 flex justify-end mb-36 xlm:px-8 px-5 gap-4">
                            <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                {t('Save Changes')}
                            </CustomButton>
                        </div>
                    </form>
                </FormProvider>
                {action.pay_structure && (
                    <StaffPaystructure handleClose={handleModalClose} setPayStructure={setPayStructure} payStructure={getValues()} shopPayment={shop.shop_payment} firstName={firstName} />
                )}
            </div>
        </div>
    );
};

export default StaffForm;
