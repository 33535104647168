import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { ReactComponent as Spark } from 'src/assets/svgs/spark.svg';
import { ReactComponent as WelcomeLoader } from 'src/assets/img/spinning-dots.svg';
import CustomButton from 'src/components/CustomButton';
import StepProgressBar from 'src/components/StepProgressBar';
import Footer from 'src/app/Layout/Footer';
import { FaCheck } from 'react-icons/fa6';

const Welcome = ({ stepLenght, currentStep }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showCheck, setShowCheck] = useState(true);
    const handleStep = () => {
        navigate(ROUTES.DASHBOARD);
    };

    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            setShowCheck(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <main className="px-6 h-[calc(100vh-84px)] overflow-y-scroll flex-1 flex">
                <div className="w-full flex justify-center relative flex-1 items-center flex-col text-center bg-banner bg-top bg-no-repeat">
                    <div className="w-[600px]">
                        <div className="">
                            <div className="flex flex-col rounded-md items-center mb-4">
                                <div className="p-[14px] bg-white border border-btnborder rounded-xl">
                                    <Spark />
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div className="w-[512px] text-center">
                                    <h3 className="fwpo_heading_title">{t('Welcome to FLAIR')}</h3>
                                    <p className="font-normal  mt-[2px] text-base text-secondaryTxtColor">{t("We're getting everything setup for you, this might take a couple seconds.")}</p>
                                </div>
                            </div>

                            <div className="flex flex-col items-center mt-[33px]">
                                {showCheck ? (
                                    <WelcomeLoader className="w-[64px] mx-auto" />
                                ) : (
                                    <div className="w-[64px] h-[64px] flex justify-center rounded-full items-center bg-primary">
                                        <FaCheck className="w-[30px] h-[30px]  text-white" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="bottom-0 right-0 max-2xl:start-[420px] start-[480px] fixed">
                <div className="w-full mt-5   mb-2  gap-4 flex justify-center mx-auto">
                    <CustomButton type="button" primary onClick={handleStep} disabled={showCheck} className="w-[600px]">
                        {t('Continue')}
                    </CustomButton>
                </div>
                <div className="w-full bg-white">
                    <StepProgressBar steps={stepLenght} currentStep={currentStep} className="w-full mx-auto justify-center" size="max-w-[190px]" />
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Welcome;
