export const API = {
    USER: {
        LOGIN_WITH_EMAIL: '/shop/login',
        LOGIN_SHOP_PHONE_EMAIL: '/shop/phone-email',
        LOGIN_SHOP_PHONE_EMAIL_VERIFY_OTP: '/shop/phone-email/verify',
        SOCIAL_LOGIN: '/shop/social/login',
        SOCIAL_CONNECT: '/shop/social/account/connect',
        SOCIAL_ACCOUNT: '/shop/social/account',
        SOCIAL_ACCOUNT_VERIFY: '/shop/social/account/verify',
        VERIFY_OTP: '/shop/verify-otp',
        VERIFY_PASSWORD: '/shop/verify-password',
        LOGOUT: '/shop/phone/verify-otp',
        FORGOT_PASSWORD: '/shop/forgot-password',
        NEW_PASSWORD: '/shop/new-password',
        CHANGE_PASSWORD: '/shop/change-password',
        PROFILE: '/shop/profile',
        UPDATE_EMAIL: '/shop/email',
        VERIFY_EMAIL: '/shop/email/verify',
        UPDATE_PHONE: '/shop/phone',
        VERIFY_PHONE: '/shop/phone/verify',
        ACCOUNT: '/shop/{shop_id}/account',
        COMPANY: '/shop/{shop_id}',
        CREATE_CONTACT_US: '/shop/{shop_id}/contact-us',
        ME: '/shop/me',
        GET_STRIPE_ACCOUNT: '/shop/stripe/account/get',
        VERIFY_STRIPE_ACCOUNT: '/shop/stripe/account/verify',
        UPDATE_STRIPE_ACCOUNT_STATUS: '/shop/stripe/account/status',
        HYPERLINKS: '/shop/{shop_id}/hyperlinks',
        FORGOT_PASSWORD_VERIFY_OTP: '/shop/forgot-password/verify',
        SIGNUP_PROFILE: '/shop/{shop_id}/profile',
        ACCOUNT_TYPE: '/shop/{shop_id}/account-type',
        BRAND: '/shop/{shop_id}/brand',
        SHOP_ME: '/shop/{shop_id}/me',
        INVITATION: '/shop/{shop_id}/staff/invitation',
        STAFFPROFILE: '/shop/{shop_id}/staff/profile',
        WORKINGHOURS: '/shop/{shop_id}/staff/working-hours',
        POLICY: '/shop/{shop_id}/setting/policy',
        CALENDAR: '/shop/{shop_id}/setting/calendar',
    },
    STRIPE: {
        LIST: '/shop/stripe/card/list',
        CREATE: '/shop/stripe/card/add',
        DELETE: '/shop/stripe/card/remove',
        ACCOUNT_DETAILS: '/shop/stripe/account',
        DEFAULT: '/shop/stripe/card/default',
        STATEMENTS: '/shop/{shop_id}/stripe/account/statements',
        BALANCE: '/shop/stripe/account/balance',
        TERMINAL_TOKEN: '/shop/{shop_id}/stripe/terminal/token',
        TERMINAL_PAYMENT: '/shop/{shop_id}/stripe/terminal/payment',
        TERMINAL_STATUS: '/shop/{shop_id}/stripe/terminal/status',
        DISCONNECT: '/shop/{shop_id}/stripe/account/disconnect',
        LOCATION: {
            LIST: '/shop/{shop_id}/stripe/location/list',
            CREATE: '/shop/{shop_id}/stripe/location/create',
            UPDATE: '/shop/{shop_id}/stripe/location/{id}/update',
            DELETE: '/shop/{shop_id}/stripe/location/{id}/delete',
        },
        READER: {
            LIST: '/shop/{shop_id}/stripe/reader/list',
            CREATE: '/shop/{shop_id}/stripe/reader/create',
            UPDATE: '/shop/{shop_id}/stripe/reader/{id}/update',
            DELETE: '/shop/{shop_id}/stripe/reader/{id}/delete',
        },
    },
    THEME: {
        LIST: '/shop/theme/list',
        UPDATE_APPEARANCE: '/shop/{shop_id}/appearance',
        GENERAL: '/shop/{shop_id}/appearance/general',
        LOGO: '/shop/{shop_id}/appearance/logo',
        TEMPLATE: {
            TEMPLATE: '/shop/{shop_id}/appearance/template',
            SECTION: '/shop/{shop_id}/appearance/template/section',
        },
    },
    PLAN: {
        UPDATE: '/shop/{shop_id}/plan/upgrade',
        GET: '/shop/plans',
    },
    DASHBOARD: {
        GET: '/shop/{shop_id}/analytics/dashboard',
    },
    ANALYTICS: {
        GET: '/shop/{shop_id}/analytics',
        EXPENSELIST: '/shop/{shop_id}/expense',
        EXPENSECREATE: '/shop/{shop_id}/expense',
        HEAT_MAP: '/shop/{shop_id}/analytics/heatmap',
    },
    CATEGORY: {
        ALL: '/shop/{shop_id}/category/all',
        LIST: '/shop/{shop_id}/category',
        CREATE: '/shop/{shop_id}/category',
        UPDATE: '/shop/{shop_id}/category/{id}',
        DELETE: '/shop/{shop_id}/category/{id}',
        GET: '/shop/{shop_id}/category/{id}',
    },
    LOCATION: {
        LIST: '/shop/{shop_id}/location',
        CREATE: '/shop/{shop_id}/location',
        GET: '/shop/{shop_id}/location/{id}',
        UPDATE: '/shop/{shop_id}/location/{id}',
        DELETE: '/shop/{shop_id}/location/{id}',
        COUNTRY: '/shop/countries',
        STATE: '/shop/states',
        CITY: '/shop/cities',
        BOOKINGS: '/shop/{shop_id}/location/{id}/bookings',
        HOURS: '/shop/{shop_id}/location/{id}/working-hours',
        STRIPE: '/shop/{shop_id}/location/{id}/stripe',
    },
    PAYMENT: {
        LIST: '/shop/{shop_id}/payment',
        RETRY: '/shop/{shop_id}/payment/{id}/retry',
        UPDATE: '/shop/{shop_id}/payment/account',
        RENT_LIST: '/shop/{shop_id}/payment/rent',
        RENT_RETRY: '/shop/{shop_id}/payment/rent/{id}/retry',
        COMMISSION_LIST: '/shop/{shop_id}/payment/commission',
        COMMISSION_RETRY: '/shop/{shop_id}/payment/commission/{id}/retry',
        RECEIPT: '/shop/{shop_id}/payment/{id}/receipt',
        BALANCE: '/shop/stripe/account/balance',
    },
    TRANSACTION_HISOTRY: {
        LIST: '/shop/{shop_id}/booking/transactions',
    },
    SERVICE: {
        ALL: '/shop/{shop_id}/service/all',
        LIST: '/shop/{shop_id}/service',
        CREATE: '/shop/{shop_id}/service',
        UPDATE: '/shop/{shop_id}/service/{id}',
        DELETE: '/shop/{shop_id}/service/{id}',
        GET: '/shop/{shop_id}/service/{id}',
        STAFF_ATTACH: '/shop/{shop_id}/service/staff',
        STAFF_DELETE: '/shop/{shop_id}/service/{shop_service_id}/staff',
        STAFF_UPDATE: '/shop/{shop_id}/service/{shop_service_id}/staff',
    },
    ADMINS: {
        LIST: '/shop/{shop_id}/admins',
        CREATE: '/shop/{shop_id}/admins',
        GET: '/shop/{shop_id}/admins/{id}',
        UPDATE: '/shop/{shop_id}/admins/{id}',
        DELETE: '/shop/{shop_id}/admins/{id}',
    },
    STAFF: {
        ALL: '/shop/{shop_id}/staff/all',
        LIST: '/shop/{shop_id}/staff',
        CREATE: '/shop/{shop_id}/staff',
        UPDATE: '/shop/{shop_id}/staff/{id}',
        DELETE: '/shop/{shop_id}/staff/{id}',
        GET: '/shop/{shop_id}/staff/{id}',
        BOOKING: '/shop/{shop_id}/staff/{id}/bookings/details',
        APPOINTMENT: '/shop/{shop_id}/staff/{id}/bookings',
        PERFORMANCE: '/shop/{shop_id}/staff/performance',
        STATUS_UPDATE: '/shop/{shop_id}/staff/{id}/status',
        BOOKING_HISTORY: '/shop/{shop_id}/staff/{id}/bookings/export',
        SERVICE_HISTORY: '/shop/{shop_id}/service/export',
    },
    RECEPTIONIST_MODE: {
        PIN_SETUP: '/shop/pin',
        PIN_VERIFY: '/shop/pin/verify',
        PIN_FORGOT: '/shop/pin/forgot',
    },
    CLIENT: {
        LIST: '/shop/{shop_id}/client',
        ALL: '/shop/{shop_id}/client/all',
        STATUSCOUNT: '/shop/{shop_id}/client/status/count',
        CREATE: '/shop/{shop_id}/client',
        GET: '/shop/{shop_id}/client/{id}',
        DELETE: '/shop/{shop_id}/client/{id}',
        EXPORT: '/shop/{shop_id}/client/export',
        IMPORT: '/shop/{shop_id}/client/import',
        SAMPLE: '/shop/{shop_id}/client/sample',
        UPDATE: '/shop/{shop_id}/client/{id}',
        APPOINTMENT: '/shop/{shop_id}/client/{id}/bookings',
        COUNT: '/shop/{shop_id}/client/{id}/count',
        CARD_LIST: '/shop/{shop_id}/client/{id}/cards',
        CARD_DEFAULT: '/shop/{shop_id}/client/{id}/stripe/card/default',
        NOTE: '/shop/{shop_id}/client/{client_id}/client-note',
        NOTEDELETE: '/shop/{shop_id}/client/{client_id}/client-note/{id}',
        NOTEUPDATE: '/shop/{shop_id}/client/{client_id}/client-note/{id}',
        TRANSACTION: '/shop/{shop_id}/client/{id}/transactions',
        TRANSACTION_HISTORY: '/shop/{shop_id}/client/{id}/transactions/export',
        BOOKING_HISTORY: '/shop/{shop_id}/client/{id}/bookings/export',
        PERFORMANCE: '/shop/{shop_id}/client/performance',
    },
    STAFF_ROLE: {
        LIST: '/shop/{shop_id}/staff/role',
        CREATE: '/shop/{shop_id}/staff/role',
        UPDATE: '/shop/{shop_id}/staff/role/{id}',
        DELETE: '/shop/{shop_id}/staff/role/{id}',
    },
    SALES: {
        LIST: '/shop/sales-tax',
    },
    BOOKING: {
        LIST: '/shop/{shop_id}/booking',
        LIST_BY_DATE: '/shop/{shop_id}/booking/date',
        CREATE: '/shop/{shop_id}/location/{location_id}/booking',
        UPDATE: '/shop/{shop_id}/location/{location_id}/booking/{id}',
        UPDATE_STATUS: '/shop/{shop_id}/booking/{id}/status',
        RECEIPT: '/shop/{shop_id}/booking/{id}/receipt',
        GET: '/shop/{shop_id}/booking/{id}',
        COMPLETE: '/shop/{shop_id}/booking/{id}/complete',
        OVERLAP: '/shop/{shop_id}/booking/overlap',
        CHECKOUT: '/shop/{shop_id}/booking/checkout',
        LOYALTY: '/shop/{shop_id}/booking/loyalty',
    },
    STAFF_BLOCK_TIME: {
        LIST: '/shop/{shop_id}/staff/{staff_id}/block-time',
        CREATE: '/shop/{shop_id}/staff/{staff_id}/block-time',
        UPDATE: '/shop/{shop_id}/staff/{staff_id}/block-time/{id}',
        DELETE: '/shop/{shop_id}/staff/{staff_id}/block-time/{id}',
    },
    WAITLIST: {
        LIST: '/shop/{shop_id}/waitlist',
        CREATE: '/shop/{shop_id}/waitlist',
        UPDATE: '/shop/{shop_id}/waitlist/{id}',
        DELETE: '/shop/{shop_id}/waitlist/{id}',
        GET: '/shop/{shop_id}/waitlist/{id}',
    },
    NOTIFICATION: {
        USER_NOTIFICATION_CREATE_UPDATE: '/shop/notification',
        LIST: '/shop/notification',
        SETTINGS: '/shop/{shop_id}/notification/setting',
        READ: '/shop/notification/mark-read',
    },
    REFER: {
        CREATE_OR_UPDATE: '/shop/{shop_id}/setting/refer-loyalty',
        REFER_STATUS: '/shop/{shop_id}/refer/status',
        CONFIG_CREATE_OR_UPDATE: '/shop/{shop_id}/refer/config',
        CONFIG_STATUS: '/shop/{shop_id}/refer/config/status',
    },
    SETTING: {
        GET: '/shop/{shop_id}/setting',
        REMINDER: '/shop/{shop_id}/setting/reminder',
    },
    PRODUCT_CATEGORY: {
        ALL: '/shop/{shop_id}/product/category/all',
        LIST: '/shop/{shop_id}/product/category',
        CREATE: '/shop/{shop_id}/product/category',
        UPDATE: '/shop/{shop_id}/product/category/{id}',
        DELETE: '/shop/{shop_id}/product/category/{id}',
        GET: '/shop/{shop_id}/product/category/{id}',
    },
    PRODUCT: {
        LIST: '/shop/{shop_id}/product',
        GET: '/shop/{shop_id}/product/{id}',
        CREATE: '/shop/{shop_id}/product',
        UPDATE: '/shop/{shop_id}/product/{id}',
        DELETE: '/shop/{shop_id}/product/{id}',
        LOCATION_PRODUCT: '/shop/{shop_id}/product/location/{id}',
        COUNT: '/shop/{shop_id}/product/{id}/count',
        VARIANT: '/shop/{shop_id}/product/{id}/variant',
        INVENTORY: '/shop/{shop_id}/product/{id}/inventory',
        ALLVARIANT: '/shop/{shop_id}/product/{id}/variant/all',
        CREATEVARIANT: '/shop/{shop_id}/product/{id}/inventory',
        UPDATEVARIANT: '/shop/{shop_id}/product/inventory/{id}',
        BOOKING: '/shop/{shop_id}/product/{id}/booking',
        LOCATION_VARIANT: '/shop/{shop_id}/product/{id}/location',
        COMMISSION_LIST: '/shop/{shop_id}/product/commission',
        COMMISSION: '/shop/{shop_id}/product/commission',
    },
    SHOP: {
        CREATE: '/shop',
        SLUG: '/shop/{shop_id}/slug',
        SLUG_EXISTS: '/shop/{shop_id}/slug/exists',
    },
};
