import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/PageHeader';
import SalesTable from './SalesTable';

const Sales = () => {
    const { t } = useTranslation();

    return (
        <div className="inner-page-wrape pb-5">
            <PageHeader title={t('Sales')} subtitle={t("Here's the list of all service and product sales.")}></PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full">
                <SalesTable />
            </div>
        </div>
    );
};

export default Sales;
