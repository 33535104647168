import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PATH } from 'src/constants/path';
import CustomButton from 'src/components/CustomButton';
import { ROUTES } from 'src/constants/routes';
import { ArrowLeft, DotsVertical, Edit02, FileX03, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import { axiosDelete, axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import moment from 'moment';
import { capitalizeFirstLetterAndUnderscore, getShortName, onError } from 'src/utils/global-functions';
import { defaultImage } from 'src/theme/Images';
import { ChevronDown, ChevronRight } from '@untitled-ui/icons-react/build/cjs';
import { Dropdown } from 'flowbite-react';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { initAction } from './Staff.interface';
import { DATE_FORMAT } from 'src/constants/common';
import { usePhone } from 'src/hooks/usePhone';
import StaffTab from './StaffTab';
import STeamDetail from '../Skeleton/Team/STeamDetail';
import { GoDotFill } from 'react-icons/go';

const StaffView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const { getFormatPhone } = usePhone();
    const [isLoading, setIsLoading] = useState(false);
    const [staff, setStaff] = useState<any>(null);
    const [isShow, setIsShow] = useState<any>({ 0: true });
    const [action, setAction] = useState(initAction);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            axiosGet(API.STAFF.GET, { shop_id: shop.id, id })
                .then((response) => {
                    const data = response.data.data;
                    setStaff(data);
                })
                .catch(() => navigate(ROUTES.STAFF.LIST))
                .finally(() => setIsLoading(false));
        }
    }, [id, shop]);

    const handleEdit = () => {
        navigate(`${PATH.STAFF.UPDATE}/${id}`);
    };

    const handleCollapse = (index: number) => () => {
        setIsShow((old: any) => ({ ...old, [index]: !old[index] }));
    };

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleModalClose = useCallback(
        (type: boolean = false) =>
            async () => {
                if (type) {
                    if (action.delete) {
                        setIsLoading(true);
                        const params = {
                            shop_id: shop.id,
                            id,
                        };
                        await axiosDelete(API.STAFF.DELETE, {}, params)
                            .then(() => navigate(ROUTES.STAFF.LIST))
                            .finally(() => setIsLoading(false));
                    } else if (action.active) {
                        setIsLoading(true);
                        const params = {
                            shop_id: shop.id,
                            id,
                        };
                        await axiosPatch(API.STAFF.STATUS_UPDATE, { is_active: !staff.shop_staff[0].is_active }, params)
                            .then(() => navigate(ROUTES.STAFF.LIST))
                            .finally(() => setIsLoading(false));
                    }
                }
                setAction(initAction);
            },
        [action],
    );

    const renderTrigger = () => (
        <div>
            <DotsVertical className="custom-icon z-20" fill="#475467" />
        </div>
    );

    return (
        <div className="inner-page-wrape !px-0">
            {isLoading ? (
                <STeamDetail />
            ) : (
                staff && (
                    <div className="flex-1 flex flex-col mb-4">
                        <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                            <div className="flex items-center justify-between w-full">
                                <Link to={ROUTES.STAFF.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5 group hover:text-gray-800">
                                    <ArrowLeft className="w-4 cursor-pointer text-gray-600 group-hover:text-gray-800" /> {t('Back to Team')}
                                </Link>
                                <div className="z-[1] location_menu">
                                    <Dropdown label="" renderTrigger={renderTrigger} className="w-[200px] border border-gray-200 rounded-lg">
                                        <Dropdown.Item icon={Edit02} onClick={handleAction('active')} className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                            <span>{staff.shop_staff[0].is_active ? t('Unlist') : t('List')}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item icon={Trash01} onClick={handleAction('delete')} className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                            <span>{t('Delete')}</span>
                                        </Dropdown.Item>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col flex-1">
                            <div className="xlm:px-8 px-5 relative mb-[26px] w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                                {/* <img
                                    src={staff.profile_image_url || defaultImage}
                                    alt={staff.full_name}
                                    title={staff.full_name}
                                    onError={onError}
                                    className="w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                                /> */}
                                {staff.profile_image_url ? (
                                    <img
                                        src={staff.profile_image_url}
                                        alt=""
                                        title={staff.full_name}
                                        onError={onError}
                                        className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                                    />
                                ) : staff.full_name ? (
                                    <div
                                        className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl border-4 border-white drop-shadow-lg flex-none -mt-[62px] flex items-center justify-center bg-[#f2f4f7] max-2xl:text-4xl text-6xl text-primary font-semibold tracking-[-1.2px]"
                                        title={staff.full_name}
                                    >
                                        <span>{getShortName(staff.full_name)}</span>
                                    </div>
                                ) : (
                                    <img
                                        src={defaultImage}
                                        alt=""
                                        className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                                    />
                                )}

                                <div className="flex justify-between items-center w-full">
                                    <div className="flex gap-2">
                                        <div className="flex flex-col">
                                            <div className="flex flex-row items-center">
                                                <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px] ">{staff.full_name}</h1>
                                                <div className="flex items-center">
                                                    <span
                                                        className={`px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max `}
                                                    >
                                                        <GoDotFill
                                                            size={12}
                                                            className={
                                                                staff.shop_staff[0].is_accepted_invitation
                                                                    ? 'text-[#6172F3]'
                                                                    : staff.shop_staff[0].is_declined_invitation
                                                                    ? 'text-[#F00]'
                                                                    : 'text-[#F38744]'
                                                            }
                                                        />
                                                        {staff.shop_staff[0].is_accepted_invitation ? t('Active') : staff.shop_staff[0].is_declined_invitation ? t('Decline') : t('Pending')}
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center">{staff.staff_role.name}</p>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <CustomButton outlinePrimary onClick={handleEdit} className="!py-2 !px-3">
                                            {t('Edit Team')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                            <div className="border-t border-[#EAECF0] py-[30px] bg-white flex flex-wrap flex-1 xlm:px-8 px-5">
                                <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_342px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8">
                                    <StaffTab staff={staff} />
                                </div>
                                <div className="w-full xl:w-[306px] xls:w-[306px]  xl:ml-8 xls:ml-4 lg:ml-2 ml-0">
                                    <div className="flex flex-col">
                                        <div className="grid grid-cols-1 gap-4 mb-5">
                                            <div className="title-block">
                                                <p className="sideBarHeading">{t('Phone Number')}</p>
                                                <h3 className="sideBarSubHeading">{getFormatPhone(staff.phone, staff.phone_country_code)}</h3>
                                            </div>
                                            <div className="title-block">
                                                <p className="sideBarHeading">{t('Email')}</p>
                                                <h3 className=" text-primary text-xs font-medium flex items-center">{staff.email}</h3>
                                            </div>
                                            <div className="title-block">
                                                <p className="sideBarHeading">{t('Last active')}</p>
                                                <h3 className="sideBarSubHeading">{staff.login_at ? moment(staff.login_at, moment.ISO_8601).format(DATE_FORMAT) : '-'}</h3>
                                            </div>
                                            <div className="title-block">
                                                <p className="sideBarHeading">{t('Account created')}</p>
                                                <h3 className="sideBarSubHeading">{moment(staff.created_at, moment.ISO_8601).format(DATE_FORMAT)}</h3>
                                            </div>
                                        </div>
                                        <div className="border rounded-xl px-3 mb-5">
                                            <div className="flex py-3 gap-1">
                                                <div className="w-1/2">
                                                    <div className="flex justify-start items-center">
                                                        {/* <div className="bg-gray-100 rounded-md p-2 w-10 h-10">
                                                        <Percent03 className="text-gray-600 " />
                                                    </div> */}
                                                        <div className="title-block ">
                                                            <p className="text-xs font-normal leading-[18px] text-gray-600">{t('Payment Structure')}</p>
                                                            <h3 className="text-xs font-medium text-primary leading-[20px] capitalize">
                                                                {capitalizeFirstLetterAndUnderscore(staff.pay_structure.pay_structure_type)}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title-block w-1/2 flex justify-between items-center">
                                                    <div>
                                                        <p className="text-xs font-normal leading-[18px] text-gray-600 ">{t('Next payout in')}</p>
                                                        <h3 className="text-xs font-medium text-primary leading-[20px]">{'0 Days'}</h3>
                                                    </div>
                                                    {/* <div>
                                                    <ChevronDown className="text-gray-700 w-5 cursor-pointer" />
                                                </div> */}
                                                </div>
                                            </div>
                                            <div className="flex py-3 border-t gap-1">
                                                {(staff.pay_structure.pay_structure_type === 'rent' || staff.pay_structure.pay_structure_type === 'commission') && (
                                                    <div className="title-block w-1/2">
                                                        <p className="text-xs font-normal leading-[18px] text-gray-600 max-w">
                                                            {staff.pay_structure.pay_structure_type === 'rent' ? t('Rent Amount') : t('Commission Percentage')}
                                                        </p>
                                                        <h3 className="text-xs font-medium text-primary leading-[20px]">
                                                            {staff.pay_structure.pay_structure_type === 'rent' ? `$${staff.pay_structure.rent_money}` : `${staff.pay_structure.commission}%`}
                                                        </h3>
                                                    </div>
                                                )}
                                                <div className="title-block w-1/2">
                                                    <p className="text-xs font-normal leading-[18px] text-gray-600">{t('Current Payment Cycle')}</p>
                                                    <h3 className="text-xs font-medium text-primary leading-[20px]">{'-'}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="text-sm font-semibold mb-4 text-gray-900">{t('Working Locations')}</h6>
                                        <div className="flex flex-col gap-5">
                                            {staff.locations.map((location: any, index: number) => (
                                                <div key={location.id} className="rounded-xl border border-gray-200 p-3 flex flex-col relative">
                                                    <div className="flex items-center">
                                                        <div className="xlm:w-10 xlm:h-10 sm:w-9 w-7 sm:h-9 h-7 sm::mr-3 mr-2.5 flex-none ">
                                                            <img
                                                                src={location.profile_image_url || defaultImage}
                                                                alt=""
                                                                title={location.name}
                                                                onError={onError}
                                                                className=" h-full w-full object-cover rounded-full"
                                                            />
                                                        </div>
                                                        <div className="flex flex-col flex-1 truncate gap-[4px] ">
                                                            <p className="text-gray-900 font-semibold text-sm truncate max-w-[230px]">{location.name}</p>
                                                            <p className="text-gray-600 text-xs font-normal truncate  max-w-56">
                                                                {location.street}, {location.city}, {location.country}
                                                            </p>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={handleCollapse(index)}
                                                            className="text-3xl hover:border hover:border-gray-200 hover:bg-gray-50 h-[28px] w-[28px] shadow-sm flex justify-center items-center rounded-full p-1 border border-transparent"
                                                        >
                                                            {isShow[index] ? (
                                                                <ChevronDown className="text-gray-700 w-5 cursor-pointer" />
                                                            ) : (
                                                                <ChevronRight className="text-gray-700 w-5 cursor-pointer" />
                                                            )}
                                                        </button>
                                                    </div>
                                                    <div className={`flex flex-col gap-4 border-t pt-4 mt-4 ${!isShow[index] ? 'd-none' : ''}`}>
                                                        <span className="text-sm font-semibold text-gray-900">Working Hours</span>
                                                        {location.staff_working_hours.length > 0 ? (
                                                            <>
                                                                <div className="grid grid-cols-2 gap-4">
                                                                    {location.staff_working_hours.map((working_hour: any, index2: number) => (
                                                                        <div key={index2} className="w-full">
                                                                            <p className="text-xs font-normal text-gray-600 mb-1 leading-[18px] capitalize">{working_hour.day}</p>
                                                                            <p className="text-xs font-semibold text-gray-600">
                                                                                {working_hour.status
                                                                                    ? `${moment(working_hour.from, 'HH:mm:ss').format('hh:mmA')} - ${moment(working_hour.to, 'HH:mm:ss').format(
                                                                                          'hh:mmA',
                                                                                      )}`
                                                                                    : t('Closed')}
                                                                            </p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="flex justify-between">
                                                                    <span className="text-sm font-semibold text-gray-900">Frequency</span>
                                                                    <span>{moment.duration(location.staff_working_hours[0].frequency_booking).asMinutes().toFixed(2)} Minutes</span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <p className="text-xs font-normal text-secondaryTxtColor text-center">{t('Working hours not available')}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
            {action.delete && (
                <DeletePopupModal onClose={handleModalClose} size="w-[400px]" title={t('Delete Staff')} description={t('Are you sure you want to delete this staff? This action cannot be undone.')} />
            )}
            {action.active && (
                <DeletePopupModal
                    primary
                    size="w-[400px]"
                    confirmButtonText={t('Yes')}
                    headerIcon={staff.shop_staff[0].is_active ? <FileX03 className="w-6 h-6 text-gray-700" /> : <Trash01 className="w-6 h-6 text-gray-700" />}
                    cancelButtonText={t('No')}
                    title={t(`${staff.shop_staff[0].is_active ? 'Unlist' : 'List'} Staff`)}
                    description={t(`Are you sure you want to ${staff.shop_staff[0].is_active ? 'unlist' : 'list'} this staff?`)}
                    onClose={handleModalClose}
                />
            )}
        </div>
    );
};

export default StaffView;
