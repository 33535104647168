import React, { useMemo } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { ArrowDown } from '@untitled-ui/icons-react/build/cjs';

const SCurrentInventory = () => {
    const { t } = useTranslation();
    const renderHeader = (header: any, field: any) => {
        const activeSortIcon = <ArrowDown className="w-4 text-gray-600 opacity-50" />;
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };
    let inventoryArray = useMemo(
        () => [
            {
                size: 'loading',
                shop_locations: { name: 'loading' },
                total_booking_quantity: 'loading',
                total_amount: 'loading',
                total_net_amount: 'loading',
                in_stock_quantity: 'loading',
                action: 'loading',
            },
        ],
        [],
    );
    return (
        <>
            <div className="flex justify-between items-center gap-2 mb-3">
                <div>
                    <h2 className="view-table-title">{t('Current Inventory')}</h2>
                    <p className="view-table-subtitle">{t('List and manage the inventory of the product.')}</p>
                </div>
                <div className="flex gap-4">
                    <div className="table-searchInput">
                        <SearchBar placeholder={t('Search')} className="form-control-md " />
                    </div>
                    <div className="w-[200px] location-dropdown">
                        <SelectBox isDisabled={true} name="page" value={{ value: 'location', label: 'location' }} isClearable={false} />
                    </div>
                </div>
            </div>
            <DataTable value={Array(25).fill(inventoryArray).flat()} totalRecords={25} dataKey="id" className="rounded-b-md overflow-hidden" paginatorClassName="table-pagination">
                <Column
                    field="size"
                    header={renderHeader(t('Unit'), 'size')}
                    body={<Skeleton />}
                    style={{ minWidth: '80px', maxWidth: '80px', width: '80px' }}
                    className="text-xs text-gray-600 font-normal truncate"
                    sortable
                ></Column>
                <Column
                    field="shop_locations.name"
                    header={renderHeader(t('Locations'), 'shop_locations.name')}
                    style={{ minWidth: '120px', maxWidth: '120px', width: '120px' }}
                    body={<Skeleton />}
                    className="text-xs text-gray-600 font-normal truncate"
                    sortable
                ></Column>
                <Column
                    field="total_booking_quantity"
                    header={renderHeader(t('Total Sold'), 'total_booking_quantity')}
                    style={{ minWidth: '110px', maxWidth: '110px', width: '110px' }}
                    body={<Skeleton />}
                    className="text-xs text-gray-600 font-medium truncate"
                    sortable
                ></Column>
                <Column
                    field="total_amount"
                    header={renderHeader(t('Total Revenue'), 'total_amount')}
                    style={{ minWidth: '140px', maxWidth: '140px', width: '140px' }}
                    body={<Skeleton />}
                    className="text-xs font-medium text-tableText truncate"
                    sortable
                ></Column>
                <Column
                    field="total_net_amount"
                    header={renderHeader(t('Net Revenue'), 'total_net_amount')}
                    style={{ minWidth: '130px', maxWidth: '130px', width: '130px' }}
                    body={<Skeleton />}
                    className="text-xs text-gray-600 font-normal truncate"
                    sortable
                ></Column>
                <Column
                    field="in_stock_quantity"
                    header={renderHeader(t('In Stock'), 'in_stock_quantity')}
                    style={{ minWidth: '100px', maxWidth: '100px', width: '100px' }}
                    body={<Skeleton />}
                    className="text-xs font-medium text-tableText truncate"
                    sortable
                ></Column>
                <Column field="action" header={'Action'} body={<Skeleton />} style={{ minWidth: '70px', maxWidth: '70px', width: '70px' }} className="text-xs font-medium text-tableText"></Column>
            </DataTable>
        </>
    );
};

export default SCurrentInventory;
