import React, { FC, useEffect, useState } from 'react';
import { defaultImage } from 'src/theme/Images';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, currentShopMe, me, shopMe } from 'src/redux/services/common/Common.slice';
import moment from 'moment';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { IDataRequest, IProps } from './Invitation.interface';
import { onError } from 'src/utils/global-functions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { BackgroundDesignImage } from 'src/theme/Images';
import { DATE_FORMAT } from 'src/constants/common';
import useImageOrientation from 'src/hooks/useImageOrientation';
import StepProgressBar from 'src/components/StepProgressBar';
import Footer from 'src/app/Layout/Footer';

const Invitation: FC<IProps> = ({ setStep, inviteStaffShop, stepLenght, currentStep }) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const currentUser = useAppSelector(currentShopMe);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [shopDetail] = useState<any>(inviteStaffShop ?? shop);

    const handleAcceptInvitation = (status: boolean) => async () => {
        setIsLoading(true);
        const cread: IDataRequest = {
            shop_id: currentUser.pay_structure.shop_id,
            status: status,
        };
        await axiosPatch(API.USER.INVITATION, cread, { shop_id: cread.shop_id })
            .then(async (response) => {
                if (status) {
                    setStep(2);
                } else if (!status && inviteStaffShop) {
                    navigate(ROUTES.DASHBOARD);
                }
                await dispatch(me());
                await dispatch(shopMe({ shop_id: shopDetail.id }));
            })
            .finally(() => setIsLoading(false));
    };

    const handleStep = () => setStep(2);
    const handleDashboard = () => navigate(ROUTES.DASHBOARD);
    const { isImageHorizontal, isHorizontal } = useImageOrientation();

    useEffect(() => {
        if (shopDetail.logo_image_url) {
            isImageHorizontal(shopDetail.logo_image_url);
        }
    }, [shopDetail]);

    return (
        <>
            <main className="p-6 h-[calc(100vh-84px)] overflow-y-scroll flex items-center flex-col justify-center">
                <div className="bg-white mt-3 shadow-lg rounded-lg px-6 py-9 mx-auto border border-borderSecondary w-full max-w-[600px]  relative z-[1]">
                    <img src={BackgroundDesignImage} alt="" className="absolute top-0 z-[-1] left-[10%]" />

                    <div className="text-center mb-[30px] ">
                        <img
                            src={shopDetail.logo_image_url || defaultImage}
                            alt=""
                            title={shopDetail.business_name}
                            onError={onError}
                            className={`mx-auto rounded-xl mb-4 object-contain ${
                                isHorizontal ? 'w-[80px] min-w-[80px] max-w-[80px] h-[56px] object-contain' : ' w-[56px] min-w-[56px] max-w-[56px] h-[56px] object-contain'
                            } `}
                        />
                        <h1 className="text-2xl leading-[30px] xxl:text-3xl xxl:leading-[38px] font-semibold text-gray-900 mt-2">{shopDetail.business_name} Invitation</h1>
                        <p className="text-base font-normal mt-[2px] text-gray-600">View the terms of the invitation below.</p>
                    </div>
                    <div className="text-gray-900">
                        <div className="mb-1.5 flex justify-between px-5 py-4 border border-borderSecondary rounded-xl text-base ">
                            <div className="font-normal text-base flex text-gray-900">Invited by:</div>
                            <div className="font-medium text-base flex text-gray-900 capitalize">
                                {currentUser.shop_staff[0]?.created_by_id ? currentUser.shop_staff[0]?.created_by.full_name : '-'}
                            </div>
                        </div>
                        <div className="border border-borderSecondary rounded-xl">
                            <div className="py-[14px] flex justify-between border-b border-dashed border-borderSecondary mx-4 text-base">
                                <div className="font-normal text-base text-[#1E1E1E]">Payment type:</div>
                                <div className="capitalize-first font-medium text-[#1E1E1E] text-base">{currentUser.pay_structure.pay_structure_type}</div>
                            </div>
                            <div className="flex justify-between border-b border-dashed border-borderSecondary  py-[14px] mx-4 text-base">
                                <div className="font-normal text-base text-[#1E1E1E]">{currentUser.pay_structure.pay_structure_type === 'commission' ? 'Your Commission:' : 'Rent amount'}</div>
                                <div className="capitalize-first font-medium text-[#1E1E1E] text-base flex">
                                    {currentUser.pay_structure.pay_structure_type === 'commission' ? `${currentUser.pay_structure.commission}%` : `$${currentUser.pay_structure.rent_money}`}
                                </div>
                            </div>
                            <div className="flex justify-between border-b border-dashed border-borderSecondary  py-[14px] mx-4 text-base">
                                <div className="font-normal text-base text-[#1E1E1E]">Start date:</div>
                                <div className="capitalize-first font-medium text-[#1E1E1E] text-base flex">{moment(currentUser.pay_structure.start_date).format(DATE_FORMAT)}</div>
                            </div>
                            <div className="flex justify-between border-b border-dashed border-borderSecondary  py-[14px] mx-4 text-base">
                                <div className="font-normal text-base text-[#1E1E1E]">Payout frequency:</div>
                                <div className="capitalize-first font-medium text-[#1E1E1E] text-base flex ">
                                    {currentUser.pay_structure.pay_structure_type === 'commission'
                                        ? `Every ${currentUser.pay_structure.shop_payment.commission_frequency.replace(/_/g, '-')}`
                                        : `Every ${currentUser.pay_structure.shop_payment.rent_frequency.replace(/_/g, '-')}`}
                                </div>
                            </div>
                            <div className="flex justify-between border-b border-dashed border-borderSecondary  py-[14px] mx-4 text-base">
                                <div className="font-normal text-base text-[#1E1E1E]">Payment day:</div>
                                <div className="font-medium text-[#1E1E1E] text-base flex capitalize-first">
                                    {currentUser.pay_structure.pay_structure_type === 'commission'
                                        ? currentUser.pay_structure.shop_payment.commission_frequency === 'monthly'
                                            ? `${currentUser.pay_structure.shop_payment.commission_month_day} day of the month`
                                            : currentUser.pay_structure.shop_payment.commission_day
                                        : currentUser.pay_structure.shop_payment.rent_frequency === 'monthly'
                                        ? `${currentUser.pay_structure.shop_payment.rent_month_day} day of the month`
                                        : currentUser.pay_structure.shop_payment.rent_day}
                                </div>
                            </div>
                            <div className="flex justify-between py-[14px] mx-4 text-base">
                                <div className="font-normal text-base text-[#1E1E1E]">First paydate:</div>
                                <div className="capitalize-first font-medium text-[#1E1E1E] text-base flex">
                                    {currentUser.pay_structure.payday ? moment(currentUser.pay_structure.payday).format(DATE_FORMAT) : '-'}
                                </div>
                            </div>
                            {currentUser.pay_structure.pay_structure_type === 'rent' && (
                                <div className="flex justify-between py-[14px] mx-4 text-base">
                                    <div className="font-normal text-base text-[#1E1E1E]">Aggregated payment:</div>
                                    <div className="capitalize-first font-medium text-[#1E1E1E] text-base flex">${currentUser.pay_structure.payment}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    {!currentUser.shop_staff[0]?.is_declined_invitation && !currentUser.shop_staff[0]?.is_accepted_invitation ? (
                        <div className="flex gap-5 mt-[30px]">
                            <CustomButton onClick={handleAcceptInvitation(false)} isLoading={isLoading} disabled={isLoading} secondary className="w-full text-sm">
                                {t('Decline')}
                            </CustomButton>
                            <CustomButton onClick={handleAcceptInvitation(true)} isLoading={isLoading} disabled={isLoading} primary className="w-full text-sm">
                                {t('Accept')}
                            </CustomButton>
                        </div>
                    ) : currentUser.shop_staff[0]?.is_declined_invitation || ['invitation', 'profile', 'working_hours'].every((step) => currentUser.shop_staff[0]?.account_step[step]) ? (
                        <div className="flex justify-center mt-6">
                            <CustomButton onClick={handleDashboard} primary className="w-full text-sm">
                                {t('Dashboard')}
                            </CustomButton>
                        </div>
                    ) : (
                        <div className="flex justify-center mt-6">
                            <CustomButton onClick={handleStep} primary className="w-full text-sm">
                                {t('Continue')}
                            </CustomButton>
                        </div>
                    )}
                </div>
            </main>
            <div className="bottom-0 right-0 max-2xl:start-[420px] start-[480px] fixed">
                <div className="w-full bg-white">
                    <StepProgressBar steps={stepLenght} currentStep={currentStep} className="w-full mx-auto justify-center" />
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Invitation;
