import { CalendarMinus02 } from '@untitled-ui/icons-react/build/cjs';
import { Label, Radio } from 'flowbite-react';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { INotificationAction } from 'src/app/Notification/Notification.interface';
import InputWithToggle from 'src/components/InputWithToggle';
import PopupModal from 'src/components/PopupModal';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import { setCalendarStep, setData, updateAppointmentStatus } from '../../Calendar.slice';
import moment from 'moment';
import Loader from 'src/components/Loader/Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getShortName } from 'src/utils/global-functions';
interface CancelModalProps {
    isOpen: boolean;
    setIsOpen: (type: keyof INotificationAction, status: boolean) => void;
    bookingId: number;
}

const CancelModal: FC<CancelModalProps> = ({ isOpen, setIsOpen, bookingId }) => {
    const { t } = useTranslation();
    const shop: any = useAppSelector(currentShop);
    const [bookingInfo, setBookingInfo] = useState<any>(null);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const getBookingInfo = async (id: number) => {
        let payload = {
            shop_id: shop.id,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setBookingInfo(response.data.data);
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
            dispatch(setData({ isLoading: false }));
        }
    };
    useEffect(() => {
        getBookingInfo(bookingId);
    }, []);
    const Title = () => (
        <div className="flex flex-row gap-3">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <CalendarMinus02 className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900">Appointment Cancellation</p>
                <span className="text-xs font-normal text-gray-500">This appointment was cancelled by {bookingInfo?.user?.full_name}</span>
            </div>
        </div>
    );
    const schema = Yup.object().shape({
        charge: Yup.number()
            .nullable()
            .when('is_charge', ([is_charge], customSchema) => (is_charge ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        charge_type: Yup.string()
            .nullable()
            .when('is_charge', ([is_charge], customSchema) => (is_charge ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        is_charge: Yup.string().required('This field is required'),
    });
    const initData = {
        charge_type: '%',
        charge: 0,
        is_charge: 'false',
    };
    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: initData,
    });
    const handleClose = () => setIsOpen('cancelledAppointment', false);

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let payload: any = {
            status: 'canceled',
        };
        if (data.is_charge === 'true') {
            payload = {
                ...payload,
                charge_type: data.charge_type === '%' ? 'percentage' : 'value',
                charge: data.charge,
            };
        }
        const payloadData = {
            shop_id: shop.id,
            id: bookingInfo.id,
            payload: payload,
        };
        const result = await dispatch(updateAppointmentStatus(payloadData));
        if (result.type === updateAppointmentStatus.fulfilled.toString()) {
            await getBookingInfo(result.payload.data.id);

            const updateState = {
                bookedSlotInfo: result.payload.data,
                getBookingList: true,
            };

            dispatch(setData(updateState));
            dispatch(setCalendarStep('bookedAppointment'));
            handleClose();
        }
        if (result.type === updateAppointmentStatus.rejected.toString()) {
            const response = result.payload;
            if (response.status === errorCode.unprocessable) {
                toast.error(response.message);
            }
        }
        setIsLoading(false);
    };
    const handleInputChange = (e: any) => {
        setValue('charge_type', e);
    };
    const isChargeWatch = watch('is_charge');
    const chargeTypeWatch = watch('charge_type');
    const handleIsCharge = (value: any) => () => {
        if (value === 'false') {
            setValue('charge', initData.charge);
            setValue('charge_type', initData.charge_type);
        }
        setValue('is_charge', value);
    };

    return isOpen ? (
        <PopupModal
            dismissible
            onClose={handleClose}
            size="w-[700px]"
            className="px-5 py-5"
            title={<Title />}
            isLoading={isLoading}
            secondaryButton={t('Close')}
            primaryButton={t("Yes, I'm sure")}
            acceptAction={handleSubmit(onSubmit)}
            declineAction={handleClose}
        >
            {bookingInfo ? (
                <>
                    <div className="border rounded-xl border-[#EAECF0]">
                        <div className="flex flex-row justify-between px-5 py-4 gap-3">
                            <div className="flex flex-row gap-3">
                                <figure className=" h-10 w-10 rounded-full ">
                                    {bookingInfo.user.profile_image_url ? (
                                        <img src={bookingInfo.user.profile_image_url} alt="" className="w-10 h-10 rounded-full" />
                                    ) : (
                                        getShortName(`${bookingInfo.user.full_name}`)
                                    )}
                                </figure>
                                <div className="flex flex-col justify-between">
                                    <div className="text-sm font-semibold text-mainTextColor flex flex-row gap-2">
                                        {bookingInfo?.user?.full_name}
                                        <span className="border-[#B9E6FE] border bg-[#F0F9FF] rounded-md h-[22px] px-1.5 py-1 flex justify-center items-center text-xs font-normal text-[#026AA2]">
                                            Appointment #{bookingInfo?.receipt_code}
                                        </span>
                                    </div>
                                    <span className="text-xs font-normal text-gray-500 leading-[18px]">
                                        {' '}
                                        {moment(`${bookingInfo.booking_date} ${bookingInfo.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').format('dddd, MMMM Do, YYYY | h:mm A')}
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center text-xs font-medium leading-[18px] text-[#667085]">{bookingInfo.location.name}</div>
                        </div>
                        <table className="flex flex-col ">
                            <thead>
                                <tr className="w-full flex bg-gray-100 *:text-gray-600 *:pl-5 *:py-[13px] *:text-xs *:text-start *:font-medium">
                                    <th className="w-[25%]">{t('Team Member')}</th>
                                    <th className="w-[20%]">{t('Location')}</th>
                                    <th className="w-[20%]">{t('Service')}</th>
                                    <th className="w-[17.5%]">{t('Duration')}</th>
                                    <th className="w-[17.5%]">{t('Price')}</th>
                                </tr>
                            </thead>
                            <tbody className="flex flex-col min-w-full  max-h-[191px] overflow-hidden overflow-y-scroll scrollbar-hide">
                                {bookingInfo.booking_services?.map((service: any, index: number) => (
                                    <tr className="border-b last:border-0 border-gray-200 min-h-[64px] h-[64px] flex w-full" key={index}>
                                        <td className="py-[12px] pl-5 min-h-[64px] h-[64px] w-[25%]">
                                            <div className="flex flex-row gap-2">
                                                <figure className=" h-10 w-10 ">
                                                    {bookingInfo.staff.profile_image_url ? (
                                                        <img src={bookingInfo.staff.profile_image_url} alt="" className="w-10 h-10 rounded-full" />
                                                    ) : (
                                                        getShortName(`${bookingInfo.staff.full_name}`)
                                                    )}
                                                </figure>
                                                <div className="flex flex-col justify-between">
                                                    <div className="text-xs font-normal text-mainTextColor w-[94px] truncate">{bookingInfo.staff.full_name}</div>
                                                    <span className="text-xs font-normal text-gray-500 leading-[18px] w-[94px] truncate">{bookingInfo.staff.staff_role.name}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="py-[23px] pl-5 text-xs font-normal text-gray-500 w-[20%] leading-[18px]  ">
                                            <span className="truncate  block">{bookingInfo.location.name}</span>
                                        </td>
                                        <td className="py-[23px] pl-5 text-xs font-normal text-[#475467] w-[20%] leading-[18px]">
                                            <span className=" block truncate">{service.service.name}</span>
                                        </td>
                                        <td className="py-[23px] pl-5 text-xs font-normal text-gray-500 w-[17.5%] leading-[18px]">
                                            <span className="block truncate">{service.duration}</span>
                                        </td>
                                        <td className="py-[23px] pl-5 text-xs font-normal text-gray-500 w-[17.5%] leading-[18px] ">
                                            <span className="truncate  block">${service.price}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {bookingInfo.booking_products.length > 0 && (
                            <div className="flex flex-col border-t bg-gray-100 px-5 py-3 gap-1 rounded-b-xl">
                                <div className="flex">
                                    <div className="text-xs font-semibold leading-[18px] text-[#475467]">Products</div>
                                </div>

                                {bookingInfo.booking_products.map((product: any, index: number) => (
                                    <>
                                        <div className="flex justify-between gap-3 border-b-2 pb-1.5 border-[#EAECF0]">
                                            <div className="text-xs font-normal leading-[18px] text-mainTextColor">
                                                {product.product.name} <span className="text-[#475467] ml-1">{`(${product.product_variant.size} ${product.product.unit_of_measure})`}</span>
                                            </div>
                                            <div className="text-xs font-normal leading-[18px] text-[#475467]">${product.product_variant.price}</div>
                                        </div>
                                    </>
                                ))}

                                <div className="flex justify-between gap-3 mt-1">
                                    <div className="text-xs font-normal leading-[18px] text-[#475467]">Subtotal</div>
                                    <div className="text-xs font-normal leading-[18px] text-[#475467]">${bookingInfo.subtotal}</div>
                                </div>
                                <div className="flex justify-between gap-3">
                                    <div className="text-xs font-normal leading-[18px] text-[#475467]">fees</div>
                                    <div className="text-xs font-normal leading-[18px] text-[#475467]">${bookingInfo.taxes_and_fees}</div>
                                </div>
                                <div className="flex justify-between gap-3">
                                    <div className="text-xs font-semibold leading-[18px] text-[#475467]">Total</div>
                                    <div className="text-xs font-semibold leading-[18px] text-[#475467]">${bookingInfo.total}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mt-4 px-5 font-normal text-sm leading-[20px] text-mainTextColor">
                        This appointment was cancelled after the cancellation window making it eligible for a cancellation fee. Specify if you would like to charge for the cancellation.
                    </div>
                    <div className="border rounded-lg border-[#EAECF0] p-5 mt-4">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-2.5">
                                <div className="font-semibold text-sm leading-[20px] text-mainTextColor">Please select the charge amount</div>

                                <div className="flex flex-row gap-2">
                                    <Controller
                                        name={`is_charge`}
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div className={`flex  ${value === 'true' ? 'mb-2' : ''}`}>
                                                <div id="isCharge" className={` flex items-center `}>
                                                    <Radio
                                                        className="custom-round-radio h-4 w-4 !ring-0 focus:!ring-0 focus:!inset-0 focus:!outline-0 !outline-0 !focus:ring-offset-0 !ring-offset-0"
                                                        id="logo-type-logo"
                                                        name="isCharge"
                                                        value={'true'}
                                                        onChange={handleIsCharge('true')}
                                                        checked={value === 'true'}
                                                    />
                                                    <Label htmlFor="logo-type-logo" className="ml-2 cursor-pointer text-xs text-gray-700 leading-[18px] font-medium">
                                                        {t('Charge client')}
                                                    </Label>
                                                </div>

                                                <div className={` flex items-center ml-5`}>
                                                    <Radio
                                                        className="custom-round-radio h-4 w-4 !ring-0 focus:!ring-0 focus:!inset-0 focus:!outline-0 !outline-0 !focus:ring-offset-0 !ring-offset-0"
                                                        id="logo-type-logo-text"
                                                        name="isCharge"
                                                        value="false"
                                                        onChange={handleIsCharge('false')}
                                                        checked={value === 'false'}
                                                    />
                                                    <Label htmlFor="logo-type-logo-text" className="ml-2 cursor-pointer text-xs text-gray-700 leading-[18px] font-medium">
                                                        {t("Don't charge")}
                                                    </Label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                                {isChargeWatch === 'true' && (
                                    <Controller
                                        name="charge"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithToggle
                                                    label={t('Enter charge amount')}
                                                    type="number"
                                                    name="charge"
                                                    placeholder={t('Enter spend')}
                                                    toggleClassName="text-white bg-blue-500"
                                                    value={value}
                                                    toggleValue={chargeTypeWatch}
                                                    setselected={handleInputChange}
                                                    onChange={(e: any) => {
                                                        let amount = e.target.value;
                                                        if (amount.length > 1) {
                                                            amount = parseFloat(amount).toString();
                                                        }
                                                        onChange(amount);
                                                    }}
                                                    error={!!errors.refferer}
                                                />

                                                {error?.type && error.type !== 'required' && <p className="text-error">{error.message}</p>}

                                                {chargeTypeWatch === '%' && (
                                                    <span className="text-gray-600 font-normal text-xs leading-[18px]">
                                                        Charge amount <span className="font-semibold">${Number((+bookingInfo.subtotal - +bookingInfo.product_amount) * (value / 100)).toFixed(2)}</span>
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                )}
                            </div>
                            {/* <div className="flex flex-col gap-2.5 mt-5">
                                <div className="font-medium text-xs leading-[20px] text-mainTextColor">Enter charge amount</div>
                                <Controller
                                    name="charge"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className="w-full">
                                            <InputWithToggle type="number" name="charge" placeholder={t('Enter charge amount')} value={value} onChange={onChange} />
                                            {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </div> */}
                        </form>
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </PopupModal>
    ) : null;
};

export default CancelModal;
