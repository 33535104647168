import React, { FC, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { Column } from 'primereact/column';
import { ArrowUp, ArrowDown, Archive } from '@untitled-ui/icons-react/build/cjs';
import { PATH } from 'src/constants/path';
import { useNavigate } from 'react-router-dom';
import { IProductTable } from './Products.interface';
import { getShortName, onError } from 'src/utils/global-functions';
import { Skeleton } from 'primereact/skeleton';
import NoDataMessage from 'src/components/NoDataMessage';

const ProductsTable: FC<IProductTable> = ({ productListArray, totalProducts, lazyState, setLazyState, isLoading, filters }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleRowClick = (row: any) => {
        navigate(`${PATH.PRODUCT.VIEW}/${row.data.id}`);
    };

    const getFullName = (row: any) => {
        let url = null;
        const image = row.images.length ? row.images.find((item: any) => item.is_main) : null;
        if (image) {
            url = image.image_url;
        }
        return (
            <div className="flex items-center">
                <figure className="w-[40px] h-[40px] basis-[40px] grow-0 shrink-0 rounded-lg overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                    {url ? <img src={url} alt="" title={row.name} onError={onError} className="w-full h-full object-cover" /> : getShortName(row.name)}
                </figure>
                <div>
                    <p className="text-xs font-medium w-[270px] min-w-[270px]  max-w-[270px] 2xl:w-[320px] 2xl:max-w-[320px] 2xl:min-w-[320px] xlm:min-w-[420px] xlm:w-[420px] xlm:max-w-[420px] truncate ">
                        {row.name}
                    </p>
                </div>
            </div>
        );
    };

    const getPrice = (row: any) => (
        <div className="flex items-center">
            <p className="text-xs font-medium w-[150px] min-w-[150px]  max-w-[150px] 2xl:w-[180px] 2xl:max-w-[180px] 2xl:min-w-[180px] xlm:min-w-[200px] xlm:w-[200px] xlm:max-w-[200px] truncate">
                {row.variants.length > 1 ? `$${row.variants[0].price}+` : `$${row.variants[0].price}`}
            </p>
        </div>
    );

    const getBookingQuantity = (row: any) => (row.total_booking_quantity ? row.total_booking_quantity : 0);

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                pay_structure: { pay_structure_type: 'loading' },
                login_at: 'loading',
                users_count: 'loading',
                new_users_count: 'loading',
                active_users_count: 'loading',
                first_name: 'loading',
                status: 'loading',
            })),
        [lazyState],
    );

    return (
        <>
            <DataTable
                lazy
                paginatorTemplate={customPaginatorTemplate}
                value={isLoading ? skeletons : productListArray}
                totalRecords={totalProducts}
                paginator={!isLoading && totalProducts > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                sortOrder={lazyState.sortOrder}
                sortField={lazyState.sortField}
                filters={lazyState.filters}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                onRowClick={handleRowClick}
                selectionMode="single"
                dataKey="id"
                className=" rounded-b-xl overflow-hidden"
                paginatorClassName="table-pagination"
                emptyMessage={
                    <NoDataMessage
                        title={`${filters.global.value ? 'No products found' : 'No products added.'}`}
                        description={`${filters.global.value ? 'Try adjusting your filters or add new products.' : 'Get started by adding your first product.'}`}
                        iconComponent={<Archive className="text-gray-700" />}
                    />
                }
            >
                <Column
                    field="name"
                    header={renderHeader(t('Product name'), 'name')}
                    body={isLoading ? <Skeleton /> : getFullName}
                    className="font-medium text-mainTextColor truncate"
                    style={{ width: '280px', minWidth: '280px', maxWidth: '280px' }}
                    sortable
                ></Column>
                <Column
                    field="total_booking_quantity"
                    header={renderHeader(t('Total order'), 'total_booking_quantity')}
                    className="text-xs leading-[18px] font-medium text-gray-600 truncate"
                    body={isLoading ? <Skeleton /> : getBookingQuantity}
                    style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                    sortable
                ></Column>
                <Column
                    field="variants_count"
                    header={renderHeader(t('Price'), 'variants_count')}
                    className="text-xs leading-[18px] font-medium text-gray-600 truncate"
                    body={isLoading ? <Skeleton /> : getPrice}
                    style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                    sortable
                ></Column>
                <Column
                    field="in_stock_quantity"
                    header={renderHeader(t('In stock'), 'in_stock_quantity')}
                    className="text-xs leading-[18px] font-medium text-gray-600  !text-start truncate"
                    body={isLoading ? <Skeleton /> : undefined}
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    sortable
                ></Column>
            </DataTable>
        </>
    );
};

export default ProductsTable;
