import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import StripeSuccess from './StripeSuccess';
import { Background1Design, StripeConnect1, StripeIcon } from 'src/theme/Images';
import { onError } from 'src/utils/global-functions';

let newWindow: any;

const StripeConnect = ({ setStep }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(userMe);
    const [isLoading, setIsLoading] = useState(false);
    const [isStripeVerified, setIsStripeVerified] = useState('');
    const shop = useAppSelector(currentShop);
    const [action, setAction] = useState({
        stripe: false,
    });
    const handleModalClose = useCallback(() => {
        setAction({
            stripe: false,
        });
    }, []);

    const handleWindowClose = (message: any) => {
        if (message === 'stripe_connect_verified') {
            //
        } else if (message === 'stripe_connect_not_verified') {
            //
        }
        newWindow.close();
        setIsStripeVerified(message);
    };
    useEffect(() => {
        if (isStripeVerified === 'stripe_connect_verified' || isStripeVerified === 'stripe_connect_pending') {
            setAction({
                stripe: true,
            });
        }
    }, [isStripeVerified]);

    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.source === newWindow) {
                handleWindowClose(event.data);
            }
        });
        return () => {
            newWindow = null;
            window.removeEventListener('message', handleWindowClose);
        };
    }, []);

    useEffect(() => {
        const userStaff = user.shop_staff[0];
        const stripeAccount = userStaff.account_step.stripe_account;
        switch (stripeAccount) {
            case true:
                setIsStripeVerified('stripe_connect_verified');
                break;
            case 'pending':
                setIsStripeVerified('stripe_connect_pending');
                break;
            default:
                setIsStripeVerified('stripe_connect_not_verified');
                break;
        }
    }, [user]);

    const getStripeAccount = async () => {
        await axiosGet(API.USER.GET_STRIPE_ACCOUNT).then((response) => {
            const url = response.data.data.url;
            const height = 800;
            const width = 1200;
            const leftPosition = window.screen.width / 2 - (width / 2 + 10);
            const topPosition = window.screen.height / 2 - (height / 2 + 50);
            newWindow = window.open(
                url,
                'Window2',
                `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`,
            );
        });
    };

    const connect = async () => {
        setIsLoading(true);
        await axiosGet(API.USER.VERIFY_STRIPE_ACCOUNT)
            .then(async (response) => {
                await dispatch(me());
                setAction({ stripe: true });
            })
            .catch(async (error) => {
                if (isStripeVerified === 'stripe_connect_pending') {
                    await dispatch(me());
                    setAction({ stripe: true });
                } else if (error.response.status === errorCode.unprocessable) {
                    getStripeAccount();
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <>
            <div className="flex flex-1 flex-col items-center text-center w-[750px] mt-5">
                <h3 className="text-2xl font-semibold mb-2 leading-[38px] text-[#101828]">{t('Connect to Stripe')}</h3>
                <div className="text-sm font-normal mb-4 text-[#475467]">
                    {t('Link your account to Stripe for secure onboarding. Follow the guided steps to connect your bank account, configure payment preferences, and start accepting payments.')}
                </div>
                <div className="h-[228px] min-h-[228px] w-full mt-4 mb-6 relative">
                    <img src={Background1Design} alt="" className="flex justify-center h-[228px] ml-[40px] relative" />
                    <div className="absolute top-[64px] left-[108px] flex flex-row justify-center items-center">
                        <div
                            className="bg-white border border-borderSecondary -mr-1.5 rounded-[20px] drop-shadow-md  w-[186px] h-[100px] flex justify-center items-center"
                            style={{ boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.08)' }}
                        >
                            <div className=" h-20 w-20 rounded-md  object-contain flex justify-center items-center">
                                <img src={shop.logo_image_url} alt="" title={shop.name} onError={onError} />
                            </div>
                        </div>

                        <div className="bg-transparent z-10 ">
                            <img src={StripeConnect1} alt="" className="" />
                        </div>
                        <div
                            className="bg-white -ml-1.5 border border-borderSecondary rounded-[20px]   w-[186px] h-[100px] flex justify-center items-center"
                            style={{ boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.08)' }}
                        >
                            <div className=" h-20  rounded-md">
                                {/* <img src={StripeIcon} alt="" /> */}
                                <StripeIcon />
                            </div>
                        </div>
                    </div>
                </div>

                <CustomButton primary onClick={connect} isLoading={isLoading} className=" w-[177px] !font-semibold !text-base rounded-lg shadow-InputAndButton">
                    Connect
                </CustomButton>
                <div className="border rounded-2xl border-[#EAECF0] p-6 w-full mt-6 mb-7">
                    <div className="flex w-full flex-row justify-between border-b gap-4 pb-4">
                        <div className="flex flex-col justify-start text-start w-[80%] gap-2">
                            <div className="text-sm font-semibold text-[#101828]">Standard</div>
                            <div className="text-sm font-normal text-[#475467]">
                                Access a complete payments platform with simple, pay-as-you-go pricing. No setup fees, monthly fees, or hidden fees.
                            </div>
                        </div>
                        <div className="flex flex-col items-end text-right w-[20%]">
                            <div className="w-max text-xs font-medium px-1.5  flex justify-center items-center rounded-full border border-[#ABEFC6] text-[#067647] bg-[#ECFDF3]">2.9% + C$0.30</div>
                            <div className="text-sm font-normal text-[#475467]">per successful charge for domestic cards</div>
                        </div>
                    </div>
                    <div className="flex w-full flex-row justify-between border-b gap-4 pt-4 pb-4">
                        <div className="flex flex-col justify-start text-start w-[80%] gap-2">
                            <div className="text-sm font-semibold text-[#101828]">Enable payouts to your bank account</div>
                            <div className="text-sm font-normal text-[#475467]">
                                Fresha is the only subscription-free software in the beauty and wellness industry! No contracts or commitments, you can cancel at any time.
                            </div>
                        </div>
                        <div className="flex flex-col items-end text-right w-[20%]">
                            <div className="w-max text-xs font-medium px-1.5  flex justify-center items-center rounded-full border border-[#ABEFC6] text-[#067647] bg-[#ECFDF3]">FREE</div>
                        </div>
                    </div>
                    <div className="flex w-full flex-row justify-between border-b gap-4 pt-4 pb-4">
                        <div className="flex flex-col justify-start text-start w-[80%] gap-2">
                            <div className="text-sm font-semibold text-[#101828]">Enable payouts to your bank account</div>
                            <div className="text-sm font-normal text-[#475467]">Get paid with free daily payouts, no more waiting.</div>
                        </div>
                        <div className="flex flex-col items-end text-right w-[20%]">
                            <div className="w-max text-xs font-medium px-1.5  flex justify-center items-center rounded-full border border-[#ABEFC6] text-[#067647] bg-[#ECFDF3]">FREE</div>
                        </div>
                    </div>
                    <div className="flex w-full flex-row justify-between border-b gap-4 pt-4 pb-4">
                        <div className="flex flex-col justify-start text-start w-[80%] gap-2">
                            <div className="text-sm font-semibold text-[#101828]">Enable payouts to your bank account</div>
                            <div className="text-sm font-normal text-[#475467]">A fee for card-present transactions using Fresha terminals.</div>
                        </div>
                        <div className="flex flex-col items-end text-right w-[20%]">
                            <div className="w-max text-xs font-medium px-1.5  flex justify-center items-center rounded-full border border-[#FEDF89] text-[#B54708] bg-[#FFFAEB]">CHARGED</div>
                        </div>
                    </div>
                    <div className="flex w-full flex-row justify-between border-b gap-4 pt-4 pb-4">
                        <div className="flex flex-col justify-start text-start w-[80%] gap-2">
                            <div className="text-sm font-semibold text-[#101828]">Enable payouts to your bank account</div>
                            <div className="text-sm font-normal text-[#475467]">A fee for card transactions made where the card is not present.</div>
                        </div>
                        <div className="flex flex-col items-end text-right w-[20%]">
                            <div className="w-max text-xs font-medium px-1.5  flex justify-center items-center rounded-full border border-[#FEDF89] text-[#B54708] bg-[#FFFAEB]">CHARGED</div>
                        </div>
                    </div>
                    <div className="flex w-full flex-row justify-between border-b gap-2 pt-4 pb-4">
                        <div className="flex flex-col justify-start text-start w-[80%] gap-2">
                            <div className="text-sm font-semibold text-[#101828]">Enable payouts to your bank account</div>
                            <div className="text-sm font-normal text-[#475467]">An additional fee when using tap to pay on mobile devices.</div>
                        </div>
                        <div className="flex flex-col items-end text-right w-[20%]">
                            <div className="w-max text-xs font-medium px-1.5  flex justify-center items-center rounded-full border border-[#FEDF89] text-[#B54708] bg-[#FFFAEB]">CHARGED</div>
                        </div>
                    </div>
                    <div className="flex w-full flex-row justify-center  gap-4 pt-4 pb-0">
                        <div className="flex flex-col justify-center text-center  gap-2">
                            <div className="text-sm font-normal text-[#475467]">Rates are exclusive of 5% sales tax.</div>
                            <div className="text-sm font-normal text-primary">Learn more about fees and commissions.</div>
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-row gap-2">
                    <CustomButton primary onClick={() => setStep(3)} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                        next step
                    </CustomButton>
                    <CustomButton secondary onClick={() => setStep(1)} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                        back step
                    </CustomButton>
                </div> */}
                {action.stripe && <StripeSuccess size="max-w-[610px]" setStep={setStep} handleClose={handleModalClose} className="p-[68px] pt-[21px]" />}
            </div>
        </>
    );
};

export default StripeConnect;
