import React, { FC, memo } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DURATION_FILTER_OPTIONS, IToolbar, STATUS_OPTIONS } from './Calendar.Interface';
import moment from 'moment-timezone';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectCalendarData, setData } from './Calendar.slice';
import { FilterLines } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import DatePickerWithArrow from 'src/components/DatePickerWithArrow/DatePickerWithArrow';
import CustomButton from 'src/components/CustomButton';
import { allShopLocations } from 'src/redux/services/common/Common.slice';

const Toolbar: FC<IToolbar> = ({ onNavigate, date, onView, view, handleAppointmentStatus, filter, staffOptions }) => {
    const calendarData: any = useAppSelector(selectCalendarData);
    const shopLocations: any = useAppSelector(allShopLocations);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleChange = (type: string) => (event: any) => {
        switch (type) {
            case 'view':
                onView(event.value);
                dispatch(
                    setData({
                        view: event.value,
                    }),
                );
                break;
            case 'staff':
                dispatch(setData({ selectedStaff: event }));
                break;
            case 'filter':
                handleAppointmentStatus(event);
                break;
            case 'location':
                dispatch(setData({ selectedLocation: event }));
                break;
            case 'date':
                onNavigate('DATE', event);
                break;
            case 'TODAY':
            case 'PREV':
            case 'NEXT':
                onNavigate(type);
                break;
        }
    };
    return (
        <div className="flex items-center mb-5 z-[99] justify-between sticky top-[98px] xxl:top-[102px] bg-white gap-4 ">
            <div className="flex items-center">
                <CustomButton outlinePrimary className="mr-4" onClick={handleChange('TODAY')}>
                    Today
                </CustomButton>
                {/* <div
                    className="flex items-center justify-center min-w-[36px] w-[36px] h-[36px] rounded-lg border border-gray-300 mr-1 shadow-InputAndButton hover:border-black"
                    role="button"
                    tabIndex={0}
                    onClick={handleChange('PREV')}
                >
                    <HiChevronLeft size={20} className="text-gray-700" />
                </div>
                <div
                    className="flex items-center justify-center min-w-[36px] w-[36px] h-[36px] rounded-lg border border-gray-300 mr-1 shadow-InputAndButton hover:border-black"
                    role="button"
                    tabIndex={0}
                    onClick={handleChange('NEXT')}
                >
                    <HiChevronRight size={20} className="text-gray-700" />
                </div> */}
                <DatePickerWithArrow
                    inputName="start_date"
                    className={`datepicker_control min-h-[34px] font-medium text-xs relative`}
                    placeholder={t('Select date')}
                    dateFormat="EEEE, MMMM do, yyyy"
                    isFormate={false}
                    InputClass="min-w-[280px]"
                    labelClassName="mb-0"
                    // InputClass={`${fieldState.error && 'is-invalid'}`}
                    value={moment(date).toDate()}
                    onChangeValue={handleChange('date')}
                />
            </div>
            <div className="flex gap-[10px] openCalendarSpacing relative">
                <div className="2xl:w-[200px] w-[180px]">
                    <SelectBox
                        isSearchable={false}
                        name="location"
                        id="location"
                        options={shopLocations}
                        classComp="outline-select-box"
                        value={calendarData.selectedLocation}
                        onChangeFunc={handleChange('location')}
                        // menuIsOpen
                        openOnFocus={true}
                        autofocus={true}
                        isClearable={false}
                    />
                </div>
                <div className="2xl:w-[200px] w-[180px]">
                    <SelectBox
                        name="team"
                        id="team"
                        options={staffOptions}
                        value={calendarData.selectedStaff}
                        onChangeFunc={handleChange('staff')}
                        classComp="outline-select-box"
                        isClearable={false}
                        isSearchable={false}
                    />
                </div>
                <div className="2xl:w-[91px] w-[91px]">
                    <SelectBox
                        name="view"
                        id="view"
                        value={DURATION_FILTER_OPTIONS.find((option) => option.value === view)}
                        options={DURATION_FILTER_OPTIONS}
                        onChangeFunc={handleChange('view')}
                        classComp="outline-select-box"
                        isClearable={false}
                        isSearchable={false}
                    />
                </div>
                <div className="relative">
                    <div className="w-[37px]">
                        <SelectBox
                            name="filter"
                            id="filter"
                            value={STATUS_OPTIONS.filter((option) => filter.includes(option.value))}
                            options={STATUS_OPTIONS}
                            onChangeFunc={handleChange('filter')}
                            classComp="outline-select-box"
                            isClearable={false}
                            isMulti={true}
                            isMultiIcon={true}
                            labelIcon={
                                <div className="relative close-btn cursor-pointer rounded-lg">
                                    <FilterLines className="w-[18px] text-gray-700" />
                                </div>
                            }
                            isSearchable={false}
                        />
                    </div>
                    {/* {filter.length > 0 && <GoDotFill size={12} className="h-5 w-5 absolute !z-[99] -top-2 -right-1  rounded-full flex  text-[#D92D20]" />} */}
                </div>

                {/* <div className="relative close-btn cursor-pointer rounded-lg">
                    <FilterFunnel02 className="w-[18px] text-gray-700" />
                </div> */}
            </div>
        </div>
    );
};
export default memo(Toolbar);
