import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PATH } from 'src/constants/path';
import CustomButton from 'src/components/CustomButton';
import { ROUTES } from 'src/constants/routes';
import { ArrowLeft, DotsVertical, Trash01, Edit02, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentRole, currentShop } from 'src/redux/services/common/Common.slice';
import moment from 'moment';
import Map from 'src/components/Map/Map';
import { onError } from 'src/utils/global-functions';
import LocationBooking from './LocationBooking';
import { defaultImage } from 'src/theme/Images';
import Badge from 'src/components/Badge';
import { initAction } from './Location.interface';
import { Dropdown } from 'flowbite-react';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { usePhone } from 'src/hooks/usePhone';
import SLocationDetails from '../Skeleton/Location/SLocationDetails';
import { ROLES } from 'src/constants/common';
import { selectStaffLocation } from './Location.slice';
import ViewStaffLocation from './ViewStaffLocation';
import NoDataMessage from 'src/components/NoDataMessage';

const scriptId = 'google-maps-api';

const ViewLocation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const staffLocationlisting = useAppSelector(selectStaffLocation);
    const role = useAppSelector(currentRole);
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const { getFormatPhone } = usePhone();
    const [isLoading, setIsLoading] = useState(true);
    const [location, setLocation] = useState<any>(null);
    const [address, setAddress] = useState(null);
    const [status, setStatus] = useState(document.getElementById(scriptId) ? true : false);
    const [action, setAction] = useState(initAction);

    window.initMap = () => {
        setStatus(true);
    };

    useEffect(() => {
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places,marker&loading=async&callback=initMap`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    }, []);

    useEffect(() => {
        if (id) {
            if (role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT) {
                getStaffLocation();
            } else {
                getLocation();
            }
        }
    }, [id, shop, staffLocationlisting]);

    const getLocation = async () => {
        setIsLoading(true);
        await axiosGet(API.LOCATION.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;
                setLocation(data);
            })
            .catch(() => navigate(ROUTES.LOCATION.LIST))
            .finally(() => setIsLoading(false));
    };
    const handleNavigation = (route: string) => () => {
        navigate(route);
    };

    const getStaffLocation = async () => {
        setIsLoading(true);
        if (staffLocationlisting.length) {
            const matchingStaffLocation = await staffLocationlisting.find((locationData: any) => locationData.id === Number(id));
            setLocation(matchingStaffLocation);
        }
        setIsLoading(false);
    };

    const handleEdit = () => {
        navigate(`${PATH.LOCATION.UPDATE}/${id}`);
    };

    const onReverseAddressFunc = (value: any) => {
        setAddress(value.fullAddress);
    };

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleModalClose = useCallback(
        (type: boolean = false) =>
            async () => {
                if (type) {
                    setIsLoading(true);
                    const params = {
                        shop_id: shop.id,
                        id,
                    };
                    await axiosDelete(API.LOCATION.DELETE, {}, params)
                        .then(() => navigate(ROUTES.LOCATION.LIST))
                        .finally(() => setIsLoading(false));
                }
                setAction(initAction);
            },
        [action],
    );

    const handleHoursClose = useCallback(() => {
        setAction(initAction);
    }, []);

    const renderTrigger = () => (
        <div>
            <DotsVertical className="custom-icon z-20" fill="#475467" />
        </div>
    );

    return (
        <div className="inner-page-wrape !px-0">
            <div className="flex-1 flex flex-col mb-4">
                <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                    <div className="flex items-center justify-between w-full">
                        <Link to={ROUTES.LOCATION.LIST} className="flex items-center text-gray-600 h-[36px] text-xs font-semibold gap-1.5 group hover:text-gray-800">
                            <ArrowLeft className="w-4 text-gray-600 group-hover:text-gray-800" /> {t('Back to Location')}
                        </Link>
                        {role.name !== ROLES.STAFF_COMMISSION && role.name !== ROLES.STAFF_RENT && (
                            <div className="z-[1] location_menu">
                                <Dropdown label="" renderTrigger={renderTrigger} className="w-[200px] border border-gray-200 rounded-lg">
                                    <Dropdown.Item icon={Trash01} onClick={handleAction('delete')} className="text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-50">
                                        <span>Delete</span>
                                    </Dropdown.Item>
                                </Dropdown>
                            </div>
                        )}
                    </div>
                </div>
                {isLoading ? (
                    <SLocationDetails />
                ) : (
                    location && (
                        <div className="flex flex-col flex-1">
                            <div className="xlm:px-8 px-5 relative mb-[26px] w-full xl:pt-[10px] pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                                <img
                                    src={location.profile_image_url || defaultImage}
                                    alt=""
                                    title={location.name}
                                    onError={onError}
                                    className="max-2xl:w-[112px] max-2xl:h-[112px] w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                                />
                                <div className="flex justify-between items-center w-full">
                                    <div>
                                        <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px] ">{location.name}</h1>
                                        <div className="flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center">
                                            {/* <MarkerPin01 className="h-4 w-4" /> */}
                                            <p className="text-sm font-normal text-secondaryTxtColor">{address}</p>
                                        </div>
                                    </div>
                                    {role.name !== ROLES.STAFF_COMMISSION && role.name !== ROLES.STAFF_RENT && (
                                        <div>
                                            <CustomButton outlinePrimary onClick={handleEdit} className="!py-2 !px-3">
                                                {t('Edit Location')}
                                            </CustomButton>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="border-t border-[#EAECF0] py-[30px] bg-white flex flex-wrap flex-1 xlm:px-8 px-5">
                                <div className="flex flex-col xl:w-[calc(100%_-_370px)] xls:w-[calc(100%_-_342px)]  w-full xl:mr-8 xls:mr-4 lg:mr-2 mr-0 xls:mb-0 mb-8">
                                    <div className={`${role.name !== ROLES.STAFF_COMMISSION && role.name !== ROLES.STAFF_RENT ? 'flex justify-between mb-6 pb-5 border-b' : ' grid grid-cols-3 pb-6'}`}>
                                        <div className="title-block">
                                            <p className="location-card-heading ">{t('Contact number')}</p>
                                            <h3 className="location-card-heading-detail ">{getFormatPhone(location.phone, location.phone_country_code)}</h3>
                                        </div>
                                        {role.name !== ROLES.STAFF_COMMISSION && role.name !== ROLES.STAFF_RENT && (
                                            <>
                                                <div className="title-block">
                                                    <p className="location-card-heading ">{t('Default currency')}</p>
                                                    <h3 className="location-card-heading-detail ">{location.currency}</h3>
                                                </div>
                                                <div className="title-block">
                                                    <p className="location-card-heading">{t('Time zone')}</p>
                                                    <h3 className="location-card-heading-detail ">{location.timezone}</h3>
                                                </div>
                                            </>
                                        )}
                                        <div className="title-block">
                                            <p className="location-card-heading">Total Clients</p>
                                            <h3 className="location-card-heading-detail ">{location.total_users}</h3>
                                        </div>
                                        <div className="title-block">
                                            <p className="location-card-heading">Active Clients</p>
                                            <h3 className="location-card-heading-detail ">0</h3>
                                        </div>
                                    </div>
                                    {role.name !== ROLES.STAFF_COMMISSION && role.name !== ROLES.STAFF_RENT && (
                                        <>
                                            <div className="mb-8">
                                                <div className="flex justify-start items-center gap-[6px] mb-3">
                                                    <h2 className="text-lg font-semibold">{t('Team Members')}</h2>
                                                    <Badge icon>{location.shop_staff.length} Members</Badge>
                                                </div>

                                                {location.shop_staff && location.shop_staff.length > 0 ? (
                                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 justify-start items-start gap-4">
                                                        {location.shop_staff.map((staff: any) => (
                                                            <div key={staff.id} className="w-full hover:shadow flex items-center gap-3 border rounded-lg p-3 border-btnborder shadow-InputAndButton">
                                                                <img src={staff.profile_image_url || defaultImage} alt="" className="w-[40px] h-[40px] rounded-full object-cover" onError={onError} />
                                                                <div>
                                                                    <p className="location-card-heading-detail truncate w-full max-w-[220px]">{staff.full_name}</p>
                                                                    <p className="header_notification_menu_profile_date truncate">{staff.staff_role.name}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <NoDataMessage
                                                        title="No Team Members Found."
                                                        description={'Start by adding your brand’s first Team Member and make it available for client bookings.'}
                                                        iconComponent={<SearchLg className="text-gray-700" />}
                                                        buttonText={t('Add Team Member')}
                                                        onButtonClick={handleNavigation(ROUTES.STAFF.CREATE)}
                                                    />
                                                )}
                                            </div>
                                            <div className="mb-8">
                                                <div className="flex justify-start items-center gap-[6px] mb-3">
                                                    <h2 className="text-lg font-semibold">{t('Services')}</h2>
                                                    <Badge icon>{location.services.length} Services</Badge>
                                                </div>
                                                {location.services && location.services.length > 0 ? (
                                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 justify-start items-start gap-4">
                                                        {location.services.map((service: any) => (
                                                            <div key={service.id} className="w-full  hover:shadow border rounded-lg p-3 border-btnborder shadow-InputAndButton">
                                                                <div className="flex justify-start items-center gap-[6px] mb-0.5">
                                                                    <p className="text-gray-900 font-semibold text-sm truncate">{service.name}</p>
                                                                    {service.type === 'variable' && <Badge icon>{service.variables.length} Options</Badge>}
                                                                </div>
                                                                <p className="header_notification_menu_profile_date truncate">
                                                                    {service.type === 'variable'
                                                                        ? `Includes ${service.variables.map((variable: any) => variable.name).join(', ')}`
                                                                        : service.description}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="border border-gray-200 px-5 p-5 flex-col rounded-xl flex justify-center items-center">
                                                        <NoDataMessage
                                                            title="No Services Found."
                                                            description={'Start by adding your brand’s first service and make it available for client bookings.'}
                                                            iconComponent={<SearchLg className="text-gray-700" />}
                                                            buttonText={t('Add Service')}
                                                            onButtonClick={handleNavigation(ROUTES.SERVICES.CREATE)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className="mb-8">
                                        <LocationBooking location={location} />
                                    </div>
                                </div>
                                <div className="w-full xl:w-[306px] xls:w-[306px]  xl:ml-8 xls:ml-4 lg:ml-2 ml-0">
                                    {status && (
                                        <div className="mb-4">
                                            <Map id="address" isCurrentLocation latitude={location.latitude} longitude={location.longitude} onReverseAddressFunc={onReverseAddressFunc} />
                                        </div>
                                    )}
                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col">
                                            <h6 className="sideBarHeading">{t('Address')}</h6>
                                            <p className="sideBarSubHeading">{address}</p>
                                        </div>
                                        {role.name !== ROLES.STAFF_COMMISSION && role.name !== ROLES.STAFF_RENT && (
                                            <>
                                                <div>
                                                    <h6 className="sideBarHeading">{t('Tax Details')}</h6>
                                                    <div className="w-full border border-btnborder rounded-md p-3 mb-3">
                                                        <p className="card_heading">{t('Business tax ID')}</p>
                                                        <p className="card_sub_heading">{location.tax_id}</p>
                                                    </div>
                                                    <div className="flex justify-between gap-3">
                                                        <div className="w-full border border-btnborder rounded-md p-3">
                                                            <p className="card_heading">{t('GST')}</p>
                                                            <p className="card_sub_heading">%{location.sales_tax.gst}</p>
                                                        </div>
                                                        <div className="w-full border border-btnborder rounded-md p-3">
                                                            <p className="card_heading">{t('PST')}</p>
                                                            <p className="card_sub_heading">%{location.sales_tax.pst}</p>
                                                        </div>
                                                        <div className="w-full border border-btnborder rounded-md p-3">
                                                            <p className="card_heading">{t('HST')}</p>
                                                            <p className="card_sub_heading">%{location.sales_tax.hst}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6 className="text-sm font-semibold mb-[10px]">{t('Default Tipping Options')}</h6>
                                                    <div className="flex justify-start gap-3">
                                                        {location.tips.length > 0 &&
                                                            location.tips.map((tip: string, index: number) => (
                                                                <div key={index} className="w-full border border-blue-300 rounded-md py-2 px-3">
                                                                    <p className="text-sm font-medium">{tip}%</p>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div>
                                            <div className="flex justify-between items-center mb-4">
                                                <h6 className="text-sm font-semibold leading-[24px]">{t('Working Hours')}</h6>
                                                {(role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT) && (
                                                    <span onClick={handleAction('WorkingHours')} className="cursor-pointer">
                                                        <Edit02 className="text-primary w-5 h-5" />
                                                    </span>
                                                )}
                                            </div>
                                            <div className="grid grid-cols-2 justify-start gap-4">
                                                {role.name === ROLES.STAFF_COMMISSION || role.name === ROLES.STAFF_RENT
                                                    ? location.staff_working_hours?.length > 0 &&
                                                      location.staff_working_hours.map((working_hour: any, index: number) => (
                                                          <div key={index} className="w-full">
                                                              <p className="header_notification_menu_profile_date mb-1 capitalize">{working_hour.day}</p>
                                                              <p className="text-xs font-semibold text-gray-600">
                                                                  {working_hour.status
                                                                      ? `${moment(working_hour.from, 'HH:mm:ss').format('hh:mmA')} - ${moment(working_hour.to, 'HH:mm:ss').format('hh:mmA')}`
                                                                      : t('Closed')}
                                                              </p>
                                                          </div>
                                                      ))
                                                    : location.working_hours?.length > 0 &&
                                                      location.working_hours.map((working_hour: any, index: number) => (
                                                          <div key={index} className="w-full">
                                                              <p className="header_notification_menu_profile_date mb-1 capitalize">{working_hour.day}</p>
                                                              <p className="text-xs font-semibold text-gray-600">
                                                                  {working_hour.status
                                                                      ? `${moment(working_hour.from, 'HH:mm:ss').format('hh:mmA')} - ${moment(working_hour.to, 'HH:mm:ss').format('hh:mmA')}`
                                                                      : t('Closed')}
                                                              </p>
                                                          </div>
                                                      ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
            {action.delete && (
                <DeletePopupModal
                    onClose={handleModalClose}
                    size="w-[400px]"
                    title={t('Delete Location')}
                    description={t('Are you sure you want to delete this location? This action cannot be undone.')}
                />
            )}
            {action.WorkingHours && <ViewStaffLocation handleClose={handleHoursClose} />}
        </div>
    );
};

export default ViewLocation;
